import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { GET } from "../../../services/AxiosService";
import Dropdown from "react-bootstrap/Dropdown";

const FormPencapaian = () => {
  const { id } = useParams(); // Ambil student_id dari URL
  const [modulData, setModulData] = useState("");
  const [tabs, setTabs] = useState([]);
  const [topic, setTopic] = useState([]);
  const [activeTab, setActiveTab] = useState("semua");
  const [selectedTopik, setSelectedTopik] = useState("");
  const [selectedNameTopik, setSelectedNameTopik] = useState("Semua Topik");
  const [formData, setFormData] = useState({
    student_name: "",
    learning_level: "",
    student_class: "",
    finish: "",
    from: "",
    topic_achievement_global: "",
    line_achievement_global: "",
    repeat_all_topic: "",
    repeat_topic: "",
    repeat_all_line: "",
    repeat_line: "",
    nilai_kuis_latin: "",
    pengulangan_kuis_latin: "",
    nilai_kuis_hijaiyah: "",
    pengulangan_kuis_hijaiyah: "",
    all_finish: "",
    repeat_all_topic: "",
    repeat_all_line: "",
    all_nilai_kuis_latin: "",
    all_pengulangan_kuis_latin: "",
    all_nilai_kuis_hijaiyah: "",
    all_pengulangan_kuis_hijaiyah: "",
    all_topic_achievement: "",
    topic_achievement: "",
    all_line_achievement: "",
    speed: "",
    line_achievement: "",
    all_speed: "",
  });

  const fetchData = async (id) => {
    try {
      const res = await GET(`/materi/detail-pencapaian-student/${id}`, {
        topicId: selectedTopik,
        modulId: modulData,
      });
      console.log("res", res);
      setTopic(res?.topicOptions);
      setFormData({
        student_name: res?.student_name,
        learning_level: res?.learning_level,
        student_class: res?.student_class,
        finish: res?.pencapaian?.finish || 0,
        from: res?.pencapaian?.from || "",
        repeat_all_topic: res?.pencapaian?.repeat_all_topic,
        repeat_topic: res?.pencapaian?.repeat_topic,
        repeat_all_line: res?.pencapaian?.repeat_all_line,
        repeat_line: res?.pencapaian?.repeat_line,
        nilai_kuis_latin: res?.pencapaian?.nilai_kuis_latin,
        pengulangan_kuis_latin: res?.pencapaian?.pengulangan_kuis_latin,
        nilai_kuis_hijaiyah: res?.pencapaian?.nilai_kuis_hijaiyah,
        pengulangan_kuis_hijaiyah: res?.pencapaian?.pengulangan_kuis_hijaiyah,
        all_finish: res?.pencapaian?.all_finish,
        repeat_all_topic: res?.pencapaian?.repeat_all_topic,
        repeat_all_line: res?.pencapaian?.repeat_all_line,
        all_nilai_kuis_latin: res?.pencapaian?.all_nilai_kuis_latin,
        all_pengulangan_kuis_latin: res?.pencapaian?.all_pengulangan_kuis_latin,
        topic_achievement_global: res?.pencapaian?.topic_achievement_global,
        all_nilai_kuis_hijaiyah: res?.pencapaian?.all_nilai_kuis_hijaiyah,
        all_pengulangan_kuis_hijaiyah: res?.pencapaian?.all_pengulangan_kuis_hijaiyah,
        line_achievement_global: res?.pencapaian?.line_achievement_global,
        topic_achievement: res?.materis?.[0]?.topic_achievement || 0,
        all_topic_achievement: res?.materis?.[0]?.all_topic_achievement || 0,
        speed: res?.materis?.[0]?.speed || 0,
        line_achievement: res?.materis?.[0]?.line_achievement || 0,
        all_speed: res?.materis?.[0]?.all_speed || 0,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTabs = async () => {
    try {
      const res = await GET("/materi/modul");
      setTabs(res);
    } catch (error) {
      console.error("Error fetching tabs:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
      fetchTabs();
    }
  }, [id, modulData, selectedTopik]);

  const handleTabClick = (tab, id) => {
    setActiveTab(tab);
    setModulData(id);
    setSelectedTopik("");
    setSelectedNameTopik("Semua Topik"); // Set the new modulData (id)
  };

  const handleSelectTopik = (e) => {
    setSelectedTopik(e.target.getAttribute("value"));
    setSelectedNameTopik(e.target.textContent);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">Pencapaian Pelajar</h4>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-5">
                  <label>Nama Pelajar</label>
                  <input type="text" className="form-control" name="student_name" placeholder="Nama Pelajar" value={formData.student_name || ""} readOnly />
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <label>Level</label>
                  <input type="text" className="form-control" name="learning_level" placeholder="Level" value={formData.learning_level || ""} readOnly />
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="row mb-3">
                <div className="col-md-5">
                  <label>Kelas</label>
                  <input type="text" className="form-control" name="student_class" placeholder="Kelas" value={formData.student_class || ""} readOnly />
                </div>
              </div>
              <br></br>
              {/* Tabs Navigation */}
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className={`nav-item nav-link ${activeTab === "semua" ? "active" : ""}`} onClick={() => handleTabClick("semua")}>
                    Semua
                  </button>
                  {tabs.map((tab) => (
                    <button key={tab.id} className={`nav-item nav-link ${activeTab === tab.volume ? "active" : ""}`} onClick={() => handleTabClick(tab.volume, tab.id)} style={{ backgroundColor: tab.color }}>
                      {tab.volume}
                    </button>
                  ))}
                </div>
              </nav>

              {/* Tab Content */}
              <div className="tab-content mt-3" id="nav-tabContent">
                {activeTab === "semua" && (
                  <div className="tab-pane fade show active" id="nav-semua" role="tabpanel">
                    <div className="card-header d-flex">
                      <h4 className="card-title">Semua Volume</h4>
                    </div>
                    <div className="card-body">
                      <div className="row mb-3">
                        {/* Pencapaian (Pelajar) */}
                        <div className="col-md-5">
                          <h4>
                            <b>Pencapaian {formData.student_name}</b>
                          </h4>
                          <div className="form-group">
                            <label>Pencapaian Topik</label>
                            <input
                              type="text"
                              className="form-control"
                              name="finish"
                              value={`${formData.finish}/${formData.from}`}
                              // placeholder="17/86"
                            />
                          </div>
                          <div className="form-group">
                            <label>Rata-Rata Pengulangan Topik</label>
                            <input
                              type="text"
                              className="form-control"
                              name="repeat_all_topic"
                              value={formData.repeat_all_topic}
                              // placeholder="3.4"
                            />
                          </div>
                          <div className="form-group">
                            <label>Rata-Rata Pengulangan Baris</label>
                            <input type="text" className="form-control" name="repeat_all_line" value={formData.repeat_all_line} placeholder="3.4" />
                          </div>
                        </div>
                        <div className="col-md-1"></div>
                        {/* Pencapaian Rata-Rata Siswa */}
                        <div className="col-md-5">
                          <h4>
                            <b>Pencapaian Rata-Rata Siswa</b>
                          </h4>
                          <div className="form-group">
                            <label>Pencapaian Topik</label>
                            <input
                              type="text"
                              className="form-control"
                              value={`${formData.all_finish}/${formData.from}`}
                              // placeholder="66/86"
                            />
                          </div>
                          <div className="form-group">
                            <label>Rata-Rata Pengulangan Topik</label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.topic_achievement_global}
                              // placeholder="3.4"
                            />
                          </div>
                          <div className="form-group">
                            <label>Rata-Rata Pengulangan Baris</label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.line_achievement_global}
                              // placeholder="3.4"
                            />
                          </div>
                        </div>
                        <div className="col-md-1"></div>
                      </div>
                    </div>
                  </div>
                )}
                {tabs
                  .filter((tab) => tab.volume === activeTab)
                  .map((tab) => (
                    <div key={tab.id} className="tab-pane fade show active">
                      {tab.volume === "Pengenalan Awal" && (
                        <div className="tab-pane fade show active" id="nav-pengenalan" role="tabpanel">
                          <div className="card-header d-flex">
                            <h4 className="card-title">Pengenalan Awal</h4>
                          </div>
                          <div className="card-body">
                            {/* Dropdown Topic */}
                            <div className="form-group mb-3">
                              <Dropdown className="mr-4">
                                <Dropdown.Toggle
                                  style={{
                                    background: "transparent",
                                    borderColor: "grey",
                                    color: "grey",
                                    // borderRadius: "0",
                                  }}
                                  id="dropdown-basic"
                                  width="200px"
                                >
                                  {selectedTopik?.length > 0 ? selectedNameTopik : "Semua Topik"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item key="all" value="" onClick={handleSelectTopik}>
                                    Semua Topik
                                  </Dropdown.Item>
                                  {Array.isArray(topic) &&
                                    topic.map((topicType) => (
                                      <Dropdown.Item key={topicType.id} value={topicType.id} onClick={handleSelectTopik}>
                                        {topicType.name}
                                      </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                              </Dropdown>
                              {/* <select className="form-control" style={{ width: '150px' }} onChange={handleTopicChange}>
                                    <option value="all">Semua Topic</option>
                                    <option value="topic1">Topic 1</option>
                                    <option value="topic2">Topic 2</option>
                                    <option value="topic3">Topic 3</option>
                                </select> */}
                            </div>
                            {/* Conditional rendering based on selected topic */}
                            {selectedNameTopik === "Semua Topik" ? (
                              <>
                                {/* All Topic */}
                                <div className="row mb-3">
                                  {/* Pencapaian (Pelajar) */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Laura C</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pencapaian Topik/Surat</label>
                                      <input type="text" className="form-control" value={`${formData.finish} / ${formData.from}`} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  {/* Pencapaian Rata-Rata Siswa */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Rata-Rata Siswa</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pencapaian Topik/Surat</label>
                                      <input type="text" className="form-control" value={""} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                                <div className="row mb-3">
                                  {/* Rata-Rata Pengulangan */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Rata-Rata Pengulangan</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Per Topik/Surat</label>
                                      <input type="text" className="form-control" value={formData.repeat_topic} />
                                    </div>
                                    <div className="form-group">
                                      <label>Per Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.repeat_line} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  <div className="col-md-5">
                                    <br></br>
                                    <div className="form-group">
                                      <label>Per Topik/Surat</label>
                                      <input type="text" className="form-control" value={formData.repeat_all_topic} />
                                    </div>
                                    <div className="form-group">
                                      <label>Per Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.repeat_all_line} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                                <div className="row mb-3">
                                  {/* Hasil Kuis Latin */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Hasil Kuis Latin</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Nilai Kuis</label>
                                      <input type="text" className="form-control" value={formData.nilai_kuis_latin} />
                                    </div>
                                    <div className="form-group">
                                      <label>Pengulangan Kuis</label>
                                      <input type="text" className="form-control" value={formData.pengulangan_kuis_latin} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  <div className="col-md-5">
                                    <br></br>
                                    <div className="form-group">
                                      <label>Nilai Kuis</label>
                                      <input type="text" className="form-control" value={formData.all_nilai_kuis_latin} />
                                    </div>
                                    <div className="form-group">
                                      <label>Pengulangan Kuis</label>
                                      <input type="text" className="form-control" value={formData.all_pengulangan_kuis_latin} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                                <div className="row mb-3">
                                  {/* Hasil Kuis Hijaiyah */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Hasil Kuis Hijaiyah</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Nilai Kuis</label>
                                      <input type="text" className="form-control" value={formData.nilai_kuis_hijaiyah} />
                                    </div>
                                    <div className="form-group">
                                      <label>Pengulangan Kuis</label>
                                      <input type="text" className="form-control" value={formData.pengulangan_kuis_hijaiyah} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  <div className="col-md-5">
                                    <br></br>
                                    <div className="form-group">
                                      <label>Nilai Kuis</label>
                                      <input type="text" className="form-control" value={formData.all_nilai_kuis_hijaiyah} />
                                    </div>
                                    <div className="form-group">
                                      <label>Pengulangan Kuis</label>
                                      <input type="text" className="form-control" value={formData.all_pengulangan_kuis_hijaiyah} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </>
                            ) : (
                              <>
                                {/* Topic Option Selected */}
                                <div className="row mb-3">
                                  {/* Pencapaian (Pelajar) */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Laura C</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pengulangan Topik/Surat params</label>
                                      <input type="text" className="form-control" value={formData.topic_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Pengulangan Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.line_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Kecepatan</label>
                                      <input type="text" className="form-control" value={formData.speed} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  {/* Pencapaian Rata-Rata Siswa */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Rata-Rata Siswa</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pengulangan Topik</label>
                                      <input type="text" className="form-control" value={formData.all_topic_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Pengulangan Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.all_topic_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Kecepatan</label>
                                      <input type="text" className="form-control" value={formData.all_speed} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {tab.volume === "Langkah Awal 1" && (
                        <div className="tab-pane fade show active" id="nav-langkah1" role="tabpanel">
                          <div className="card-header d-flex">
                            <h4 className="card-title">Langkah Awal 1</h4>
                          </div>
                          <div className="card-body">
                            {/* Dropdown Topic */}
                            <div className="form-group mb-3">
                              <Dropdown className="mr-4">
                                <Dropdown.Toggle
                                  style={{
                                    background: "transparent",
                                    borderColor: "grey",
                                    color: "grey",
                                    // borderRadius: "0",
                                  }}
                                  id="dropdown-basic"
                                  width="200px"
                                >
                                  {selectedTopik?.length > 0 ? selectedNameTopik : "Semua Topik"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item key="all" value="" onClick={handleSelectTopik}>
                                    Semua Topik
                                  </Dropdown.Item>
                                  {Array.isArray(topic) &&
                                    topic.map((topicType) => (
                                      <Dropdown.Item key={topicType.id} value={topicType.id} onClick={handleSelectTopik}>
                                        {topicType.name}
                                      </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                              </Dropdown>
                              {/* <select className="form-control" style={{ width: '150px' }} onChange={handleTopicChange}>
                                    <option value="all">Semua Topic</option>
                                    <option value="topic1">Topic 1</option>
                                    <option value="topic2">Topic 2</option>
                                    <option value="topic3">Topic 3</option>
                                </select> */}
                            </div>
                            {/* Conditional rendering based on selected topic */}
                            {selectedNameTopik === "Semua Topik" ? (
                              <>
                                {/* All Topic */}
                                <div className="row mb-3">
                                  {/* Pencapaian (Pelajar) */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Laura C</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pencapaian Topik/Surat</label>
                                      <input type="text" className="form-control" value={`${formData.finish} / ${formData.from}`} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  {/* Pencapaian Rata-Rata Siswa */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Rata-Rata Siswa</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pencapaian Topik/Surat</label>
                                      <input type="text" className="form-control" value={""} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                                <div className="row mb-3">
                                  {/* Rata-Rata Pengulangan */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Rata-Rata Pengulangan</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Per Topik/Surat</label>
                                      <input type="text" className="form-control" value={formData.repeat_topic} />
                                    </div>
                                    <div className="form-group">
                                      <label>Per Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.repeat_line} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  <div className="col-md-5">
                                    <br />
                                    <div className="form-group">
                                      <label>Per Topik/Surat</label>
                                      <input type="text" className="form-control" value={formData.repeat_all_topic} />
                                    </div>
                                    <div className="form-group">
                                      <label>Per Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.repeat_all_line} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </>
                            ) : (
                              <>
                                {/* Topic Option Selected */}
                                <div className="row mb-3">
                                  {/* Pencapaian (Pelajar) */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Laura C</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pengulangan Topik/Surat</label>
                                      <input type="text" className="form-control" value={formData.topic_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Pengulangan Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.line_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Kecepatan</label>
                                      <input type="text" className="form-control" value={formData.speed} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  {/* Pencapaian Rata-Rata Siswa */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Rata-Rata Siswa</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pengulangan Topik</label>
                                      <input type="text" className="form-control" value={formData.all_topic_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Pengulangan Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.all_topic_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Kecepatan</label>
                                      <input type="text" className="form-control" value={formData.all_speed} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {tab.volume === "Langkah Awal 2" && (
                        <div className="tab-pane fade show active" id="nav-langkah2" role="tabpanel">
                          <div className="card-header d-flex">
                            <h4 className="card-title">Langkah Awal 2</h4>
                          </div>
                          <div className="card-body">
                            {/* Dropdown Topic */}
                            <div className="form-group mb-3">
                              <Dropdown className="mr-4">
                                <Dropdown.Toggle
                                  style={{
                                    background: "transparent",
                                    borderColor: "grey",
                                    color: "grey",
                                    // borderRadius: "0",
                                  }}
                                  id="dropdown-basic"
                                  width="200px"
                                >
                                  {/* {selectedTypeBuku?.length > 0 ? selectedNameTypeBuku : 'Semua Tipe Buku'} */}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item key="all" value="" onClick={""}>
                                    Semua Topic
                                  </Dropdown.Item>
                                  {Array.isArray(topic) &&
                                    topic.map((topicType) => (
                                      <Dropdown.Item key={topicType.id} value={topicType.id} onClick={""}>
                                        {topicType.name}
                                      </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            {/* Conditional rendering based on selected topic */}
                            {selectedTopik === "all" ? (
                              <>
                                {/* All Topic */}
                                <div className="row mb-3">
                                  {/* Pencapaian (Pelajar) */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Laura C</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pencapaian Topik/Surat</label>
                                      <input type="text" className="form-control" value={`${formData.finish} / ${formData.from}`} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  {/* Pencapaian Rata-Rata Siswa */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Rata-Rata Siswa</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pencapaian Topik/Surat</label>
                                      <input type="text" className="form-control" value={""} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                                <div className="row mb-3">
                                  {/* Rata-Rata Pengulangan */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Rata-Rata Pengulangan</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Per Topik/Surat</label>
                                      <input type="text" className="form-control" value={formData.repeat_topic} />
                                    </div>
                                    <div className="form-group">
                                      <label>Per Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.repeat_line} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  <div className="col-md-5">
                                    <br />
                                    <div className="form-group">
                                      <label>Per Topik/Surat</label>
                                      <input type="text" className="form-control" value={formData.repeat_all_topic} />
                                    </div>
                                    <div className="form-group">
                                      <label>Per Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.repeat_all_line} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </>
                            ) : (
                              <>
                                {/* Topic Option Selected */}
                                <div className="row mb-3">
                                  {/* Pencapaian (Pelajar) */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Laura C</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pengulangan Topik/Surat</label>
                                      <input type="text" className="form-control" value={formData.topic_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Pengulangan Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.line_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Kecepatan</label>
                                      <input type="text" className="form-control" value={formData.speed} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  {/* Pencapaian Rata-Rata Siswa */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Rata-Rata Siswa</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pengulangan Topik</label>
                                      <input type="text" className="form-control" value={formData.all_topic_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Pengulangan Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.all_topic_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Kecepatan</label>
                                      <input type="text" className="form-control" value={formData.all_speed} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {tab.volume === "Al Qur'an" && (
                        <div className="tab-pane fade show active" id="nav-quran" role="tabpanel">
                          <div className="card-header d-flex">
                            <h4 className="card-title">Al Quran</h4>
                          </div>
                          <div className="card-body">
                            {/* Dropdown Topic */}
                            <div className="form-group mb-3">
                              <Dropdown className="mr-4">
                                <Dropdown.Toggle
                                  style={{
                                    background: "transparent",
                                    borderColor: "grey",
                                    color: "grey",
                                    // borderRadius: "0",
                                  }}
                                  id="dropdown-basic"
                                  width="200px"
                                >
                                  {selectedTopik?.length > 0 ? selectedNameTopik : "Semua Topik"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item key="all" value="" onClick={handleSelectTopik}>
                                    Semua Topik
                                  </Dropdown.Item>
                                  {Array.isArray(topic) &&
                                    topic.map((topicType) => (
                                      <Dropdown.Item key={topicType.id} value={topicType.id} onClick={handleSelectTopik}>
                                        {topicType.name}
                                      </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                              </Dropdown>
                              {/* <select className="form-control" style={{ width: '150px' }} onChange={handleTopicChange}>
                                    <option value="all">Semua Topic</option>
                                    <option value="topic1">Topic 1</option>
                                    <option value="topic2">Topic 2</option>
                                    <option value="topic3">Topic 3</option>
                                </select> */}
                            </div>
                            {/* Conditional rendering based on selected topic */}
                            {selectedNameTopik === "Semua Topik" ? (
                              <>
                                {/* All Topic */}
                                <div className="row mb-3">
                                  {/* Pencapaian (Pelajar) */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Laura C</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pencapaian Topik/Surat</label>
                                      <input type="text" className="form-control" value={`${formData.finish} / ${formData.from}`} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  {/* Pencapaian Rata-Rata Siswa */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Rata-Rata Siswa</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pencapaian Topik/Surat</label>
                                      <input type="text" className="form-control" value={""} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                                <div className="row mb-3">
                                  {/* Rata-Rata Pengulangan */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Rata-Rata Pengulangan</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Per Topik/Surat</label>
                                      <input type="text" className="form-control" value={formData.repeat_topic} />
                                    </div>
                                    <div className="form-group">
                                      <label>Per Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.repeat_line} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  <div className="col-md-5">
                                    <br />
                                    <div className="form-group">
                                      <label>Per Topik/Surat</label>
                                      <input type="text" className="form-control" value={formData.repeat_all_topic} />
                                    </div>
                                    <div className="form-group">
                                      <label>Per Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.repeat_all_line} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </>
                            ) : (
                              <>
                                {/* Topic Option Selected */}
                                <div className="row mb-3">
                                  {/* Pencapaian (Pelajar) */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Laura C</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pengulangan Topik/Surat</label>
                                      <input type="text" className="form-control" value={formData.topic_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Pengulangan Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.line_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Kecepatan</label>
                                      <input type="text" className="form-control" value={formData.speed} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                  {/* Pencapaian Rata-Rata Siswa */}
                                  <div className="col-md-5">
                                    <h4>
                                      <b>Pencapaian Rata-Rata Siswa</b>
                                    </h4>
                                    <div className="form-group">
                                      <label>Pengulangan Topik</label>
                                      <input type="text" className="form-control" value={formData.all_topic_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Pengulangan Baris/Ayat</label>
                                      <input type="text" className="form-control" value={formData.all_topic_achievement} />
                                    </div>
                                    <div className="form-group">
                                      <label>Kecepatan</label>
                                      <input type="text" className="form-control" value={formData.all_speed} />
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormPencapaian;
