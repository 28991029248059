import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET } from '../../../services/AxiosService';
import Swal from 'sweetalert2';

const JadwalKelas = () => {
  const navigate= useNavigate();
  const router = useNavigate();
  const [data, setData] = useState([]);
  const [periode, setPeriode] = useState([]);
  const [selectedPeriode, setSelectedPeriode] = useState('');
  const [selectedNamePeriod, setSelectedNamePeriod] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");

  const fetchData = async (page, perPage, sortField, sortOrder, search, period) => {
    let periodData = periode; 
    if (!period) {
      const resPeriode = await GET(`master-data/period`);
      periodData = resPeriode?.data;
      setPeriode(resPeriode?.data);
    }
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      period: period?.length > 0 ? period : periodData[0]?.name,
    };
    const res = await GET(`/schedules`, params);
    setData(res?.data);
    setTotalRows(res?.amount);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedPeriode);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedPeriode]);

  const columns = [
    {
      name: 'Nama Kelas',
      selector: row => row.class_name,
      sortable: true,
      field: "name",
    },
    {
      name: 'Kota/Kabupaten',
      selector: row => row.nama_kota_kabupaten,
    },
    {
      name: 'Lokasi',
      selector: row => row.name_location,
    },
    {
      name: 'Nama Pengajar',
      selector: row => row.assessor,
      cell: (row) => (
        <div>
          {row.assessor?.map((assessors, index) => (
            <span key={assessors.id}>
              {assessors.name}{index < row.assessor.length - 1 ? ', ' : ''}
            </span>
          ))}
        </div>
      )
    },
    {
      name: 'Nama Pelajar',
      selector: row => row.students,
      cell: (row) => (
        <div>
          {row.students?.map((student, index) => (
            <span key={student.id}>
              {student.name}{index < row.students.length - 1 ? ', ' : ''}
            </span>
          ))}
        </div>
      )
    },
    {
      name: 'Kegiatan',
      selector: row => row.title,
    },
    {
      name: 'Hari',
      selector: row => row.days,
      cell: (row) => (
        <div>
          {row.days?.map((day, index) => (
            <span key={day.id}>
              {day.day}{index < row.days.length - 1 ? ', ' : ''}
            </span>
          ))}
        </div>
      )
    },
    {
      name: 'Waktu (WIB)',
      selector: row => row.time_start,
      cell: (row) => (
        <div>{row.time_start} - {row.time_finish}</div>
      )
    },
    {
      name: 'Aksi',
      selector: row => row.id,
      width: "130px",
      cell: (row) => (
        <div>
          <button
            className="btn btn-primary shadow btn-xs sharp"
            onClick={() => navigate(`/${row.id}/form-jadwal/Ubah`)}
            title="Edit"
          >
            <i className="fa fa-pencil"></i>
          </button>
          <Link className="btn btn-danger shadow btn-xs sharp mx-1" title="Delete" onClick={() => handleDeleteClick(row.id)}>
            <i className="fa fa-trash"></i>
          </Link>
        </div>
      ),
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleSelectPeriode = (e) => {
    setSelectedPeriode(e.target.getAttribute('value'));
    setSelectedNamePeriod(e.target.textContent);
  };

  // Set default selected period on component load
  useEffect(() => {
    if (periode?.length > 0 && !selectedPeriode) {
      const defaultPeriod = periode.find((item) => item.status === 1);
      if (defaultPeriod) {
        setSelectedPeriode(defaultPeriod.name);
        setSelectedNamePeriod(defaultPeriod.name);
      }
    }
  }, [periode, selectedPeriode]); // Add selectedPeriod as a dependency

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/schedules/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus jadwal kelas!",
        }).then(() => {
          Swal.close();
            const defaultPeriod = periode.find((item) => item.status === 1);
            if (defaultPeriod) {
              setSelectedPeriode(defaultPeriod.name);
              setSelectedNamePeriod(defaultPeriod.name);
            }
        });
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Jadwal Kelas</Card.Title>
            </Card.Header>
            <div className="d-flex mt-4">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                    {selectedPeriode?.length > 0 ? selectedNamePeriod : 'Semua Periode'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {Array.isArray(periode) && (periode).map((periodes) => (
                      <Dropdown.Item key={periodes.id} value={periodes.name} onClick={handleSelectPeriode}>
                        {periodes.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end px-4">
                <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-jadwal/Tambah`)}>
                  Tambah Jadwal
                </button>
                <div className="input-group search-area d-xl-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari Jadwal Kelas"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                  defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default JadwalKelas