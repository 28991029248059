import React from "react";
import { Link } from "react-router-dom";

const PageTitle = ({ pageHeading, motherMenu, activeMenu }) => {
	let path = window.location.pathname.split("/");
	const getMotherMenusPath = (menu) => {
        if (menu === "Verifikasi Pelajar Tuna Netra") {
            return "verifikasi-pelajar";
        } else if (menu === "Verifikasi Pendamping") {
            return "verifikasi-pendamping";
        } else {
            return menu.toLowerCase().replace(/ /g, "-");
        }
    };

	return (
		<div className="page-titles">
			<h4>{activeMenu}{pageHeading}</h4>
			<ol className="breadcrumb">
				<li className="breadcrumb-item">
					<Link to={`/${getMotherMenusPath(motherMenu)}`}>{motherMenu}</Link>
				</li>
				<li className="breadcrumb-item active">
					{activeMenu}
				</li>
			</ol>
		</div>
	);
};

export default PageTitle;
