/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link, useParams, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import Select from "react-select";

const FormPengguna = () => {
  const { id, flag } = useParams();
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    hp_no: "",
    email: "",
    name: "",
    password: "",
    password_confirmation: "",
    userRole: [{ role_id: "" }],
  });
  console.log("formData", formData.email);
  const [role, setRole] = useState([]);
  const [site, setSite] = useState([]);
  const [siteSelectShow, setSiteSelectShow] = useState([false]);
  const [addButtonShow, setAddButtonShow] = useState(false);
  const [element, setElement] = useState([0]);
  const navigate = useNavigate();

  const fetchData = async () => {
    const res = await GET(`user/web/${id}`);
    console.log("email", res.data.email);
    // setFormData(res?.data);
    setFormData({
      hp_no: res?.data?.hp_no,
      email: res?.data?.email,
      name: res?.data?.name,
      password: res?.data?.password, // Directly use the object { id, name }
      password_confirmation: res?.data?.password_confirmation,
      userRole: res?.data?.userRole,
    });
    setSiteSelectShow(res?.data.userRole.map((x) => x.role.site === 1));
    setElement(res?.data.userRole.map((x, idx) => idx));
    if (flag !== "detail") {
      setAddButtonShow(true);
    }
  };

  const fetchDataRole = async () => {
    const res = await GET(`role`);
    setRole(res?.data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)));
  };

  const fetchDataSite = async () => {
    const res = await GET(`site`);
    setSite(res?.data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)));
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "hp_no" || name === "password" || name === "password_confirmation") {
      value = value.replace(/\D/g, "");
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  // const handleRoleChange = (e, idx) => {
  //   const { name, value } = e.target;
  //   const current = formData.userRole.map((x, index) => {
  //     if (idx === index) {
  //       x.role_id = value;
  //     }
  //     return x;
  //   });
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: current,
  //   }));

  //   const needSite = role.filter(x => x.id === value)[0]?.site === 1;
  //   const temp = siteSelectShow.map((x, index) => {
  //     return idx === index ? needSite : x;
  //   });
  //   setSiteSelectShow(temp);
  //   setAddButtonShow(!needSite);

  //   setValidated(!needSite);
  // };

  // const handleSiteChange = (e, idx) => {
  //   const { options } = e.target;
  //   const value = [];
  //   for (let i = 0, l = options.length; i < l; i++) {
  //     if (options[i].selected) {
  //       value.push(options[i].value);
  //     }
  //   }
  //   const current = formData.userRole.map((x, index) => {
  //     if (idx === index) {
  //       x.userRoleSites = value.map(r => {
  //         return { site_id: r };
  //       });
  //     }
  //     return x;
  //   });
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     'userRole': current,
  //   }));

  //   const form = e.currentTarget.form;
  //   const isValid = form.checkValidity();

  //   setValidated(isValid);

  //   if (isValid) {
  //     form.classList.remove("was-validated");
  //   } else {
  //     form.classList.add("was-validated");
  //   }

  //   setAddButtonShow(value.length > 0);
  // };

  // const handleSelectChange = (selectedOptions, name) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: selectedOptions.map(option => option.value),
  //   }));
  // };
  const handleSelectChange = (selectedOptions, name) => {
    const newRoles = selectedOptions.map((option) => {
      const selectedRole = role.find((x) => x.id === option.value);
      return {
        role_id: option.value,
        userRoleSites: selectedRole && selectedRole.site === 1 ? [] : null,
      };
    });

    const newSiteSelectShow = selectedOptions.map((option) => {
      const selectedRole = role.find((x) => x.id === option.value);
      return selectedRole && selectedRole.site === 1;
    });

    setFormData((prevData) => ({
      ...prevData,
      [name]: newRoles,
    }));

    setSiteSelectShow(newSiteSelectShow);
  };

  const handleSiteChange = (e, idx) => {
    const { options } = e.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    const current = formData.userRole.map((x, index) => {
      if (idx === index) {
        x.userRoleSites = value.map((r) => {
          return { site_id: r };
        });
      }
      return x;
    });

    setFormData((prevData) => ({
      ...prevData,
      userRole: current,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }

    setAddButtonShow(value.length > 0);
  };

  const handleRemoveClick = async (idx) => {
    setElement(element.filter((value, index) => index !== idx));
    setSiteSelectShow(siteSelectShow.filter((value, index) => index !== idx));
    const filtered = formData.userRole.filter((value, index) => index !== idx);
    setFormData((prevData) => ({
      ...prevData,
      userRole: filtered,
    }));
    let showButton = true;
    for (let i = 0; i < filtered.length; i++) {
      if (role.filter((x) => x.id === filtered[i].role_id)[0]?.site === 1) {
        if (!filtered[i].userRoleSites || filtered[i].userRoleSites.length === 0) {
          showButton = false;
        }
      }
    }
    setAddButtonShow(showButton);
    setValidated(showButton);
  };

  const handleAddClick = async () => {
    setElement([...element, element.length]);
    setSiteSelectShow([...siteSelectShow, false]);
    setAddButtonShow(false);
    setFormData((prevData) => ({
      ...prevData,
      userRole: [...formData.userRole, { role_id: "" }],
    }));

    setValidated(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan perubahan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let res;
      if (id) {
        delete formData.id;
        res = await PATCH(`user/${id}`, formData);
      } else {
        res = await POST(`user/web`, formData);
      }
      if (res) {
        Swal.fire({
          icon: "success",
          title: !id ? `Pengguna ${formData.name} berhasil ditambahkan.` : `Pengguna ${formData.name} berhasil diubah.`,
        }).then(() => {
          Swal.close();
          navigate(`/daftar-pengguna`);
        });
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    fetchDataRole();
    fetchDataSite();
  }, []);

  return (
    <Fragment>
      <PageTitle activeMenu={`${id ? flag.charAt(0).toUpperCase() + flag.slice(1) : "Tambah"} Pengguna`} motherMenu="Daftar Pengguna" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            {flag === "detail" && (
              <div className="card-header">
                <div></div>
                <Link className="btn btn-primary mr-2" to={`/daftar-pengguna`}>
                  Kembali
                </Link>
              </div>
            )}
            <div className="card-body">
              <div className="form-validation mt-3">
                <form className="form-valide" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-xl-4">
                      <div className="form-group">
                        <label>
                          No HP<span className="text-danger">*</span>
                        </label>
                        <input disabled={id} type="text" className="form-control" value={formData.hp_no} name="hp_no" onChange={handleChange} maxLength="15" minLength="8" required />
                        <Form.Control.Feedback type="invalid">No HP harus diisi dengan angka minimal 8 digit.</Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="form-group">
                        <label>Email</label>
                        <input disabled={flag === "detail"} type="email" className="form-control" value={formData.email} name="email" onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="form-group">
                        <label>
                          Nama Lengkap<span className="text-danger">*</span>
                        </label>
                        <input disabled={flag === "detail"} type="text" className="form-control" value={formData.name} name="name" onChange={handleChange} maxLength="30" minLength="4" required />
                        <Form.Control.Feedback type="invalid">Nama Lengkap harus diisi minimal 4 karakter.</Form.Control.Feedback>
                      </div>
                    </div>
                  </div>

                  {flag !== "detail" && (
                    <div className="row">
                      <div className="col-xl-4">
                        <div className="form-group">
                          <label>
                            Password{id ? " Baru" : ""}
                            <span className="text-danger">*</span>
                          </label>
                          <input disabled={flag === "detail"} type="password" className="form-control" value={formData.password} name="password" onChange={handleChange} maxLength="6" minLength="6" required={!id} />
                          <Form.Control.Feedback type="invalid">Passsword harus diisi dengan angka 6 digit.</Form.Control.Feedback>
                          {id && <label>Hanya diisi jika ingin mengubah password. Selain itu kosongkan.</label>}
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="form-group">
                          <label>
                            Konfirmasi Password{id ? " Baru" : ""}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            disabled={flag === "detail"}
                            type="password"
                            className="form-control"
                            value={formData.password_confirmation}
                            name="password_confirmation"
                            onChange={handleChange}
                            maxLength="6"
                            minLength="6"
                            required={!id || formData.password}
                          />
                          <Form.Control.Feedback type="invalid">Konfirmasi Passsword harus diisi dengan angka 6 digit.</Form.Control.Feedback>
                          {id && <label>Hanya diisi jika ingin mengubah password. Selain itu kosongkan.</label>}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-xl-5">
                      {role?.length > 0 && (
                        <div className="form-group">
                          <label>
                            Role<span className="text-danger">*</span>
                          </label>
                          <Select
                            isMulti
                            name="userRole"
                            options={role.map((item) => ({ value: item.id, label: item.name }))}
                            onChange={(selectedOptions) => handleSelectChange(selectedOptions, "userRole")}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            disabled={flag === "detail"}
                            // defaultValue={role.filter(item => formData?.userRole?.includes(item.id)).map(item => ({ value: item.id, label: item.name }))}
                            defaultValue={formData?.userRole
                              .filter((roleObj) => roleObj.role_id)
                              .map((roleObj) => ({
                                value: roleObj.role_id,
                                label: role.find((item) => item.id === roleObj.role_id)?.name,
                              }))}
                          />
                        </div>
                      )}

                      {/* <label>Role<span className="text-danger">*</span></label>
                      {element.map(el => (
                        <div key={el} className='form-group' style={{ marginBottom: '71px' }}>
                          <select
                            disabled={flag === 'detail'}
                            className='form-control'
                            value={formData.userRole[el]?.role_id}
                            name="userRole"
                            onChange={(c) => {
                              handleRoleChange(c, el);
                            }}
                            required
                          >
                            <option value="">Pilih Role</option>
                            {Array.isArray(role) && role.map((r) => (
                              <option
                                key={r.id}
                                value={r.id}
                              >
                                {r.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Role harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                      ))} */}
                    </div>
                    <div className="col-xl-5">
                      {siteSelectShow.map((r, idx) => (
                        <Fragment key={idx}>
                          {r ? (
                            <div className="form-group">
                              <select
                                multiple
                                disabled={flag === "detail"}
                                className="form-control"
                                value={formData.userRole[idx]?.userRoleSites?.map((x) => x.site_id)}
                                name="site_id"
                                onChange={(c) => {
                                  handleSiteChange(c, idx);
                                }}
                                required
                              >
                                {Array.isArray(site) &&
                                  site.map((r) => (
                                    <option key={r.id} value={r.id}>
                                      {r.name}
                                    </option>
                                  ))}
                              </select>
                              <Form.Control.Feedback type="invalid">Site harus dipilih.</Form.Control.Feedback>
                            </div>
                          ) : (
                            // <div className='form-group' style={{ height: '96px' }}></div>
                            ""
                          )}
                        </Fragment>
                      ))}
                      {/* {siteSelectShow.map((r, idx) => (
                        <Fragment key={idx}>
                          {r ? (
                            <div className='form-group'>
                              <select
                                multiple
                                disabled={flag === 'detail'}
                                className='form-control'
                                value={formData.userRole[idx]?.userRoleSites?.map(x => x.site_id)}
                                name="site_id"
                                onChange={(c) => {
                                  handleSiteChange(c, idx);
                                }}
                                required
                              >
                                {Array.isArray(site) && site.map((r) => (
                                  <option
                                    key={r.id}
                                    value={r.id}
                                  >
                                    {r.name}
                                  </option>
                                ))}
                              </select>
                              <Form.Control.Feedback type="invalid">
                                Site harus dipilih.
                              </Form.Control.Feedback>
                            </div>
                          ) : (
                            <div className='form-group' style={{ height: '96px' }}></div>
                          )}
                        </Fragment>
                      ))} */}
                    </div>
                    {/* <div className="col-xl-2">
                      {(flag !== 'detail' && element.length > 1) && element.map(el => (
                        <div key={el} className='form-group' style={{ height: '96px' }}>
                          <Link className="btn btn-info shadow btn-xs sharp" style={{ marginTop: '36px' }} onClick={() => { handleRemoveClick(el); }}>
                            <i className="fa fa-minus"></i>
                          </Link>
                        </div>
                      ))}
                    </div> */}
                  </div>

                  {/* {addButtonShow && (
                    <div className="row">
                      <div className="col-xl-10"></div>
                      <div className="col-xl-2">
                        <div className='form-group'>
                          <Link className="btn btn-info shadow btn-xs sharp" onClick={handleAddClick}>
                            <i className="fa fa-plus"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )} */}

                  {flag !== "detail" && (
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-pengguna`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" disabled={!validated}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormPengguna;
