import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import Select from 'react-select';
import DataTable from "react-data-table-component";

const FormJadwalKelas = () => {
  const location = useLocation();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const { id, flag } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    learning_level: "",
    period: "",
    location: "",
    city: "",
    teachers_id: "",
    students_id: "",
  });
  const [formDataJadwal, setFormDataJadwal] = useState({
    period: "",
    class_id: "",
    title: "",
    day_id: "",
    time_start: "",
    time_finish: "",
    teachers_id: "",
    students_id: "",
    nama_kota_kabupaten: '',
    location_name: '',
    location: '',
  });
  const [kelas, setKelas] = useState([]);
  const [validated, setValidated] = useState(false);
  const [periode, setPeriode] = useState([]);
  const [lokasi, setLokasi] = useState([]);
  const [levelBelajar, setLevelBelajar] = useState([]);
  const [kota, setKota] = useState([]);
  const [hari, setHari] = useState([]);
  const [pengajar, setPengajar] = useState([]);
  const [pelajar, setPelajar] = useState([]);
  const [editFormData, setEditFormData] = useState({
    name: "",
    learning_level: "",
    period: "",
    location: "",
    city: "",
    teachers_id: "",
    students_id: "",
    naikkelsd: "",
    learning:"",

  });
  const formRef = useRef(null);
  const [touched, setTouched] = useState(false);

  const fetchDataPeriode = async () => {
    const res = await GET(`master-data/period`);
    setPeriode(res?.data);
  };
  const fetchDataLokasi = async (city) => {
    const res = await GET(`master-data/parent/${city}`);
    setLokasi(res?.data);
  };
  const fetchDataLevelBelajar = async () => {
    const res = await GET(`master-data/type?type=level belajar`);
    setLevelBelajar(res?.data);
  };
  const fetchDataKota = async () => {
    const res = await GET(`master-data/type?type=kota kabupaten`);
    setKota(res?.data);
  };
  const fetchDataKelas = async (idPeriode) => {
    const res = await GET(`class/periode/${idPeriode}`);
    setKelas(res?.data);
  };
  const fetchDataHari = async () => {
    const res = await GET(`master-data/type?type=Hari`);
    setHari(res?.data);
  };

  // GET JADWAL KELAS
  const fetchDataJadwal = async () => {
    const res = await GET(`/schedules/${id}`);
    const processedDay = res?.data?.days?.map(day => ({ day_id: day?.days_id }));
    //const processedDay = res?.days?.map(day => ({ day_id: day?.id }));
    setFormDataJadwal({
      period: res?.data.periode_class_id,
      class_id: res?.data?.class,
      title: res?.data?.title,
      day_id: processedDay,
      time_start: res?.data?.time_start,
      time_finish: res?.data?.time_finish,
      teachers_id: res?.data?.assessor,
      students_id: res?.data?.students,
      nama_kota_kabupaten: res?.data?.nama_kota_kabupaten,
      location_name: res?.data?.name_location,
      location: res?.data?.location,
    });

    //console.log("testuuu",formDataJadwal.day_id)
  };

  useEffect(() => {
    console.log("FormDataJadwal updated:", formDataJadwal.day_id);
  }, [formDataJadwal]);

  const handleChangeJadwal = (e) => {
    const { name, value } = e.target;
    setFormDataJadwal((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setTouched(true);

    if (name === 'period') {
      setFormDataJadwal((prevData) => ({
        ...prevData,
        class_id: "",
        nama_kota_kabupaten: "",
        location_name: "",
        location: "",
        teachers_id: "",
        students_id: "",
        [name]: value,
      }));
      setKelas([]);
    }

    if (name === 'class_id') {
      const selectedKelas = kelas.find(kelasByPeriod => kelasByPeriod.class_id === value);
      setFormDataJadwal((prevData) => ({
        ...prevData,
        [name]: value,
        nama_kota_kabupaten: selectedKelas ? selectedKelas.kota_kabupaten?.name : "",
        location_name: selectedKelas ? selectedKelas.location_name : "",
        //location: selectedKelas ? selectedKelas.location_name : prevData.location,
        location: selectedKelas ? selectedKelas.location_id : prevData.location,
        teachers_id: selectedKelas ? selectedKelas.teachers : "",
        students_id: selectedKelas ? selectedKelas.students : "",
      }));
    }

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleSelectChange = (selectedOptions, name) => {
    const newData = selectedOptions.map(option => ({
      day_id: option.day_id,
    }));

    setFormDataJadwal((prevData) => ({
      ...prevData,
      [name]: newData,
    }));

    setTouched(true);

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  useEffect(() => {
    fetchDataPeriode();
    fetchDataLevelBelajar();
    fetchDataKota();
    if (id?.length > 0 && flag === "Ubah") {
      fetchDataJadwal();
    }

    fetchDataHari();
  }, []);

  useEffect(() => {
    if (formDataJadwal?.period?.length > 0) {
      fetchDataKelas(formDataJadwal.period);
    }
  }, [formDataJadwal.period]);

  useEffect(() => {
    if (editFormData?.city) {
      fetchDataLokasi(editFormData.city);
    }
    if (formData?.city) {
      fetchDataLokasi(formData.city);
    }
  }, [editFormData.city, formData.city]);

  const handleSubmitJadwal = async (e) => {
    e.preventDefault();
    const requestBody = {
      class_id: formDataJadwal.class_id,
      title: formDataJadwal.title,
      days: formDataJadwal.day_id,
      time_start: formDataJadwal.time_start,
      time_finish: formDataJadwal.time_finish,
      location: formDataJadwal.location,
      // teachers: formDataJadwal.teachers_id,
      // students: formDataJadwal.students_id,
      // order: formDataJadwal.order === null || formDataJadwal.order === "" ? 1 : formDataJadwal.order
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await POST(`/schedules`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil membuat jadwal kelas!",
      }).then(() => {
        Swal.close();
        setFormDataJadwal({
          class_id: "",
          title: "",
          day: "",
          time_start: "",
          time_finish: "",
          teachers_id: "",
          students_id: "",
          nama_kota_kabupaten: '',
          location_name: '',
        });
        router("/jadwal-kelas")
      });
    }
  };

  const handleEditSubmitJadwal = async (e) => {
    e.preventDefault();
    const requestBody = {
      class_id: formDataJadwal.class_id,
      title: formDataJadwal.title,
      days: formDataJadwal.day_id,
      time_start: formDataJadwal.time_start,
      time_finish: formDataJadwal.time_finish,
      location: formDataJadwal.location,
      // teachers: formDataJadwal.teachers_id,
      // students: formDataJadwal.students_id,
      // order: formDataJadwal.order === null || formDataJadwal.order === "" ? 1 : formDataJadwal.order
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/schedules/${id}`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Data Jadwal Kelas berhasil diubah!",
      }).then(() => {
        Swal.close();
        setFormDataJadwal({
          class_id: "",
          title: "",
          day: "",
          time_start: "",
          time_finish: "",
          teachers_id: "",
          students_id: "",
          nama_kota_kabupaten: '',
          location_name: '',
        });
        router("/jadwal-kelas")
      });
    }
  };

  const selectStyle = {
    control: (provided) => ({
      ...provided,
      // height: '50px',
      minHeight: '55px'
    }),
  }

  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Jadwal`}
        motherMenu="Kelola Jadwal"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Jadwal</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                {flag === "Tambah" ? (
                  <form className="form-valide" ref={formRef} onSubmit={handleSubmitJadwal}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Periode<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formDataJadwal.period}
                            name="period"
                            onChange={handleChangeJadwal}
                            required
                          >
                            <option value="">Pilih Periode</option>
                            {Array.isArray(periode) && periode.map((periodes) => (
                              <option
                                key={periodes.id}
                                value={periodes.id}
                              >
                                {periodes.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Periode harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group'>
                          <label>Kelas<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formDataJadwal.class_id}
                            name="class_id"
                            onChange={handleChangeJadwal}
                            required
                          >
                            <option value="">Pilih Kelas</option>
                            {Array.isArray(kelas) && kelas.map((kelasByPeriod) => (
                              <option
                                key={kelasByPeriod.class_id}
                                value={kelasByPeriod.class_id}
                              >
                                {kelasByPeriod.class_name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Kelas harus dipilih.
                          </Form.Control.Feedback>
                        </div>

                        <div className='form-group'>
                          <label>Kota/Kabupaten<span className="text-danger">*</span></label>
                          <input
                            name='nama_kota_kabupaten'
                            type='text'
                            className='form-control'
                            value={formDataJadwal.nama_kota_kabupaten}
                            disabled
                          />
                        </div>
                        <div className='form-group'>
                          <label>Lokasi<span className="text-danger">*</span></label>
                          <input
                            name="location_name"
                            type='text'
                            className='form-control'
                            value={formDataJadwal.location_name}
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">
                            Lokasi harus diisi minimal 3 karakter.
                          </Form.Control.Feedback>
                        </div>

                        {pengajar?.length > 0 && (
                          <div className='form-group'>
                            {formDataJadwal?.teachers_id && (
                              <>
                                <label>Nama Pengajar<span className="text-danger">*</span></label>
                                <Select
                                  isMulti
                                  name="teachers_id"
                                  options={pengajar.map(item => ({ value: item.id, label: item.user?.name }))}
                                  onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'teachers_id')}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  defaultValue={formDataJadwal?.teachers_id?.filter(teacherObj => teacherObj?.id).map(teacherObj => ({ value: teacherObj?.id, label: pengajar?.find(item => item?.user_id === teacherObj?.id)?.user?.name }))}
                                  styles={selectStyle}
                                  isDisabled={true}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-xl-6">
                        {pelajar?.length > 0 && (
                          <div className='form-group'>
                            {formDataJadwal?.students_id && (
                              <>
                                <label>Nama Pelajar<span className="text-danger">*</span></label> 
                                <Select
                                  isMulti
                                  name="students_id"
                                  options={pelajar.map(item => ({ value: item.id, label: item.user?.name }))}
                                  onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'students_id')}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  defaultValue={formDataJadwal?.students_id?.filter(studentObj => studentObj?.id).map(studentObj => ({ value: studentObj?.id, label: pelajar?.find(item => item?.user_id === studentObj?.id)?.user?.name }))} 
                                  styles={selectStyle}
                                  isDisabled={true}
                                />
                              </>
                            )}
                          </div>
                        )}
                        <div className='form-group'>
                          <label>Kegiatan<span className="text-danger">*</span></label>
                          <input
                            type='text'
                            className='form-control'
                            name="title"
                            value={formDataJadwal.title}
                            onChange={handleChangeJadwal}
                            maxLength="30"
                            minLength="5"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Kegiatan harus diisi minimal 5 karakter.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group'>
                          <label>Hari<span className="text-danger">*</span></label>
                          <select
                            name="day_id"
                            onChange={(e) => {
                              const selectedOption = {
                                day_id: e.target.value, // Get the value of the selected option
                              };
                              handleSelectChange([selectedOption], 'day_id'); // Wrap in an array to match expected function input
                            }}
                            value={formDataJadwal?.day_id?.[0]?.day_id || ""} // Dynamically set the selected value
                            className='form-control'
                            styles={selectStyle}
                          >
                            {formDataJadwal?.day_id === "" && touched && (
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Hari harus dipilih.
                              </span>
                            )}
                            <option value="">Pilih Hari</option>
                            {Array.isArray(hari) && hari.map((hariKey) => (
                              <option
                                key={hariKey.id}
                                value={hariKey.id}
                              >
                                {hariKey.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* {hari?.length > 0 && (
                          <div className='form-group'>
                            <label>Hari<span className="text-danger">*</span></label>
                            <select
                              name="day_id"
                              options={hari.map(item => ({ value: item.id, label: item.name }))}
                              onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'day_id')}
                              className='form-control'
                              // classNamePrefix="select"
                              styles={selectStyle}
                            />
                            {formDataJadwal?.day_id === "" && touched && (
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Hari harus dipilih.
                              </span>
                            )}
                          </div>
                        )} */}
                        <div className='form-group'>
                          <label>Waktu<span className="text-danger">*</span></label>
                          <div className="d-flex">
                            <input
                              className='form-control col-md-5'
                              type="time"
                              id="appt"
                              name="appt"
                              value={formDataJadwal.time_start}
                              onChange={(e) => {
                                const newTimeStart = e.target.value;
                                setFormDataJadwal({
                                  ...formDataJadwal,
                                  time_start: newTimeStart,
                                });

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();
                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              required
                            />
                            <span className="col-md-2 mt-2"><hr style={{ height: "2px", background: "black", width: "20px" }} /></span>
                            <input
                              className='form-control col-md-5'
                              type="time"
                              id="appt"
                              name="appt"
                              value={formDataJadwal.time_finish}
                              onChange={(e) => {
                                const newTimeFinish = e.target.value;
                                setFormDataJadwal({
                                  ...formDataJadwal,
                                  time_finish: newTimeFinish,
                                });

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();
                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              required
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Waktu harus diisi.
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="form-group mb-3 d-flex">
                        <div className="col-xl-6">
                          <Link className="btn btn-light w-100" to={`/jadwal-kelas`}>
                            Batal
                          </Link>
                        </div>
                        <div className="col-xl-6">
                          <button type="submit" className="btn btn-primary w-100" disabled={!validated || formDataJadwal.day_id === ""}>
                            Simpan Perubahan
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                ) : flag === "Ubah" ? (
                  <form className="form-valide" ref={formRef} onSubmit={handleEditSubmitJadwal}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Periode<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formDataJadwal.period}
                            name="period"
                            onChange={handleChangeJadwal}
                            required
                          >
                            <option value="">Pilih Periode</option>
                            {Array.isArray(periode) && periode.map((periodes) => (
                              <option
                                key={periodes.id}
                                value={periodes.id}
                              >
                                {periodes.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Periode harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group'>
                          <label>Kelas<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formDataJadwal.class_id}
                            name="class_id"
                            onChange={handleChangeJadwal}
                            required
                          >
                            <option value="">Pilih Kelas</option>
                            {Array.isArray(kelas) && kelas.map((kelasByPeriod) => (
                              <option
                                key={kelasByPeriod.class_id}
                                value={kelasByPeriod.class_id}
                              >
                                {kelasByPeriod.class_name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Kelas harus dipilih.
                          </Form.Control.Feedback>
                        </div>

                        <div className='form-group'>
                          <label>Kota/Kabupaten<span className="text-danger">*</span></label>
                          <input
                            name='nama_kota_kabupaten'
                            type='text'
                            className='form-control'
                            value={formDataJadwal.nama_kota_kabupaten}
                            disabled
                          />
                        </div>
                        <div className='form-group'>
                          <label>Lokasi<span className="text-danger">*</span></label>
                          <input
                            name="location_name"
                            type='text'
                            className='form-control'
                            value={formDataJadwal.location_name}
                            disabled
                          />
                        </div>

                        {pengajar.length > 0 && (
                          <div className='form-group'>
                            <label>Nama Pengajar<span className="text-danger">*</span></label>
                            {formDataJadwal?.teachers_id && (
                              <Select
                                isMulti
                                name="teachers_id"
                                options={pengajar.map(item => ({ value: item.id, label: item.user?.name }))}
                                onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'teachers_id')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={formDataJadwal?.teachers_id?.filter(pengajarObj => pengajarObj.id).map(pengajarObj => ({ value: pengajarObj.id, label: pengajar?.find(item => item.user_id === pengajarObj.id)?.user?.name }))}
                                styles={selectStyle}
                                isDisabled={true}
                              />
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-xl-6">
                        {pelajar?.length > 0 && (
                          <div className='form-group'>
                            <label>Nama Pelajar<span className="text-danger">*</span></label>
                            {formDataJadwal?.students_id && (
                              <Select
                                isMulti
                                name="students_id"
                                options={pelajar.map(item => ({ value: item.id, label: item.user?.name }))}
                                onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'students_id')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                // defaultValue={formDataJadwal?.students_id?.filter(studentObj => studentObj.id).map(studentObj => ({ value: studentObj.id, label: pelajar?.find(item => item.id === studentObj.id)?.user?.name }))}
                                defaultValue={formDataJadwal?.students_id?.filter(studentObj => studentObj?.id).map(studentObj => ({ value: studentObj?.id, label: pelajar?.find(item => item?.user_id === studentObj?.id)?.user?.name }))}
                                styles={selectStyle}
                                isDisabled={true}
                              />
                            )}
                          </div>
                        )}
                        <div className='form-group'>
                          <label>Kegiatan<span className="text-danger">*</span></label>
                          <input
                            type='text'
                            className='form-control'
                            name="title"
                            value={formDataJadwal.title}
                            onChange={handleChangeJadwal}
                            maxLength="30"
                            minLength="5"
                            disabled={locations === "Detail"}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Kegiatan harus diisi minimal 5 karakter.
                          </Form.Control.Feedback>
                        </div>

                        <div className='form-group'>
                          <label>Hari<span className="text-danger">*</span></label>
                          <select
                            name="day_id"
                            onChange={(e) => {
                              const selectedOption = {
                                day_id: e.target.value, // Get the value of the selected option
                              };
                              handleSelectChange([selectedOption], 'day_id'); // Wrap in an array to match expected function input
                            }}
                            value={formDataJadwal?.day_id?.[0]?.day_id || ""} // Dynamically set the selected value
                            className='form-control'
                            styles={selectStyle}
                          >
                            {formDataJadwal?.day_id === "" && touched && (
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Hari harus dipilih.
                              </span>
                            )}
                            <option value="">Pilih Kelas</option>
                            {Array.isArray(hari) && hari.map((hariKey) => (
                              <option
                                key={hariKey.id}
                                value={hariKey.id}
                              >
                                {hariKey.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* {hari.length > 0 && (
                          <div className='form-group'>
                            <label>Hari adi<span className="text-danger">*</span></label>
                            {formDataJadwal?.day_id && (
                              <Select
                                isMulti
                                name="day_id"
                                options={hari.map(item => ({ value: item.id, label: item.name }))}
                                onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'day_id')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={
                                  Array.isArray(formDataJadwal?.day_id)
                                    ? formDataJadwal?.day_id.filter(dayObj => dayObj?.day_id).map(dayObj => ({
                                      value: dayObj?.day_id,
                                      label: hari?.find(item => item.id === dayObj?.day_id)?.name,
                                    }))
                                    : []
                                }
                                //defaultValue={formDataJadwal?.day_id?.filter(dayobj => dayobj?.id).map(dayobj => ({value: dayobj?.id, label: hari?.find(item=> item?.id === dayobj?.id)?.name}))}
                                styles={selectStyle}
                              />
                            )}
                            {formDataJadwal?.day_id === "" || formDataJadwal?.day_id?.length === 0 && touched && (
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Hari harus dipilih.
                              </span>
                            )}
                          </div>
                        )} */}
                        <div className='form-group'>
                          <label>Waktu<span className="text-danger">*</span></label>
                          <div className="d-flex">
                            <input
                              className='form-control col-md-5'
                              type="time"
                              id="appt"
                              name="appt"
                              value={formDataJadwal.time_start}
                              onChange={(e) => {
                                const newTimeStart = e.target.value;
                                setFormDataJadwal({
                                  ...formDataJadwal,
                                  time_start: newTimeStart,
                                });

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();
                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              required
                            />
                            <span className="col-md-2 mt-2"><hr style={{ height: "2px", background: "black", width: "20px" }} /></span>
                            <input
                              className='form-control col-md-5'
                              type="time"
                              id="appt"
                              name="appt"
                              value={formDataJadwal.time_finish}
                              onChange={(e) => {
                                const newTimeFinish = e.target.value;
                                setFormDataJadwal({
                                  ...formDataJadwal,
                                  time_finish: newTimeFinish,
                                });

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();
                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              required
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Waktu harus diisi minimal 3 karakter.
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="form-group mb-3 d-flex">
                        <div className="col-xl-6">
                          <Link className="btn btn-light w-100" to={`/jadwal-kelas`}>
                            Batal
                          </Link>
                        </div>
                        <div className="col-xl-6">
                          <button type="submit" className="btn btn-primary w-100" disabled={!validated || formDataJadwal.day_id === "" || formDataJadwal?.day_id.length === 0}>
                            Simpan Perubahan
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormJadwalKelas