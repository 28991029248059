import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Badge, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET, PATCH } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck, faBookOpenReader, faBookReader, faFileCircleXmark, faFileCircleCheck } from "@fortawesome/free-solid-svg-icons";

const DaftarSaran = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [tipePelajar, setTipePelajar] = useState([]);
  const [status, setStatus] = useState([]);

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedTipePelajar, setSelectedTipePelajar] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const fetchData = async (page, perPage, sortField, sortOrder, search, tipePelajar, status) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      type: tipePelajar,
      status: status,
    };
    const res = await GET(`/suggestion`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };

  const fetchTipePelajar = async () => {
    const res = await GET(`/master-data/type?type=pelajar`);
    setTipePelajar(res?.data);
  };
  const dataStatus = {
    0: "Belum Dibaca",
    1: "Sudah Dibaca",
    2: "Sudah Ditindak Lanjuti",
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedTipePelajar, selectedStatus);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedTipePelajar, selectedStatus]);
  useEffect(() => {
    fetchTipePelajar();
  }, []);

  const columns = [
    {
      name: "Waktu Submit",
      sortable: true,
      field: "created_at",
      selector: (row) => {
        const date = new Date(row.created_at);
        const formattedDate = date?.toLocaleDateString("id-ID", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        });
        const formattedTime = date?.toLocaleTimeString("id-ID", {
          hour: "2-digit",
          minute: "2-digit",
        });
        return `${formattedDate} ${formattedTime}`;
      },
    },
    {
      name: "Nama Pelajar",
      selector: (row) => row.user?.name,
      cell: (row) => (
        <button onClick={() => navigate(`/${row.student_id}/form-pelajar/Detail`)} style={{ background: "transparent", border: "none" }}>
          <span style={{ color: "#0C718C", textDecoration: "underline" }}>{row.user?.name}</span>
        </button>
      ),
    },
    {
      name: "Tipe Pelajar",
      selector: (row) => row.user?.name_type,
    },
    {
      name: "Judul Saran",
      selector: (row) => row.title,
    },
    {
      name: "Deskripsi Saran",
      selector: (row) => row.description,
      width: "320px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "145px",
      cell: (row) => {
        let displayText;
        if (row.status === 2) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Sudah ditindak <br /> lanjuti
            </Badge>
          );
        } else if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-info badge-rounded">
              Sudah dibaca
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Belum dibaca
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      },
    },
    {
      name: "Aksi",
      selector: (row) => row.id,
      width: "180px",
      cell: (row) => (
        <div>
          {row.status === 0 ? (
            <button className="btn btn-info shadow btn-xs sharp mx-1" title="Sudah dibaca" onClick={() => handleRead(row.id)}>
              <FontAwesomeIcon icon={faFileCircleCheck} />
            </button>
          ) : row.status === 1 ? (
            <>
              <button className="btn btn-danger shadow btn-xs sharp" title="Belum dibaca" onClick={() => handleUnread(row.id)}>
                <FontAwesomeIcon icon={faFileCircleXmark} />
              </button>
              <button className="btn btn-success shadow btn-xs sharp mx-1" title="Sudah ditindak lanjut" onClick={() => handleAccept(row.id)}>
                <FontAwesomeIcon icon={faClipboardCheck} />
              </button>
            </>
          ) : (
            row.status === 2 && (
              <button className="btn btn-info shadow btn-xs sharp mx-1" title="Sudah dibaca" onClick={() => handleRead(row.id)}>
                <FontAwesomeIcon icon={faFileCircleCheck} />
              </button>
            )
          )}
          <Link className="btn btn-danger shadow btn-xs sharp" title="Delete" onClick={() => handleDeleteClick(row.id)}>
            <i className="fa fa-trash"></i>
          </Link>
        </div>
      ),
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleSelectTipePelajar = (e) => {
    setSelectedTipePelajar(e.target.getAttribute("value"));
  };
  const handleSelectStatusPesanan = (e) => {
    setSelectedStatus(e.target.getAttribute("value"));
  };

  const handleAccept = async (id) => {
    const result = await Swal.fire({
      title: "Apakah Anda yakin akan mengubah status saran menjadi Sudah Ditindak Lanjuti?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await PATCH(`suggestion/${id}`, { status: 2 });
      if (res) {
        Swal.fire({
          icon: "success",
          title: `Saran ditandai Sudah Ditindak Lanjuti!`,
        }).then(() => {
          Swal.close();
          fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
        });
      }
    }
  };
  const handleRead = async (id) => {
    const result = await Swal.fire({
      title: "Apakah Anda yakin akan mengubah status saran menjadi sudah dibaca?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await PATCH(`suggestion/${id}`, { status: 1 });
      if (res) {
        Swal.fire({
          icon: "success",
          title: `Saran ditandai sudah dibaca!`,
        }).then(() => {
          Swal.close();
          fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
        });
      }
    }
  };
  const handleUnread = async (id) => {
    const result = await Swal.fire({
      title: "Apakah Anda yakin akan mengubah status saran menjadi belum dibaca?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await PATCH(`suggestion/${id}`, { status: 0 });
      if (res) {
        Swal.fire({
          icon: "success",
          title: `Saran ditandai belum dibaca!`,
        }).then(() => {
          Swal.close();
          fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
        });
      }
    }
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/suggestion/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus saran!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };
  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Saran</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4">
              <div className="d-flex flex-wrap px-4">
                <Dropdown>
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey", borderRadius: "0" }} id="dropdown-basic">
                    {selectedTipePelajar?.length > 0 ? selectedTipePelajar : "Semua Tipe Pelajar"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectTipePelajar}>
                      Semua Tipe Pelajar
                    </Dropdown.Item>
                    {Array.isArray(tipePelajar) &&
                      tipePelajar.map((pelajarTipe) => (
                        <Dropdown.Item key={pelajarTipe.id} value={pelajarTipe.name} onClick={handleSelectTipePelajar}>
                          {pelajarTipe.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey", borderRadius: "0" }} id="dropdown-basic">
                    {selectedStatus?.length > 0 ? dataStatus[selectedStatus] : "Semua Status"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectStatusPesanan}>
                      Semua Status
                    </Dropdown.Item>
                    {Object.entries(dataStatus).map(([key, value]) => (
                      <Dropdown.Item key={key} value={key} onClick={handleSelectStatusPesanan}>
                        {value}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="d-flex align-self-baseline px-4">
                <div className="input-group search-area d-xl-inline-flex">
                  <input type="text" className="form-control" placeholder="Cari Saran" value={searchTerm} onChange={handleSearchChange} />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                  defaultSortFieldId={1}
                  defaultSortAsc={sortOrder === "asc"}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DaftarSaran;
