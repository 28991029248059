import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Badge } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET, PATCH } from '../../../services/AxiosService';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";

const DaftarPaketBuku = () => {
  const navigate = useNavigate();
  function currencyFormat(num) {
    num = parseFloat(num);
    return num
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      .replace(/\.00$/, "");
  }
  const [data, setData] = useState();
  //server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");

  const dataDummy = [
    {
      name: 'Langkah Awal 1',
      harga: '25000',
      isi_paket: 'Juz 30',
      status: 0,
      deskripsi: "deskripsi langkah awal 1",
      jumlah_diperlukan: 5,
    },
    {
      name: 'Langkah Awal 2',
      harga: '15000',
      isi_paket: 'Langkah Awal 1, Langkah Awal 2, AlQuran',
      status: 1,
      deskripsi: "deskripsi langjkah awal 2",
      jumlah_diperlukan: 3
    },
  ];

  const fetchData = async (page, perPage, sortField, sortOrder, search) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      // type_donation:tipeDonasi,
    };
    const res = await GET(`/book-package`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };
  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    localStorage.setItem('searchTerm', e.target.value);
  };

  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  const handleLockUnlock = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`book-package/${id}/lock-unlock`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: res.message,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };

  const handleDeleteClick = async (id) => {
    console.log(id);
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/book-package/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus paket buku!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  const columns = [
    {
      name: 'Nama Paket',
      selector: row => row.name,
    },
    {
      name: 'Harga',
      selector: row => row.price,
      cell: (row)=> (
        <span>{currencyFormat(row.price)}</span>

      ),
    },
    {
      name: 'Isi Paket',
      selector: row => row.package_contents,
    },
    {
      name: 'Status',
      selector: row => row.status,
      width: "130px",
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Aktif
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Tidak Aktif
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      }
    },
    {
      name: 'Deskripsi',
      selector: row => row.description,
    },
    {
      name: 'Jumlah Diperlukan',
      selector: row => row.amount_needed,
    },
    {
      name: 'Aksi',
      selector: row => row.id,
      width: "180px",
      cell: (row) => (
        <div>
          {row.status === 1 ? (
            <Link
              className="btn btn-danger shadow btn-xs sharp"
              onClick={() => handleLockUnlock(row.id)}
              title="Non-Aktif"
            >
              <i className="fa fa-lock"></i>
            </Link>
          ) : (
            <Link
              className="btn btn-success shadow btn-xs sharp"
              onClick={() => handleLockUnlock(row.id)}
              title="Aktif"
            >
              <i className="fa fa-unlock"></i>
            </Link>
          )}
          <button
            className="btn btn-info shadow btn-xs sharp mx-1"
            onClick={() => navigate(`/${row.id}/form-paket-buku/Detail`)}
            title="Detail"
          >
            <i className="fa fa-eye"></i>
          </button>
          <button
            className="btn btn-primary shadow btn-xs sharp mr-1"
            onClick={() => navigate(`/${row.id}/form-paket-buku/Edit`)}
            title="Edit"
          >
            <i className="fa fa-pencil"></i>
          </button>
          <Link
            className="btn btn-danger shadow btn-xs sharp"
            title="Delete"
            onClick={() => handleDeleteClick(row.id)}
          >
            <i className="fa fa-trash"></i>
          </Link>
        </div>
      ),
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };
  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Paket Buku</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4">
              <div className='d-flex flex-wrap px-4'>
              </div>

              <div className="d-flex align-self-baseline px-4">
                <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-paket-buku/Tambah`)}>
                  Tambah Paket
                </button>
                <div className="input-group search-area d-xl-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari Donasi"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                  defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default DaftarPaketBuku