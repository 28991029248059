import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from "sweetalert2";

const TambahConfigMasterData = () => {
  const { id, flag } = useParams();
  console.log(id)
  const location = useLocation();
  const router = useNavigate();
  const [tipe, setTipe] = useState([]);
  const [config, setConfig] = useState([]);
  const [parent, setParent] = useState([]);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    parent: "",
    name: "",
    description: "",
    order: "",
  });

  const fetchData = async (id) => {
    console.log('Fetch Data ID', id)
  //   const res = await GET(`/master-data/${id}`);
  //   setEditFormData(res?.data);
  //   setEditFormData({
  //     name: res?.data?.name,
  //     type: res?.data?.type,
  //     description: res?.data?.description,
  //     status: res?.data?.status,
  //     parent: res?.data?.parent,
  //     order: res?.data?.order,
  //   });
  //   if (res?.data?.parent) {
  //     const parent = await GET(`/master-data/type?type=${res?.data?.parentData.type}`);
  //     setParent(parent?.data);
  //   }
  };

  const fetchDataType = async () => {
    const res = await GET(`/master-data/type?type=Tipe Konfig`);
    setTipe(res?.data);
  };

  const fetchDataParent = async () => {
    const res = await GET(`/master-data/config`);
    setParent(res?.data);
  };

  useEffect(() => {
    fetchDataType();
    fetchDataParent();
    if (flag !== "Tambah") {
      fetchData(id);
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      type: formData.type,
      parent: formData.type === 'Parent' ? '' : formData.parent,
      name: formData.name,
      description: formData.description,
      order: formData.order === null || formData.order === "" ? 1 : formData.order
    };
    console.log('payload',requestBody)
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await POST(``);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil Membuat Konfigurasi Master Data!",
      }).then(() => {
        Swal.close();
        setFormData({
          name: "",
          type: "",
          parent: "",
          description: "",
          order: "",
        });
        router("/master-data-config");
      });
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      type: formData.type,
      parent: formData.type === 'Parent' ? '' : formData.parent,
      name: formData.name,
      description: formData.description,
      order: formData.order === null || formData.order === "" ? 1 : formData.order
    };
    console.log('payload',requestBody)
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(``);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil Membuat Konfigurasi Master Data!",
      }).then(() => {
        Swal.close();
        setFormData({
          name: "",
          type: "",
          parent: "",
          description: "",
          order: "",
        });
        router("/master-data-config");
      });
    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Konfigurasi Master Data`}
        motherMenu="Daftar Konfigurasi Master Data"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Konfigurasi Master Data</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/master-data-config`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {flag === "Tambah" ?  (
                  <form className="form-valide">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Pilih Tipe<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.type}
                            name="type"
                            onChange={handleChange}
                            required
                          >
                            <option>Pilih Tipe</option>
                            {Array.isArray(tipe) && tipe.map((configData) => (
                              <option
                                key={configData.id}
                                value={configData.description}
                              >
                                {configData.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Pilih Salah Satu.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group'>
                          <label>Nama<span className="text-danger">*</span></label>
                          <input
                            name="name"
                            className='form-control'
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Nama harus diisi.
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        {formData.type == "Child" && (
                          <div className='form-group'>
                            <label>Pilih Parent<span className="text-danger">*</span></label>
                            <select
                              className='form-control'
                              value={formData.parent}
                              name="parent"
                              onChange={handleChange}
                              required
                            >
                              <option value="">Pilih Parent</option>
                              {Array.isArray(parent) && parent.map((configData) => (
                                <option
                                  key={configData.id}
                                  value={configData.type}
                                >
                                  {configData.type}
                                </option>
                              ))}
                            </select>
                            <Form.Control.Feedback type="invalid">
                              Tipe harus diisi.
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </div>
                    </div>
                      <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/master-data-config`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="button" onClick={handleSubmit} className="btn btn-primary w-100">
                          Simpan Perubahan
                        </button>
                      </div>
                     </div>
                  </form>
                ) : flag === 'Ubah' ? (
                  <form className="form-valide">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Pilih Tipe<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.type}
                            name="type"
                            onChange={handleChange}
                            required
                          >
                            <option>Pilih Tipe</option>
                            {Array.isArray(tipe) && tipe.map((configData) => (
                              <option
                                key={configData.id}
                                value={configData.description}
                              >
                                {configData.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Pilih Salah Satu.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group'>
                          <label>Nama<span className="text-danger">*</span></label>
                          <input
                            name="description"
                            className='form-control'
                            value={formData.description}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Nama harus diisi.
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        {formData.type == "Child" && (
                          <div className='form-group'>
                            <label>Pilih Parent<span className="text-danger">*</span></label>
                            <select
                              className='form-control'
                              value={formData.parent}
                              name="parent"
                              onChange={handleChange}
                              required
                            >
                              <option value="">Pilih Parent</option>
                              {Array.isArray(parent) && parent.map((configData) => (
                                <option
                                  key={configData.id}
                                  value={configData.type}
                                >
                                  {configData.type}
                                </option>
                              ))}
                            </select>
                            <Form.Control.Feedback type="invalid">
                              Tipe harus diisi.
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </div>
                    </div>
                      <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/master-data-config`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" onClick={handleEditSubmit} className="btn btn-primary w-100">
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === 'Detail' ? (
                  <form className="form-valide">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Pilih Tipe<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.type}
                            name="type"
                            onChange={handleChange}
                            required
                            disabled
                          >
                            <option>Pilih Tipe</option>
                            {Array.isArray(tipe) && tipe.map((configData) => (
                              <option
                                key={configData.id}
                                value={configData.description}
                              >
                                {configData.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Pilih Salah Satu.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group'>
                          <label>Nama<span className="text-danger">*</span></label>
                          <input
                            name="description"
                            className='form-control'
                            value={formData.description}
                            onChange={handleChange}
                            required
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">
                            Nama harus diisi.
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        {formData.type == "Child" && (
                          <div className='form-group'>
                            <label>Pilih Parent<span className="text-danger">*</span></label>
                            <select
                              className='form-control'
                              value={formData.parent}
                              name="parent"
                              onChange={handleChange}
                              required
                              disabled
                            >
                              <option value="">Pilih Parent</option>
                              {Array.isArray(parent) && parent.map((configData) => (
                                <option
                                  key={configData.id}
                                  value={configData.type}
                                >
                                  {configData.type}
                                </option>
                              ))}
                            </select>
                            <Form.Control.Feedback type="invalid">
                              Tipe harus diisi.
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                ) : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )













}

export default TambahConfigMasterData;
