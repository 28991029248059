import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from "sweetalert2";

const EditMasterData = () => {
  const { id, flag } = useParams();
  const location = useLocation();
  const router = useNavigate();
  const [tipe, setTipe] = useState([]);
  const [config, setConfig] = useState([]);
  const [parent, setParent] = useState([]);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    description: "",
    parent: "",
    order: "",
  });
  const [order, setOrder] = useState([]);
  const [showNested, setShowNested] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: "",
    type: "",
    description: "",
    status: "",
    parent: "",
    order: "",
  });

  const fetchData = async () => {
    const res = await GET(`/master-data/${id}`);
    setEditFormData(res?.data);
    setEditFormData({
      name: res?.data?.name,
      type: res?.data?.type,
      description: res?.data?.description,
      status: res?.data?.status,
      parent: res?.data?.parent,
      order: res?.data?.order,
    });
    if (res?.data?.parent) {
      const parent = await GET(`/master-data/type?type=${res?.data?.parentData.type}`);
      setParent(parent?.data);
    }
  };
  

  const fetchDataOrder = async () => {
    const res = await GET(`/master-data/type?type=${formData.type}`);
    setOrder(res?.data);
    console.log("sesuai type", res?.data);
  };

  const fetchDataParent = async () => {
    const res = await GET(`/master-data/type?type=${config.filter(x => x.type === formData.type)[0].parent}`);
    setParent(res?.data);
  };

  const getStatus = (status) => {
    if (status === 0) {
      return "Tidak Aktif";
    } else if (status === 1) {
      return "Aktif";
    } else {
      return status;
    }
  };

  const fetchDataConfig = async () => {
    const res = await GET(`master-data/config`);
    setConfig(res?.data);
  };

  useEffect(() => {
    fetchDataConfig();
    if (flag !== "Tambah") {
      fetchData();
    }
  }, []);
  useEffect(() => {
    if (formData.type.length > 0) {
      fetchDataOrder()
      if (config.filter(x => x.type === formData.type)[0].parent) {
        fetchDataParent()
      }
    }
  }, [formData.type]);

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const {
      name,
      type,
      description,
      parent,
      // order
    } = editFormData;

    const requestBody = {
      name,
      type,
      description,
      parent,
      // order
    };

    const result = await Swal.fire({
      title: 'Apakah Anda yakin akan menyimpan perubahan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await PATCH(`/master-data/${id}`, requestBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Data Master Data berhasil diubah!",
        }).then(() => {
          Swal.close();
          fetchData()
        });
        router("/daftar-master-data")
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleChangeOrder = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === 'palingAtas') {
      setFormData((prevData) => ({
        ...prevData,
        order: "1",
      }));
    } else if (selectedValue === 'palingBawah') {
      const maxOrder = Math.max(...order.map(item => item.order));
      setFormData((prevData) => ({
        ...prevData,
        order: (maxOrder + 1).toString(),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        order: selectedValue,
      }));
    }

    setShowNested(selectedValue !== 'palingAtas' && selectedValue !== 'palingBawah' && selectedValue !== '');

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      name: formData.name,
      type: formData.type,
      parent: formData.parent,
      description: formData.description,
      order: formData.order === null || formData.order === "" ? 1 : formData.order
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await POST(`/master-data`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil membuat master data!",
      }).then(() => {
        Swal.close();
        setFormData({
          name: "",
          type: "",
          parent: "",
          description: "",
          order: "",
        });
        router("/daftar-master-data");
      });
    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Master Data`}
        motherMenu="Daftar Master Data"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Master Data</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/daftar-master-data`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {flag !== "Tambah" ? (
                  <form className="form-valide" onSubmit={handleEditSubmit}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Nama<span className="text-danger">*</span></label>
                          <input
                            type='text'
                            className='form-control'
                            name="name"
                            value={editFormData.name}
                            onChange={handleChangeEdit}
                            maxLength="100"
                            minLength="1"
                            disabled={flag === "Detail"}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Nama harus diisi minimal 1 karakter.
                          </Form.Control.Feedback>
                        </div>

                        {config.filter(x => x.type === editFormData.type)[0]?.parent && (
                          <div className='form-group'>
                            <label>Parent</label>
                            <select
                              className='form-control'
                              value={editFormData.parent}
                              name="parent"
                              onChange={handleChangeEdit}
                              required
                              disabled={flag === "Detail"}
                            >
                              <option>Pilih Parent</option>
                              {Array.isArray(parent) && parent.map((parentData) => (
                                <option
                                  key={parentData.id}
                                  value={parentData.id}
                                >
                                  {parentData.name}
                                </option>
                              ))}
                            </select>
                            <Form.Control.Feedback type="invalid">
                              Parent harus diisi.
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </div>

                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Tipe<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={editFormData.type}
                            name="type"
                            onChange={handleChangeEdit}
                            required
                            disabled={flag === "Detail"}
                          >
                            {Array.isArray(config) && config.map((configData) => (
                              <option
                                key={configData.id}
                                value={configData.type}
                              >
                                {configData.type}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Tipe harus diisi.
                          </Form.Control.Feedback>
                        </div>

                        <div className='form-group'>
                          <label>Status</label>
                          <input
                            type='text'
                            className='form-control'
                            value={getStatus(editFormData.status)}
                            disabled
                          />
                        </div>
                      </div>

                      <div className='form-group'>
                        <label>Deskripsi<span className="text-danger">*</span></label>
                        <textarea
                          name="description"
                          className='form-control'
                          rows="5"
                          value={editFormData.description}
                          onChange={handleChangeEdit}
                          maxLength="400"
                          // minLength="1"
                          required
                          disabled={flag === "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          Deskripsi harus diisi.
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    {flag === "Detail" ? (
                      ""
                    ) : (
                      <div className="form-group mb-3 d-flex">
                        <div className="col-xl-6">
                          <Link className="btn btn-light w-100" to={`/daftar-master-data`}>
                            Batal
                          </Link>
                        </div>
                        <div className="col-xl-6">
                          <button type="submit" className="btn btn-primary w-100">
                            Simpan Perubahan
                          </button>
                        </div>
                      </div>
                    )}

                  </form>
                ) : (
                  <form className="form-valide" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Nama<span className="text-danger">*</span></label>
                          <input
                            type='text'
                            className='form-control'
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            maxLength="100"
                            minLength="1"
                            disabled={flag === "Detail"}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Nama harus diisi minimal 1 karakter.
                          </Form.Control.Feedback>
                        </div>

                        <div className='form-group'>
                          <label>Deskripsi<span className="text-danger">*</span></label>
                          <textarea
                            name="description"
                            className='form-control'
                            rows="5"
                            value={formData.description}
                            onChange={handleChange}
                            maxLength="200"
                            required
                            disabled={flag === "Detail"}
                          />
                          <Form.Control.Feedback type="invalid">
                            Deskripsi harus diisi.
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Tipe<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.type}
                            name="type"
                            onChange={handleChange}
                            required
                            disabled={flag === "Detail"}
                          >
                            <option key="" value="">Pilih Tipe</option>
                            {Array.isArray(config) && config.map((configData) => (
                              <option
                                key={configData.id}
                                value={configData.type}
                              >
                                {configData.type}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Tipe harus diisi.
                          </Form.Control.Feedback>
                        </div>
                        {order.length > 0 && (
                          <Form.Group controlId="posisi">
                            {/* Tipe Letak/Posisi */}
                            <Form.Label>Letak/Posisi</Form.Label>
                            <Form.Control as="select" onChange={handleChangeOrder} required>
                              <option value="">Pilih Letak/Posisi</option>
                              <option value="palingAtas">Paling Atas</option>
                              {order && order.length > 1 && (
                                <option value="setelahMateri">Setelah Data</option>
                              )}
                              {order && order.length > 0 && (
                                <option value="palingBawah">Paling Bawah</option>
                              )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Harus pilih salah satu.
                            </Form.Control.Feedback>

                            {showNested && (
                              <Form.Group className="mt-3" controlId="typeSelection">
                                <Form.Label>Pilih Materi</Form.Label>
                                <Form.Control as="select" onChange={handleChangeOrder}>
                                  {order && order
                                    .sort((a, b) => a.order - b.order)
                                    .map((dataOrder) => (
                                      <option key={dataOrder.id} value={dataOrder.order + 1}>
                                        {dataOrder.name}
                                      </option>
                                    ))
                                  }
                                </Form.Control>
                              </Form.Group>
                            )}
                          </Form.Group>
                        )}
                        {config.filter(x => x.type === formData.type)[0]?.parent && (
                          <div className='form-group'>
                            <label>Parent</label>
                            <select
                              className='form-control'
                              value={formData.parent}
                              name="parent"
                              onChange={handleChange}
                              required
                              disabled={flag === "Detail"}
                            >
                              <option>Pilih Parent</option>
                              {Array.isArray(parent) && parent.map((parentData) => (
                                <option
                                  key={parentData.id}
                                  value={parentData.id}
                                >
                                  {parentData.name}
                                </option>
                              ))}
                            </select>
                            <Form.Control.Feedback type="invalid">
                              Parent harus diisi.
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-master-data`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100">
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default EditMasterData