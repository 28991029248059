import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET } from '../../../services/AxiosService';
import 'moment/locale/id';
import Swal from 'sweetalert2';

const DaftarSlotAssessment = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [kota, setKota] = useState([]);
    const [lokasi, setLokasi] = useState([]);
    const [selectedKota, setSelectedKota] = useState('');
    const [selectedLokasi, setSelectedLokasi] = useState('');
    // server side
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState("");

    const fetchData = async (page, perPage, sortField, sortOrder, search, kotaKabupaten, location) => {
        const params = {
          page,
          size: perPage,
          sort: sortField,
          orderby: sortOrder,
          search,
          city: kotaKabupaten,
          location: location
        };
        const res = await GET(`/class/slot-assessment`, params);
        setData(res?.data);
        setTotalRows(res?.meta?.amount);
    };
    const fetchKotaKabupaten = async () => {
        const res = await GET(`master-data/type?type=Kota Kabupaten`);
        setKota(res?.data);
    };
    console.log('dataSet',data)
    const fetchDataLokasi = async () => {
        const res = await GET(`master-data/type?type=Lokasi`);
        setLokasi(res?.data.filter(x => x.parent));
    };

    const fetchServerData = () => {
        fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedKota, selectedLokasi);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
        setResetPaginationToggle(!resetPaginationToggle);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSort = (column, direction) => {
        setSortField(column.field);
        setSortOrder(direction);
    };

    useEffect(() => {
        fetchServerData();
    }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedKota, selectedLokasi]);
    useEffect(() => {
        fetchKotaKabupaten();
        fetchDataLokasi();
    }, []);

    const columns = [
        {
          name: 'Kota/Kabupaten',
          selector: row => row.location?.kota_kabupaten?.name,
        },
        {
          name: 'Lokasi',
          selector: row => row.location?.name,
        },
        {
          name: 'Hari',
          selector: row => row.day.name,
        },
        {
          name: 'Waktu (WIB)',
          selector: row => row.time_start,
          cell: (row) => (
            <div>{row.time_start} - {row.time_finish}</div>
          )
        },
        {
          name: 'Kapasitas',
          selector: row => row.capacity,
        },
        {
          name: 'Aksi',
          selector: row => row.id,
          width: "130px",
          cell: (row) => (
            <div>
              <button
                className="btn btn-primary shadow btn-xs sharp"
                onClick={() => navigate(`/${row.id}/form-slot-assessment/Ubah`)}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </button>
              <Link className="btn btn-danger shadow btn-xs sharp mx-1" title="Delete" onClick={() => handleDeleteClick(row.id)}>
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        },
      ];

        const customStyles = {
            headRow:{
                style: {
                    backgroundColor: "#FCFCFD",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "black",
                },
            },
            rows:{
                style:{
                    backgroundColor: "#FFFFFF",
                    "&:nth-child(2n)": {
                        backgroundColor: "#F3F0FD",
                    },
                },
            },

        };

        const handleSelectKota = (e) => {
          setSelectedLokasi('');
            setSelectedKota(e.target.getAttribute('value'));
        };

        const handleSelectLokasi = (e) => {
            setSelectedLokasi(e.target.getAttribute('value'));
            if (e.target.getAttribute('value')) {
              setSelectedKota(kota.filter(xx => xx.id === lokasi.filter(x => x.name === e.target.getAttribute('value'))[0].parent)[0].name);
            }
        };

        const handleDeleteClick = async (id) => {
            const result = await Swal.fire({
            title: "Yakin ingin hapus data?",
            text: "Data yang dihapus tidak dapat dikembalikan!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ya",
            cancelButtonText: "Batal",
            });

            if (result.isConfirmed) {
            Swal.fire({
                title: "Loading...",
                allowOutsideClick: false,
                didOpen: () => {
                Swal.showLoading();
                },
            });

            const res = await DELETE(`/class/slot-assessment/${id}`);
            if (res) {
                fetchData();
                Swal.fire({
                icon: "success",
                title: "Berhasil menghapus slot assessment!",
                }).then(() => {
                Swal.close();
                });
            }
            }
        };

        return (
            <Fragment>
              <Row>
                <Col lg={12}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Daftar Slot Assessment</Card.Title>
                    </Card.Header>
                    <div className="d-flex mt-4">
                      <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex px-4">
                        <Dropdown className="mr-4">
                          <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey", height: "55px" }} id="dropdown-basic">
                            {selectedKota?.length > 0 ? selectedKota : "Semua Kota/Kabupaten"}
                          </Dropdown.Toggle>
        
                          <Dropdown.Menu>
                            <Dropdown.Item key="all" value="" onClick={handleSelectKota}>
                              Semua Kota/Kabupaten
                            </Dropdown.Item>
                            {Array.isArray(kota) && (kota).map((kotas) => (
                              <Dropdown.Item key={kotas.id} value={kotas.name} onClick={handleSelectKota}>
                                {kotas.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>


                        <Dropdown className="mr-4">
                          <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey", height: "55px" }} id="dropdown-basic">
                            {selectedLokasi?.length > 0 ? selectedLokasi : "Semua Lokasi"}
                          </Dropdown.Toggle>
        
                          <Dropdown.Menu>
                            <Dropdown.Item key="all" value="" onClick={handleSelectLokasi}>
                              Semua Lokasi
                            </Dropdown.Item>
                            {Array.isArray(lokasi) && (lokasi).map((lokasis) => (
                              <Dropdown.Item key={lokasis.id} value={lokasis.name} parent={lokasis.parent} onClick={handleSelectLokasi}>
                                {lokasis.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
        
                      <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end px-4">
                        <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-slot-assessment/Tambah`)}>
                          Tambah Slot
                        </button>
                        <div className="input-group search-area d-xl-inline-flex">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Cari Slot Assessment"
                            value={searchTerm}
                            onChange={handleSearchChange}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <Link to="#">
                                <i className="flaticon-381-search-2" />
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Card.Body>
                      {data && data.length > 0 ? (
                        <DataTable
                          columns={columns}
                          data={data}
                          customStyles={customStyles}
                          pagination
                          paginationServer
                          paginationRowsPerPageOptions={[10, 25, 50]}
                          paginationResetDefaultPage={resetPaginationToggle}
                          paginationPerPage={perPage}
                          paginationTotalRows={totalRows}
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handlePerPageChange}
                          onSort={handleSort}
                          defaultSortFieldId={3}
                          defaultSortAsc={sortOrder === 'asc'}
                        />
                      ) : (
                        <p>Tidak ada data</p>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Fragment>
          )
}

export default DaftarSlotAssessment;
