import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GET, PATCH } from "../../../services/AxiosService";
import Swal from "sweetalert2";

const FormPeminatKotaLain = () => {
  const location = useLocation();
  const { id, flag } = useParams();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const [formData, setFormData] = useState({
    name: "",
    hp_no: "",
    enthusiast_name: "",
  });
  const [validated, setValidated] = useState(false);

  const fetchData = async () => {
    const res = await GET(`/enthusiast-other-city/${id}`);

    setFormData({
      name: res?.data.user_name,
      hp_no: res?.data.user_hp_no,
      enthusiast_name: res?.data.enthusiast_name,
    });
  };

  useEffect(() => {
    fetchData();
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      enthusiast_name: formData.enthusiast_name,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/enthusiast-other-city/${id}`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil mengubah peminat kota lain!",
      }).then(() => {
        Swal.close();
        setFormData({
          name: "",
          hp_no: "",
          enthusiast_name: "",
        });
        router("/daftar-peminat-kota-lain")
      });

    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Daftar Peminat Kota Lain`}
        motherMenu="Daftar Peminat Kota Lain"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Peminat Kota Lain</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form className="form-valide" onSubmit={handleEditSubmit}>
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className='form-group col-md-6 col-1'>
                        <label>Nama Pelajar<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          type='text'
                          value={formData.name}
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-6 col-1'>
                        <label>No HP<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          type='text'
                          value={formData.hp_no}
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-6 col-1'>
                        <label>Kota<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="enthusiast_name"
                          type='text'
                          value={formData.enthusiast_name}
                          onChange={handleChange}
                          minLength="3"
                          maxLength="20"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Kota harus diisi minimal 3 karakter.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 d-flex">
                    <div className="col-xl-6">
                      <Link className="btn btn-light w-100" to={`/daftar-peminat-kota-lain`}>
                        Batal
                      </Link>
                    </div>
                    <div className="col-xl-6">
                      <button type="submit" className="btn btn-primary w-100">
                        Simpan Perubahan
                      </button>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormPeminatKotaLain