import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Badge } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET, PATCH } from '../../../services/AxiosService';
import Swal from 'sweetalert2';
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";

const TentangyayasanAplikasi = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [tipe, setTipe] = useState([]);
  const [selectedTipe, setSelectedTipe] = useState("");
  const [selectedNameTipe, setSelectedNameTipe] = useState("");

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");

  const fetchData = async (page, perPage, sortField, sortOrder, search, selectedTipe) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      me: selectedTipe
    };
    const res = await GET(`/about`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };

  const fetchDataTipe = async () => {
    const res = await GET(`master-data/type?type=Tentang`);
    setTipe(res?.data);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedTipe);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedTipe]);

  useEffect(() => {
    fetchDataTipe();
  }, []);

  const columns = [
    {
      name: 'Tipe',
      selector: row => row.masterDataAsType?.name,
    },
    {
      name: 'Judul',
      selector: row => row.title,
    },
    {
      name: 'Tanggal Update',
      selector: row => {
        const date = new Date(row.updated_at);
        const formattedDate = date?.toLocaleDateString('id-ID', {
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        });
        return `${formattedDate}`;
      },
    },
    {
      name: 'Updated By',
      selector: row => row.updated_by,
    },
    {
      name: 'Status',
      selector: row => row.status,
      width: "130px",
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Aktif
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Tidak Aktif
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      }
    },
    {
      name: 'Urutan',
      selector: row => row.order,
      width: "130px",
      cell: (row) => {
        const maxOrder = Math.max(...data.map(item => item.order));
        return (
          <div>
            <Link className={`btn btn-success shadow btn-xs sharp mx-1 ${row.order === 1 ? 'disabled' : ''}`} title="up" onClick={() => handleUpClick(row.id)}>
              <i className="fa fa-arrow-up"></i>
            </Link>
            <Link className={`btn btn-success shadow btn-xs sharp ${row.order === maxOrder ? 'disabled' : ''}`} title="down" onClick={() => handleDownClick(row.id)}>
              <i className="fa fa-arrow-down"></i>
            </Link>
          </div>
        )
      },
    },
    {
      name: 'Aksi',
      selector: row => row.id,
      width: "150px",
      cell: (row) => (
        <div>
          {row.status === 1 ? (
            <Link
              className="btn btn-danger shadow btn-xs sharp"
              onClick={() => handleLockOpenClick(row.id)}
              title="Non-Aktif"
            >
              <i className="fa fa-lock"></i>
            </Link>
          ) : (
            <Link
              className="btn btn-success shadow btn-xs sharp"
              onClick={() => handleLockOpenClick(row.id)}
              title="Aktif"
            >
              <i className="fa fa-unlock"></i>
            </Link>
          )}

          <button
            className="btn btn-info shadow btn-xs sharp mx-1"
            title="Detail"
            onClick={() => navigate(`/${row.id}/form-tentang/Detail`)}
          >
            <i className="fa fa-eye"></i>
          </button>

          <button
            className="btn btn-primary shadow btn-xs sharp"
            title="Edit"
            onClick={() => navigate(`/${row.id}/form-tentang/Ubah`)}
          >
            <i className="fa fa-pencil"></i>
          </button>

          <Link className="btn btn-danger shadow btn-xs sharp ml-1" title="delete" onClick={() => handleDeleteClick(row.id)}>
            <i className="fa fa-trash"></i>
          </Link>
        </div>
      ),
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleLockOpenClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/about/${id}/lockunlock`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: `${res?.message}`,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };

  const handleUpClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/about/${id}/move-up`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Urutan dipindah ke atas!",
      }).then(() => {
        Swal.close();
        fetchData();
      });
    }
  };
  const handleDownClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/about/${id}/move-down`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Urutan dipindah ke bawah!",
      }).then(() => {
        Swal.close();
        fetchData();
      });
    }
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/about/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus tentang!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  const handleSelectTipe = (e) => {
    setSelectedTipe(e.target.getAttribute("value"));
    setSelectedNameTipe(e.target.textContent);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Tentang Yayasan dan Aplikasi</Card.Title>
            </Card.Header>
            <div className="d-flex mt-4">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                    }}
                    id="dropdown-basic"
                  >
                    {selectedTipe?.length > 0
                      ? selectedNameTipe
                      : "Semua Tipe"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      key="all"
                      value=""
                      onClick={handleSelectTipe}
                    >
                      Semua Tipe
                    </Dropdown.Item>
                    {Array.isArray(tipe) &&
                      tipe.map((pelajarTipe) => (
                        <Dropdown.Item
                          key={pelajarTipe.id}
                          value={pelajarTipe.name}
                          onClick={handleSelectTipe}
                        >
                          {pelajarTipe.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end px-4">
                <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-tentang/Tambah`)}>
                  Tambah Tentang
                </button>
                <div className="input-group search-area d-xl-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari Tentang"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                // defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default TentangyayasanAplikasi