import React, { Fragment, useEffect, useState, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link, useParams, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import moment from "moment";
const FormPanduan = () => {
  const { id, flag } = useParams();
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState(null);
  const [isNewFileSelected, setIsNewFileSelected] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [order, setOrder] = useState([]);
  const [formData, setFormData] = useState({
    topic: "",
    image: "",
    content: "",
    order: "",
    last_updated: "",
  });
  const navigate = useNavigate();
  const fileSizeLimit = 2 * 1024 * 1024;
  const [showNested, setShowNested] = useState(false);
  useEffect(() => {
    if (id) {
      fetchData();
    }
    fetchDataletak();
  }, []);
  const fetchData = async () => {
    const res = await GET(`/guide/${id}`);
    setFormData(res?.data);
    //setOrder(res?.data);
  };

  const fetchDataletak = async () => {
    const res = await GET(`/guide`);
    setOrder(res.data);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const form = e.currentTarget.form;
    const isValid = form.checkValidity();
    setValidated(isValid);
    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > fileSizeLimit) {
        return Swal.fire({
          icon: "error",
          title: `Ukuran file melebihi batas maksimum (2 MB)`,
        });
      }
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
      if (!allowedTypes.includes(selectedFile.type)) {
        return Swal.fire({
          icon: "error",
          title: `File harus dalam format (jpg, jpeg, png, gif)`,
        });
      }
      setFile(selectedFile);
      setValidated(true);
      setIsNewFileSelected(true); // Set the flag when a new file is selected
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleChangeOrder = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "palingAtas") {
      setFormData((prevData) => ({
        ...prevData,
        order: "1",
      }));
    } else if (selectedValue === "palingBawah") {
      const maxOrder = Math.max(...order.map((item) => item.order));
      setFormData((prevData) => ({
        ...prevData,
        order: (maxOrder + 1).toString(),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        order: selectedValue,
      }));
    }

    setShowNested(selectedValue !== "palingAtas" && selectedValue !== "palingBawah" && selectedValue !== "");

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan perubahan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let { topic, content, order } = formData;

      if (file) {
        // Jika ada file, upload dan dapatkan responsnya
        const formDataObject = new FormData();
        formDataObject.append("type", "content_image");
        formDataObject.append("file", file);

        const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });

        // Set `image` dengan nama atau URL dari file yang diunggah (sesuai kebutuhan API)
        formData.image = fileUploadResponse?.file?.id || fileUploadResponse?.file?.url;
      }

      // Buat request body
      const requestBody = {
        topic,
        image: formData.image,
        order,
        content,
      };

      const requestBodyy = {
        topic,
        image: formData.image,
        content,
      };

      let res;
      if (id) {
        res = await PATCH(`/guide/${id}`, requestBodyy);
      } else {
        res = await POST(`/guide`, requestBody);
      }

      if (res) {
        Swal.fire({
          icon: "success",
          title: "Data Konten berhasil diubah!",
        }).then(() => {
          Swal.close();
          navigate(`/daftar-panduan`);
        });
      }
    }
  };

  // useEffect(() => {
  //   console.log("tets", flag); // Pastikan nilai flag adalah 'tambah' atau 'Tambah'
  // }, [flag]);

  return (
    <Fragment>
      <PageTitle activeMenu={`${flag} Panduan`} motherMenu="Daftar Panduan" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            {flag === "Detail" && (
              <div className="card-header">
                <div></div>
                <Link className="btn btn-primary mr-2" to={`/daftar-panduan`}>
                  Kembali
                </Link>
              </div>
            )}
            <div className="card-body">
              <div className="form-validation mt-3">
                {flag === "Tambah" ? (
                  <form className="form-valide" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xl-4">
                        <div className="form-group">
                          <label>
                            Topik Panduan<span className="text-danger">*</span>
                          </label>
                          <input disabled={flag === "detail"} className="form-control" value={formData.topic} name="topic" onChange={handleChange} required />
                          <Form.Control.Feedback type="invalid">Page harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>
                            Gambar<span className="text-danger">*</span>
                          </label>
                          {/* {!formData.type || contentType.filter(x => x.id === formData.type)[0]?.name.includes('Gambar') ? ( */}
                          <>
                            {!id && (
                              <div className="input-group">
                                <button type="button" className="btn btn-primary" disabled={flag === "detail"} onClick={() => fileInputRef.current.click()}>
                                  Pilih File
                                </button>
                                <span className="input-group-text">{file ? (file.name.length > 25 ? `${file.name.slice(0, 25)}...` : file.name) : "Tidak ada file yang dipilih..."}</span>
                                <input type="file" ref={fileInputRef} className="d-none" onChange={handleFileChange} accept=".jpg, .jpeg, .png, .gif" required isInvalid={file === null && validated} />
                                {file && <Form.Control.Feedback type="invalid">Konten harus diisi.</Form.Control.Feedback>}
                              </div>
                            )}
                            {imagePreviewUrl && flag !== "detail" && isNewFileSelected && (
                              <div className="d-flex flex-column my-1">
                                <Form.Label>{!id ? "Preview" : "Foto Panduan"}</Form.Label>
                                <img src={imagePreviewUrl} alt="Foto Panduan Preview" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                              </div>
                            )}
                            <div>
                              {id && !isNewFileSelected && formData && formData.image ? (
                                <div>
                                  {formData?.image ? (
                                    <img src={`${process.env.REACT_APP_API_BASE_URL}files/${formData.image}/download`} alt="image" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                                  ) : (
                                    <p>Tidak Ada Photo</p>
                                  )}
                                </div>
                              ) : null}
                              {flag === "Edit" && (
                                <div>
                                  <div className="input-group">
                                    <button type="button" className="btn btn-primary my-2" onClick={() => fileInputRef.current.click()}>
                                      Ganti Foto
                                    </button>
                                  </div>
                                  <input type="file" ref={fileInputRef} className="d-none" onChange={handleFileChange} accept=".jpg, .jpeg, .png, .gif" />
                                  <Form.Control.Feedback type="invalid">Silakan pilih file foto konten</Form.Control.Feedback>
                                </div>
                              )}
                            </div>
                          </>
                          {/* ) : ( */}
                          {flag === "Detail" && (
                            <>
                              <textarea
                                className="form-control"
                                rows="5"
                                value={formData.image}
                                name="image"
                                onChange={handleChange}
                                maxLength="300"
                                minLength="3"
                                required
                                disabled // Jika ingin textarea hanya tampilan saja tanpa bisa diubah pada kondisi detail
                              />
                              <Form.Control.Feedback type="invalid">Konten harus diisi minimal 3 karakter.</Form.Control.Feedback>
                            </>
                          )}
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-4">
                        <div className="form-group">
                          <label>
                            Isi Panduan<span className="text-danger">*</span>
                          </label>
                          <textarea name="content" className="form-control" rows="5" value={formData.content} onChange={handleChange} maxLength="1000" disabled={flag === "Detail"} required />
                          <Form.Control.Feedback type="invalid">Page harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>
                        {/* <div className="form-group">
                        <Form.Label>Letak/Posisi</Form.Label>
                        <Form.Control as="select" onChange={handleChangeOrder} required>
                          <option value="">Pilih Letak/Posisi</option>
                          <option value="palingAtas">Paling Atas</option>
                          {order && order.length > 1 && (
                            <option value="setelahMateri">Setelah Data</option>
                          )} 
                          {order && order.length > 0 && (
                            <option value="palingBawah">Paling Bawah</option>
                          )} 
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Harus pilih salah satu.
                        </Form.Control.Feedback>

                        {showNested && (
                          <Form.Group className="mt-3" controlId="typeSelection">
                            <Form.Label>Pilih Data</Form.Label>
                            <Form.Control as="select" onChange={handleChangeOrder}>
                              {order && order
                                .sort((a, b) => a.order - b.order)
                                .map((dataOrder) => (
                                  <option key={dataOrder.id} value={dataOrder.order + 1}>
                                    {dataOrder.topic}
                                  </option>
                                ))
                              }
                            </Form.Control>
                          </Form.Group>
                        )}
                      </div>  */}
                        <Form.Group controlId="posisi">
                          {/* Tipe Letak/Posisi */}
                          {flag !== "Edit" && flag !== "Detail" && (
                            <>
                              <Form.Label>Letak/Posisi</Form.Label>
                              <Form.Control as="select" onChange={handleChangeOrder} required>
                                <option value="">Pilih Letak/Posisi</option>
                                <option value="palingAtas">Paling Atas</option>
                                {order && order.length > 1 && <option value="setelahMateri">Setelah Data</option>}
                                {order && order.length > 0 && <option value="palingBawah">Paling Bawah</option>}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                              {showNested && (
                                <Form.Group className="mt-3" controlId="typeSelection">
                                  <Form.Label>Pilih Data</Form.Label>
                                  <Form.Control as="select" onChange={handleChangeOrder}>
                                    {order &&
                                      order
                                        .sort((a, b) => a.order - b.order)
                                        .map((dataOrder) => (
                                          <option key={dataOrder.id} value={dataOrder.order + 1}>
                                            {dataOrder.topic}
                                          </option>
                                        ))}
                                  </Form.Control>
                                </Form.Group>
                              )}
                            </>
                          )}
                        </Form.Group>
                        {flag === "Detail" && (
                          <div className="row">
                            <div className="col-xl-4">
                              <div className="form-group" style={{ whiteSpace: "nowrap" }}>
                                <label style={{ whiteSpace: "nowrap" }}>Last Updated</label>
                                <input disabled type="text" name="last_updated" className="form-control" style={{ width: "100%" }} value={moment(formData.last_updated).format("DD MMMM YYYY")} />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {flag !== "Detail" && (
                      <div className="form-group mb-3 d-flex">
                        <div className="col-xl-6">
                          <Link className="btn btn-light w-100" to={`/daftar-panduan`}>
                            Batal
                          </Link>
                        </div>
                        <div className="col-xl-6">
                          <button type="submit" className="btn btn-primary w-100" disabled={!validated}>
                            Simpan Perubahan
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                ) : flag === "Edit" ? (
                  <form className="form-valide" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xl-4">
                        <div className="form-group">
                          <label>
                            Topik Panduan<span className="text-danger">*</span>
                          </label>
                          <input disabled={flag === "detail"} className="form-control" value={formData.topic} name="topic" onChange={handleChange} required />
                          <Form.Control.Feedback type="invalid">Page harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>
                            Gambar<span className="text-danger">*</span>
                          </label>
                          {/* {!formData.type || contentType.filter(x => x.id === formData.type)[0]?.name.includes('Gambar') ? ( */}
                          <>
                            {!id && (
                              <div className="input-group">
                                <button type="button" className="btn btn-primary" disabled={flag === "detail"} onClick={() => fileInputRef.current.click()}>
                                  Pilih File
                                </button>
                                <span className="input-group-text">{file ? (file.name.length > 25 ? `${file.name.slice(0, 25)}...` : file.name) : "Tidak ada file yang dipilih..."}</span>
                                <input type="file" ref={fileInputRef} className="d-none" onChange={handleFileChange} accept=".jpg, .jpeg, .png, .gif" required isInvalid={file === null && validated} />
                                {file && <Form.Control.Feedback type="invalid">Konten harus diisi.</Form.Control.Feedback>}
                              </div>
                            )}
                            {imagePreviewUrl && flag !== "detail" && isNewFileSelected && (
                              <div className="d-flex flex-column my-1">
                                <Form.Label>{!id ? "Preview" : "Foto Panduan"}</Form.Label>
                                <img src={imagePreviewUrl} alt="Foto Panduan Preview" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                              </div>
                            )}
                            <div>
                              {id && !isNewFileSelected && formData && formData.image ? (
                                <div>
                                  {formData?.image ? (
                                    <img src={`${process.env.REACT_APP_API_BASE_URL}files/${formData.image}/download`} alt="image" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                                  ) : (
                                    <p>Tidak Ada Photo</p>
                                  )}
                                </div>
                              ) : null}
                              {flag === "Edit" && (
                                <div>
                                  <div className="input-group">
                                    <button type="button" className="btn btn-primary my-2" onClick={() => fileInputRef.current.click()}>
                                      Ganti Foto
                                    </button>
                                  </div>
                                  <input type="file" ref={fileInputRef} className="d-none" onChange={handleFileChange} accept=".jpg, .jpeg, .png, .gif" />
                                  <Form.Control.Feedback type="invalid">Silakan pilih file foto konten</Form.Control.Feedback>
                                </div>
                              )}
                            </div>
                          </>
                          {/* ) : ( */}
                          {flag === "Detail" && (
                            <>
                              <textarea
                                className="form-control"
                                rows="5"
                                value={formData.image}
                                name="image"
                                onChange={handleChange}
                                maxLength="300"
                                minLength="3"
                                required
                                disabled // Jika ingin textarea hanya tampilan saja tanpa bisa diubah pada kondisi detail
                              />
                              <Form.Control.Feedback type="invalid">Konten harus diisi minimal 3 karakter.</Form.Control.Feedback>
                            </>
                          )}
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-4">
                        <div className="form-group">
                          <label>
                            Isi Panduan<span className="text-danger">*</span>
                          </label>
                          <textarea name="content" className="form-control" rows="5" value={formData.content} onChange={handleChange} maxLength="1000" disabled={flag === "Detail"} required />
                          <Form.Control.Feedback type="invalid">Page harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>
                        {/* <div className="form-group">
                        <Form.Label>Letak/Posisi</Form.Label>
                        <Form.Control as="select" onChange={handleChangeOrder} required>
                          <option value="">Pilih Letak/Posisi</option>
                          <option value="palingAtas">Paling Atas</option>
                          {order && order.length > 1 && (
                            <option value="setelahMateri">Setelah Data</option>
                          )} 
                          {order && order.length > 0 && (
                            <option value="palingBawah">Paling Bawah</option>
                          )} 
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Harus pilih salah satu.
                        </Form.Control.Feedback>

                        {showNested && (
                          <Form.Group className="mt-3" controlId="typeSelection">
                            <Form.Label>Pilih Data</Form.Label>
                            <Form.Control as="select" onChange={handleChangeOrder}>
                              {order && order
                                .sort((a, b) => a.order - b.order)
                                .map((dataOrder) => (
                                  <option key={dataOrder.id} value={dataOrder.order + 1}>
                                    {dataOrder.topic}
                                  </option>
                                ))
                              }
                            </Form.Control>
                          </Form.Group>
                        )}
                      </div>  */}
                        <Form.Group controlId="posisi">
                          {/* Tipe Letak/Posisi */}
                          {flag !== "Edit" && flag !== "Detail" && (
                            <>
                              <Form.Label>Letak/Posisi</Form.Label>
                              <Form.Control as="select" onChange={handleChangeOrder} required>
                                <option value="">Pilih Letak/Posisi</option>
                                <option value="palingAtas">Paling Atas</option>
                                {order && order.length > 1 && <option value="setelahMateri">Setelah Data</option>}
                                {order && order.length > 0 && <option value="palingBawah">Paling Bawah</option>}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                              {showNested && (
                                <Form.Group className="mt-3" controlId="typeSelection">
                                  <Form.Label>Pilih Data</Form.Label>
                                  <Form.Control as="select" onChange={handleChangeOrder}>
                                    {order &&
                                      order
                                        .sort((a, b) => a.order - b.order)
                                        .map((dataOrder) => (
                                          <option key={dataOrder.id} value={dataOrder.order + 1}>
                                            {dataOrder.topic}
                                          </option>
                                        ))}
                                  </Form.Control>
                                </Form.Group>
                              )}
                            </>
                          )}
                        </Form.Group>
                        {flag === "Detail" && (
                          <div className="row">
                            <div className="col-xl-4">
                              <div className="form-group" style={{ whiteSpace: "nowrap" }}>
                                <label style={{ whiteSpace: "nowrap" }}>Last Updated</label>
                                <input disabled type="text" name="last_updated" className="form-control" style={{ width: "100%" }} value={moment(formData.last_updated).format("DD MMMM YYYY")} />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {flag !== "Detail" && (
                      <div className="form-group mb-3 d-flex">
                        <div className="col-xl-6">
                          <Link className="btn btn-light w-100" to={`/daftar-panduan`}>
                            Batal
                          </Link>
                        </div>
                        <div className="col-xl-6">
                          <button type="submit" className="btn btn-primary w-100" disabled={!validated}>
                            Simpan Perubahan
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                ) : flag === "Detail" ? (
                  <form className="form-valide">
                    <div className="row">
                      <div className="col-xl-4">
                        <div className="form-group">
                          <label>
                            Topik Panduan<span className="text-danger">*</span>
                          </label>
                          <input disabled={flag === "detail"} className="form-control" value={formData.topic} name="topic" onChange={handleChange} required />
                          <Form.Control.Feedback type="invalid">Page harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>
                            Gambar<span className="text-danger">*</span>
                          </label>
                          {/* {!formData.type || contentType.filter(x => x.id === formData.type)[0]?.name.includes('Gambar') ? ( */}
                          <>
                            {!id && (
                              <div className="input-group">
                                <button type="button" className="btn btn-primary" disabled={flag === "detail"} onClick={() => fileInputRef.current.click()}>
                                  Pilih File
                                </button>
                                <span className="input-group-text">{file ? (file.name.length > 25 ? `${file.name.slice(0, 25)}...` : file.name) : "Tidak ada file yang dipilih..."}</span>
                                <input type="file" ref={fileInputRef} className="d-none" onChange={handleFileChange} accept=".jpg, .jpeg, .png, .gif" required isInvalid={file === null && validated} />
                                {file && <Form.Control.Feedback type="invalid">Konten harus diisi.</Form.Control.Feedback>}
                              </div>
                            )}
                            {imagePreviewUrl && flag !== "detail" && isNewFileSelected && (
                              <div className="d-flex flex-column my-1">
                                <Form.Label>{!id ? "Preview" : "Foto Panduan"}</Form.Label>
                                <img src={imagePreviewUrl} alt="Foto Panduan Preview" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                              </div>
                            )}
                            <div>
                              {id && !isNewFileSelected && formData && formData.image ? (
                                <div>
                                  {formData?.image ? (
                                    <img src={`${process.env.REACT_APP_API_BASE_URL}files/${formData.image}/download`} alt="image" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                                  ) : (
                                    <p>Tidak Ada Photo</p>
                                  )}
                                </div>
                              ) : null}
                              {flag === "Edit" && (
                                <div>
                                  <div className="input-group">
                                    <button type="button" className="btn btn-primary my-2" onClick={() => fileInputRef.current.click()}>
                                      Ganti Foto
                                    </button>
                                  </div>
                                  <input type="file" ref={fileInputRef} className="d-none" onChange={handleFileChange} accept=".jpg, .jpeg, .png, .gif" />
                                  <Form.Control.Feedback type="invalid">Silakan pilih file foto konten</Form.Control.Feedback>
                                </div>
                              )}
                            </div>
                          </>
                          {/* ) : ( */}
                          {flag === "Detail" && (
                            <>
                              <textarea
                                className="form-control"
                                rows="5"
                                value={formData.image}
                                name="image"
                                onChange={handleChange}
                                maxLength="300"
                                minLength="3"
                                required
                                disabled // Jika ingin textarea hanya tampilan saja tanpa bisa diubah pada kondisi detail
                              />
                              <Form.Control.Feedback type="invalid">Konten harus diisi minimal 3 karakter.</Form.Control.Feedback>
                            </>
                          )}
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-4">
                        <div className="form-group">
                          <label>
                            Isi Panduan<span className="text-danger">*</span>
                          </label>
                          <textarea name="content" className="form-control" rows="5" value={formData.content} onChange={handleChange} maxLength="1000" disabled={flag === "Detail"} required />
                          <Form.Control.Feedback type="invalid">Page harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>
                        {/* <div className="form-group">
                        <Form.Label>Letak/Posisi</Form.Label>
                        <Form.Control as="select" onChange={handleChangeOrder} required>
                          <option value="">Pilih Letak/Posisi</option>
                          <option value="palingAtas">Paling Atas</option>
                          {order && order.length > 1 && (
                            <option value="setelahMateri">Setelah Data</option>
                          )} 
                          {order && order.length > 0 && (
                            <option value="palingBawah">Paling Bawah</option>
                          )} 
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Harus pilih salah satu.
                        </Form.Control.Feedback>

                        {showNested && (
                          <Form.Group className="mt-3" controlId="typeSelection">
                            <Form.Label>Pilih Data</Form.Label>
                            <Form.Control as="select" onChange={handleChangeOrder}>
                              {order && order
                                .sort((a, b) => a.order - b.order)
                                .map((dataOrder) => (
                                  <option key={dataOrder.id} value={dataOrder.order + 1}>
                                    {dataOrder.topic}
                                  </option>
                                ))
                              }
                            </Form.Control>
                          </Form.Group>
                        )}
                      </div>  */}
                        <Form.Group controlId="posisi">
                          {/* Tipe Letak/Posisi */}
                          {flag !== "Edit" && flag !== "Detail" && (
                            <>
                              <Form.Label>Letak/Posisi</Form.Label>
                              <Form.Control as="select" onChange={handleChangeOrder} required>
                                <option value="">Pilih Letak/Posisi</option>
                                <option value="palingAtas">Paling Atas</option>
                                {order && order.length > 1 && <option value="setelahMateri">Setelah Data</option>}
                                {order && order.length > 0 && <option value="palingBawah">Paling Bawah</option>}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                              {showNested && (
                                <Form.Group className="mt-3" controlId="typeSelection">
                                  <Form.Label>Pilih Data</Form.Label>
                                  <Form.Control as="select" onChange={handleChangeOrder}>
                                    {order &&
                                      order
                                        .sort((a, b) => a.order - b.order)
                                        .map((dataOrder) => (
                                          <option key={dataOrder.id} value={dataOrder.order + 1}>
                                            {dataOrder.topic}
                                          </option>
                                        ))}
                                  </Form.Control>
                                </Form.Group>
                              )}
                            </>
                          )}
                        </Form.Group>
                        {flag === "Detail" && (
                          <div className="row">
                            <div className="col-xl-4">
                              <div className="form-group" style={{ whiteSpace: "nowrap" }}>
                                <label style={{ whiteSpace: "nowrap" }}>Last Updated</label>
                                <input disabled type="text" name="last_updated" className="form-control" style={{ width: "100%" }} value={moment(formData.last_updated).format("DD MMMM YYYY")} />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default FormPanduan;
