import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Badge } from 'react-bootstrap'
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { DELETE, GET, PATCH } from '../../../services/AxiosService';
import Swal from 'sweetalert2';

const DaftarMasterData = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");

  const fetchData = async (page, perPage, sortField, sortOrder, search) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
    };
    const res = await GET(`/master-data`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    localStorage.setItem('searchTerm', e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder]);

  useEffect(() => {
    const loadPaginationState = async () => {
      const savedSearchTerm = localStorage.getItem('searchTerm');
      if (savedSearchTerm) {
        setSearchTerm(savedSearchTerm);
        // Hapus setelah get data
        // localStorage.removeItem('searchTerm');
      }
    };

    loadPaginationState();
  }, []);

  const columns = [
    {
      name: 'Nama',
      selector: row => row.name,
    },
    {
      name: 'Tipe',
      selector: row => row.type,
      sortable: true,
      field: "type",
    },
    {
      name: 'Deskripsi',
      selector: row => row.description,
    },
    {
      name: 'Status',
      selector: row => row.status,
      width: "130px",
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Aktif
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Tidak Aktif
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      }
    },
    {
      name: 'Urutan',
      selector: row => row.id,
      // width: "150px",
      cell: (row) => {
        const maxOrdersByType = data.reduce((acc, item) => {
          if (!acc[item.type]) {
            acc[item.type] = item.order;
          } else {
            acc[item.type] = Math.max(acc[item.type], item.order);
          }
          return acc;
        }, {});

        const maxOrderForCurrentType = maxOrdersByType[row.type];

        return (
          <div>
            <Link className={`btn btn-success shadow btn-xs sharp mx-1 ${row.order === 1 ? 'disabled' : ''}`} title="up" onClick={() => handleUpClick(row.id)}>
              <i className="fa fa-arrow-up"></i>
            </Link>
            <Link className={`btn btn-success shadow btn-xs sharp ${row.order === maxOrderForCurrentType ? 'disabled' : ''}`} title="down" onClick={() => handleDownClick(row.id)}>
              <i className="fa fa-arrow-down"></i>
            </Link>
          </div>
        )
      },
    },
    {
      name: 'Aksi',
      selector: row => row.id,
      width: "150px",
      cell: (row) => (
        <div>
          {row.status === 1 ? (
            <Link
              className="btn btn-danger shadow btn-xs sharp"
              onClick={() => handleLockOpenClick(row.id)}
              title="Non-Aktif"
            >
              <i className="fa fa-lock"></i>
            </Link>
          ) : (
            <Link
              className="btn btn-success shadow btn-xs sharp"
              onClick={() => handleLockOpenClick(row.id)}
              title="Aktif"
            >
              <i className="fa fa-unlock"></i>
            </Link>
          )}

          <button
            className="btn btn-info shadow btn-xs sharp mx-1"
            title="Detail"
            onClick={() => navigate(`/${row.id}/form-master-data/Detail`)}
          >
            <i className="fa fa-eye"></i>
          </button>

          <button
            className="btn btn-primary shadow btn-xs sharp"
            title="Edit"
            onClick={() => navigate(`/${row.id}/form-master-data/Ubah`)}
          >
            <i className="fa fa-pencil"></i>
          </button>

          <Link className="btn btn-danger shadow btn-xs sharp ml-1" title="delete" onClick={() => handleDeleteClick(row.id)}>
            <i className="fa fa-trash"></i>
          </Link>
        </div>
      ),
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/master-data/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus master data!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  const handleLockOpenClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/master-data/${id}/lockunlock`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: `${res?.message}`,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };

  const handleUpClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/master-data/${id}/move-up`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Urutan dipindah ke atas!",
      }).then(() => {
        Swal.close();
        fetchData();
      });
    }
  };
  const handleDownClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/master-data/${id}/move-down`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Urutan dipindah ke bawah!",
      }).then(() => {
        Swal.close();
        fetchData();
      });
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Master Data</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-end mt-4 mr-5">
              <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-master-data/Tambah`)}>
                Tambah Master Data
              </button>

              <div className="input-group search-area d-xl-inline-flex">
                <input type="text" className="form-control" placeholder="Cari Master Data" value={searchTerm} onChange={handleSearchChange} />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <Link to="#">
                      <i className="flaticon-381-search-2" />
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                  defaultSortFieldId={2}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default DaftarMasterData