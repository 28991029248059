import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { GET, PATCH, POST } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";

const FormTemanBelajar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, flag } = useParams();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const [validated, setValidated] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [formData, setFormData] = useState({
    user_no_hp: "",
    user_name: "",
    recommendation_hp_no: "",
    recommendation_name: "",
    student_type_name: "",
    created_at:"",
    student_id: "",
  });

  const fetchData = async (page, perPage, sortField, sortOrder, search) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
    };
    const res = await GET(`/student-recommendation/${id}`, params);
    setFormData({
      student_type_name:res?.data?.student_type_name,
      student_id: res?.data?.student_id,
      created_at: res?.data?.created_at,
      user_no_hp: res?.data?.user_no_hp,
      user_name: res?.data?.user_name,
      recommendation_hp_no: res.data?.recommendation_hp_no,
      recommendation_name: res.data?.recommendation_name,
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  console.log('formData',formData)

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    if (flag === "Detail") {
      fetchData();
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };


  // const handleEditSubmit = async (e) => {
  //   e.preventDefault();
  //   const requestBody = {
  //     recommendation_hp_no: formData.recommendation_hp_no,
  //     recommendation_name: formData.recommendation_name,
  //   };

  //   Swal.fire({
  //     title: "Loading...",
  //     allowOutsideClick: false,
  //     didOpen: () => {
  //       Swal.showLoading();
  //     },
  //   });

  //   const res = await PATCH(`/student-recommendation/${id}`, requestBody);
  //   if (res) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Data teman belajar berhasil diubah!",
  //     }).then(() => {
  //       Swal.close();
  //       setFormData({
  //         user_no_hp: "",
  //         user_name: "",
  //         recommendation_hp_no: "",
  //         recommendation_name: "",
  //       });
  //       router("/daftar-teman-belajar-rekomendasi")
  //     });
  //   }
  // };


  const columns = [
    {
      name: 'Nama Teman Yang Merekomendasikan',
      selector: row => row.user_name,
      cell: row =>
        <button onClick={() => navigate(`/${row.student_id}/detail-pelajar/Detail`)} style={{ background: "transparent", border: "none" }}>
          <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user_name}</span>
        </button>,
    },
    {
      name: 'Nomor Teman Yang Merekomendasikan',
      selector: row => row.user_no_hp,
    },
    {
      name: 'Tipe Pelajar',
      selector: row => row.student_type_name,
    },
    {
      name: 'Waktu Rekomendasi',
      field:'created_at',
      selector: row =>{
        const tanggal = new Date(row.created_at);
        const formatTanggal = tanggal?.toLocaleDateString('id-ID',{
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        });
        const formatWaktu = tanggal?.toLocaleTimeString('id-ID', {
          hour: '2-digit',
          minute: '2-digit'
        });
        return `${formatTanggal} ${formatWaktu}`;
      },
    },
   
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
    table: {
      style: {
        marginBottom: "0",
      },
    },
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Teman Belajar Rekomendasi`}
        motherMenu="Teman Belajar Rekomendasi"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Teman Belajar</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form className="form-valide">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className='form-group '>
                        <label>Nama Teman<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="recommendation_name"
                          type='text'
                          value={formData.recommendation_name}
                          onChange={handleChange}
                          minLength="3"
                          maxLength="20"
                          disabled
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama teman harus diisi minimal 3 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>No HP Teman<span className="text-danger">*</span></label>
                        <input
                          name='recommendation_hp_no'
                          type='text'
                          className='form-control'
                          disabled
                          value={formData.recommendation_hp_no}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setFormData((prevState) => ({
                              ...prevState,
                              recommendation_hp_no: numericValue,
                            }));
                          }}
                        />
                      </div>
                      {/* <div className='form-group col-md-6 col-1'>
                        <label>Nama Pelajar (yang merekomendasikan)<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          type='text'
                          value={formData.user_name}
                          required
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          Kota harus diisi minimal 3 karakter.
                        </Form.Control.Feedback>
                      </div> */}
                      {/* <div className='form-group col-md-6 col-1'>
                        <label>No HP Pelajar (yang merekomendasikan)<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={formData.user_no_hp}
                          disabled
                        />
                      </div> */}
                    </div>
                  </div>

                  {formData ? (
                  <DataTable
                    columns={columns}
                    data={[formData]}  // Masukkan formData ke dalam array
                    customStyles={customStyles}
                    pagination
                    paginationServer
                    paginationRowsPerPageOptions={[10, 25, 50]}
                    paginationResetDefaultPage={resetPaginationToggle}
                    paginationPerPage={perPage}
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerPageChange}
                    onSort={handleSort}
                    defaultSortFieldId={1}
                  />
                  ) : (
                  <p>Tidak ada data</p>
                  )}

                  {/* {formData && formData.length > 0 ? (
                    <DataTable
                    columns={columns}
                    data={formData}
                    customStyles={customStyles}
                    />
                  ):(
                    <p> Tidak ada data</p>
                  )} */}

                  {/* <div className="form-group mb-3 d-flex">
                    <div className="col-xl-6">
                      <Link className="btn btn-light w-100" to={`/daftar-teman-belajar-rekomendasi`}>
                        Batal
                      </Link>
                    </div>
                    <div className="col-xl-6">
                      <button type="submit" className="btn btn-primary w-100">
                        Simpan Perubahan
                      </button>
                    </div>
                  </div> */}
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormTemanBelajar