export const MenuList = [
  {
    title: "Pelajar",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-graduation-cap"></i>,
    content: [
      {
        title: "Daftar Pelajar",
        to: "daftar-pelajar",
      },
      {
        title: "Daftar Teman Rekomendasi",
        to: "daftar-teman-belajar-rekomendasi",
      },
    ],
  },
  {
    title: "Kelas Private",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-university"></i>,
    content: [
      {
        title: "Slot Assessment",
        to: "daftar-slot-assessment",
      },
      {
        title: "Assessment",
        to: "jadwal-assessment",
      },
      {
        title: "Kelas",
        to: "kelola-kelas",
      },
      {
        title: "Jadwal Kelas",
        to: "jadwal-kelas",
      },
      {
        title: "Peminat Kota Lain",
        to: "daftar-peminat-kota-lain",
      },
      // {
      //     title:'Slot Assessment',
      //     to: 'daftar-slot-assessment',
      // },
    ],
  },
  {
    title: "Pencapaian",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-sticky-note"></i>,
    content: [
      {
        title: "Laporan Kelas Privat",
        to: "daftar-laporan",
      },
      {
        title: "Pencapaian Aplikasi",
        to: "daftar-pencapaian",
      },
    ],
  },
  {
    title: "Buku dan Pesanan",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-book"></i>,
    content: [
      {
        title: "Daftar Buku",
        to: "daftar-buku",
      },
      {
        title: "Paket Buku",
        to: "daftar-paket-buku",
      },
      {
        title: "Pesanan Buku",
        to: "daftar-pesanan-buku",
      },
    ],
  },
  {
    title: "Donasi dan Infaq",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-book"></i>,
    content: [
      {
        title: "Daftar Donasi dan Infaq",
        to: "daftar-donasi",
      },
      {
        title: "Alokasi Buku",
        to: "daftar-alokasi-buku",
      },
      {
        title: "Alokasi Infaq",
        to: "daftar-alokasi-pengeluaran-infaq",
      },
    ],
  },
  {
    title: "Berita & Cerita Hikmah",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-heart"></i>,
    content: [
      {
        title: "Inbox Kirim Pesan",
        to: "daftar-inbox",
      },
      {
        title: "Inbox Config",
        to: "daftar-inbox-config",
      },
      {
        title: "Daftar Cerita Hikmah",
        to: "daftar-cerita-hikmah",
      },
    ],
  },
  {
    title: "Konten & Materi Belajar",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa flaticon-381-notepad"></i>,
    content: [
      {
        title: "Daftar Materi",
        to: "daftar-materi",
      },
      {
        title: "Daftar Konten",
        to: "daftar-konten",
      },
    ],
  },
  {
    title: "Informasi dan Saran",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-sticky-note"></i>,
    content: [
      {
        title: "Tentang Aplikasi dan Yayasan",
        to: "tentang-yayasan-dan-aplikasi",
      },
      {
        title: "Panduan",
        to: "daftar-panduan",
      },
      {
        title: "Saran",
        to: "daftar-saran",
      },
    ],
  },
  {
    title: "Master Data",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-database"></i>,
    content: [
      {
        title: "Master Data",
        to: "daftar-master-data",
      },
      {
        title: "Master Data Periode",
        to: "master-data-periode",
      },
      {
        title: "Master Data Konfigurasi",
        to: "master-data-config",
      },
      {
        title: "Master Data Provinsi",
        to: "daftar-provinsi",
      },
      {
        title: "Master Data Kabupaten kota",
        to: "daftar-kabupaten",
      },
    ],
  },
  {
    title: "Pengaturan",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-cogs"></i>,
    content: [
      {
        title: "Role",
        to: "daftar-role",
      },
      {
        title: "Pengguna",
        to: "daftar-pengguna",
      },
      {
        title: "Fitur",
        to: "daftar-fitur",
      },
      {
        title: "Set/Copy Progress",
        to: "set-Set-or-copy-progress",
      },
    ],
  },
  //#region Utility Functions
  // //Dashboard
  // {
  //     title: 'Dashboard',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-networking"></i>,
  //     content: [
  //         {
  //             title: 'Dashboard',
  //             to: 'dashboard',
  //         },
  //         {
  //             title: 'Orders',
  //             to: 'orders',
  //         },
  //         {
  //             title: 'Order ID',
  //             to: 'order-id',
  //         },
  //         {
  //             title: 'Customers',
  //             to: 'general-customers',
  //         },
  //         {
  //             title: 'Analytics',
  //             to: 'analytics',

  //         },
  //         {
  //             title: 'Reviews',
  //             to: 'review',
  //         },
  // 		{
  //             title: 'Task',
  //             to: 'task',
  //         },
  //     ],
  // },

  // //Apps
  // {
  //     title: 'Apps',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-television"></i>,
  //     content: [
  //         {
  //             title: 'Profile',
  //             to: 'app-profile'
  //         },
  //         // {
  //         //     title: 'Edit Profile',
  //         //     to: 'edit-profile'
  //         // },
  //         {
  //             title: 'Post Details',
  //             to: 'post-details'
  //         },
  //         {
  //             title: 'Email',
  //             hasMenu : true,
  //             content: [
  //                 {
  //                     title: 'Compose',
  //                     to: 'email-compose',
  //                 },
  //                 {
  //                     title: 'Index',
  //                     to: 'email-inbox',
  //                 },
  //                 {
  //                     title: 'Read',
  //                     to: 'email-read',
  //                 }
  //             ],
  //         },
  //         {
  //             title:'Calendar',
  //             to: 'app-calender'
  //         },
  //         {
  //             title: 'Shop',
  //             hasMenu : true,
  //             content: [
  //                 {
  //                     title: 'Product Grid',
  //                     to: 'ecom-product-grid',
  //                 },
  //                 {
  //                     title: 'Product List',
  //                     to: 'ecom-product-list',
  //                 },
  //                 {
  //                     title: 'Product Details',
  //                     to: 'ecom-product-detail',
  //                 },
  //                 {
  //                     title: 'Order',
  //                     to: 'ecom-product-order',
  //                 },
  //                 {
  //                     title: 'Checkout',
  //                     to: 'ecom-checkout',
  //                 },
  //                 {
  //                     title: 'Invoice',
  //                     to: 'ecom-invoice',
  //                 },
  //                 {
  //                     title: 'Customers',
  //                     to: 'ecom-customers',
  //                 },
  //             ],
  //         },
  //     ],
  // },
  // //Charts
  // {
  //     title: 'Charts',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-controls-3"></i>,
  //     content: [

  //         {
  //             title: 'RechartJs',
  //             to: 'chart-rechart',
  //         },
  //         {
  //             title: 'Chartjs',
  //             to: 'chart-chartjs',
  //         },
  //         {
  //             title: 'Sparkline',
  //             to: 'chart-sparkline',
  //         },
  //         {
  //             title: 'Apexchart',
  //             to: 'chart-apexchart',
  //         },
  //     ]
  // },
  // //Boosttrap
  // {
  //     title: 'Bootstrap',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-internet"></i>,
  //     content: [
  //         {
  //             title: 'Accordion',
  //             to: 'ui-accordion',
  //         },
  //         {
  //             title: 'Alert',
  //             to: 'ui-alert',
  //         },
  //         {
  //             title: 'Badge',
  //             to: 'ui-badge',
  //         },
  //         {
  //             title: 'Button',
  //             to: 'ui-button',
  //         },
  //         {
  //             title: 'Modal',
  //             to: 'ui-modal',
  //         },
  //         {
  //             title: 'Button Group',
  //             to: 'ui-button-group',
  //         },
  //         {
  //             title: 'List Group',
  //             to: 'ui-list-group',
  //         },
  //         {
  //             title: 'Cards',
  //             to: 'ui-card',
  //         },
  //         {
  //             title: 'Carousel',
  //             to: 'ui-carousel',
  //         },
  //         {
  //             title: 'Dropdown',
  //             to: 'ui-dropdown',
  //         },
  //         {
  //             title: 'Popover',
  //             to: 'ui-popover',
  //         },
  //         {
  //             title: 'Progressbar',
  //             to: 'ui-progressbar',
  //         },
  //         {
  //             title: 'Tab',
  //             to: 'ui-tab',
  //         },
  //         {
  //             title: 'Typography',
  //             to: 'ui-typography',
  //         },
  //         {
  //             title: 'Pagination',
  //             to: 'ui-pagination',
  //         },
  //         {
  //             title: 'Grid',
  //             to: 'ui-grid',
  //         },
  //     ]
  // },
  // //plugins
  // {
  //     title:'Plugins',
  //     classsChange: 'mm-collapse',
  //     iconStyle : <i className="flaticon-381-heart"></i>,
  //     content : [
  //         {
  //             title:'Select 2',
  //             to: 'uc-select2',
  //         },
  //         {
  //             title:'Sweet Alert',
  //             to: 'uc-sweetalert',
  //         },
  //         {
  //             title:'Toastr',
  //             to: 'uc-toastr',
  //         },
  //         {
  //             title:'Jqv Map',
  //             to: 'map-jqvmap',
  //         },
  //         {
  //             title:'Light Gallery',
  //             to: 'uc-lightgallery',
  //         },
  //     ]
  // },
  // //Widget
  // {
  //     title:'Widget',
  //     //classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-settings-2"></i>,
  //     to: 'widget-basic',
  // },
  // //Forms
  // {
  //     title:'Forms',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-notepad"></i>,
  //     content : [
  //         {
  //             title:'Form Elements',
  //             to: 'form-element',
  //         },
  //         {
  //             title:'Wizard',
  //             to: 'form-wizard',
  //         },
  //         {
  //             title:'CkEditor',
  //             to: 'form-ckeditor',
  //         },
  //         {
  //             title:'Pickers',
  //             to: 'form-pickers',
  //         },
  //         {
  //             title:'Form Validate',
  //             to: 'form-validation',
  //         },

  //     ]
  // },
  // //Table
  // {
  //     title:'Table',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-network"></i>,
  //     content : [
  //         {
  //             title:'Table Filtering',
  //             to: 'table-filtering',
  //         },
  //         {
  //             title:'Table Sorting',
  //             to: 'table-sorting',
  //         },
  //         {
  //             title:'Bootstrap',
  //             to: 'table-bootstrap-basic',
  //         },

  //     ]
  // },
  // //Pages
  // {
  //     title:'Pages',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-layer-1"></i>,
  //     content : [
  //         {
  //             title:'Error',
  //             hasMenu : true,
  //             content : [
  //                 {
  //                     title: 'Error 400',
  //                     to : 'page-error-400',
  //                 },
  //                 {
  //                     title: 'Error 403',
  //                     to : 'page-error-403',
  //                 },
  //                 {
  //                     title: 'Error 404',
  //                     to : 'page-error-404',
  //                 },
  //                 {
  //                     title: 'Error 500',
  //                     to : 'page-error-500',
  //                 },
  //                 {
  //                     title: 'Error 503',
  //                     to : 'page-error-503',
  //                 },
  //             ],
  //         },
  //         {
  //             title:'Lock Screen',
  //             to: 'page-lock-screen',
  //         },

  //     ]
  // },

  // {
  //     title:'Pengaturan',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-settings-4"></i>,
  //     content : [
  //         {
  //             title:'RBAC',
  //             hasMenu : true,
  //             content : [
  //                 {
  //                     title: 'Role Akses',
  //                     to : 'role-akses',
  //                 },
  //             ],
  //         },
  //     ]
  // },
  // {
  //     title:'Pendamping',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-notepad"></i>,
  //     content : [
  //         {
  //             title:'Daftar Pendamping',
  //             to: 'daftar-pendamping',
  //         },
  //         {
  //             title:'Verifikasi Pendamping',
  //             to: 'verifikasi-pendamping',
  //         },
  //     ]
  // },
  // {
  //     title:'Konten',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="fa fa-newspaper-o"></i>,
  //     content : [
  //         {
  //             title:'Daftar Konten',
  //             to: 'daftar-konten',
  //         },
  //     ]
  // },
  // {
  //     title:'Alokasi Buku',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="fa fa-book"></i>,
  //     content : [
  //         {
  //             title:'Daftar Alokasi Buku',
  //             to: 'daftar-alokasi-buku',
  //         },
  //     ]
  // },
  // {
  //     title:'Laporan',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="fa fa-book"></i>,
  //     content : [
  //         {
  //             title:'Daftar Laporan',
  //             to: 'daftar-laporan',
  //         },
  //     ]
  // },
  // {
  //     title:'Teman Belajar',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="fa fa-users"></i>,
  //     content : [
  //         {
  //             title:'Rekomendasi',
  //             to: 'daftar-teman-belajar-rekomendasi',
  //         },
  //     ]
  // },
  // {
  //     title:'Panduan',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="fa fa-users"></i>,
  //     content : [
  //         {
  //             title:'Daftar Panduan',
  //             to: 'daftar-panduan',
  //         },
  //     ]
  // },
  // {
  //     title:'Inbox',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="fa fa-users"></i>,
  //     content : [
  //         {
  //             title:'Daftar Inbox Config',
  //             to: 'daftar-inbox-config',
  //         },
  //         {
  //             title:"Daftar Inbox",
  //             to:'daftar-inbox',
  //         },
  //     ]
  // },
  // {
  //     title:'Pencapaian',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="fa fa-users"></i>,
  //     content : [
  //         {
  //             title:'Daftar Pencapaian',
  //             to: 'daftar-pencapaian',
  //         },
  //     ]
  // },
  // {
  //     title:'Pengguna',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="fa fa-user"></i>,
  //     content : [
  //         {
  //             title:'Daftar Pengguna',
  //             to: 'daftar-pengguna',
  //         },
  //     ]
  // },
  // {
  //     title:'Role',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="fa fa-id-card"></i>,
  //     content : [
  //         {
  //             title:'Daftar Role',
  //             to: 'daftar-role',
  //         },
  //     ]
  // },
  // {
  //     title:'Tentang',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="fa fa-sticky-note"></i>,
  //     content : [
  //         {
  //             title:'Yayasan dan Aplikasi',
  //             to: 'tentang-yayasan-dan-aplikasi',
  //         },
  //     ]
  // },
  //#endregion
];
