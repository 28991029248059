import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Button, } from 'react-bootstrap'
import profile from "../../../images/profile.jpeg";
import PageTitle from "../../layouts/PageTitle";
import { useLocation, useParams } from 'react-router-dom';
import { GET } from "../../../services/AxiosService";

const DetailPendamping = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [gender, setGender] = useState([]);
  const location = useLocation();

  const fetchData = async () => {
    const res = await GET(`companion/${id}`);
    setData(res?.data);
  };

  const fetchDataGender = async () => {
    const res = await GET(`master-data/type?type=gender`);
    setGender(res?.data);
  };

  useEffect(() => {
    fetchData();
    fetchDataGender();
  }, []);

  const getDayLabel = (status) => {
    if (status === 1) {
      return "Senin";
    } else if (status === 2) {
      return "Selasa";
    } else if (status === 3) {
      return "Rabu";
    } else if (status === 4) {
      return "Kamis";
    } else if (status === 5) {
      return "Jumat";
    } else if (status === 6) {
      return "Sabtu";
    } else if (status === 7) {
      return "Minggu";
    } else {
      return status;
    }
  };

  const getGenderName = (genderId) => {
    const genderItem = gender.find(g => g.id === genderId);
    return genderItem ? genderItem.name : '';
  };

  return (
    <div className="h-80">
      <PageTitle
        activeMenu={data.user?.name}
        pageContent={data.user?.name}
        motherMenu={location.state?.from || ''}
      />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Informasi  Pendamping</Card.Title>
              <Button href={`${process.env.PUBLIC_URL}/${location.state?.from === "Verifikasi Pendamping" ? "verifikasi-pendamping" : "daftar-pendamping"}`} className='mr-2' variant='primary'>
                Kembali
              </Button>
            </Card.Header>
            <Card.Body>
              <h3>Informasi Dasar</h3>
              <div className="profile-photo text-center">
                <img src={profile} width={130} className="img-fluid rounded-circle" alt="profile" />
              </div>
              <div className="row">
                <div className="d-flex mt-4">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Nama Lengkap</span>
                    <h4>{data.user?.name}</h4>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Nomor HP</span>
                    <h4>{data.user?.hp_no}</h4>
                  </div>
                </div>

                <div className="d-flex mt-4">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>ID Pendamping</span>
                    <h4>{`${location.state?.from === "Verifikasi Pendamping" ? data.reg_code : data.code}`}</h4>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Jenis Kelamin</span>
                    <h4>{data ? getGenderName(data.gender) : ''}</h4>
                  </div>
                </div>

                <div className="d-flex mt-4">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Email</span>
                    <h4>{data.user?.email}</h4>
                  </div>
                </div>

                <div className="d-flex mt-4">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <span>Alamat</span>
                    <h4>{data.address}</h4>
                  </div>
                </div>

                <div className="d-flex mt-4">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>RT/RW</span>
                    <h4>{data.rt}/{data.rw}</h4>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Desa/Kelurahan</span>
                    <h4>{data.urbanVillage?.name}</h4>
                  </div>
                </div>

                <div className="d-flex mt-4">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Kecamatan</span>
                    <h4>{data.urbanVillage?.subDistrict.name}</h4>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Kabupaten/Kota</span>
                    <h4>{data.urbanVillage?.subDistrict?.district.name}</h4>
                  </div>
                </div>

                <div className="d-flex mt-4">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Provinsi</span>
                    <h4>{data.urbanVillage?.subDistrict?.district?.province?.name}</h4>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Kode Pos</span>
                    <h4>{data.postal_code}</h4>
                  </div>
                </div>
              </div>

              <hr />

              <h3>Informasi Lengkap</h3>
              <div className="row">
                <div className="d-flex mt-4">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>NIK</span>
                    <h4>{data.nik}</h4>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <span>Pekerjaan</span>
                    <h4>Karyawan Swasta</h4>
                  </div> */}
                </div>

                <div className="d-flex mt-4">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <span>Jadwal Available</span>
                  </div>
                </div>

                <div className="row d-flex mt-3 ml-2">
                  {data.companionSchedules &&
                    data.companionSchedules.map((schedule, index) => (
                      <div className="mt-3 col-lg-2 col-md-3 col-sm-4 col-6">
                        <h4>{getDayLabel(schedule.day)}</h4>
                        <span>{schedule.time_start} - {schedule.time_finish}</span>
                      </div>
                    ))}
                </div>

                {/* <div className="row d-flex mt-3 ml-2">
                  <div className="mt-3 col-lg-2 col-md-3 col-sm-4 col-6">
                    <h4>Senin</h4>
                    <span>12:00 - 14:00</span>
                  </div>
                  <div className="mt-3 col-lg-2 col-md-3 col-sm-4 col-6">
                    <h4>Selasa</h4>
                    <span>12:00 - 14:00</span>
                  </div>
                  <div className="mt-3 col-lg-2 col-md-3 col-sm-4 col-6">
                    <h4>Selasa</h4>
                    <span>12:00 - 14:00</span>
                  </div>
                  <div className="mt-3 col-lg-2 col-md-3 col-sm-4 col-6">
                    <h4>Rabu</h4>
                    <span>12:00 - 14:00</span>
                  </div>
                  <div className="mt-3 col-lg-2 col-md-3 col-sm-4 col-6">
                    <h4>Kamis</h4>
                    <span>12:00 - 14:00</span>
                  </div>
                  <div className="mt-3 col-lg-2 col-md-3 col-sm-4 col-6">
                    <h4>Jumat</h4>
                    <span>12:00 - 14:00</span>
                  </div>
                  <div className="mt-3 col-lg-2 col-md-3 col-sm-4 col-6">
                    <h4>Sabtu</h4>
                    <span>12:00 - 14:00</span>
                  </div>
                  <div className="mt-3 col-lg-2 col-md-3 col-sm-4 col-6">
                    <h4>Minggu</h4>
                    <span>12:00 - 14:00</span>
                  </div>
                </div> */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default DetailPendamping