import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { GET, PATCH, POST } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";

const FormLaporanUbah = () => {
  const location = useLocation();
  const {ids, idPeriode} = location.state || {}; // Use a default empty object in case state is undefined
  const role = localStorage.getItem("accessRole");
  const [formData, setFormData] = useState({
    id: "",
    orientation: "",
    self_concept: "",
    problem_solving: "",
    communication: "",
    updated_at: "",
    updated_by: "",
    status: ""
  })
  const { id, flag, idPeriod } = useParams();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const fetchData = async () => {
    const res = await GET(`class/student/${id}/${idPeriod}`);
    setFormData(res?.dataEntity);
  };

  useEffect(() => {
    fetchData();
  }, [])

  const handleUbahLaporan = () => {
    console.log(`Navigating to: /${id}/${idPeriode}/form-laporan/Ubah`);
    navigate(`/${id}/${idPeriode}/form-laporan/Ubah`)
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === 'hp_no' || name === 'password' || name === 'password_confirmation') {
      value = value.replace(/\D/g, "");
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const { orientation, self_concept, problem_solving, communication } = formData;
    const requestBody = {
      class_participant_id: id,
      period_id: idPeriod,
      orientation,
      self_concept,
      problem_solving,
      communication,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/class-participant-report/${formData.id}`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil mengubah laporan!",
      }).then(() => {
        Swal.close();
        setFormData({
          id: "",
          class_participant_id: "",
          period_id: "",
          orientation: "",
          self_concept: "",
          problem_solving: "",
          communication: "",
          updated_at: "",
          updated_by: "",
        });
        router("/daftar-laporan")
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { orientation, self_concept, problem_solving, communication } = formData;
    const requestBody = {
      class_participant_id: id,
      period_id: idPeriod,
      orientation,
      self_concept,
      problem_solving,
      communication,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await POST(`/class-participant-report`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil membuat laporan!",
      }).then(() => {
        Swal.close();
        setFormData({
          id: "",
          class_participant_id: "",
          period_id: "",
          orientation: "",
          self_concept: "",
          problem_solving: "",
          communication: "",
          updated_at: "",
          updated_by: "",
        });
        router("/daftar-laporan");
      });
    }
  };

  const handleStatusAccept = async (id) => {
    console.log('id',id)
    const result = await Swal.fire({
      title: 'Apakah anda ingin menyetujui laporan ini?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await PATCH(`class-participant-report/${formData.id}/persetujuan-laporan`, { status: 1 });
      if (res) {
        Swal.fire({
          icon: "success",
          title: `Laporan disetujui!`,
        }).then(() => {
          Swal.close();
          fetchData();
        });
      }
    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Daftar Laporan`}
        motherMenu="Daftar Laporan"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} laporan</h4>
              {/* {flag === "Detail" && (
                <div>
                  <Link className="btn btn-info mx-1" onClick={handleUbahLaporan}>
                    Ubah Laporan
                  </Link>
                  {(role == "Admin" || role == "Admin Sistem" && formData?.status === 0) && (
                    <Link className="btn btn-success mx-1" onClick={handleStatusAccept}>
                      Setujui Laporan
                    </Link>
                  )}
                  <Link className="btn btn-primary mx-1" to={`/daftar-laporan`}>
                    Kembali
                  </Link>
                </div>
              )} */}
            </div>
            <div className="card-body">
              <div className="form-validation">
                  <form className="form-valide">
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className='form-group col-md-6 col-12'>
                        <label>Nama Pelajar</label>
                        <input
                          className='form-control'
                          type='text'
                          value={formData?.classParticipant?.user?.name}
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Kelas</label>
                        <input
                          className='form-control'
                          type='text'
                          value={formData?.classParticipant?.class?.name}
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Pengajar</label>
                        <input
                          className='form-control'
                          type='text'
                          value={formData?.teachers}
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Bulan</label>
                        <input
                          className='form-control'
                          type='text'
                          value={formData?.period?.description}
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-12 col-12'>
                        <label>Tujuan Kelas<span className="text-danger">*</span></label>
                        <textarea
                          className='form-control'
                          name="article"
                          rows="8"
                          value={formData?.classParticipant?.class?.masterDataAsLearningLevel?.description}
                          placeholder="Masukkan tujuan kelas"
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-12 col-12'>
                        <label>Orientasi<span className="text-danger">*</span></label>
                        <textarea
                          className='form-control'
                          name="orientation"
                          rows="8"
                          value={formData?.orientation}
                          onChange={handleChange}
                          placeholder="Masukkan orientasi"
                          minLength="10"
                          maxLength="1000"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Orientasi harus diisi minimal 10 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group col-md-12 col-12'>
                        <label>Konsep Diri<span className="text-danger">*</span></label>
                        <textarea
                          className='form-control'
                          name="self_concept"
                          rows="8"
                          value={formData?.self_concept}
                          onChange={handleChange}
                          placeholder="Masukkan konsep diri"
                          minLength="10"
                          maxLength="1000"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Konsep diri harus diisi minimal 10 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group col-md-12 col-12'>
                        <label>Problem Solving<span className="text-danger">*</span></label>
                        <textarea
                          className='form-control'
                          name="problem_solving"
                          rows="8"
                          value={formData?.problem_solving}
                          onChange={handleChange}
                          placeholder="Masukkan problem solving"
                          minLength="10"
                          maxLength="1000"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Problem solving harus diisi minimal 10 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group col-md-12 col-12'>
                        <label>Komunikasi<span className="text-danger">*</span></label>
                        <textarea
                          className='form-control'
                          name="communication"
                          rows="8"
                          value={formData?.communication}
                          onChange={handleChange}
                          placeholder="Masukkan komunikasi"
                          minLength="10"
                          maxLength="1000"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Komunikasi harus diisi minimal 10 karakter.
                        </Form.Control.Feedback>
                      </div>
                      {flag === "Detail" && (
                        <>
                          <div className='form-group col-md-6 col-12'>
                            <label>Last Update<span className="text-danger">*</span></label>
                            <input
                              className='form-control'
                              type='text'
                              value={formData?.updated_at}
                              disabled
                            />
                          </div>
                          <div className='form-group col-md-6 col-12'>
                            <label>Updated By<span className="text-danger">*</span></label>
                            <input
                              className='form-control'
                              type='text'
                              value={formData?.updated_by}
                              disabled
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-laporan`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={formData?.id == undefined ? handleSubmit : handleEditSubmit}>
                          Simpan Perubahan
                        </button>
                      </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormLaporanUbah