import React, { useState } from "react";
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/logoBraille.png";
import logoText from "../../../images/logo-text.png";

export function NavMenuToggle() {
   setTimeout(() => {
      let mainwrapper = document.querySelector("#main-wrapper");
      if (mainwrapper.classList.contains('menu-toggle')) {
         mainwrapper.classList.remove("menu-toggle");
      } else {
         mainwrapper.classList.add("menu-toggle");
      }
   }, 200);
}

const NavHader = () => {
   const [toggle, setToggle] = useState(false);
   return (
      <div className="nav-header">
         {/* <Link to="/" className="brand-logo">
            <img className="logo-abbr" src={logo} alt="" />
            <img className="logo-compact" src={logoText} alt="" />
            <img className="brand-title" src={logoText} alt="" />
         </Link> */}
         <Link to="/" className="brand-logo">
            <div className="d-flex p-1 align-items-center" style={{ backgroundColor: "#F4F7FF", borderRadius: "8px" }}>
               <img className="logo-abbr align-items-center" src={logo} height={30} width={85} alt="" />
               <b className="brand-title" style={{ color: "#0C718C", fontSize: "14px" }}>Yayasan Hikmah Mata Hati</b>
            </div>
         </Link>

         <div className="nav-control"
            onClick={() => {
               setToggle(!toggle)
               NavMenuToggle()
            }
            }
         >
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
