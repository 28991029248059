import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { GET, PATCH, POST } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { addMethod } from "yup";

const FormPaketBuku = () => {
  const location = useLocation();
  const { id, flag } = useParams();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const navigate = useNavigate();
  const [formDatas, setFormDatas] = useState({
    name: "",
    description: "",
    status: 0,
    amount_needed: "",
    book: [],
  })
  const [editFormData, setEditFormData] = useState({
    name: "",
    description: "",
    status: 0,
    amount_needed: "",
    book: [],
  })
  const [validated, setValidated] = useState(false);
  const [touched, setTouched] = useState(false);
  const formRef = useRef(null);
  const [buku, setBuku] = useState([])

  // Dropdown Isi Buku
  const fetchDataBuku = async () => {
    const res = await GET(`book?size=-1`);
    setBuku(res?.data);
  }
  // Get Detail
  const fetchData = async (id) => {
    try {
      const res = await GET(`/book-package/${id}`);

      setEditFormData({
        name: res?.data?.name,
        description: res?.data?.description,
        amount_needed: res?.data?.amount_needed,
        status: res?.data?.status,
        book: res?.data?.bookPackageDetails.map(detail => ({
          id: detail.book.id, // Nested id extraction
          name: detail.book.name
        }))
      })
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const bukuPromise = fetchDataBuku();
        const dataPromise = id ? fetchData(id) : Promise.resolve();
        await Promise.all([bukuPromise, dataPromise]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchAllData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setEditFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // TAMBAH
  const handleSubmit = async (e) => {
    e.preventDefault();

    const transformedFormDatas = {
      ...formDatas,
      amount_needed: parseInt(formDatas.amount_needed, 10),  // or Number(formDatas.amount_needed)
      book: formDatas.book.map(bookId => ({ id: bookId }))
    };

    const result = await Swal.fire({
      title: 'Apakah Anda yakin akan menyimpan perubahan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let res;
      res = await POST(`book-package`, transformedFormDatas);
      if (res) {
        Swal.fire({
          icon: "success",
          title: !id ? `Paket Buku ${formDatas.name} berhasil ditambahkan.` : `Paket Buku ${formDatas.name} berhasil diubah.`,
        }).then(() => {
          Swal.close();
          navigate(`/daftar-paket-buku`)
        });
      }
    }
  };

  // EDIT
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const transformedFormDataEditt = {
      ...editFormData,
      book: editFormData.book.map(bookId => ({ id: bookId.id })) // Map valid entries to the required format
  };
    console.log('handleEditSubmit', transformedFormDataEditt)
    const result = await Swal.fire({
      title: 'Apakah Anda yakin akan menyimpan perubahan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let res;
      res = await PATCH(`book-package/${id}`, transformedFormDataEditt);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Paket Buku berhasil diubah!",
        }).then(() => {
          Swal.close();
          navigate(`/daftar-paket-buku`)
        });
      }
    }
   
  }

  const handleSelectChange = (selectedOptions, name) => {
    setFormDatas((prevData) => ({
      ...prevData,
      [name]: selectedOptions.map(option => option.value),
    }));

    setEditFormData((prevData) => ({
      ...prevData,
      [name]: selectedOptions.map(option => ({
          id: option.value,
          name: option.label
      })),
  }));

    setTouched(true);

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const currencyFormat = (number) => {
    if (!number) return '0';
    return `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
  };

  const selectStyle = {
    control: (provided) => ({
      ...provided,
      // height: '50px',
      minHeight: '55px'
    }),
  }

  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Paket Buku`}
        motherMenu="Daftar Paket Buku"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Paket Buku</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/daftar-paket-buku`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {/* TAMBAH */}
                {flag === "Tambah" ?(
                  <form className="form-valide" ref={formRef}>
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className='form-group col-md-6 col-12'>
                        <label>Nama Paket<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="name"
                          type='text'
                          value={formDatas.name}
                          onChange={handleInputChange}
                          placeholder="Masukkan judul cerita"
                          minLength="5"
                          maxLength="100"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Judul harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                      {buku?.length > 0 && (
                        <div className='form-group col-md-6 col-12'>
                          <>
                            <label>Isi Paket<span className="text-danger">*</span></label>
                            <Select
                              isMulti
                              name="isi_paket"
                              options={buku.map(item => ({ value: item.id, label: item.name }))}
                              onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'book')}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              defaultValue={buku.filter(item => formDatas?.book?.includes(item.id)).map(item => ({ value: item.id, label: item.name }))}
                              styles={selectStyle}
  
                            />
                          </>
                          {/* )} */}
                        </div>
                      )}
                      <div className='form-group col-md-6 col-12' hidden>
                        <label>Harga (Rp)<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="price"
                          type='text'
                          value={formDatas.price}
                          onChange={(e) => {
                            const { value } = e.target;
                            const parsedValue = parseFloat(value.replace(/[^0-9]/g, ""));
                            const formattedValue = isNaN(parsedValue) ? '' : currencyFormat(parsedValue);
                            setFormDatas((prevState) => ({
                              ...prevState,
                              price: formattedValue,
                            }));
                          }}
                          required
                          maxLength="11"
                        />
                        <Form.Control.Feedback type="invalid">
                          Harga harus diisi.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Jumlah Diperlukan<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="amount_needed"
                          type='text'
                          value={formDatas?.amount_needed}
                          onChange={handleInputChange}
                          required
                          maxLength="11"
                        />
                        <Form.Control.Feedback type="invalid">
                          Jumlah diperlukan harus diisi.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group col-md-12 col-12'>
                        <label>Deskripsi<span className="text-danger">*</span></label>
                        <textarea
                          className='form-control'
                          name="description"
                          value={formDatas.description}
                          onChange={handleInputChange}
                          rows="5"
                          placeholder="Masukkan Deskripsi Buku"
                          minLength="5"
                          maxLength="100"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Deskripsi harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-paket-buku`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100"
                        onClick={handleSubmit}
                        // disabled={!validated}
                        >
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                </form>
                ) : flag === "Edit" ? (
                  <form className="form-valide" ref={formRef}>
                    <div className="row">
                      <div className="d-flex flex-wrap">
                        <div className='form-group col-md-6 col-12'>
                          <label>Nama Paket<span className="text-danger">*</span></label>
                          <input
                            className='form-control'
                            name="name"
                            type='text'
                            value={editFormData.name}
                            onChange={handleInputChange}
                            placeholder="Masukkan judul cerita"
                            minLength="5"
                            maxLength="100"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Judul harus diisi minimal 5 karakter.
                          </Form.Control.Feedback>
                        </div>
                        {editFormData.book?.length > 0 && (
                          <div className='form-group col-md-6 col-12'>
                            <>
                              <label>Isi Paket<span className="text-danger">*</span></label>
                              <Select
                                isMulti
                                name="book"
                                options={buku.map(item => ({ value: item.id, label: item.name }))}
                                onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'book')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={editFormData.book.map(bookObj => ({
                                  value: bookObj.id,
                                  label: bookObj.name
                                })) || []}
                              />
                            </>
                            {/* )} */}
                          </div>
                        )}
                        {/* <div className='form-group col-md-6 col-12' hidden>
                          <label>Harga (Rp)<span className="text-danger">*</span></label>
                          <input
                            className='form-control'
                            name="price"
                            type='text'
                            value={data.price}
                            onChange={(e) => {
                              const { value } = e.target;
                              const parsedValue = parseFloat(value.replace(/[^0-9]/g, ""));
                              const formattedValue = isNaN(parsedValue) ? '' : currencyFormat(parsedValue);
                              setData((prevState) => ({
                                ...prevState,
                                price: formattedValue,
                              }));
                            }}
                            required
                            maxLength="11"
                            disabled={location.state?.from === "Detail"}
                          />
                          <Form.Control.Feedback type="invalid">
                            Harga harus diisi.
                          </Form.Control.Feedback>
                        </div> */}
                        <div className='form-group col-md-6 col-12'>
                          <label>Jumlah Diperlukan<span className="text-danger">*</span></label>
                          <input
                            className='form-control'
                            name="amount_needed"
                            type='text'
                            value={editFormData?.amount_needed}
                            onChange={handleInputChange}
                            required
                            maxLength="11"
                          />
                          <Form.Control.Feedback type="invalid">
                            Jumlah diperlukan harus diisi.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group col-md-12 col-12'>
                          <label>Deskripsi<span className="text-danger">*</span></label>
                          <textarea
                            className='form-control'
                            name="description"
                            value={editFormData?.description}
                            onChange={handleInputChange}
                            rows="5"
                            placeholder="Masukkan judul cerita"
                            minLength="5"
                            maxLength="100"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Deskripsi harus diisi minimal 5 karakter.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                      <div className="form-group mb-3 d-flex">
                        <div className="col-xl-6">
                          <Link className="btn btn-light w-100" to={`/daftar-paket-buku`}>
                            Batal
                          </Link>
                        </div>
                        <div className="col-xl-6">
                          <button type="submit" className="btn btn-primary w-100"
                          onClick = {handleEditSubmit}
                          // onClick={locations === "Tambah" ? handleSubmit : handleEditSubmit} disabled={!validated}
                          >
                            Simpan Perubahan
                          </button>
                      </div>
                    </div>
                  </form>
                ): flag === "Detail" ? (
                  <form className="form-valide" ref={formRef}>
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className='form-group col-md-6 col-12'>
                        <label>Nama Paket<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="name"
                          type='text'
                          value={editFormData.name}
                          onChange={''}
                          placeholder="Masukkan judul cerita"
                          minLength="5"
                          maxLength="100"
                          required
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          Judul harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                      {editFormData.book?.length > 0 && (
                        <div className='form-group col-md-6 col-12'>
                          <>
                            <label>Isi Paket<span className="text-danger">*</span></label>
                            <Select
                              isMulti
                              name="book"
                              options={buku.map(item => ({ value: item.id, label: item.name }))}
                              onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'book')}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              defaultValue={editFormData.book.map(bookObj => ({
                                value: bookObj.id,
                                label: bookObj.name
                              })) || []}
                              styles={selectStyle}
                              isDisabled={true}  // Use isDisabled instead of disabled
                            />
                          </>
                          {/* )} */}
                        </div>
                      )}
                      <div className='form-group col-md-6 col-12' hidden>
                        <label>Harga (Rp)<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="price"
                          type='text'
                          value={''}
                          onChange={''}
                          required
                          maxLength="11"
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          Harga harus diisi.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Jumlah Diperlukan<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="amount_needed"
                          type='text'
                          value={editFormData?.amount_needed}
                          onChange={''}
                          required
                          maxLength="11"
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          Jumlah diperlukan harus diisi.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group col-md-12 col-12'>
                        <label>Deskripsi<span className="text-danger">*</span></label>
                        <textarea
                          className='form-control'
                          name="description"
                          type='text'
                          value={editFormData.description}
                          onChange={''}
                          rows="5"
                          placeholder="Masukkan Deskripsi Buku"
                          minLength="5"
                          maxLength="100"
                          required
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          Deskripsi harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                </form>
                ): ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormPaketBuku