import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { GET, PATCH, POST } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";

const FormAssessment = () => {
  const location = useLocation();
  const router = useNavigate();
  const { id,flag } = useParams();
  const [locations, setLocations] = useState(location.state?.from);
  const [pelajar, setPelajar] = useState([]);
  const [kota, setKota] = useState([]);
  const [lokasi, setLokasi] = useState([]);
  const [pengajar, setPengajar] = useState([]);
  const [selectedPengajar, setSelectedPengajar] = useState([]);
  const [selectedPelajar, setSelectedPelajar] = useState([]);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    time_start: "",
    time_finish: "",
    location: "",
    date: "",
    assessor_id: "",
    students_id: "",
    city: "",
  });
  const formRef = useRef(null);
  const [touched, setTouched] = useState(false);

  const fetchDataKota = async () => {
    const res = await GET(`master-data/type?type=kota kabupaten`);
    setKota(res?.data);
  };
  const fetchDataLokasi = async (city) => {
    const res = await GET(`master-data/parent/${city}`);
    setLokasi(res?.data);
  };
  const fetchDataPengajar = async () => {
    const res = await GET(`class/type-student?codeType=1`);
    setPengajar(res?.data);

    // if (formData?.teachers_id) {
    //   const selectedOptions = res?.data.filter(item =>
    //     formData?.teachers_id?.includes(item.id)
    //   );
    //   setSelectedPengajar(selectedOptions.map(item => ({
    //     value: item.id,
    //     label: item.name
    //   })));
    // }
  };
  const fetchDataPelajar = async () => {
    const res = await GET(`class/type-student?codeType=0`);
    setPelajar(res?.data);

    // if (formDataJadwal?.students_id) {
    //   const selectedOptions = res?.data.filter(item =>
    //     formDataJadwal?.students_id?.includes(item.id)
    //   );
    //   setSelectedPelajar(selectedOptions.map(item => ({
    //     value: item.id,
    //     label: item.name
    //   })));
    // }
  };

  const fetchData = async () => {
    const res = await GET(`/schedule-assessment/${id}`);
    const processedAssessor = res?.data?.assessor?.map(assessor => ({ user_id: assessor.user_id }));
    const processedStudents = res?.data?.students?.map(student => ({ user_id: student.user_id }));
    setFormData({
      date: res?.data?.date,
      time_start: res?.data?.time_start,
      time_finish: res?.data?.time_finish,
      location: res?.data?.location,
      city: res?.data?.kota_kabupaten,
      assessor_id: processedAssessor,
      students_id: processedStudents,
    });
  };

  useEffect(() => {
    fetchDataKota();
    fetchDataPengajar();
    fetchDataPelajar();

    if (flag === "Edit") {
      fetchData();
    }
  }, []);
  useEffect(() => {
    if (formData?.city) {
      fetchDataLokasi(formData.city);
    }
  }, [formData.city]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setTouched(true);

    if (name === 'city') {
      formData.location = "";
    }

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleDateChange = (date) => {
    const isoDateString = date?.toISOString();
    const formattedDate = isoDateString?.split('T')[0];
    setFormData({
      ...formData,
      date: formattedDate
    });

    setTouched(true);

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
        form.classList.remove("was-validated");
    } else {
        form.classList.add("was-validated");
    }
  };
  const handleSelectChange = (selectedOptions, name) => {
    const newData = selectedOptions.map(option => {
      return {
        user_id: option.value,
      };
    });

    setFormData((prevData) => ({
      ...prevData,
      [name]: newData,
    }));

    setTouched(true);

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
        form.classList.remove("was-validated");
    } else {
        form.classList.add("was-validated");
    }
  };

  const selectStyle = {
    control: (provided) => ({
      ...provided,
      minHeight: '55px'
    }),
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      location: formData.location,
      time_start: formData.time_start,
      time_finish: formData.time_finish,
      date: formData.date,
      assessor: formData.assessor_id,
      students: formData.students_id,
      // order: formData.order === null || formData.order === "" ? 1 : formData.order
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await POST(`/schedule-assessment`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil membuat jadwal assessment!",
      }).then(() => {
        Swal.close();
        setFormData({
          time_start: "",
          time_finish: "",
          location: "",
          date: "",
          assessor_id: "",
          students_id: "",
          city: "",
        });
        router("/jadwal-assessment")
      });

    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      location: formData.location,
      time_start: formData.time_start,
      time_finish: formData.time_finish,
      date: formData.date,
      assessor: formData.assessor_id,
      students: formData.students_id,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/schedule-assessment/${id}`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Data Jadwal assessment berhasil diubah!",
      }).then(() => {
        Swal.close();
        setFormData({
          time_start: "",
          time_finish: "",
          location: "",
          date: "",
          assessor_id: "",
          students_id: "",
          city: "",
        });
        router("/jadwal-assessment")
      });
    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Assessment`}
        motherMenu="Jadwal Assessment"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Assessment</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/jadwal-assessment`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {flag === "Tambah" ? (
                  <form className="form-valide" ref={formRef} onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Kota/Kabupaten<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.city}
                            name="city"
                            onChange={handleChange}
                          // required
                          >
                            <option value="">Pilih Kota/Kabupaten</option>
                            {Array.isArray(kota) && kota.map((kotas) => (
                              <option
                                key={kotas.id}
                                value={kotas.id}
                              >
                                {kotas.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Kota/Kabupaten harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group'>
                          <label>Lokasi<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.location}
                            name="location"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Pilih Lokasi</option>
                            {Array.isArray(lokasi) && lokasi.map((locations) => (
                              <option
                                key={locations.id}
                                // value={locations.name === 'Tuna Netra' ? 1 : locations.name === 'Orang Awas' ? 2 : ''}
                                value={locations.id}
                              >
                                {locations.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Lokasi harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group row'>
                          <p className="mb-1">Tanggal<span className="text-danger">*</span></p>
                          <DatePicker
                            name="date"
                            selected={formData.date ? new Date(formData.date) : null}
                            onChange={handleDateChange}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            required
                          />
                          {formData.date === "" && touched && ( // Menampilkan pesan feedback jika bank_code kosong
                            <span
                              className="mb-1 text-danger"
                              style={{ fontSize: "14px" }}
                            >
                              Tanggal harus diisi.
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Waktu<span className="text-danger">*</span></label>
                          <div className="d-flex">
                            <input
                              className='form-control col-md-5'
                              type="time"
                              id="appt"
                              name="appt"
                              value={formData.time_start}
                              onChange={(e) => {
                                const newTimeStart = e.target.value;
                                setFormData({
                                  ...formData,
                                  time_start: newTimeStart,
                                });

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();
                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              required
                            />
                            <span className="col-md-2 mt-2"><hr style={{ height: "2px", background: "black", width: "20px" }} /></span>
                            <input
                              className='form-control col-md-5'
                              type="time"
                              id="appt"
                              name="appt"
                              value={formData.time_finish}
                              onChange={(e) => {
                                const newTimeFinish = e.target.value;
                                setFormData({
                                  ...formData,
                                  time_finish: newTimeFinish,
                                });

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();
                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              required
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Waktu harus diisi.
                          </Form.Control.Feedback>
                        </div>
                        {pengajar?.length > 0 && (
                          <div className='form-group'>
                            <label>Nama Assesor<span className="text-danger">*</span></label>
                            <Select
                              isMulti
                              name="assessor_id"
                              options={pengajar.map(item => ({ value: item.user_id, label: item.user?.name }))}
                              onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'assessor_id')}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={selectStyle}
                            />
                            {formData.assessor_id === "" && touched && ( // Menampilkan pesan feedback jika bank_code kosong
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Assesor harus dipilih.
                              </span>
                            )}
                          </div>
                        )}
                        {pelajar?.length > 0 && (
                          <div className='form-group'>
                            <label>Nama Pelajar<span className="text-danger">*</span></label>
                            <Select
                              isMulti
                              name="students_id"
                              options={pelajar.map(item => ({ value: item.user_id, label: item.user?.name }))}
                              onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'students_id')}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={selectStyle}
                            />
                            {formData.students_id === "" && touched && ( // Menampilkan pesan feedback jika bank_code kosong
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Pelajar harus dipilih.
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/jadwal-assessment`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" disabled={!validated || formData.assessor_id === "" || formData.students_id === ""}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === "Edit" ? (
                  <form className="form-valide" ref={formRef} noValidate onSubmit={handleEditSubmit}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Kota/Kabupaten<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.city}
                            name="city"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Pilih Kota/Kabupaten</option>
                            {Array.isArray(kota) && kota.map((kotas) => (
                              <option
                                key={kotas.id}
                                value={kotas.id}
                              >
                                {kotas.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Kota/Kabupaten harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group'>
                          <label>Lokasi<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.location}
                            name="location"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Pilih Lokasi</option>
                            {Array.isArray(lokasi) && lokasi.map((locations) => (
                              <option
                                key={locations.id}
                                value={locations.id}
                              >
                                {locations.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Lokasi harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group'>
                          <p className="mb-1">Tanggal<span className="text-danger">*</span></p>
                          <DatePicker
                            name="date"
                            selected={formData.date ? new Date(formData.date) : null}
                            onChange={handleDateChange}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            required
                          />
                          {formData.date === "" && touched && ( // Menampilkan pesan feedback jika bank_code kosong
                            <span
                              className="mb-1 text-danger"
                              style={{ fontSize: "14px" }}
                            >
                              Tanggal harus diisi.
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Waktu<span className="text-danger">*</span></label>
                          <div className="d-flex">
                            <input
                              className='form-control col-md-5'
                              type="time"
                              id="appt"
                              name="appt"
                              value={formData.time_start}
                              onChange={(e) => {
                                const newTimeStart = e.target.value;
                                setFormData({
                                  ...formData,
                                  time_start: newTimeStart,
                                });

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();
                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              required
                            />
                            <span className="col-md-2 mt-2"><hr style={{ height: "2px", background: "black", width: "20px" }} /></span>
                            <input
                              className='form-control col-md-5'
                              type="time"
                              id="appt"
                              name="appt"
                              value={formData.time_finish}
                              onChange={(e) => {
                                const newTimeFinish = e.target.value;
                                setFormData({
                                  ...formData,
                                  time_finish: newTimeFinish,
                                });

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();
                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              required
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Waktu harus diisi minimal 3 karakter.
                          </Form.Control.Feedback>
                        </div>
                        {pengajar?.length > 0 && (
                          <div className='form-group'>
                            <label>Nama Assesor<span className="text-danger">*</span></label>
                            {formData?.assessor_id && (
                              <Select
                                isMulti
                                name="assessor_id"
                                options={pengajar.map(item => ({ value: item.user_id, label: item.user?.name }))}
                                onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'assessor_id')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                styles={selectStyle}
                                defaultValue={formData?.assessor_id?.filter(assessorObj => assessorObj?.user_id)?.map(assessorObj => ({ value: assessorObj?.user_id, label: pengajar?.find(item => item?.user_id === assessorObj?.user_id)?.user?.name }))}
                              />
                            )}
                            {formData.assessor_id === "" || formData?.assessor_id?.length === 0 && touched && ( // Menampilkan pesan feedback jika bank_code kosong
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Assesor harus dipilih.
                              </span>
                            )}
                          </div>
                        )}
                        {pelajar?.length > 0 && (
                          <div className='form-group'>
                            <label>Nama Pelajar<span className="text-danger">*</span></label>
                            {formData.students_id && (
                              <Select
                                isMulti
                                name="students_id"
                                options={pelajar.map(item => ({ value: item.user_id, label: item.user?.name }))}
                                onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'students_id')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                styles={selectStyle}
                                defaultValue={formData?.students_id?.filter(studentObj => studentObj?.user_id).map(studentObj => ({ value: studentObj?.user_id, label: pelajar?.find(item => item?.user_id === studentObj?.user_id)?.user?.name }))}
                              />
                            )}
                            {formData.students_id === "" || formData?.students_id?.length === 0 && touched && ( // Menampilkan pesan feedback jika bank_code kosong
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Pelajar harus dipilih.
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    {locations === "Detail" ? (
                      ""
                    ) : (
                      <div className="form-group mb-3 d-flex">
                        <div className="col-xl-6">
                          <Link className="btn btn-light w-100" to={`/jadwal-assessment`}>
                            Batal
                          </Link>
                        </div>
                        <div className="col-xl-6">
                          <button type="submit" className="btn btn-primary w-100" disabled={!validated || formData.assessor_id === "" || formData.students_id === ""}>
                            Simpan Perubahan
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormAssessment