import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Badge, Tab, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { GET, PATCH } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import moment from "moment";
import Form from "react-bootstrap/Form";

const DaftarPelajar = () => {
  const [data, setData] = useState([]);
  const [tipePelajar, setTipePelajar] = useState([]);
  const [levelBelajar, setLevelBelajar] = useState([]);
  const [verifikasi, setVerifikasi] = useState([]);
  const [role, SetRole] = useState([]);
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");

  const [selectedTipePelajar, setSelectedTipePelajar] = useState("");
  const [selectedNameTipePelajar, setSelectedNameTipePelajar] = useState("");
  const [selectedLevelBelajar, setSelectedLevelBelajar] = useState("");
  const [selectedVerified, setSelectedVerified] = useState("");
  const [selectedNameVerified, setSelectedNameVerified] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedNameRole, setSelectedNameRole] = useState("");

  const navigate = useNavigate();

  const fetchData = async (page, perPage, sortField, sortOrder, search, tipePelajar, levelBelajar, verifikasi, role) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      blind: tipePelajar,
      levels: levelBelajar,
      verified: verifikasi,
      teacher: role,
    };
    const res = await GET(`student`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };
  const fetchDataTipePelajar = async () => {
    const res = await GET(`master-data/type?type=tipe pelajar`);
    setTipePelajar(res?.data);
  };
  const fetchDataLevelBelajar = async () => {
    const res = await GET(`master-data/type?type=Level Belajar`);
    console.log(levelBelajar);

    setLevelBelajar(res?.data);
  };

  const fetchDataVerified = async () => {
    const res = await GET(`master-data/type?type=Tipe Verifikasi`);
    setVerifikasi(res?.data);
  };

  const fetchDataRole = async () => {
    const res = await GET(`master-data/type?type=Tipe Pengajar`);
    SetRole(res?.data);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedTipePelajar, selectedLevelBelajar, selectedVerified, selectedRole);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // fetchServerData(page, perPage, null, null, searchTerm);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);

    // setTimeout(() => {
    // fetchServerData(1, perPage, null, null, e.target.value);
    // }, 300);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedTipePelajar, selectedLevelBelajar, selectedVerified, selectedRole]);
  useEffect(() => {
    fetchDataTipePelajar();
    fetchDataLevelBelajar();
    fetchDataVerified();
    fetchDataRole();
  }, []);

  const getVerificationStatus = (status) => {
    return status === "1" ? "Terverifikasi" : "Belum Terverifikasi";
  };
  // const getVerificationStatus = (status) => {
  //   return status === 'Terverifikasi' ? '1' : '0';
  // };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const columns = [
    {
      name: "ID Pelajar",
      selector: (row) => row.code,
      sortable: true,
      field: "code",
      cell: (row) => <div>{row.verified === 1 ? <span>{row.code}</span> : <span>{row.reg_code}</span>}</div>,
    },
    {
      name: "Nama Pelajar",
      selector: (row) => row.user?.name,
      sortable: true,
      field: "name",
      // cell: row => <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user.name}</span>,
    },
    {
      name: "No HP",
      selector: (row) => row.user?.hp_no,
      sortable: true,
      field: "nohp",
    },
    {
      name: "Tipe Pelajar",
      selector: (row) => row.studentType?.name,
      // cell: (row) => {
      //   let displayText;
      //   if (row.blind === 1) {
      //     displayText = (
      //       <div>Tuna Netra</div>
      //     );
      //   } else if (row.blind === 2) {
      //     displayText = (
      //       <div>Orang Awas</div>
      //     );
      //   } else {
      //     displayText = row.blind;
      //   }
      //   return displayText;
      // }
    },
    {
      name: "Status Verifikasi",
      selector: (row) => row.verified,
      field: "status_verifikasi",
      // cell: (row) => {
      //   return <div>{getVerificationStatus(row.verified)}</div>;
      // },
      cell: (row) => {
        return row.verified === 0 ? <div>Belum Verifikasi</div> : row.verified === 1 ? <div>Terverifikasi</div> : row.verified;
      },
    },
    {
      name: "Role",
      selector: (row) => row.teacher,
      field: "role",
      cell: (row) => {
        return row.teacher === 0 ? <div>Bukan Pengajar</div> : row.teacher === 1 ? <div>Pengajar</div> : row.teacher;
      },
    },
    {
      name: "Tanggal Registrasi",
      sortable: true,
      field: "regdate",
      selector: (row) => {
        const date = new Date(row.app_reg_date);
        const formattedDate = date?.toLocaleDateString("id-ID", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        });
        return `${formattedDate}`;
      },
    },
    {
      name: "Level Belajar",
      selector: (row) => row.studentAssessments?.studentLevel?.name,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "130px",
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Aktif
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Tidak Aktif
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      },
    },
    // {
    //   name: 'Nama Pengajar',
    //   selector: row => row.nama_pengajar,
    //   cell: row => <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.nama_pengajar}</span>,
    // },
    {
      name: "Aksi",
      selector: (row) => row.id,
      width: "130px",
      cell: (row) => (
        <div>
          {row.status === 1 ? (
            <Link className="btn btn-danger shadow btn-xs sharp" onClick={() => handleLockClick(row.id)} title="Non-Aktif">
              {/* <FontAwesomeIcon icon={faLock} /> */}
              <i className="fa fa-lock"></i>
            </Link>
          ) : (
            <Link className="btn btn-success shadow btn-xs sharp" onClick={() => handleLockOpenClick(row.id)} title="Aktif">
              {/* <FontAwesomeIcon icon={faLockOpen} /> */}
              <i className="fa fa-unlock"></i>
            </Link>
          )}

          <button className="btn btn-info shadow btn-xs sharp mx-1" title="Detail" onClick={() => navigate(`/${row.id}/detail-pelajar/Detail`)}>
            <i className="fa fa-eye"></i>
          </button>

          <button className="btn btn-primary shadow btn-xs sharp" title="Edit" onClick={() => navigate(`/${row.id}/form-pelajar/Edit`)}>
            <i className="fa fa-pencil"></i>
          </button>
        </div>
      ),
    },
  ];

  const handleSelectTipePelajar = (e) => {
    setSelectedTipePelajar(e.target.getAttribute("value"));
    setSelectedNameTipePelajar(e.target.textContent);
  };

  console.log("ini yaaa", selectedTipePelajar, selectedNameTipePelajar);
  const handleSelectLevelBelajar = (e) => {
    setSelectedLevelBelajar(e.target.getAttribute("value"));
  };

  const handleSelectVerified = (e) => {
    setSelectedVerified(e.target.getAttribute("value"));
    setSelectedNameVerified(e.target.textContent);
  };
  // const handleSelectVerified = (e) => {
  //   const value = e.target.getAttribute("value");
  //   setSelectedVerified(value); // Set the selected value (0 or 1)
  // };

  const handleSelectRole = (e) => {
    setSelectedRole(e.target.getAttribute("value"));
    setSelectedNameRole(e.target.textContent);
  };

  const handleLockClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`student/${id}`, { status: 0 });
    if (res) {
      Swal.fire({
        icon: "success",
        title: `Pelajar Non-Aktif!`,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };
  const handleLockOpenClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`student/${id}`, { status: 1 });
    if (res) {
      Swal.fire({
        icon: "success",
        title: `Pelajar Aktif!`,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Pelajar</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4">
              <div className="d-flex flex-wrap px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                    }}
                    id="dropdown-basic"
                  >
                    {selectedTipePelajar?.length > 0 ? selectedNameTipePelajar : "Semua Tipe Pelajar"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectTipePelajar}>
                      Semua Tipe Pelajar
                    </Dropdown.Item>
                    {Array.isArray(tipePelajar) &&
                      tipePelajar.map((pelajarTipe) => (
                        <Dropdown.Item key={pelajarTipe.id} value={pelajarTipe.description} onClick={handleSelectTipePelajar}>
                          {pelajarTipe.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                    }}
                    id="dropdown-basic"
                  >
                    {selectedLevelBelajar?.length > 0 ? selectedLevelBelajar : "Semua Level Belajar"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectLevelBelajar}>
                      Semua Level Belajar
                    </Dropdown.Item>
                    {Array.isArray(levelBelajar) &&
                      levelBelajar.map((levels) => (
                        <Dropdown.Item key={levels.id} value={levels.name} onClick={handleSelectLevelBelajar}>
                          {levels.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                    {selectedVerified?.length > 0 ? selectedNameVerified : "Semua Status Terverifikasi"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectVerified}>
                      Semua Status Verifikasi
                    </Dropdown.Item>
                    {Array.isArray(verifikasi) &&
                      verifikasi.map((very) => (
                        <Dropdown.Item key={very.id} value={very.description} onClick={handleSelectVerified}>
                          {/* {very.description === '1' ? 'Terverifikasi' : 'Belum Terverifikasi'}  */}
                          {very.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>

                {/* <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                    {selectedVerified?.length > 0 ? selectedVerified : "Semua Status Terverifikasi"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>

                  <Dropdown.Item
                      key="all"
                      value=""
                      onClick={handleSelectTipePelajar}
                    >
                      Semua Status Terverifikasi
                    </Dropdown.Item>
                    <Dropdown.Item
                      key="all"
                      value=""
                      onClick={handleSelectTipePelajar}
                    >
                      Terverifikasi
                    </Dropdown.Item>

                    <Dropdown.Item
                      key="all"
                      value=""
                      onClick={handleSelectTipePelajar}
                    >
                      Belum Verifikasi
                    </Dropdown.Item> */}

                {/* <Dropdown.Item key="all" value="" onClick={handleSelectVerified}>
                    Semua Status Verifikasi
                    </Dropdown.Item>
                    {Array.isArray(verifikasi) && (verifikasi).map((very) => (
                      <Dropdown.Item key={very.id} value={very.verified} onClick={handleSelectVerified}>
                         {very.verified} 
                      </Dropdown.Item>
                    ))} */}
                {/* </Dropdown.Menu>
                </Dropdown>  */}

                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                    }}
                    id="dropdown-basic"
                  >
                    {selectedRole?.length > 0 ? selectedNameRole : "Semua Role"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectRole}>
                      Semua Role
                    </Dropdown.Item>
                    {Array.isArray(role) &&
                      role.map((roles) => (
                        <Dropdown.Item key={roles.id} value={roles.description} onClick={handleSelectRole}>
                          {roles.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>

                {/* <Form.Check inline label="Terverifikasi" name="group1" /> */}
                {/* <Form.Check inline label="Pengajar" name="group1" /> */}
              </div>

              <div className="d-flex align-self-baseline px-4">
                <div className="input-group search-area d-xl-inline-flex">
                  <input type="text" className="form-control" placeholder="Cari Pelajar" value={searchTerm} onChange={handleSearchChange} />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  defaultSortFieldId={2}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
              {/* <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        <div className="d-flex justify-content-end mb-4">
                          <div className="input-group search-area d-xl-inline-flex">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Cari Pelajar"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <Link to="#">
                                  <i className="flaticon-381-search-2" />
                                </Link>
                              </span>
                            </div>
                          </div>
                        </div>
                        <DataTable
                          columns={data.columns}
                          data={dataTable}
                          customStyles={customStyles}
                          pagination
                        />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DaftarPelajar;
