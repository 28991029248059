import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Logout } from '../../../store/actions/AuthActions';
import { isAuthenticated } from '../../../store/selectors/AuthSelectors';
import Swal from "sweetalert2";
import { POST } from "../../../services/AxiosService";


function LogoutPage(props) {
  const router = useNavigate();
  async function onLogout() {
    try {
      const result = await Swal.fire({
        title: 'Yakin ingin Logout?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#5932EA',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal'
      });

      if (result.isConfirmed) {
        const res = await POST(`/auth/logout`);
        if (res) {
          Swal.fire(
            "Logout Sukses",
          );
          localStorage.clear();
          router("/login");
        }
      }
    } catch (error) {
      console.error('Logout:error', error);
      Swal.fire('Error!', 'Terjadi kesalahan saat logout.', 'error');
    }
  };
  return (
    <>
      <button className="dropdown-item ai-icon" onClick={onLogout}>
        <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg"
          className="text-danger" width={18} height={18} viewBox="0 0 24 24"
          fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
        >
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
          <polyline points="16 17 21 12 16 7" />
          <line x1={21} y1={12} x2={9} y2={12} />
        </svg>
        <span className="ml-2" >Logout </span>
      </button>
    </>
  )
}
export default LogoutPage;