import React, { Fragment } from 'react'

import { Row, Col, Card, Table, Button } from 'react-bootstrap'
import { Link } from "react-router-dom";

const RoleAkses = () => {
  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Role</Card.Title>
              <Link
                to="/tambah-role"
              >
                <Button className='mr-2' variant='info btn-rounded'>
                  <span className='btn-icon-left text-info'>
                    <i className='fa fa-plus color-info' />
                  </span>
                  Tambah Role
                </Button>
              </Link>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>Nama Role</strong>
                    </th>
                    <th>
                      <strong>Pembayaran</strong>
                    </th>
                    <th>
                      <strong>Pembelajaran</strong>
                    </th>
                    <th>
                      <strong>Kurikulum</strong>
                    </th>
                    <th>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Administrator</td>
                    <td>
                      <div className="align-items-center">
                        <i className="fa fa-check text-success mr-1"></i>{" "}
                        Lihat transaksi
                        <br/>
                        <i className="fa fa-check text-success mr-1"></i>{" "}
                        Edit transaksi
                      </div>
                    </td>
                    <td>
                      <div className="align-items-center">
                        <i className="fa fa-close text-danger mr-1"></i>{" "}
                        Tidak mendapat akses
                      </div>
                    </td>
                    <td>
                      <div className="align-items-center">
                        <i className="fa fa-check text-success mr-1"></i>{" "}
                        Lihat kurikulum
                        <br/>
                        <i className="fa fa-check text-success mr-1"></i>{" "}
                        Menjalankan kurikulum (add, edit, delete)
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link
                          to="/email-compose"
                          className="btn btn-primary shadow btn-xs sharp mr-1"
                        >
                          <i className="fa fa-pencil"></i>
                        </Link>
                        <Link
                          to="/email-compose"
                          className="btn btn-danger shadow btn-xs sharp"
                        >
                          <i className="fa fa-trash"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default RoleAkses
