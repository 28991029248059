import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { Card } from 'react-bootstrap'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .max(50, "Your username must consist of at least 3 characters ")
    .required("Please enter a username"),
  password: Yup.string()
    .min(5, "Your password must be at least 5 characters long")
    .max(50, "Your password must be at least 5 characters long")
    .required("Please provide a password"),
});

const FormValidation = () => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Fragment>
      <PageTitle
        activeMenu="Tambah Role"
        motherMenu="Daftar Role"
      />

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Tambah Role Baru</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Nama Role<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                        />
                      </div>
                      <div className='form-group'>
                        <label>Akses<span className="text-danger">*</span></label>
                        <Card>
                          <Card.Header>
                            <Card.Title>Pembayaran</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <div className='row'>
                              <div className='col-12'>
                                <div className='custom-control custom-checkbox mb-3'>
                                  <input
                                    type='checkbox'                      
                                    className='custom-control-input'
                                    id='viewTransaction'
                                  />
                                  <label
                                    className='custom-control-label'
                                    htmlFor='viewTransaction'
                                  >
                                    Lihat transaksi
                                  </label>
                                </div>
                              </div>

                              <div className='col-12'>
                                <div className='custom-control custom-checkbox mb-3'>
                                  <input
                                    type='checkbox'                      
                                    className='custom-control-input'
                                    id='editTransaction'
                                  />
                                  <label
                                    className='custom-control-label'
                                    htmlFor='editTransaction'
                                  >
                                    Edit transaksi
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                        <Card>
                          <Card.Header>
                            <Card.Title>Pembelajaran</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <div className='row'>
                              <div className='col-12'>
                                <div className='custom-control custom-checkbox mb-3'>
                                  <input
                                    type='checkbox'                      
                                    className='custom-control-input'
                                    id='viewCourse'
                                  />
                                  <label
                                    className='custom-control-label'
                                    htmlFor='viewCourse'
                                  >
                                    Lihat pelajaran
                                  </label>
                                </div>
                              </div>

                              <div className='col-12'>
                                <div className='custom-control custom-checkbox mb-3'>
                                  <input
                                    type='checkbox'                      
                                    className='custom-control-input'
                                    id='editCourse'
                                  />
                                  <label
                                    className='custom-control-label'
                                    htmlFor='editCourse'
                                  >
                                    Menjalankan pelajaran (add, edit, delete)
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                        <Card>
                          <Card.Header>
                            <Card.Title>Kurikulum</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <div className='row'>
                              <div className='col-12'>
                                <div className='custom-control custom-checkbox mb-3'>
                                  <input
                                    type='checkbox'                      
                                    className='custom-control-input'
                                    id='viewCourse'
                                  />
                                  <label
                                    className='custom-control-label'
                                    htmlFor='viewCourse'
                                  >
                                    Lihat kurikulum
                                  </label>
                                </div>
                              </div>

                              <div className='col-12'>
                                <div className='custom-control custom-checkbox mb-3'>
                                  <input
                                    type='checkbox'                      
                                    className='custom-control-input'
                                    id='editCourse'
                                  />
                                  <label
                                    className='custom-control-label'
                                    htmlFor='editCourse'
                                  >
                                    Menjalankan kurikulum (add, edit, delete)
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormValidation;
