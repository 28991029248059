import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Table, Button, Badge } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from "react-data-table-component";
import { GET, PATCH } from "../../../services/AxiosService";
import Swal from 'sweetalert2';

const VerifikasiPelajar = () => {
  // const [selectedStatus, setSelectedStatus] = useState('Semua Status Assessment');
  // const [selectedTipePelajar, setSelectedTipePelajar] = useState('Semua Tipe Pelajar');
  const [data, setData] = useState([]);
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const navigate = useNavigate();

  const fetchData = async (page, perPage, sortField, sortOrder, search) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      blind: 1,
      verified: 0,
      search,
    };
    const res = await GET(`student`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };
  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // fetchServerData(page, perPage, null, null, searchTerm);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);

    // setTimeout(() => {
    // fetchServerData(1, perPage, null, null, e.target.value);
    // }, 300);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder]);

  const columns = [
    {
      name: 'ID Calon Pelajar',
      selector: row => row.reg_code,
    },
    {
      name: 'Nama Calon Pelajar',
      selector: row => row.user.name,
      // cell: row => <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user.name}</span>,
    },
    {
      name: 'No Telepon',
      selector: row => row.hp_no,
      cell: (row) => (
        <div>{row.user?.hp_no}</div>
      )
    },
    {
      name: 'Nama Wali',
      selector: row => row.guardian_name,
      cell: row => <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.guardian_name}</span>,
    },
    {
      name: 'No Telepon Wali',
      selector: row => row.guardian_hp_no,
    },
    {
      name: 'Aksi',
      width: "130px",
      cell: (row) => (
        <div>
          <button className="btn btn-info shadow btn-xs sharp" title="Detail" onClick={() => navigate(`/${row.id}/detail-pelajar`, { state: { from: 'Verifikasi Pelajar Tuna Netra' } })}>
            <i className="fa fa-eye"></i>
          </button>

          <Link
            className="btn btn-primary shadow btn-xs sharp mx-1"
            // onClick={() => handleVerifikasi(row)}
            title="Edit"
            to={`/${row.id}/form-pelajar`}
          >
            <i className="fa fa-pencil"></i>
          </Link>

          <Link className="btn btn-success shadow btn-xs sharp" title="verifikasi" onClick={() => handleVerifikasi(row)}>
            <i className="fa fa-check"></i>
          </Link>
        </div>
      ),
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
    // table: {
    //   style: {
    //     minHeight: dropdownOpenId ? "300px" : "unset",
    //   },
    // },
  };
  // const data = [
  //   {
  //     id: "CPLJ014",
  //     nama_calon_pelajar: "Sari W",
  //     hp_no: "08838344625",
  //     nama_wali: "Supardi",
  //     hp_no_wali: "08838344337",
  //   },
  //   {
  //     id: "CPLJ016",
  //     nama_calon_pelajar: "Ira A",
  //     hp_no: "08322873000",
  //     nama_wali: "Mirah",
  //     hp_no_wali: "08322873999",
  //   },
  // ]

  // const handleSelectStatus = (e) => {
  //   setSelectedStatus(e.target.getAttribute('value'));
  // };
  // const handleSelectTipePelajar = (e) => {
  //   setSelectedTipePelajar(e.target.getAttribute('value'));
  // };
  // const statusMap = {
  //   1: 'Belum di Jadwalkan',
  //   2: 'Terjadwalkan',
  //   3: 'Sudah Assessment',
  //   "Semua Status Assessment": 'Semua Status Assessment',
  // };
  // const tipePelajarMap = {
  //   1: 'Tuna Netra',
  //   2: 'Orang Awas',
  //   "Semua Tipe Pelajar": 'Semua Tipe Pelajar',
  // };

  const handleVerifikasi = async (row) => {
    const result = await Swal.fire({
      title: `Apakah Anda yakin bahwa calon pelajar ${row.user.name} terverifikasi?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await PATCH(`student/${row.id}`, { verified: 1 });
      if (res) {
        Swal.fire({
          icon: "success",
          title: `Calon pelajar ${row.user.name} telah terverifikasi.`,
        }).then(() => {
          Swal.close();
          fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
        });
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Verifikasi Pelajar Tuna Netra</Card.Title>
            </Card.Header>
            <div className="d-flex mt-4">
              <div className="col-6 col-sm-6 col-md-8 col-lg-9 d-flex px-4">
                {/* <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                    {statusMap[selectedStatus]}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleSelectStatus} value="1">Belum di Jadwalkan</Dropdown.Item>
                    <Dropdown.Item onClick={handleSelectStatus} value="2">Terjadwalkan</Dropdown.Item>
                    <Dropdown.Item onClick={handleSelectStatus} value="3">Sudah Assessment</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                    {tipePelajarMap[selectedTipePelajar]}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleSelectTipePelajar} value="1">Tuna Netra</Dropdown.Item>
                    <Dropdown.Item onClick={handleSelectTipePelajar} value="2">Orang Awas</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>

              <div className="col-6 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-end px-4">
                <div className="input-group search-area d-xl-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari Pelajar"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  defaultSortFieldId={3}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default VerifikasiPelajar