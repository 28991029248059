import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { Badge, Card } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import profile from "../../../images/profile.jpeg";
import { GET, PATCH } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import Select from 'react-select';
import DataTable from "react-data-table-component";

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .max(50, "Your username must consist of at least 3 characters ")
    .required("Please enter a username"),
  password: Yup.string()
    .min(5, "Your password must be at least 5 characters long")
    .max(50, "Your password must be at least 5 characters long")
    .required("Please provide a password"),
});

const DetailPelajar = () => {
  const [showPassword, setShowPassword] = useState(false);
  const router = useNavigate();
  const { id,flag } = useParams();
  const [tipePelajar, setTipePelajar] = useState([]);
  const [verifikasi, setVerifikasi] = useState([]);
  const [role, setRole] = useState([]);
  const [gender, setGender] = useState([]);
  const [pekerjaan, setPekerjaan] = useState([]);
  const [pendidikan, setPendidikan] = useState([]);
  const [levelBelajar, setLevelBelajar] = useState([]);
  const [city, setCity] = useState({});
  const [lokasi, setLokasi] = useState({});
  const [relation, setRelation] = useState([]);
  const [riwayatKesehatan, setRiwayatKesehatan] = useState([]);
  const [provinsi, setProvinsi] = useState([]);
  const [kabupaten, setKabupaten] = useState([]);
  const [kecamatan, setKecamatan] = useState([]);
  const [desa, setDesa] = useState([]);
  const location = useLocation();
  const [locations, setLocations] = useState(location.state?.from?.length > 0 ? location?.state?.from : "Detail");
  const [temanBelajar, setTemanBelajar] = useState([]);

  const [validated, setValidated] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: "",
    birth_date: "",
    hp_no: "",
    blind: "",
    student_type: "",
    gender: "",
    address_1: "",
    summary: '',
    rt: "",
    rw: "",
    nik: "",
    verified: "",
    teacher: "",
    app_reg_date: "",
    work: "",
    learning_level: "",
    last_education: "",
    kotaKabupaten: "",
    learning_location: '',
    // alamat
    urbanVillageId: "",
    subDistrictId: "",
    districtId: "",
    provinceId: "",
    postal_code: "",

    city:"",
    provinces:"",
    district:"",
    urbanVillage:"",
    // wali
    guardian_name: "",
    guardian_hp_no: "",
    guardian_email: "",
    guardian_gender: "",
    guardian_nik: "",
    relation: "",
    // disabilitas
    year: "",
    read_write: "",
    latin_braille: "",
    guidance_braille: "",
    total_memorization_letters: "",
    memorized_letters: "",
    hearing_problems: "",
    health_problems: "",
    health_problems_other: "",
    health_problems_checkbox: "",

  });

  useEffect(() => {
    if (editFormData?.kotaKabupaten) {
      fetchDataLokasi(editFormData.kotaKabupaten);
    }
  }, [editFormData.kotaKabupaten]);

  const fetchData = async () => {
    const res = await GET(`student/${id}`);
    console.log('respon',res)
    // setEditFormData(res?.data);
    const healt = Array.isArray(res?.data?.studentAssessments?.health_problems)
      ? res?.data?.studentAssessments?.health_problems.map(problem => problem.id)
      : [];
    setEditFormData({
      code: res?.data?.code,
      reg_code: res?.data?.reg_code,
      name: res?.data.user?.name,
      birth_date: res?.data.studentPersonal?.birth_date,
      hp_no: res?.data.user?.hp_no,
      blind: res?.data.blind,
      gender: res?.data.studentPersonal?.gender,
      email: res?.data.user?.email,
      address_1: res?.data.studentPersonal?.address_1,
      summary: res?.data.studentAssessments?.summary,
      rt: res?.data.studentPersonal?.rt,
      subDistrictId: res?.data.studentPersonal?.urbanVillage?.subDistrict?.id,
      provinceId: res?.data.studentPersonal?.urbanVillage?.subDistrict?.district?.province?.id,
      rw: res?.data.studentPersonal?.rw,
      urbanVillageId: res?.data.studentPersonal?.urbanVillage?.id,
      districtId: res?.data.studentPersonal?.urbanVillage?.subDistrict?.district?.id,
      postal_code: res?.data.studentPersonal?.postal_code,
      guardian_name: res.data.guardian_name,
      guardian_email: res.data.guardian_email,
      guardian_nik: res?.data.guardian_nik,
      guardian_hp_no: res?.data.guardian_hp_no,
      guardian_gender: res?.data.guardian_gender,
      relation: res?.data.studentPersonal?.relation,
      year: res?.data.studentPersonal?.year,
      latin_braille: res?.data.studentPersonal?.latin,
      hearing_problems: res?.data.studentPersonal?.hearing_problems,
      read_write: res?.data.studentPersonal?.read_write,
      guidance_braille: res?.data.studentPersonal?.hijaiyah,
      total_memorization_letters: res?.data.studentPersonal?.total_memorization_letters,
      memorized_letters: res?.data.studentPersonal?.memorized_letters,
      studentPersonalId: res?.data.studentPersonal?.id,
      nik: res?.data?.studentPersonal?.nik,
      verified: res?.data?.verified,
      teacher: res?.data?.teacher,
      app_reg_date: res?.data?.app_reg_date,
      student_type: res?.data?.student_type,
      work: res?.data?.studentAssessments?.work,
      learning_level: res?.data?.studentAssessments?.learning_level,
      last_education: res?.data?.studentAssessments?.last_education,
      kotaKabupaten: res?.data?.studentAssessments?.learning_location?.kotaKabupaten?.id,
      learning_location: res?.data?.studentAssessments?.learning_location?.id,
      health_problems: healt,
      health_problems_other: res?.data?.studentAssessments?.health_problems_other,
      provinces: res?.data?.studentPersonal?.district?.province.name,
      city:res?.data?.studentPersonal?.district?.name,
      district:res?.data?.studentPersonal?.sub_district,
      urbanVillage:res?.data?.studentPersonal?.urban_village
    });
    if (healt.includes('c9948d65-f202-453c-9959-2e0e85a89033')) {
      setShowOtherHealthProblems(true);
    } else {
      // Jika ID tidak sesuai, sembunyikan dan kosongkan input "health_problems_other"
      setShowOtherHealthProblems(false);
      setEditFormData((prev) => ({
        ...prev,
        health_problems_other: "",  // Kosongkan input
      }));
    }

    const idProvinsi = res?.data.studentPersonal?.urbanVillage?.subDistrict?.district?.province?.id;
    const idDistrict = res?.data.studentPersonal?.urbanVillage?.subDistrict?.district?.id;
    const idSubDistrict = res?.data.studentPersonal?.urbanVillage?.subDistrict?.id;
    fetchDataProvinsi(idProvinsi, idDistrict, idSubDistrict)
    const response = await GET(`master-data/type?type=Riwayat Kesehatan`);
    setRiwayatKesehatan(response?.data);
  };

  console.log('editFormData',editFormData)

  const fetchDataTipePelajar = async (tipePelajar) => {
    const res = await GET(`master-data/type?type=Tipe Pelajar`);
    setTipePelajar(res?.data);
  };
  const fetchDataGender = async () => {
    const res = await GET(`master-data/type?type=Gender`);
    setGender(res?.data);
  };
  const fetchDataPekerjaan = async () => {
    const res = await GET(`master-data/type?type=Pekerjaan`);
    setPekerjaan(res?.data);
  };
  const fetchDataPendidikan = async () => {
    const res = await GET(`master-data/type?type=Pendidikan`);
    setPendidikan(res?.data);
  };
  const fetchDataLevelBelajar = async () => {
    const res = await GET(`master-data/type?type=Level Belajar`);
    setLevelBelajar(res?.data);
  };
  const fetchDataCity = async () => {
    const res = await GET(`master-data/type?type=Kota Kabupaten`);
    setCity(res?.data);
  }

  const fetchDataLokasi = async (kotaKabupaten) => {
    const res = await GET(`master-data/parent/${kotaKabupaten}`);
    setLokasi(res?.data);
  };


  const fetchDataVerified = async (verifikasi) => {
    const res = await GET(`master-data/type?type=Tipe Verifikasi`);
    setVerifikasi(res?.data);
  };

  const fetchDataRole = async () => {
    const res = await GET(`master-data/type?type=Tipe Pengajar`);
    setRole(res?.data);
  };
  const fetchDataRiwayatKesehatan = async () => {
    try {
      const res = await GET(`master-data/type?type=Riwayat Kesehatan`);
    } catch (error) {
      console.error("Error saat mengambil data riwayat kesehatan:", error); // Tangani error yang mungkin terjadi
    }
  };


  const fetchDataRelation = async () => {
    const res = await GET(`master-data/type?type=Hubungan`);
    setRelation(res?.data);
  };

  const fetchDataProvinsi = async (idProvinsi, idDistrict, idSubDistrict) => {
    const res = await GET(`province`);
    setProvinsi(res?.data);
  };

  console.log('provinsi',provinsi)

  const fetchTemanBelajar = async () => {
    const res = await GET(`student-recommendation/${id}/teman-belajar`);
    setTemanBelajar(res?.data);
  };

  const isDisabled = editFormData.blind === 0;

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'kotaKabupaten') {
      const selectedKab = city.find(kab => kab.id === value);
      if (selectedKab) {
        setLokasi(selectedKab.learning_location);
        editFormData.learning_location = "";
      } else {
        setLokasi([]);
      }
    }

    if (name === 'provinceId') {
      const selectedProvince = provinsi.find(prov => prov.id === value);
      if (selectedProvince) {
        setKabupaten(selectedProvince.districts);
        editFormData.districtId = "";
        editFormData.subDistrictId = "";
        editFormData.urbanVillageId = "";
      } else {
        setKabupaten([]);
      }
    }

    if (name === 'districtId' || name === 'provinceId') {
      const selectedDistrict = kabupaten.find(prov => prov.id === value);
      if (selectedDistrict) {
        setKecamatan(selectedDistrict.subDistricts);
        editFormData.subDistrictId = "";
        editFormData.urbanVillageId = "";
      } else {
        setKecamatan([]);
      }
    }

    if (name === 'subDistrictId' || name === 'provinceId' || name === 'districtId') {
      const selectedSubDistrict = kecamatan.find(prov => prov.id === value);
      if (selectedSubDistrict) {
        setDesa(selectedSubDistrict.urbanVillages);
        editFormData.urbanVillageId = ""
      } else {
        setDesa([]);
      }
    }

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleDateChange = (date) => {
    const isoDateString = date.toISOString();
    const formattedDate = isoDateString.split('T')[0];
    setEditFormData({
      ...editFormData,
      birth_date: formattedDate
    });
  };
  const handleYearChange = (date) => {
    const month = date.getMonth(); // Mengambil bulan dari tanggal
    const year = date.getFullYear(); // Mengambil tahun dari tanggal
    setEditFormData({
      ...editFormData,
      year: `${year}-${String(month + 1).padStart(2, '0')}` // Format YYYY-MM
    });
  };

  const handleSelectChange = (selectedOptions, name) => {
    // Update form data berdasarkan pilihan
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: selectedOptions.map(option => option.value),
    }));

    // Ambil semua nilai yang dipilih
    const test = selectedOptions.map(option => option.value);

    // Jika ID khusus dipilih, tampilkan input lainnya
    if (test.includes('c9948d65-f202-453c-9959-2e0e85a89033')) {
      setShowOtherHealthProblems(true);
    } else {
      // Jika ID tidak sesuai, sembunyikan dan kosongkan input "health_problems_other"
      setShowOtherHealthProblems(false);
      setEditFormData((prev) => ({
        ...prev,
        health_problems_other: "",  // Kosongkan input
      }));
    }
  };

  const [showOtherHealthProblems, setShowOtherHealthProblems] = useState(false);
  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setShowOtherHealthProblems(checked);
    if (!checked) {
      setEditFormData((prevData) => ({
        ...prevData,
        health_problems_other: '',
      }));
    }
  };
  useEffect(() => {
    if (editFormData?.health_problems_other?.length > 0) {
      setShowOtherHealthProblems(true);
    }
  }, [editFormData?.health_problems_other]);

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const {
      name,
      blind,
      student_type,
      verified,
      teacher,
      birth_date,
      gender,
      rt,
      rw,
      postal_code,
      address_1,
      summary,
      guardian_name,
      guardian_nik,
      guardian_gender,
      guardian_hp_no,
      year,
      latin_braille,
      hearing_problems,
      read_write,
      guidance_braille,
      total_memorization_letters,
      memorized_letters,
      urbanVillageId,
      relation,
      nik,
      work,
      learning_level,
      last_education,
      learning_location,
      health_problems,
      health_problems_other,
    } = editFormData;

    const requestBody = {
      guardian_name,
      guardian_nik,
      guardian_gender,
      guardian_hp_no,
      blind,
      student_type,
      verified,
      teacher,
      user: {
        name,
      },
      personalDetails: {
        birth_date,
        gender,
        rt,
        rw,
        postal_code,
        address_1,
        year,
        latin_braille,
        hearing_problems,
        read_write,
        guidance_braille,
        total_memorization_letters,
        memorized_letters,
        urban_village_id: urbanVillageId,
        relation,
        nik
      },
      studentAssessments: {
        work,
        learning_level,
        last_education,
        summary,
        health_problems,
        health_problems_other,
        learning_location,

      }
    };

    const result = await Swal.fire({
      title: 'Apakah Anda yakin akan menyimpan perubahan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await PATCH(`student/${id}`, requestBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Data Pelajar berhasil diubah!",
        }).then(() => {
          Swal.close();
          fetchData()
        });
        router("/daftar-pelajar")
      }
    }
  };
  function getFormattedDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, '0');
    return new Date(`${yyyy}-${mm}-${dd}`);
  }

  // teman belajar
  const columns = [
    {
      name: 'No HP',
      selector: row => row.recomendation_hp_no,
    },
    {
      name: 'Nama',
      selector: row => row.recomendation_name,
    },
    {
      name: 'Status',
      selector: row => row.status,
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Sudah bergabung
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Belum bergabung
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      }
    },
  ];

  //pemesanan buku pelajar
  const columnsPemesananBuku = [
    {
      name: 'Judul Buku',
      selector: row => row.recomendation_name,
    },
    {
      name: 'Status',
      selector: row => row.status,
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Sudah bergabung
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Belum bergabung
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      }
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleUbahLaporan = () => {
    setLocations("Ubah");
  };

  useEffect(() => {
    if (riwayatKesehatan.length > 0) {
      // Panggil handleSelectChange dengan data yang valid jika diperlukan
    }
  }, [riwayatKesehatan]);


  const defaultValues = Array.isArray(editFormData?.health_problems)
    ? riwayatKesehatan.filter(item =>
      editFormData?.health_problems?.map(problem => problem.id).includes(item.id)
    ).map(item => ({ value: item.id, label: item.name }))
    : [];

    useEffect(() => {
      fetchData().then(fetchDataTipePelajar);
      fetchDataGender();
      fetchDataRelation();
      fetchDataPekerjaan();
      fetchDataPendidikan();
      fetchDataLevelBelajar();
      fetchDataCity();
      fetchDataVerified();
      // fetchDataLokasi();
      if (editFormData?.kotaKabupaten == "undefined") {
        fetchDataLokasi();
      }
      fetchDataRole();
      fetchDataRiwayatKesehatan();
      if (locations === "Detail") {
        fetchTemanBelajar();
      }
    }, []);

  // useEffect(() => {
  //   if (riwayatKesehatan.length > 0 && Array.isArray(editFormData?.health_problems)) {
  //     const selectedOptions = riwayatKesehatan
  //       .filter(item => editFormData.health_problems.includes(item.id))
  //       .map(item => ({ value: item.id, label: item.name }));

  //     handleSelectChange(selectedOptions, 'health_problems');
  //   }
  // }, [riwayatKesehatan, editFormData?.health_problems]);

  const healthProblems = Array.isArray(editFormData?.health_problems)
    ? editFormData.health_problems
    : [];

  const tutu = riwayatKesehatan
    .filter(item => healthProblems.map(problem => problem.id).includes(item.id))
    .map(item => ({ value: item.id, label: item.name }))


  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Pelajar`}
        motherMenu="Daftar Pelajar"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="row">
                <h4 className="card-title mb-2">{flag} Data Pelajar</h4>
                <span>Form untuk {flag} pelajar baru</span>
              </div>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/${id}/form-pelajar/Edit`}>
                  Ubah Pelajar
                </Link>
              )}
            </div>
            <div className="card-body">
              <h3>Data Umum</h3>
              <div className="profile-photo text-center">
                <img src={profile} width={130} className="img-fluid rounded-circle" alt="profile" />
              </div>
              <div className="form-validation mt-3">
                <form className="form-valide" onSubmit={handleEditSubmit}>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>ID Pelajar<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={`${editFormData.verified === 1 ? editFormData.code : editFormData.reg_code}`}
                          disabled
                        // name="name"
                        // onChange={handleChangeEdit}
                        // maxLength="100"
                        // minLength="3"
                        // required
                        />
                      </div>
                      <div className='form-group'>
                        <label>Nama Lengkap<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.name}
                          name="name"
                          onChange={handleChangeEdit}
                          maxLength="100"
                          minLength="3"
                          required
                          disabled={flag=== "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama Lengkap harus diisi minimal 3 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <p className="mb-1">Tanggal Registrasi<span className="text-danger">*</span></p>
                        <DatePicker
                          name="birth_date"
                          selected={editFormData.app_reg_date ? new Date(editFormData.app_reg_date) : null}
                          onChange={handleDateChange}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                          maxDate={getFormattedDate()}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Tipe Pelajar<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.student_type}
                          name="student_type"
                          onChange={handleChangeEdit}
                          disabled
                        >
                          {Array.isArray(tipePelajar) && tipePelajar.map((tipe) => (
                            <option
                              key={tipe.id}
                              // value={tipe.name === 'Tuna Netra' ? 1 : tipe.name === 'Orang Awas' ? 2 : ''}
                              value={tipe.id}
                            >
                              {tipe.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='form-group'>
                        <label>Terverifikasi<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.verified}
                          name="verified"
                          onChange={handleChangeEdit}
                          disabled
                        >
                          {/* <option key="0" value="0">
                            Belum Verifikasi
                          </option>
                          <option key="1" value="1">
                            Terverifikasi
                          </option> */}
                          {Array.isArray(verifikasi) && verifikasi.map((very) => (
                            <option
                              key={very.description}
                              // value={tipe.name === 'Tuna Netra' ? 1 : tipe.name === 'Orang Awas' ? 2 : ''}
                              value={very.description}
                            >
                              {very.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='form-group'>
                        <label>Role<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.teacher}
                          name="teacher"
                          onChange={handleChangeEdit}
                          disabled
                        >
                          {/* <option key="0" value="0">
                            Bukan Pengajar
                          </option>
                          <option key="1" value="1">
                            Pengajar
                          </option> */}
                          <option>Pilih Role</option>
                          {Array.isArray(role) && role.map((roles) => (
                            <option
                              key={roles.description}
                              // value={tipe.name === 'Tuna Netra' ? 1 : tipe.name === 'Orang Awas' ? 2 : ''}
                              value={roles.description}
                            >
                              {roles.name}
                            </option>
                          ))}


                        </select>
                      </div>

                      <div className='form-group'>
                        <label>Jenis Kelamin<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.gender}
                          name="gender"
                          onChange={handleChangeEdit}
                          disabled
                        >
                          <option>Pilih Jenis Kelamin</option>
                          {Array.isArray(gender) && gender.map((genderUser) => (
                            <option
                              key={genderUser.id}
                              value={genderUser.id}
                            >
                              {genderUser.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <h3>Data Pribadi</h3>
                  <div className="row mt-3">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>NIK<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.nik}
                          required
                          minLength="8"
                          disabled
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setEditFormData((prevState) => ({
                              ...prevState,
                              nik: numericValue,
                            }));

                            const form = e.currentTarget.form;
                            const isValid = form.checkValidity();

                            setValidated(isValid);

                            if (isValid) {
                              form.classList.remove("was-validated");
                            } else {
                              form.classList.add("was-validated");
                            }
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          NIK harus diisi minimal 8 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <br/>
                      <div className='form-group'>
                        <label>Nomor HP<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.hp_no}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setEditFormData((prevState) => ({
                              ...prevState,
                              hp_no: numericValue,
                            }));
                          }}
                          disabled
                        />
                      </div>
                      <div className='form-group'>
                        <label>Kabupaten Kota/Kota Madya<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          value={editFormData.city}
                          name="districtId"
                          onChange={handleChangeEdit}
                          required
                          disabled
                        >
                          {/* {Array.isArray(kabupaten) && [
                            editFormData.districtId === '' && (
                              <option key="" value="">Pilih Kabupaten Kota/Kota Madya</option>
                            ),
                            ...kabupaten.map((dataKabupaten) => (
                              <option
                                key={dataKabupaten.id}
                                value={dataKabupaten.id}
                              >
                                {dataKabupaten.name}
                              </option>
                            ))
                          ]} */}
                        </input>
                        <Form.Control.Feedback type="invalid">
                          Kabupaten Kota harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>Desa/Kelurahan<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.urbanVillage}
                          name="urbanVillageId"
                          onChange={handleChangeEdit}
                          maxLength="4"
                          required
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                             Desa/Kelurahan harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group' hidden>
                        <label>Desa/Kelurahan<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.urbanVillageId}
                          name="urbanVillageId"
                          onChange={handleChangeEdit}
                          required
                          disabled
                        >
                          {Array.isArray(desa) && [
                            editFormData.urbanVillageId === '' && (
                              <option key="" value="">Pilih Desa/Kelurahan</option>
                            ),
                            ...desa.map((dataDesa) => (
                              <option
                                key={dataDesa.id}
                                value={dataDesa.id}
                              >
                                {dataDesa.name}
                              </option>
                            ))
                          ]}
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Desa/Kelurahan harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>RT<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.rt}
                          name="rt"
                          onChange={handleChangeEdit}
                          maxLength="4"
                          required
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          RT harus diisi.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>Kode Pos<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.postal_code}
                          disabled
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setEditFormData((prevState) => ({
                              ...prevState,
                              postal_code: numericValue,
                            }));
                          }}
                          maxLength="5"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Kode Pos harus diisi.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <p className="mb-1">Tanggal Lahir<span className="text-danger">*</span></p>
                        <DatePicker
                          name="birth_date"
                          selected={editFormData.birth_date ? new Date(editFormData.birth_date) : null}
                          onChange={handleDateChange}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                          maxDate={getFormattedDate()}
                          required
                          disabled
                        />
                        <br />
                        {editFormData.birth_date === undefined && (
                          <small className="text-danger">Tanggal Lahir harus diisi.</small>
                        )}
                      </div>
                      {/* {editFormData.blind === 2 && ( */}
                      {/* <div className='form-group'>
                        <label>Email</label>
                        <input
                          type='email'
                          className='form-control'
                          value={editFormData.email}
                          name="email"
                          onChange={handleChangeEdit}
                          disabled
                        />
                      </div> */}
                      {/* )} */}
                      <br/>
                      <div className='form-group'>
                        <label>Provinsi<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          value={editFormData.provinces}
                          name="provinceId"
                          // onChange={handleChangeEdit}
                          required
                          disabled
                        >
                          {/* {Array.isArray(provinsi) && [
                            editFormData.provinceId === '' || editFormData.provinceId === undefined && (
                              <option key="" value="">Pilih Provinsi</option>
                            ),
                            ...provinsi.map((dataProvinsi) => (
                              <option
                                key={dataProvinsi.id}
                                value={dataProvinsi.id}
                              >
                                {dataProvinsi.name}
                              </option>
                            ))
                          ]} */}
                        </input>
                        <Form.Control.Feedback type="invalid">
                          Provinsi harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>Kecamatan<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.district}
                          name="subDistrictId"
                          onChange={handleChangeEdit}
                          maxLength="4"
                          required
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                            Kecamatan harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group' hidden>
                        <label>Kecamatan<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.subDistrictId}
                          name="subDistrictId"
                          onChange={handleChangeEdit}
                          required
                          disabled
                        >
                          {Array.isArray(kecamatan) && [
                            editFormData.subDistrictId === '' && (
                              <option key="" value="">Pilih Kecamatan</option>
                            ),
                            ...kecamatan.map((dataKecamatan) => (
                              <option
                                key={dataKecamatan.id}
                                value={dataKecamatan.id}
                              >
                                {dataKecamatan.name}
                              </option>
                            ))
                          ]}
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Kecamatan harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>RW<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.rw}
                          name="rw"
                          onChange={handleChangeEdit}
                          maxLength="4"
                          required
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          RW harus diisi.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>Pekerjaan<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.work}
                          name="work"
                          onChange={handleChangeEdit}
                          disabled
                        >
                          <option>Piih Pekerjaan</option>
                          {Array.isArray(pekerjaan) && pekerjaan.map((pekerjaanUser) => (
                            <option
                              key={pekerjaanUser.id}
                              value={pekerjaanUser.id}
                            >
                              {pekerjaanUser.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className='form-group'>
                    <label>Alamat<span className="text-danger">*</span></label>
                    <textarea
                      className='form-control'
                      rows="5"
                      value={editFormData.address_1}
                      name="address_1"
                      onChange={handleChangeEdit}
                      maxLength="200"
                      minLength="5"
                      required
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      Alamat harus diisi minimal 5 karakter.
                    </Form.Control.Feedback>
                  </div>

                  <hr />

                  <h3>Data Akademik</h3>
                  <div className="row mt-3">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Pendidikan<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.last_education}
                          name="last_education"
                          onChange={handleChangeEdit}
                          disabled
                        >
                          <option>Pilih Pendidikan</option>
                          {Array.isArray(pendidikan) && pendidikan.map((pendidikanUser) => (
                            <option
                              key={pendidikanUser.id}
                              value={pendidikanUser.id}
                            >
                              {pendidikanUser.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Level Belajar<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.learning_level}
                          name="learning_level"
                          onChange={handleChangeEdit}
                          disabled
                        >
                          <option>Pilih Level Belajar</option>
                          {Array.isArray(levelBelajar) && levelBelajar.map((levelBelajarUser) => (
                            <option
                              key={levelBelajarUser.id}
                              value={levelBelajarUser.id}
                            >
                              {levelBelajarUser.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Kota/Kabupaten</label>
                        <select
                          className='form-control'
                          value={editFormData.kotaKabupaten}
                          name="kotaKabupaten"
                          onChange={handleChangeEdit}
                          disabled
                        >
                          <option key="" value="">Pilih Kota/Kabupaten</option>
                          {Array.isArray(city) && city.map((cityUser) => (
                            <option
                              key={cityUser.id}
                              value={cityUser.id}
                            >
                              {cityUser.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Lokasi</label>
                        <select
                          className='form-control'
                          value={editFormData.learning_location}
                          name="learning_location"
                          onChange={handleChangeEdit}
                          disabled
                        >
                          <option value={""}>Pilih Lokasi</option>
                          {Array.isArray(lokasi) && lokasi.map((lokasiUser) => (
                            <option
                              key={lokasiUser.id}
                              value={lokasiUser.id}
                            >
                              {lokasiUser.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label>Hasil Assessment</label>
                    <textarea
                      className='form-control'
                      rows="3"
                      value={editFormData.summary}
                      name="summary"
                      onChange={handleChangeEdit}
                      maxLength="200"
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      Assessment harus diisi minimal 5 karakter.
                    </Form.Control.Feedback>
                  </div>
                  <hr />
                  <h3>Pendamping</h3>
                  <div className="row mt-3">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Nama Wali<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.guardian_name}
                          name="guardian_name"
                          onChange={handleChangeEdit}
                          maxLength="100"
                          minLength="3"
                          required
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama Wali harus diisi minimal 3 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>Hubungan Wali<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.relation}
                          name="relation"
                          onChange={handleChangeEdit}
                          disabled
                        >
                          <option>Pilih Hubungan Wali</option>
                          {Array.isArray(relation) && relation.map((relationUser) => (
                            <option
                              key={relationUser.id}
                              value={relationUser.id}
                            >
                              {relationUser.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Nomor HP Wali<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.guardian_hp_no}
                          maxLength="100"
                          minLength="11"
                          required
                          disabled
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setEditFormData((prevState) => ({
                              ...prevState,
                              guardian_hp_no: numericValue,
                            }));
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Nomor Hp Wali harus diisi minimal 11 karakter.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <h3>Data Disabilitas</h3>
                  <div className="row mt-3">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <p className="mb-1">Hambatan Penglihatan Sejak Kapan<span className="text-danger">*</span></p>
                        {/* <DatePicker value={editFormData.studentPersonal?.year} name="birth_date" className="form-control" /> */}
                        <DatePicker
                          name="year"
                          selected={editFormData.year ? new Date(editFormData.year) : null}
                          onChange={handleYearChange}
                          showYearPicker
                          dateFormat="yyyy"
                          className="form-control"
                          maxDate={getFormattedDate()}
                          required
                          disabled
                        />
                        <br />
                        {editFormData.year === undefined && (
                          <small className="text-danger">Form ini harus diisi.</small>
                        )}
                      </div>
                      <div className='form-group'>
                        <label>Bisa Braille Latin<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.latin_braille}
                          name="latin_braille"
                          onChange={handleChangeEdit}
                          required
                          disabled
                        >
                          <option value='0'>Tidak</option>
                          <option value='1'>Iya</option>
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Form ini harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Bisa Braille Hijaiyah<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.guidance_braille}
                          name="guidance_braille"
                          onChange={handleChangeEdit}
                          disabled
                          required
                        >
                          <option value='0'>Tidak</option>
                          <option value='1'>Iya</option>
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Form ini harus dipilih.
                        </Form.Control.Feedback>
                      </div>

                      {riwayatKesehatan?.length > 0 && (
                        <div className='form-group'>
                          <label>Riwayat Kesehatan<span className="text-danger">*</span></label>


                          <Select
                            isMulti
                            name="health_problems"

                            options={riwayatKesehatan.map(item => ({ value: item.id, label: item.name }))}
                            onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'health_problems')}
                            className="basic-multi-select"
                            classNamePrefix="select"

                            // defaultValue={riwayatKesehatan
                            //   .filter(item => Array.isArray(healthProblems) && healthProblems.map(problem => problem.id).includes(item.id))
                            //   .map(item => ({ value: item.id, label: item.name }))
                            // }

                            // defaultValue={riwayatKesehatan
                            //   .filter(item => healthProblems.some(problem => problem.id === item.id))
                            //   .map(item => ({ value: item.id, label: item.name }))}

                            defaultValue={riwayatKesehatan
                              .filter(item => healthProblems.some(problem => problem === item.id || problem.id === item.id))
                              .map(item => ({ value: item.id, label: item.name }))}

                            // defaultValue={riwayatKesehatan
                            //   .filter(item => healthProblems.map(problem => problem.id).includes(item.id))
                            //   .map(item => ({ value: item.id, label: item.name }))
                            // }
                            isDisabled
                          />

                          {/* <input
                            type="checkbox"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChange}
                            checked={showOtherHealthProblems}
                            disabled
                          /> */}
                          <label class="ml-1" for="flexCheckDefault">
                            Riwayat Kesehatan Lainnya
                          </label>
                        </div>
                      )}

                      {showOtherHealthProblems && (
                        <div className='form-group'>
                          <label>Riwayat Kesehatan Lainnya<span className="text-danger">*</span></label>
                          <input
                            type='text'
                            className='form-control'
                            //value={editFormData?.health_problems_other}
                            value={editFormData?.health_problems_other || ""}
                            name="health_problems_other"
                            onChange={handleChangeEdit}
                            maxLength="100"
                            minLength="3"
                            required
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">
                            Riwayat kesehatan lainnya harus diisi minimal 3 karakter.
                          </Form.Control.Feedback>
                        </div>
                      )}
                    </div>
                  </div>

                  <hr />

                  {locations === "Detail" && (
                    <>
                      <h3 className="mb-4">Teman Belajar</h3>

                      {temanBelajar && temanBelajar.length > 0 ? (
                        <DataTable
                          columns={columns}
                          data={temanBelajar}
                          customStyles={customStyles}
                        />
                      ) : (
                        <p>Tidak ada data</p>
                      )}

                      <hr />

                      <h3 className="mb-4">Pemesanan Buku</h3>

                      {temanBelajar && temanBelajar.length > 0 ? (
                        <DataTable
                          columns={columnsPemesananBuku}
                          data={temanBelajar}
                          customStyles={customStyles}
                        />
                      ) : (
                        <p>Tidak ada data</p>
                      )}
                    </>
                  )}
                  {/* pengajar disini */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DetailPelajar;