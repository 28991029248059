import {
    GET_PENDAMPINGS_SUCCESS_ACTION,
    GET_PENDAMPINGS_FAILED_ACTION,
    LOADING_TOGGLE_ACTION,
} from '../actions/pendampingActions';

const initialState = {
    pendampings: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function PendampingReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PENDAMPINGS_SUCCESS_ACTION:
            return {
                ...state,
                pendampings: action.payload,
                errorMessage: '',
                successMessage: 'Data fetched successfully',
                showLoading: false,
            };
        case GET_PENDAMPINGS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LOADING_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: action.payload,
            };
        default:
            return state;
    }
}
