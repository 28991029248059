import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { GET } from "../../../services/AxiosService";

const FormDonasiAlokasiBukuPelajar = () => {
  const location = useLocation();
  const { id, flag } = useParams();
  // console.log("id", id);
  const navigate = useNavigate();
  const [locations, setLocations] = useState(location?.state?.from?.length > 0 ? location?.state?.from : "Detail");
  const [validated, setValidated] = useState(false);

  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    donation_code: "",
    user_name: "",
    student_type_name: "",
    status: "",
    type_donation: "",
    paid_at: "",
    paymentMethod: "",
    status_name: "",
  });

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const fetchData = async (id) => {
    try {
      const res = await GET(`donation/${id}`);
      console.log("res?.data", res?.data);
      setFormData({
        ...res?.data,
        paymentMethod: res?.data.paymentMethod?.name || "", 
        status_name : res?.data?.order?.status_name,// Ambil nama jika ada
      });
      setData(res?.data.detail_donation || []); // Update data state dengan detail_donation
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      donation_code: formData.donation_code,
      user_name: formData.user_name,
      student_type_name: formData.student_type_name,
      status: formData.status,
      amount: formData.amount,
      paid_at: formData.paid_at,
      paymentMethod: formData.paymentMethod,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // Implement the submit logic here
    // await POST('your-endpoint', requestBody);
    Swal.close(); // Close the loading state
  };

  const columns = [
    {
      name: 'Tipe Donasi',
      selector: row => row.donation_type,
    },
    {
      name: 'Detail Donasi',
      selector: row => row.book_name,
    },
    {
      name: 'Harga (Rp)',
      selector: row => row.harga,
    },
    {
      name: 'Qty',
      selector: row => row.book_qty,
    },
    {
      name: 'Total (Rp)',
      selector: row => row.amount,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
    table: {
      style: {
        marginBottom: "0",
      },
    },
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Daftar Donasi`}
        motherMenu="Daftar Donasi"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Donasi</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/daftar-alokasi-buku`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {flag === 'Detail' ? (
                  <form className="form-valide">
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className='form-group col-md-6 col-12'>
                        <label>ID Donatur</label>
                        <input
                          className='form-control'
                          type='text'
                          name="donation_code"
                          value={formData.donation_code}
                          required
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Nama Donatur</label>
                        <input
                          className='form-control'
                          type='text'
                          name="user_name"
                          value={formData.user_name}
                          required
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Tipe Donatur</label>
                        <input
                          className='form-control'
                          type="text"
                          name='student_type_name'
                          value={formData.student_type_name}
                          required
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Status Pesanan</label>
                        <input
                          className='form-control'
                          type='text'
                          name="status"
                          value= {formData.status_name === 0 ? "Belum Bayar" : "Dibayar"}
                          required
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Cara Pembayaran</label>
                        <input
                          className='form-control'
                          type='text'
                          name="paymentMethod"
                          value={formData.paymentMethod}
                          required
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Waktu Bayar</label>
                        <input
                          className='form-control'
                          type='text'
                          name="paid_at"
                          value={formData.paid_at? (()=>{
                            const tanggal = new Date(formData.paid_at);
                            const formatTanggal = tanggal?.toLocaleDateString('id-ID',{
                              day: '2-digit',
                              month: 'long',
                              year: 'numeric'
                            });
                            const formatWaktu = tanggal?.toLocaleTimeString('id-ID',{
                              hour: '2-digit',
                              minute: '2-digit'
                            });
                            return `${formatTanggal} ${formatWaktu}`;
                          })(): ""}
                          required
                          disabled
                        />
                      </div>

                      <h3 className="mb-4 mt-2">Detail Donasi</h3>

                      {data && data.length > 0 ? (
                        <DataTable
                          columns={columns}
                          data={data}
                          customStyles={customStyles}
                        />
                      ) : (
                        <p>Tidak ada data</p>
                      )}

                      <table className="table table-striped">
                        <tbody>
                          <tr style={{ background: "#FFFFFF", color: "black" }}>
                            <th className="col-8" scope="row">Total yang Didonasikan (Rp)</th>
                            <td className="col-2">{formData.amount}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
                ) : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormDonasiAlokasiBukuPelajar;
