import React, { Fragment, useState, useEffect, useCallback } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { GET, PATCH } from "../../../services/AxiosService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleCheck,faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const DaftarAlokasiBukuPelajar = () => {
  const location = useLocation();
  const { id, flag } = useParams();
  const navigate = useNavigate();
  const [locations, setLocations] = useState(location?.state?.from?.length > 0 ? location?.state?.from : "Alokasi");
  const [validated, setValidated] = useState(false);
  const [clickedRowId, setClickedRowId] = useState(null);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    donation_id: "",
    book_name: "",
    book_qty: "",
    status: "",
    type_donation: "",
    paid_at: "",
    paymentMethod: "",
    book_id:"",
    id:"",
    order_id:""
  });
  const [formDataDown, setFormDataDown] = useState({
    donation_id: "",
    book_name: "",
    book_qty: "",
    status: "",
    type_donation: "",
    paid_at: "",
    paymentMethod: "",
    book_id:"",
    id:"",
    order_id:""
  });

  const fetchData = async (id) => {
    try {
      const res = await GET(`/donation/allocation/buku-pelajar/${id}`);
      setFormData({
        ...res?.data,
        paymentMethod: res?.data.paymentMethod?.name || "", // Ambil nama jika ada
      });
      console.log('data id',res.data)
      setData(res?.data.recipients || []); // Update data state dengan detail_donation
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const fetchDataDown = async (bookId,idDetail) => {
      try {
        const res = await GET(`/donation/students/${bookId}/${idDetail}`);
        setFormDataDown(res?.data)
        console.log('resBawah', res);
      } catch (error) {
        console.log("Error fetching data", error);
      }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  useEffect(() => {
    if (formData.book_id,formData.id) {
      fetchDataDown(formData.book_id,formData.id);
    }
  }, [formData.book_id,formData.id]);

  console.log('Locations',locations)
  const isFormDataReady = formData.id && formData.book_id && formData.order_id;

  const handleLockClick = useCallback(
    async (studentId) => {
      if (!formData.book_id || !formData.id || !formData.order_id) {
        console.log("formData is not ready:", formData);
        return; // Exit if formData is incomplete
      }

      const reqBody = {
        studentId: studentId,
        donation_detail_id: formData.id,
        student_bookId: formData.book_id,
        orderId: formData.order_id,
      };

      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await PATCH(`donation/allocation/assign-unassign`, reqBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: res.message,
        }).then(() => {
          Swal.close();
          if (id) fetchData(id); // Refresh main data
          if (formData.book_id && formData.id) fetchDataDown(formData.book_id, formData.id); // Refresh dependent data
        });
      }
    },
    [formData, id, fetchData, fetchDataDown]
  );

  const columns = [
    {
      name: "Nama Pelajar",
      selector: (row) => row.name,
    },
    {
      name: "Waktu Pemesanan",
      selector: row => {
        const date = new Date(row.waktu_pemesanan);
        const formattedDate = date?.toLocaleDateString('id-ID', {
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        });
        return `${formattedDate}`;
      },
    },
    {
      name: "Status",
      selector: (row) => row.status_alokasi,
    },
    {
      name: "Aksi",
      // selector: (row) => row.status_alokasi,
      width: "150px",
      cell: (row) => (
        <div>
          {row.status_alokasi === 'Sudah Dialokasikan' ? (
            <Link
              className="btn btn-danger shadow btn-xs sharp"
              onClick={() => handleLockClick(row.student_id)}
              title="Belum Dialokasikan"
            >
              {/* <FontAwesomeIcon icon={faCheckCircle} /> */}
              <i className="fa fa-lock"></i>
            </Link>
          ) : (
            <Link
              className="btn btn-success shadow btn-xs sharp"
              onClick={() => handleLockClick(row.student_id)}
              title="Sudah Dialokasikan"
            >
             <FontAwesomeIcon icon={faCheckCircle} />
              {/* <i className="fa fa-unlock"></i> */}
            </Link>
          )}
          {/* <button
            className="btn btn-success shadow btn-xs sharp mx-1"
            title="Sudah dibaca"
            onClick={(e) => {
              e.preventDefault();
              console.log('id', row.student_id)
              setClickedRowId(prevId => (prevId === row.student_id ? null : row.student_id));
            }}
          >
            <FontAwesomeIcon icon={row.status === "Belum Dialokasikan" ?  faFileCircleCheck : faCheckCircle} />
          </button> */}
        </div>
      ),
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  return (
    <Fragment>
      {/* <PageTitle activeMenu={`${id ? (locations.charAt(0).toUpperCase() + locations.slice(1)) : 'Tambah'} Buku`} motherMenu="Daftar Alokasi Buku" /> */}
      <PageTitle 
      activeMenu={`${flag} Buku`} 
      motherMenu="Daftar Alokasi Buku" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Buku</h4>
              <Link className="btn btn-primary" to={`/daftar-alokasi-buku`}>
                Kembali
              </Link>
            </div>
            <div className="card-body">
              <div className="form-validation">
              {/* <form className="form-valide" onSubmit={handleSubmit}> */}
              {flag === 'Alokasi' ? (
                <form className="form-valide" >
                <div className="row">
                  <div className="d-flex flex-wrap">
                    <div className="form-group col-md-6 col-12">
                      <label>ID Pesanan</label>
                      <input
                        className="form-control"
                        type="text"
                        name="donation_code"
                        value={formData.donation_id}
                        required
                        disabled
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label>Nama Donatur</label>
                      <input
                        className="form-control"
                        type="text"
                        name="user_name"
                        value={formData.donation_name}
                        required
                        disabled
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label>Judul Buku</label>
                      <input
                        className="form-control"
                        type="text"
                        name="student_type_name"
                        value={formData.book_name}
                        required
                        disabled
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label>Qty</label>
                      <input
                        className="form-control"
                        type="text"
                        name="book_qty"
                        value={formData.book_qty}
                        required
                        disabled
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label>Status</label>
                      <input
                        className="form-control"
                        type="text"
                        name="paymentMethod"
                        value= {formData.status === 0 ? "Belum di Alokasikan" : "Sudah di Alokasikan"}
                        required
                        disabled
                      />
                    </div>
                      {formDataDown && formDataDown.length > 0 ? (
                        <DataTable
                          columns={columns}
                          data={formDataDown}
                          customStyles={customStyles}
                        />
                      ) : (
                        <p>Tidak ada data</p>
                      )}
                  </div>
                </div>
              </form>
              ) : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DaftarAlokasiBukuPelajar;
