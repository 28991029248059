import {
    getPendampings,
} from '../../services/pendampingService';

export const GET_PENDAMPINGS_SUCCESS_ACTION = '[get pendampings action] success';
export const GET_PENDAMPINGS_FAILED_ACTION = '[get pendampings action] failed';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';

export function fetchPendampings() {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        getPendampings()
            .then((response) => {
                dispatch(getPendampingsSuccessAction(response));
            })
            .catch((error) => {
                dispatch(getPendampingsFailedAction(error.message));
            })
            .finally(() => {
                dispatch(loadingToggleAction(false));
            });
    };
}

export function getPendampingsSuccessAction(data) {
    return {
        type: GET_PENDAMPINGS_SUCCESS_ACTION,
        payload: data,
    };
}

export function getPendampingsFailedAction(message) {
    return {
        type: GET_PENDAMPINGS_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
