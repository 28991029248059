import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Card, Badge } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { GET, PATCH } from "../../../services/AxiosService";
import Swal from 'sweetalert2';

const DaftarPendamping = () => {
  const [data, setData] = useState([]);
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const navigate = useNavigate();

  const fetchData = async (page, perPage, sortField, sortOrder, search) => {
    const params = {
      page,
      size: perPage,
      sort: sortField ? sortField : "full_name",
      orderby: sortOrder,
      verified: 1,
      search,
    };
    const res = await GET(`companion`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
    console.log(res?.data);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // fetchServerData(page, perPage, null, null, searchTerm);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);

    // setTimeout(() => {
    // fetchServerData(1, perPage, null, null, e.target.value);
    // }, 300);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder]);

  // edit
  const [editModalShow, setEditModalShow] = useState(false);
  const [editModalId, setEditModalId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    nama_pendamping: "",
    hp_no: "",
    email: "",
    pelajar_tunanetra_didampingi: "",
    skor_pendamping: "",
    jumlah_task_selesai: "",
  });
  const [validated, setValidated] = useState(false);
  // Pelajar Tuna Netra yang Didampingi
  const [detailModalShow, setDetailModalShow] = useState(false);
  const [dataDetail, setDataDetail] = useState([]);
  const handleDetailModalClose = () => {
    setDetailModalShow(false);
  };
  const handleDetailClick = async (row) => {
    // console.log("halo", row.nama_pendamping);
    setDataDetail({
      namaPendamping: row.nama_pendamping,
    });

    setDetailModalShow(true);
  };
  // Skor Pendamping
  const [detailModalShowSkorPendamping, setDetailModalShowSkorPendamping] = useState(false);
  const [dataDetailSkorPendamping, setDataDetailSkorPendamping] = useState([]);
  const handleDetailModalCloseSkorPendamping = () => {
    setDetailModalShowSkorPendamping(false);
  };
  const handleDetailClickSkorPendamping = async (row) => {
    // console.log("halo", row.nama_pendamping);
    setDataDetailSkorPendamping({
      namaPendamping: row.nama_pendamping,
    });

    setDetailModalShowSkorPendamping(true);
  };
  // Jumlah Task Telah Diselesaikan
  const [detailModalShowJumlahTaskSelesai, setDetailModalShowJumlahTaskSelesai] = useState(false);
  const [dataDetailJumlahTaskSelesai, setDataDetailJumlahTaskSelesai] = useState([]);
  const handleDetailModalCloseJumlahTaskSelesai = () => {
    setDetailModalShowJumlahTaskSelesai(false);
  };
  const handleDetailClickJumlahTaskSelesai = async (row) => {
    // console.log("halo", row.nama_pendamping);
    setDataDetailJumlahTaskSelesai({
      namaPendamping: row.nama_pendamping,
    });

    setDetailModalShowJumlahTaskSelesai(true);
  };
  // dropdown
  const [selectedStatus, setSelectedStatus] = useState('Semua Status');

  // const data = [
  //   {
  //     id: "PDP001",
  //     nama_pendamping: 'Bambang Sutrisno',
  //     hp_no: '8838344625',
  //     email: 'bambang@gmail.com',
  //     pelajar_tunanetra_didampingi: '5',
  //     skor_pendamping: '5.7',
  //     jumlah_task_selesai: '7',
  //     status: 0
  //   },
  //   {
  //     id: "PDP002",
  //     nama_pendamping: 'Sutrisno Alam',
  //     hp_no: '89767344625',
  //     email: 'sutrisno@gmail.com',
  //     pelajar_tunanetra_didampingi: '3',
  //     skor_pendamping: '5',
  //     jumlah_task_selesai: '2',
  //     status: 1
  //   },
  // ];
  const columns = [
    {
      name: 'ID Pendamping',
      selector: row => row.code,
      width: "150px",
    },
    {
      name: 'Nama Pendamping',
      selector: (row) => row.user.name,
      width: "180px",
      // cell: (row) => (
      //   <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user?.name}</span>
      // ),
    },
    {
      name: 'No Telepon',
      selector: (row) => row.user.hp_no,
      width: "130px",
      cell: (row) => (
        <div>{row.user?.hp_no}</div>
      )
    },
    {
      name: 'Email',
      selector: row => row.user.email,
      width: "160px",
      cell: (row) => (
        <div>{row.user?.email}</div>
      )
    },
    {
      name: 'Pelajar Tuna Netra yang Didampingi',
      selector: row => row.studentCount,
      cell: (row) => (
        <Link className="p-1 text-info" title="Detail" onClick={() => handleDetailClick(row)}>
          <span>{row.studentCount}</span>
        </Link>
      )
    },
    {
      name: 'Skor Pendamping',
      selector: row => row.skor_pendamping,
      cell: (row) => (
        <Link className="p-1 text-info" title="Detail" onClick={() => handleDetailClickSkorPendamping(row)}>
          {row.skor_pendamping}
        </Link>
      )
    },
    {
      name: 'Jumlah Task yang Diselesaikan',
      selector: row => row.jumlah_task_selesai,
      cell: (row) => (
        <Link className="p-1 text-info" title="Detail" onClick={() => handleDetailClickJumlahTaskSelesai(row)}>
          {row.jumlah_task_selesai}
        </Link>
      )
    },
    {
      name: 'Status',
      selector: row => row.status,
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Aktif
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Tidak Aktif
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      }
    },
    {
      name: 'Aksi',
      // selector: row => row.aksi,
      width: "130px",
      cell: (row) => (
        <div>
          {row.status === 1 ? (
            <Link
              className="btn btn-danger shadow btn-xs sharp"
              onClick={() => handleLockClick(row.id)}
              title="Non-Aktif"
            >
              {/* <FontAwesomeIcon icon={faLock} /> */}
              <i className="fa fa-lock"></i>
            </Link>
          ) : (
            <Link
              className="btn btn-success shadow btn-xs sharp"
              onClick={() => handleLockOpenClick(row.id)}
              title="Aktif"
            >
              {/* <FontAwesomeIcon icon={faLockOpen} /> */}
              <i className="fa fa-unlock"></i>
            </Link>
          )}

          <button
            className="btn btn-info shadow btn-xs sharp mx-1"
            title="Detail"
            // onClick={() => handleDetailClick(row)}
            to={`/${row.id}/detail-pendamping`}
            onClick={() => navigate(`/${row.id}/detail-pendamping`, { state: { from: 'Daftar Pendamping' } })}
          >
            {/* <FontAwesomeIcon icon={faEye} /> */}
            <i className="fa fa-eye"></i>
          </button>

          <Link
            className="btn btn-primary shadow btn-xs sharp"
            // onClick={() => handleEditClick(row)}
            to={`/${row.id}/edit-pendamping`}
            title="Edit"
          >
            <i className="fa fa-pencil"></i>
          </Link>
        </div>
      ),
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
    table: {
      style: {
        // minHeight: dropdownOpenId ? "300px" : "unset",
        marginBottom: "0",
      },
    },
  };

  const dataPelajarTunanetraDidampingi = [
    {
      id_pelajar: "PLJ014",
      nama_pelajar: 'Sari W',
      hp_no: '82788464299',
      level_belajar: 'Ula',
    },
    {
      id_pelajar: "PLJ015",
      nama_pelajar: 'Ira A',
      hp_no: '89837438264',
      level_belajar: 'Wustho',
    },
  ];
  const columnsPelajarTunanetraDidampingi = [
    {
      name: 'ID Pelajar',
      selector: row => row.id_pelajar,
    },
    {
      name: 'Nama Pelajar',
      selector: row => row.nama_pelajar,
    },
    {
      name: 'No Telepon',
      selector: row => row.hp_no,
    },
    {
      name: 'Level Belajar',
      selector: row => row.level_belajar,
    },
  ];

  const dataSkorPendamping = [
    {
      jenis_skor: 'Menyelesaikan Task',
      skor: '7',
    },
    {
      jenis_skor: 'Ketepatan Waktu',
      skor: '5',
    },
    {
      jenis_skor: 'Kelengkapan',
      skor: '5',
    },
  ];
  const columnsSkorPendamping = [
    {
      name: 'Jenis Skor',
      selector: row => row.jenis_skor,
    },
    {
      name: 'Skor',
      selector: row => row.skor,
    },
  ];

  const dataJumlahTaskSelesai = [
    {
      jenis_task: 'Operasional Belajar',
      jumlah: '3',
    },
    {
      jenis_task: 'Antar jemput',
      jumlah: '2',
    },
    {
      jenis_task: 'Kegiatan outdoor',
      jumlah: '2',
    },
  ];
  const columnsJumlahTaskSelesai = [
    {
      name: 'Jenis Task',
      selector: row => row.jenis_task,
    },
    {
      name: 'Skor',
      selector: row => row.jumlah,
    },
  ];

  const handleEditClick = (row) => {
    setEditFormData({
      nama_pendamping: row.nama_pendamping,
      hp_no: row.hp_no,
      email: row.email,
      pelajar_tunanetra_didampingi: row.pelajar_tunanetra_didampingi,
      skor_pendamping: row.skor_pendamping,
      jumlah_task_selesai: row.jumlah_task_selesai,
    });
    setEditModalShow(true);
    setEditModalId(row.id);
  };
  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleEditModalClose = () => {
    setEditModalShow(false);
  };

  // dropdown
  const handleSelect = (e) => {
    // setSelectedStatus(e.target.textContent);
    setSelectedStatus(e.target.getAttribute('value'));
  };
  const statusMap = {
    0: 'Tidak Aktif',
    1: 'Aktif',
    "Semua Status": 'Semua Status',
  };

  // aktif/inaktif
  const handleLockClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`companion/${id}`, { status: 0 });
    if (res) {
      Swal.fire({
        icon: "success",
        title: `Pendamping Non-Aktif!`,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };
  const handleLockOpenClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`companion/${id}`, { status: 1 });
    if (res) {
      Swal.fire({
        icon: "success",
        title: `Pendamping Aktif!`,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Pendamping</Card.Title>
            </Card.Header>
            <div className="d-flex mt-4 px-5">
              <Dropdown className="mr-auto">
                <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                  {statusMap[selectedStatus]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleSelect} value="1">Aktif</Dropdown.Item>
                  <Dropdown.Item onClick={handleSelect} value="0">Tidak Aktif</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <div className="">
                <div className="input-group search-area d-xl-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari Pendamping"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {/* <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
              /> */}
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  defaultSortFieldId={3}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        show={detailModalShow}
        onHide={handleDetailModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Pelajar Tuna Netra yang Didampingi {dataDetail.namaPendamping}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <DataTable
              columns={columnsPelajarTunanetraDidampingi}
              data={dataPelajarTunanetraDidampingi}
              customStyles={customStyles}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={detailModalShowSkorPendamping}
        onHide={handleDetailModalCloseSkorPendamping}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Skor Pendamping {dataDetailSkorPendamping.namaPendamping}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={columnsSkorPendamping}
            data={dataSkorPendamping}
            customStyles={customStyles}
          />
          <div className="d-flex mt-1">
            <h6 className="col-md-6 font-weight-bold">
              Skor Rata-rata:
            </h6>
            <h6 className="col-md-6 font-weight-bold">
              5.7
            </h6>
          </div>

        </Modal.Body>
      </Modal>

      <Modal
        show={detailModalShowJumlahTaskSelesai}
        onHide={handleDetailModalCloseJumlahTaskSelesai}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Jumlah Task Telah Diselesaikan {dataDetailJumlahTaskSelesai.namaPendamping}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={columnsJumlahTaskSelesai}
            data={dataJumlahTaskSelesai}
            customStyles={customStyles}
          />
          <div className="d-flex mt-1">
            <h6 className="col-md-6 font-weight-bold">
              Total Task:
            </h6>
            <h6 className="col-md-6 font-weight-bold">
              7
            </h6>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={editModalShow}
        onHide={handleEditModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Ubah Pendamping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            // onSubmit={handleEditSubmit} 
            noValidate validated={validated}
          >
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Nama Pendamping</Form.Label>
              <Form.Control
                type="text"
                name="nama_pendamping"
                placeholder="Masukkan nama pendamping"
                value={editFormData.nama_pendamping}
                onChange={handleChangeEdit}
                maxLength="100"
                pattern=".{5,}"
                required
              />
              <Form.Control.Feedback type="invalid">
                Nama Pendaming harus diisi minimal 5 karakter.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicType">
              <Form.Label>No Telepon</Form.Label>
              <Form.Control
                type="text"
                name="hp_no"
                placeholder="Masukkan no telepon"
                value={editFormData.hp_no}
                // onChange={handleChangeEdit}
                onChange={(e) => {
                  const { value } = e.target;
                  // Hanya karakter angka
                  const numericValue = value.replace(/\D/g, "");
                  setEditFormData((prevState) => ({
                    ...prevState,
                    hp_no: numericValue,
                  }));
                  // Validasi form
                  const form = e.currentTarget.form;
                  const isValid = form.checkValidity();
                  setValidated(isValid);
                  if (isValid) {
                    form.classList.remove("was-validated");
                  } else {
                    form.classList.add("was-validated");
                  }
                }}
                maxLength="12"
                pattern="[0-9]{8,}"
                required
              />
              <Form.Control.Feedback type="invalid">
                No Telepon harus diisi dengan angka minimal 8 digit.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="nama_pendamping"
                placeholder="Masukkan email"
                value={editFormData.email}
                // onChange={handleChangeEdit}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                Email harus diisi minimal 5 karakter.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Pelajar Tunanetra yang Didampingi</Form.Label>
              <Form.Control
                type="number"
                name="pelajar_tunanetra_didampingi"
                placeholder="Masukkan pelajar tunanetra yang didampingi"
                value={editFormData.pelajar_tunanetra_didampingi}
                onChange={handleChangeEdit}
                maxLength="100"
                pattern=".{5,}"
                required
              />
              <Form.Control.Feedback type="invalid">
                Pelajar Tunanetra yang Didampingi harus diisi menggunakan format angka.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Skor Pendamping</Form.Label>
              <Form.Control
                type="number"
                name="skor_pendamping"
                placeholder="Masukkan skor pendamping"
                value={editFormData.skor_pendamping}
                onChange={handleChangeEdit}
                maxLength="100"
                pattern=".{5,}"
                required
              />
              <Form.Control.Feedback type="invalid">
                Skor Pendamping harus diisi menggunakan format angka.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Jumlah Task yang Diselesaikan</Form.Label>
              <Form.Control
                type="number"
                name="jumlah_task_selesai"
                placeholder="Masukkan jumlah task yang diselesaikan"
                value={editFormData.jumlah_task_selesai}
                onChange={handleChangeEdit}
                maxLength="100"
                pattern=".{5,}"
                required
              />
              <Form.Control.Feedback type="invalid">
                Jumlah Task yang Diselesaikan harus diisi menggunakan format angka.
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={!validated}
            >
              Simpan
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default DaftarPendamping