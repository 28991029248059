import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";

const FormPeriode = () => {
  const { id, flag } = useParams();
  const location = useLocation();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const userId = localStorage.getItem("accessId");
  const [month, setMonth] = useState([]);
  const formRef = useRef(null);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    start_date: "",
    end_date: "",
  });
  const [selectedMonths, setSelectedMonths] = useState([]);

  const fetchData = async () => {
    const res = await GET(`master-data/period/${id}`);
    setFormData({
      name: res.data?.name,
      start_date: res.data?.start_date,
      end_date: res.data?.end_date,
    });
    setSelectedMonths(res.data?.month);
  };

  const fetchDataMonth = async () => {
    const res = await GET(`master-data/type?type=Bulan`);
    setMonth(res?.data);
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
    fetchDataMonth();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleMonthChange = (id) => {
    setSelectedMonths((prevSelected) => (prevSelected.includes(id) ? prevSelected.filter((monthId) => monthId !== id) : [...prevSelected, id]));
  };

  const handleStartDateChange = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    setFormData({
      ...formData,
      start_date: formattedDate,
    });

    // setTouched(true);

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleEndDateChange = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setFormData({
      ...formData,
      end_date: formattedDate,
    });

    // setTouched(true);

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      user_id: userId,
      name: formData.name,
      month: selectedMonths,
      start_date: formData.start_date,
      end_date: formData.end_date,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await POST(`/master-data/period`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil membuat periode!",
      }).then(() => {
        Swal.close();
        setFormData({
          name: "",
          start_date: "",
          end_date: "",
        });
        router("/master-data-periode");
      });
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      user_id: userId,
      name: formData.name,
      month: selectedMonths,
      start_date: formData.start_date,
      end_date: formData.end_date,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/master-data/period/${id}`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil mengubah periode!",
      }).then(() => {
        Swal.close();
        setFormData({
          name: "",
          start_date: "",
          end_date: "",
        });
        router("/master-data-periode");
      });
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu={`${flag} Master Data Periode`} motherMenu="Master Data Periode" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Periode</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/master-data-periode`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form className="form-valide" onSubmit={handleSubmit} ref={formRef}>
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className="form-group col-md-6 col-12">
                        <label>
                          Nama<span className="text-danger">*</span>
                        </label>
                        <input name="name" className="form-control" type="text" onChange={handleChange} value={formData.name} disabled={flag == "Detail"} />
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <label>
                          Bulan<span className="text-danger">*</span>
                        </label>
                        <div className="d-flex flex-wrap">
                          {month.map((item) => (
                            <div className="col-md-4 col-6" key={item.id}>
                              <Form.Check type="checkbox" id={item.id} label={item.description} onChange={() => handleMonthChange(item.id)} checked={selectedMonths.includes(item.id)} disabled={flag == "Detail"} />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <div className="row">
                          <label>
                            Tanggal Mulai<span className="text-danger">*</span>
                          </label>
                          <DatePicker
                            name="start_date"
                            selected={formData.start_date ? new Date(formData.start_date) : null}
                            onChange={handleStartDateChange}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            disabled={flag == "Detail"}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <div className="row">
                          <label>
                            Tanggal Akhir<span className="text-danger">*</span>
                          </label>
                          <DatePicker name="end_date" selected={formData.end_date ? new Date(formData.end_date) : null} onChange={handleEndDateChange} dateFormat="yyyy-MM-dd" className="form-control" disabled={flag == "Detail"} required />
                        </div>
                      </div>
                    </div>
                  </div>

                  {(flag === "Tambah" || flag === "Ubah") && (
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/master-data-periode`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={flag === "Tambah" ? handleSubmit : handleEditSubmit}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormPeriode;
