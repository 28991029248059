import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Table, Button, Badge } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { GET, PATCH } from "../../../services/AxiosService";
import Swal from 'sweetalert2';

const VerifikasiPendamping = () => {
  const [data, setData] = useState([]);
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");

  const [editModalShow, setEditModalShow] = useState(false);
  const [editModalId, setEditModalId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    nik: "",
    nama_pendamping: "",
    hp_no: "",
  });
  const [validated, setValidated] = useState(false);
  // const [selectedStatus, setSelectedStatus] = useState('Semua Status');
  const navigate = useNavigate();

  const columns = [
    {
      name: 'ID Calon Pendamping',
      selector: row => row.reg_code,
      // width: "150px",
    },
    {
      name: 'Nama Pendamping',
      selector: (row) => row.user.name,
      // width: "180px",
      // cell: (row) => (
      //   <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user?.name}</span>
      // ),
    },
    {
      name: 'No Telepon',
      selector: (row) => row.user.hp_no,
      // width: "130px",
      cell: (row) => (
        <div>{row.user?.hp_no}</div>
      )
    },
    {
      name: 'Email',
      selector: row => row.user.email,
      // width: "160px",
      cell: (row) => (
        <div>{row.user?.email}</div>
      )
    },
    // {
    //   name: 'Status Verifikasi',
    //   selector: row => row.status,
    //   cell: (row) => {
    //     let displayText;
    //     if (row.status === 0) {
    //       displayText = (
    //         <Badge as="a" variant="outline-light badge-rounded">
    //           Ditunda
    //         </Badge>
    //       );
    //     } else if (row.status === 1) {
    //       displayText = (
    //         <Badge as="a" variant="outline-info badge-rounded">
    //           Menunggu
    //         </Badge>
    //       );
    //     } else if (row.status === 2) {
    //       displayText = (
    //         <Badge as="a" variant="outline-primary badge-rounded">
    //           Ditolak
    //         </Badge>
    //       );
    //     } else {
    //       displayText = row.status;
    //     }
    //     return displayText;
    //   }
    // },
    {
      name: 'Aksi',
      // selector: row => row.aksi,
      width: "130px",
      cell: (row) => (
        <div>
          <button className="btn btn-info shadow btn-xs sharp" title="Detail" onClick={() => navigate(`/${row.id}/detail-pendamping`, { state: { from: 'Verifikasi Pendamping' } })}>
            <i className="fa fa-eye"></i>
          </button>

          <Link
            className="btn btn-primary shadow btn-xs sharp mx-1"
            to={`/${row.id}/edit-pendamping`}
            title="Edit"
          >
            <i className="fa fa-pencil"></i>
          </Link>

          <Link className="btn btn-success shadow btn-xs sharp" title="verifikasi" onClick={() => handleVerifikasi(row)}>
            <i className="fa fa-check"></i>
          </Link>
        </div>
      ),
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
    // table: {
    //   style: {
    //     minHeight: dropdownOpenId ? "300px" : "unset",
    //   },
    // },
  };
  // const data = [
  //   {
  //     nik: "352514010120010002",
  //     nama_pendamping: 'Bambang Ari',
  //     hp_no: '08838344625',
  //     email: 'ari@gmail.com',
  //     status: 0
  //   },
  //   {
  //     nik: "352514010120010003",
  //     nama_pendamping: 'Budi Sutrisno',
  //     hp_no: '08322873000',
  //     email: 'budi@gmail.com',
  //     status: 2
  //   },
  //   {
  //     nik: "352514010120010004",
  //     nama_pendamping: 'Andre Budiman',
  //     hp_no: '081239873000',
  //     email: 'andre@gmail.com',
  //     status: 1
  //   },
  // ]
  const fetchData = async (page, perPage, sortField, sortOrder, search) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      verified: 0,
      search,
    };
    const res = await GET(`companion`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };
  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // fetchServerData(page, perPage, null, null, searchTerm);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);

    // setTimeout(() => {
    // fetchServerData(1, perPage, null, null, e.target.value);
    // }, 300);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder]);

  const handleEditClick = (row) => {
    setEditFormData({
      nik: row.nik,
      nama_pendamping: row.nama_pendamping,
      hp_no: row.hp_no,
    });
    setEditModalShow(true);
    setEditModalId(row.id);
  };
  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleEditModalClose = () => {
    setEditModalShow(false);
  };

  const handleVerifikasi = async (row) => {
    const result = await Swal.fire({
      title: `Apakah Anda yakin bahwa calon pendamping ${row.user.name} terverifikasi?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await PATCH(`companion/${row.id}`, { verified: 1 });
      if (res) {
        Swal.fire({
          icon: "success",
          title: `Calon pendamping ${row.user.name} telah terverifikasi.`,
        }).then(() => {
          Swal.close();
          fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
        });
      }
    }
  };

  // const handleSelect = (e) => {
  //   setSelectedStatus(e.target.getAttribute('value'));
  // };
  // const statusMap = {
  //   1: 'Menunggu',
  //   2: 'Ditunda',
  //   3: 'Ditolak',
  //   "Semua Status": 'Semua Status',
  // };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Verifikasi Pendamping</Card.Title>
            </Card.Header>
            <div className="d-flex flex-row-reverse mt-4 px-5">
              {/* <Dropdown className="mr-auto">
                <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                  {statusMap[selectedStatus]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleSelect} value="1">Menunggu</Dropdown.Item>
                  <Dropdown.Item onClick={handleSelect} value="2">Ditunda</Dropdown.Item>
                  <Dropdown.Item onClick={handleSelect} value="3">Ditolak</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}


              <div className="input-group search-area d-xl-inline-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Cari Pendamping"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <Link to="#">
                      <i className="flaticon-381-search-2" />
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  defaultSortFieldId={3}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        show={editModalShow}
        onHide={handleEditModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Ubah Pendamping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            // onSubmit={handleEditSubmit} 
            noValidate validated={validated}
          >
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>NIK</Form.Label>
              <Form.Control
                type="number"
                name="nik"
                placeholder="Masukkan NIK"
                value={editFormData.nik}
                onChange={handleChangeEdit}
                maxLength="16"
                pattern=".{16,}"
                required
              />
              <Form.Control.Feedback type="invalid">
                NIK harus diisi menggunakan format angka minimal 16 digit.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Nama Pendamping</Form.Label>
              <Form.Control
                type="text"
                name="nama_pendamping"
                placeholder="Masukkan nama pendamping"
                value={editFormData.nama_pendamping}
                onChange={handleChangeEdit}
                maxLength="100"
                pattern=".{5,}"
                required
              />
              <Form.Control.Feedback type="invalid">
                Nama Pendaming harus diisi minimal 5 karakter.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicType">
              <Form.Label>No Telepon</Form.Label>
              <Form.Control
                type="text"
                name="hp_no"
                placeholder="Masukkan no telepon"
                value={editFormData.hp_no}
                // onChange={handleChangeEdit}
                onChange={(e) => {
                  const { value } = e.target;
                  // Hanya karakter angka
                  const numericValue = value.replace(/\D/g, "");
                  setEditFormData((prevState) => ({
                    ...prevState,
                    hp_no: numericValue,
                  }));
                  // Validasi form
                  const form = e.currentTarget.form;
                  const isValid = form.checkValidity();
                  setValidated(isValid);
                  if (isValid) {
                    form.classList.remove("was-validated");
                  } else {
                    form.classList.add("was-validated");
                  }
                }}
                maxLength="12"
                pattern="[0-9]{8,}"
                required
              />
              <Form.Control.Feedback type="invalid">
                No Telepon harus diisi dengan angka minimal 8 digit.
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={!validated}
            >
              Simpan
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default VerifikasiPendamping