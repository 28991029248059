import React, { Fragment, useEffect, useState } from 'react'
import PageTitle from '../../layouts/PageTitle'
import { Link, useParams } from 'react-router-dom'
import profile from "../../../images/profile.jpeg";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import { GET, PATCH } from "../../../services/AxiosService";
import Swal from 'sweetalert2';

const EditPendamping = () => {
  const { id } = useParams();
  const [validated, setValidated] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: "",
    gender: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    urbanVillageId: "",
    nik: "",
    // birth_date: "",
    hp_no: "",
    email: "",
    rt: "",
    rw: "",
    postal_code: "",
    address: "",
  });
  const [gender, setGender] = useState([]);
  const [provinsi, setProvinsi] = useState([]);
  const [kabupaten, setKabupaten] = useState([]);
  const [kecamatan, setKecamatan] = useState([]);
  const [desa, setDesa] = useState([]);

  useEffect(() => {
    fetchData();
    fetchDataGender();
  }, []);
  const fetchData = async () => {
    const res = await GET(`companion/${id}`);
    setEditFormData(res?.data);
    setEditFormData({
      name: res?.data.user?.name,
      gender: res?.data.gender,
      provinceId: res?.data.urbanVillage?.subDistrict?.district?.province?.id,
      districtId: res?.data.urbanVillage?.subDistrict?.district?.id,
      subDistrictId: res?.data.urbanVillage?.subDistrict?.id,
      urbanVillageId: res?.data.urbanVillage?.id,
      nik: res?.data.nik,
      birth_date: res?.data.birth_date,
      hp_no: res?.data.user?.hp_no,
      email: res?.data.user?.email,
      rt: res?.data.rt,
      rw: res?.data.rw,
      postal_code: res?.data.postal_code,
      address: res?.data.address,
    });
    const idProvinsi = res?.data.urbanVillage?.subDistrict?.district?.province?.id;
    const idDistrict = res?.data.urbanVillage?.subDistrict?.district?.id;
    const idSubDistrict = res?.data.urbanVillage?.subDistrict?.id;
    fetchDataProvinsi(idProvinsi, idDistrict, idSubDistrict)
  };

  const fetchDataGender = async () => {
    const res = await GET(`master-data/type?type=gender`);
    setGender(res?.data);
  };

  const fetchDataProvinsi = async (idProvinsi, idDistrict, idSubDistrict) => {
    const res = await GET(`province`);
    setProvinsi(res?.data);
    if (idProvinsi) {
      const selectedProvince = res.data.find(prov => prov.id === idProvinsi);
      if (selectedProvince) {
        setKabupaten(selectedProvince.districts);

        if (idDistrict) {
          const selectedDistrict = selectedProvince.districts.find(dis => dis.id === idDistrict);
          setKecamatan(selectedDistrict.subDistricts)

          if (idSubDistrict) {
            const selectedSubDistrict = selectedDistrict.subDistricts.find(des => des.id === idSubDistrict);
            setDesa(selectedSubDistrict.urbanVillages);
          }
        }
      }
    }
  };

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'provinceId') {
      const selectedProvince = provinsi.find(prov => prov.id === value);
      if (selectedProvince) {
        setKabupaten(selectedProvince.districts);
        editFormData.districtId = "";
        editFormData.subDistrictId = "";
        editFormData.urbanVillageId = "";
      } else {
        setKabupaten([]);
      }
    }

    if (name === 'districtId' || name === 'provinceId') {
      const selectedDistrict = kabupaten.find(prov => prov.id === value);
      if (selectedDistrict) {
        setKecamatan(selectedDistrict.subDistricts);
        editFormData.subDistrictId = "";
        editFormData.urbanVillageId = "";
      } else {
        setKecamatan([]);
      }
    }

    if (name === 'subDistrictId' || name === 'provinceId' || name === 'districtId') {
      const selectedSubDistrict = kecamatan.find(prov => prov.id === value);
      if (selectedSubDistrict) {
        setDesa(selectedSubDistrict.urbanVillages);
        editFormData.urbanVillageId = ""
      } else {
        setDesa([]);
      }
    }

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleDateChange = (date) => {
    const isoDateString = date.toISOString();
    const formattedDate = isoDateString.split('T')[0];
    setEditFormData({
      ...editFormData,
      birth_date: formattedDate
    });
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const {
      name,
      gender,
      urbanVillageId,
      nik,
      birth_date,
      hp_no,
      email,
      rt,
      rw,
      postal_code,
      address,
    } = editFormData;

    const requestBody = {
      gender,
      birth_date,
      rt,
      rw,
      postal_code,
      address,
      nik,
      urban_village_id: urbanVillageId,
      user: {
        name,
        hp_no,
        email,
      },
    };

    const result = await Swal.fire({
      title: 'Apakah Anda yakin akan menyimpan perubahan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await PATCH(`companion/${id}`, requestBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Data Pendamping berhasil diubah!",
        }).then(() => {
          Swal.close();
          fetchData()
        });
      }
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Edit Pendamping" motherMenu="Daftar Pendamping" />
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className="card-header row">
              <h4 className="card-title mb-2">Ubah Data Pendamping</h4>
              <span>Form untuk mengubah data pendamping</span>
            </div>
            <div className='card-body'>
              <h3>Informasi Dasar</h3>
              <div className="profile-photo text-center">
                <img src={profile} width={130} className="img-fluid rounded-circle" alt="profile" />
              </div>
              <div className='form-validation mt-3'>
                <form className='form-valide' onSubmit={handleEditSubmit}>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Nama Lengkap<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.name}
                          name="name"
                          onChange={handleChangeEdit}
                          maxLength="100"
                          minLength="3"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama Lengkap harus diisi minimal 3 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <p className="mb-1">Tanggal Lahir<span className="text-danger">*</span></p>
                        <DatePicker
                          name="birth_date"
                          selected={editFormData.birth_date ? new Date(editFormData.birth_date) : null}
                          onChange={handleDateChange}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                          required
                        />
                        <br />
                        {editFormData.birth_date === undefined && (
                          <small className="text-danger">Tanggal Lahir harus diisi.</small>
                        )}
                      </div>
                      <div className='form-group'>
                        <label>Nomor HP<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.hp_no}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            // setEditFormData((prevState) => ({
                            //   ...prevState,
                            //   hp_no: numericValue,
                            // }));
                          }}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Jenis Kelamin<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.gender}
                          name="gender"
                          onChange={handleChangeEdit}
                          required
                        >
                          {Array.isArray(gender) && gender.map((genderUser) => (
                            <option
                              key={genderUser.id}
                              value={genderUser.id}
                            >
                              {genderUser.name}
                            </option>
                          ))}
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Jenis Kelamin harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>NIK<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.nik}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setEditFormData((prevState) => ({
                              ...prevState,
                              nik: numericValue,
                            }));
                            const form = e.currentTarget.form;
                            const isValid = form.checkValidity();
                            setValidated(isValid);
                            if (isValid) {
                              form.classList.remove("was-validated");
                            } else {
                              form.classList.add("was-validated");
                            }
                          }}
                          minLength="9"
                          maxLength="16"
                          required
                        />
                      </div>
                      <div className='form-group'>
                        <label>Email</label>
                        <input
                          type='email'
                          className='form-control'
                          value={editFormData.email}
                          name="email"
                          onChange={handleChangeEdit}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className='form-group'>
                    <label>Alamat<span className="text-danger">*</span></label>
                    <textarea
                      className='form-control'
                      rows="5"
                      value={editFormData.address}
                      name="address"
                      onChange={handleChangeEdit}
                      maxLength="200"
                      minLength="5"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Alamat harus diisi minimal 5 karakter.
                    </Form.Control.Feedback>
                  </div>

                  <div className="row">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Provinsi<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.provinceId}
                          name="provinceId"
                          onChange={handleChangeEdit}
                          required
                        >
                          {Array.isArray(provinsi) && [
                            editFormData.provinceId === '' || editFormData.provinceId === undefined && (
                              <option key="" value="">Pilih Provinsi</option>
                            ),
                            ...provinsi.map((dataProvinsi) => (
                              <option
                                key={dataProvinsi.id}
                                value={dataProvinsi.id}
                              >
                                {dataProvinsi.name}
                              </option>
                            ))
                          ]}
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Provinsi harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>Kecamatan<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.subDistrictId}
                          name="subDistrictId"
                          onChange={handleChangeEdit}
                          required
                        >
                          {Array.isArray(kecamatan) && [
                            editFormData.subDistrictId === '' && (
                              <option key="" value="">Pilih Kecamatan</option>
                            ),
                            ...kecamatan.map((dataKecamatan) => (
                              <option
                                key={dataKecamatan.id}
                                value={dataKecamatan.id}
                              >
                                {dataKecamatan.name}
                              </option>
                            ))
                          ]}
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Kecamatan harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>RT<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.rt}
                          name="rt"
                          onChange={handleChangeEdit}
                          maxLength="4"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          RT harus diisi.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>Kode Pos<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.postal_code}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setEditFormData((prevState) => ({
                              ...prevState,
                              postal_code: numericValue,
                            }));
                          }}
                          maxLength="5"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Kode Pos harus diisi.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Kabupaten Kota<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.districtId}
                          name="districtId"
                          onChange={handleChangeEdit}
                          required
                        >
                          {Array.isArray(kabupaten) && [
                            editFormData.districtId === '' && (
                              <option key="" value="">Pilih Kabupaten</option>
                            ),
                            ...kabupaten.map((dataKabupaten) => (
                              <option
                                key={dataKabupaten.id}
                                value={dataKabupaten.id}
                              >
                                {dataKabupaten.name}
                              </option>
                            ))
                          ]}
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Kabupaten Kota harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>Desa/Kelurahan<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={editFormData.urbanVillageId}
                          name="urbanVillageId"
                          onChange={handleChangeEdit}
                          required
                        >
                          {Array.isArray(desa) && [
                            editFormData.urbanVillageId === '' && (
                              <option key="" value="">Pilih Desa/Kelurahan</option>
                            ),
                            ...desa.map((dataDesa) => (
                              <option
                                key={dataDesa.id}
                                value={dataDesa.id}
                              >
                                {dataDesa.name}
                              </option>
                            ))
                          ]}
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Desa/Kelurahan harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group'>
                        <label>RW<span className="text-danger">*</span></label>
                        <input
                          type='text'
                          className='form-control'
                          value={editFormData.rw}
                          name="rw"
                          onChange={handleChangeEdit}
                          maxLength="4"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          RW harus diisi.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 d-flex">
                    <div className="col-xl-6">
                      <Link className="btn btn-light w-100" to={`/daftar-pendamping`}>
                        Batal
                      </Link>
                    </div>
                    <div className="col-xl-6">
                      <button type="submit" className="btn btn-primary w-100" disabled={!validated}>
                        Simpan Perubahan
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default EditPendamping