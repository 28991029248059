import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET } from '../../../services/AxiosService';
import moment from 'moment';
import 'moment/locale/id';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";

const JadwalAssessment = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [kota, setKota] = useState([]);
  const [selectedKota, setSelectedKota] = useState('');
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [internalDateTo, setInternalDateTo] = useState(null);

  const fetchData = async (page, perPage, sortField, sortOrder, search, kotaKabupaten, dateFrom) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      city: kotaKabupaten,
      start_date: dateFrom,
      end_date: internalDateTo
    };
    const res = await GET(`/schedule-assessment`, params);    console.log('resd',res)
    setData(res?.data);
    setTotalRows(res?.amount);
  };
  const fetchKotaKabupaten = async () => {
    const res = await GET(`master-data/type?type=Kota Kabupaten`);
    setKota(res?.data);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedKota, dateFrom, dateTo);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedKota, dateFrom, dateTo]);
  useEffect(() => {
    fetchKotaKabupaten();
  }, []);

  const columns = [
    {
      name: 'Kota/Kabupaten',
      selector: row => row.nama_kota_kabupaten,
    },
    {
      name: 'Lokasi',
      sortable: true,
      field: "location",
      selector: row => row.name_location,
    },
    {
      name: 'Hari, Tanggal',
      selector: row => row.days,
      sortable: true,
      field: "date",
      cell: (row) => (
        <div>{row.days}, {moment(row.date).locale('id').format('D MMMM YYYY')}</div>
      )
    },
    {
      name: 'Waktu (WIB)',
      selector: row => row.time_start,
      cell: (row) => (
        <div>{row.time_start} - {row.time_finish}</div>
      )
    },
    {
      name: 'Nama Asesor',
      selector: row => row.assessor,
      cell: (row) => (
        <div>

          {row.assessor?.map((assessors, index) => (
            <span key={assessors.id}>
              {assessors.name}{index < row.assessor.length - 1 ? ', ' : ''}
            </span>
          ))}
        </div>
      )
    },
    {
      name: 'Nama Pelajar',
      selector: row => row.students,
      cell: (row) => (
        <div>
          {row.students?.map((student, index) => (
            <button onClick={() => navigate(`/${row.students[0]?.user_id}/form-pelajar`, { state: { from: 'Detail' } })} style={{ background: "transparent", border: "none" }}>
              <span key={student.id} style={{ color: '#0C718C', textDecoration: 'underline' }}>{student.name}{index < row.students.length - 1 ? ', ' : ''}</span>
            </button>
            // <span key={student.id}>
            //   {student.name}{index < row.students.length - 1 ? ', ' : ''}
            // </span>
          ))}
        </div>
      )
    },
    {
      name: 'Aksi',
      selector: row => row.id,
      width: "130px",
      cell: (row) => (
        <div>
          <button
            className="btn btn-primary shadow btn-xs sharp"
            onClick={() => navigate(`/${row.id}/form-assessment/Edit`)}
            title="Edit"
          >
            <i className="fa fa-pencil"></i>
          </button>
          <Link className="btn btn-danger shadow btn-xs sharp mx-1" title="Delete" onClick={() => handleDeleteClick(row.id)}>
            <i className="fa fa-trash"></i>
          </Link>
        </div>
      ),
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleSelectKota = (e) => {
    setSelectedKota(e.target.getAttribute('value'));
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/schedule-assessment/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus jadwal assessment!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  const handleDateFromChange = (date) => {
    const formattedDate = date?.toLocaleDateString('en-CA');
    setDateFrom(formattedDate);
    setDateTo(null); // Reset dateTo jika dateFrom diubah
    setInternalDateTo(null);
  };
  
  const handleDateToChange = (date) => {
    const formattedDate = date?.toLocaleDateString('en-CA');
    setDateTo(formattedDate);
  
    if (date) {
      // Menambahkan 1 hari secara internal untuk keperluan filter
      const nextDay = new Date(date);
      nextDay.setDate(nextDay.getDate() + 1);
      const formattedNextDay = nextDay.toLocaleDateString('en-CA');
      setInternalDateTo(formattedNextDay); // Set tanggal +1 hari secara internal
    } else {
      setInternalDateTo(null);
    }
  };

  const DatePickerWithClearIcon = ({ value, onChange, placeholderText }) => {
    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <DatePicker
          className='form-control'
          value={value || ''}
          onChange={onChange}
          dateFormat="yyyy-MM-dd"
          placeholderText={placeholderText}
          style={{ maxHeight: "45px", paddingRight: '30px' }}
        />
        {value && (
          <i
            className="fa fa-times"
            onClick={() => onChange(null)}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              color: '#aaa'
            }}
          />
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Jadwal Assessment</Card.Title>
            </Card.Header>
            <div className="d-flex mt-4">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey", height: "55px" }} id="dropdown-basic">
                    {selectedKota?.length > 0 ? selectedKota : "Semua Kota/Kabupaten"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectKota}>
                      Semua Kota/Kabupaten
                    </Dropdown.Item>
                    {Array.isArray(kota) && (kota).map((kotas) => (
                      <Dropdown.Item key={kotas.id} value={kotas.name} onClick={handleSelectKota}>
                        {kotas.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="d-flex">
                  <DatePickerWithClearIcon value={dateFrom} onChange={handleDateFromChange} placeholderText='Tanggal Awal' />
                  <DatePickerWithClearIcon value={dateTo} onChange={handleDateToChange} placeholderText='Tanggal Akhir' />
                </div>
              </div>

              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end px-4">
                <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-assessment/Tambah`)}>
                  Tambah Jadwal
                </button>
                <div className="input-group search-area d-xl-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari Jadwal Assessment"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                  defaultSortFieldId={3}
                  defaultSortAsc={sortOrder === 'asc'}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default JadwalAssessment