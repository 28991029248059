import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import Select from 'react-select';
import DataTable from "react-data-table-component";

const FormKelolaKelas = () => {
  const location = useLocation();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const { id, flag } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    learning_level: "",
    period: "",
    location: "",
    city: "",
    teachers_id: "",
    students_id: "",
  });
  const [formDataJadwalss, setFormDataJadwal] = useState({
    period: "",
    class_id: "",
    title: "",
    day_id: "",
    time_start: "",
    time_finish: "",
    teachers_id: "",
    students_id: "",
    nama_kota_kabupaten: '',
    location_name: '',
    location: '',
  });
  const [kelas, setKelas] = useState([]);
  const [validated, setValidated] = useState(false);
  const [periode, setPeriode] = useState([]);
  const [lokasi, setLokasi] = useState([]);
  const [levelBelajar, setLevelBelajar] = useState([]);
  const [kota, setKota] = useState([]);
  const [hari, setHari] = useState([]);
  const [pengajar, setPengajar] = useState([]);
  const [pelajar, setPelajar] = useState([]);
  const [selectedPengajar, setSelectedPengajar] = useState([]);
  const [selectedPelajar, setSelectedPelajar] = useState([]);
  const [editFormData, setEditFormData] = useState({
    name: "",
    learning_level: "",
    period: "",
    location: "",
    city: "",
    teachers_id: "",
    students_id: "",
    naikkelsd: "",
    learning:"",

  });
  const formRef = useRef(null);
  const [touched, setTouched] = useState(false);
  const [tujuanKelas, setTujuanKelas] = useState("")

  const fetchDataPeriode = async () => {
    const res = await GET(`master-data/period`);
    setPeriode(res?.data);
  };
  const fetchDataLokasi = async (city) => {
    const res = await GET(`master-data/parent/${city}`);
    setLokasi(res?.data);
  };
  const fetchDataLevelBelajar = async () => {
    const res = await GET(`master-data/type?type=level belajar`);
    setLevelBelajar(res?.data);
  };
  const fetchDataKota = async () => {
    const res = await GET(`master-data/type?type=kota kabupaten`);
    setKota(res?.data);
  };
  const fetchDataKelas = async (idPeriode) => {
    const res = await GET(`class/periode/${idPeriode}`);
    setKelas(res?.data);
  };
  const fetchDataHari = async () => {
    const res = await GET(`master-data/type?type=Hari`);
    setHari(res?.data);
  };

  // GET KELAS
  const fetchData = async () => {
    const res = await GET(`/class/${id}`);
    const processedTeachers = res?.teachers?.map(teacher => ({ user_id: teacher.user_id }));
    const processedStudents = res?.students?.map(student => ({ user_id: student.user_id }));
    const testing = res?.students?.map(student => ({ learning_level: student.learning_level }))
    const resTujuanKelas = await GET(`master-data/type?type=level belajar`);
    const selectLevel = resTujuanKelas?.data.find(learning_level => learning_level.id === res?.learning_level?.id);
    setEditFormData({
      name: res?.name,
      learning_level: res?.learning_level?.id,
      period: res?.period?.id,
      location: res?.location?.id,
      city: res?.kota_kabupaten?.id,
      teachers_id: processedTeachers,
      students_id: processedStudents,
      naikkelsd: res.students,
      learning: testing,

    });
    setTujuanKelas(selectLevel.description)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'learning_level') {
      const selectLevel = levelBelajar.find(learning_level => learning_level.id === value);
      setTujuanKelas(selectLevel ? selectLevel.description : "");
    }

    setTouched(true);

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleSelectChangeKelas = (selectedOptions, name) => {
    const newData = selectedOptions.map(option => {
      return {
        user_id: option.value,
      };
    });

    setFormData((prevData) => ({
      ...prevData,
      [name]: newData,
    }));

    setTouched(true);

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const fetchDataPengajar = async () => {
    const res = await GET(`class/type-student?codeType=1`);
    setPengajar(res?.data);

    // if (formDataJadwal?.teachers_id) {
    //   const selectedOptions = res?.data.filter(item =>
    //     formDataJadwal?.teachers_id?.includes(item.id)
    //   );
    //   setSelectedPengajar(selectedOptions.map(item => ({
    //     value: item.id,
    //     label: item.name
    //   })));
    // }
  };
  const fetchDataPelajar = async () => {
    const res = await GET(`class/participant?codeType=0&levelClass=${formData.learning_level}`);
    setPelajar(res?.data);

    // if (formDataJadwal?.students_id) {
    //   const selectedOptions = res?.data.filter(item =>
    //     formDataJadwal?.students_id?.includes(item.id)
    //   );
    //   setSelectedPelajar(selectedOptions.map(item => ({
    //     value: item.id,
    //     label: item.name
    //   })));
    // }
  };
  const fetchDataPelajarEdit = async () => {
    const res = await GET(`class/participant?codeType=0&levelClass=${editFormData.learning_level}`);
    setPelajar(res?.data);

    // if (formDataJadwal?.students_id) {
    //   const selectedOptions = res?.data.filter(item =>
    //     formDataJadwal?.students_id?.includes(item.id)
    //   );
    //   setSelectedPelajar(selectedOptions.map(item => ({
    //     value: item.id,
    //     label: item.name
    //   })));
    // }
  };

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'learning_level') {
      const selectLevel = levelBelajar.find(learning_level => learning_level.id === value);
      setTujuanKelas(selectLevel ? selectLevel.description : "");
    }

    setTouched(true);

    if (name === 'city') {
      editFormData.location = "";
    }

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleChangeEditBaru = (e) => {
    const { name, value } = e.target;
    const newValue = editFormData.naikkelsd.map(r => {
      if(r.user_id === name ){
        r.learning_level = value
      }
      return r
    })
    setEditFormData((prevData) => ({
      ...prevData,
      naikkelsd: newValue,
    }));

    

    setTouched(true);

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleSelectChangeEdit = (selectedOptions, name) => {
    const newData = selectedOptions.map(option => {
      return {
        user_id: option.value,
      };
    });

    setEditFormData((prevData) => ({
      ...prevData,
      [name]: newData,
    }));

    setTouched(true);

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  useEffect(() => {
    fetchDataPeriode();
    fetchDataLevelBelajar();
    fetchDataKota();
    if (id?.length > 0 && flag === "Ubah" || flag === "Naik") {
      fetchData();
    }
    fetchDataHari();
    fetchDataPengajar();
  }, []);

  useEffect(() => {
    if (!id) {
      fetchDataPelajar();
    }
    if (id) {
      fetchDataPelajarEdit();
    }
  }, [formData.learning_level, editFormData.learning_level]);

  // useEffect(() => {
  //   if (formDataJadwal?.period?.length > 0) {
  //     fetchDataKelas(formDataJadwal.period);
  //   }
  // }, [formDataJadwal.period]);

  useEffect(() => {
    if (editFormData?.city) {
      fetchDataLokasi(editFormData.city);
    }
    if (formData?.city) {
      fetchDataLokasi(formData.city);
    }
  }, [editFormData.city, formData.city]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      name: formData.name,
      learning_level: formData.learning_level,
      period: formData.period,
      location: formData.location,
      teachers: formData.teachers_id,
      students: formData.students_id,
      // order: formData.order === null || formData.order === "" ? 1 : formData.order
    };
    // const yes = editFormData?.teachers_id?.filter(pengajarObj => pengajarObj?.user_id).map(pengajarObj => ({ value: pengajarObj?.user_id, label: pengajar?.find(item => item?.user_id === pengajarObj?.user_id)?.user?.name }))
    // console.log('y',yes)

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await POST(`/class`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil membuat kelas!",
      }).then(() => {
        Swal.close();
        setFormData({
          name: "",
          learning_level: "",
          period: "",
          location: "",
          teachers_id: "",
          students_id: "",
        });
        router("/kelola-kelas")
      });

    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const {
      name,
      learning_level,
      period,
      location,
      teachers_id,
      students_id,
    } = editFormData;

    const requestBody = {
      name,
      learning_level,
      period,
      location,
      teachers: teachers_id,
      students: students_id,
    };

    const result = await Swal.fire({
      title: 'Apakah Anda yakin akan menyimpan perubahan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await PATCH(`/class/${id}`, requestBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Data Kelas berhasil diubah!",
        }).then(() => {
          Swal.close();
          fetchData();
          router("/kelola-kelas")
        });
      }
    }
  };

  const handleNaikSubmit = async (e) => {
    e.preventDefault();

    const {
      name,
      learning_level,
      period,
      location,
      teachers_id,
      students_id,
      naikkelsd,
      learning,

    } = editFormData;

    const requestBody = {
      name,
      learning_level,
      period,
      location,
      teachers: teachers_id,
      students: naikkelsd
        
        // students_id,
        // learning,

      
    };

    const result = await Swal.fire({
      title: 'Apakah Anda yakin akan menyimpan perubahan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await PATCH(`/class/Upclass/${id}`, requestBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Data Kelas berhasil diubah!",
        }).then(() => {
          Swal.close();
          fetchData();
          router("/kelola-kelas")
        });
      }
    }
  };

  const selectStyle = {
    control: (provided) => ({
      ...provided,
      // height: '50px',
      minHeight: '55px'
    }),
  }

  // teman belajar
  const columns = [
    {
      name: 'Nama Pelajar',
      selector: row => row.name,
      // cell:(row)=>(
      //   <div>
      //     <select
      //     className="form-control"
      //     required
      //     style={{background: "transparent", border: "none"}}
      //     >
      //       {Array.isArray(pelajar) && pelajar.map((pel)=> (
      //         <option
      //         key={pel.id}
      //         value={pel.id}
      //         >
      //           {pel?.user?.name}
      //         </option>
      //       ))}

      //     </select>
      //   </div>
      // )
    },
    {
      name: 'Kelas',
      selector: row => row.learning_level,
      cell:(row)=>(
        <select
        className="form-control"
        value={row.learning_level}
        //row={row}
        name={row.user_id}
        onChange={handleChangeEditBaru}
        required
        >
          <option value="">Pilih Level</option>
          {Array.isArray(levelBelajar) && levelBelajar.map((leves)=>(
            <option
            key={leves.id}
            value={leves.id}
            >
              {leves.name}

            </option>
          ))}

        </select>
      )

      // cell: (row) => (
      //   <div>
      //     <select
      //       className='form-control'
      //       // value={editFormData.city}
      //       // onChange={handleChangeEdit}
      //       // name="city"
      //       required
      //       style={{ background: "transparent", border: "none" }}
      //     >
      //       {/* <option value="">Pilih Level</option> */}
      //       {Array.isArray(levelBelajar) && levelBelajar.map((levels) => (
      //         <option
      //           key={levels.id}
      //           value={levels.id}
      //         >
      //           {levels.name}
      //         </option>
      //       ))}
      //     </select>
      //   </div>
      // )
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  }

  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Kelas`}
        motherMenu="Kelola Kelas"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Kelas</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/kelola-kelas`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {flag === "Tambah" ? (
                  <form className="form-valide" ref={formRef} onSubmit={handleSubmit}>
                    <div className="row">
                      <div>
                        <div className='form-group col-md-6 col-12'>
                          <label>Periode<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.period}
                            name="period"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Pilih Periode </option>
                            {Array.isArray(periode) && periode.map((periodes) => (
                              <option
                                key={periodes.id}
                                // value={periodes.name === 'Tuna Netra' ? 1 : periodes.name === 'Orang Awas' ? 2 : ''}
                                value={periodes.id}
                              >
                                {periodes.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Periode harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="d-flex flex-wrap">
                        <div className='form-group col-md-6 col-12'>
                          <label>Nama Kelas<span className="text-danger">*</span></label>
                          <input
                            type='text'
                            className='form-control'
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            maxLength="20"
                            minLength="5"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Nama kelas harus diisi minimal 5 karakter.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group col-md-6 col-12'>
                          <label>Level<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.learning_level}
                            name="learning_level"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Pilih Level</option>
                            {Array.isArray(levelBelajar) && levelBelajar.map((levels) => (
                              <option
                                key={levels.id}
                                value={levels.id}
                              >
                                {levels.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Level harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group co-md-12 col-12'>
                          <label>Tujuan Kelas<span className="text-danger">*</span></label>
                          <textarea
                            className='form-control'
                            rows="5"
                            value={tujuanKelas}
                            onChange={handleChange}
                            disabled
                          />
                        </div>


                        {pengajar?.length > 0 && (
                          <div className='form-group col-md-6 col-12'>
                            <label>Nama Pengajar<span className="text-danger">*</span></label>
                            <Select
                              isMulti
                              name="teachers_id"
                              options={pengajar.map(item => ({ value: item.user_id, label: item.user?.name }))}
                              onChange={(selectedOptions) => handleSelectChangeKelas(selectedOptions, 'teachers_id')}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={selectStyle}
                            />
                            {formData.teachers_id === "" && touched && ( // Menampilkan pesan feedback jika bank_code kosong
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Pengajar harus dipilih.
                              </span>
                            )}
                          </div>
                        )} 
                        {pelajar?.length > 0 && (
                          <div className='form-group col-md-6 col-12'>
                            <label>Nama Pelajar</label>
                            <Select
                              isMulti
                              name="students_id"
                              options={pelajar.map(item => ({ value: item.user_id, label: item.user?.name }))}
                              onChange={(selectedOptions) => handleSelectChangeKelas(selectedOptions, 'students_id')}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={selectStyle}
                            />
                            {formData.students_id === "" && touched && (
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Pelajar harus dipilih.
                              </span>
                            )}
                          </div>
                        )} 
                        {/* {pengajar?.length > 0 && (
                          <div className='form-group col-md-6 col-12'>
                            <label>Nama Pengajar<span className="text-danger">*</span></label>
                            <Select
                              isMulti
                              name="teachers_id"
                              options={pengajar.map(item => ({ value: item.user_id, label: item.user?.name }))}
                              onChange={(selectedOptions) => handleSelectChangeKelas(selectedOptions, 'teachers_id')}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={selectStyle}
                            /> */}
                            {/* {formData.teachers_id === "" && touched && ( // Menampilkan pesan feedback jika bank_code kosong
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Pengajar harus dipilih.
                              </span>
                            )} */}
                          {/* </div>
                        )} */}
                        {/* {pelajar?.length > 0 && (
                          <div className='form-group col-md-6 col-12'>
                            <label>Nama Pelajar</label>
                            <Select
                              isMulti
                              name="students_id"
                              options={pelajar.map(item => ({ value: item.user_id, label: item.user?.name }))}
                              onChange={(selectedOptions) => handleSelectChangeKelas(selectedOptions, 'students_id')}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={selectStyle}
                            /> */}
                            {/* {formData.students_id === "" && touched && (
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Pelajar harus dipilih.
                              </span>
                            )} */}
                          {/* </div>
                        )} */}
                        <div className='form-group col-md-6 col-12'>
                          <label>Kota/Kabupaten<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.city}
                            name="city"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Pilih Kota/Kabupaten</option>
                            {Array.isArray(kota) && kota.map((kotas) => (
                              <option
                                key={kotas.id}
                                value={kotas.id}
                              >
                                {kotas.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Kota/Kabupaten harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group col-md-6 col-12'>
                          <label>Lokasi<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.location}
                            name="location"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Pilih Lokasi</option>
                            {Array.isArray(lokasi) && lokasi.map((locations) => (
                              <option
                                key={locations.id}
                                // value={locations.name === 'Tuna Netra' ? 1 : locations.name === 'Orang Awas' ? 2 : ''}
                                value={locations.id}
                              >
                                {locations.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Lokasi harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/kelola-kelas`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" disabled={!validated || formData.teachers_id === ""}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === "Ubah" ? (
                  <form className="form-valide" ref={formRef} onSubmit={handleEditSubmit}>
                    <div className="row">
                      <div>
                        <div className='form-group col-md-6 col-12'>
                          <label>Periode ubah<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={editFormData.period}
                            name="period"
                            onChange={handleChangeEdit}
                            required
                            //disabled={location.state?.from === "Naik"}
                          >
                            <option value="">Pilih Periode</option>
                            {Array.isArray(periode) && periode.map((periodes) => (
                              <option
                                key={periodes.id}
                                value={periodes.id}
                              >
                                {periodes.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Periode harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap">
                        <div className='form-group col-md-6 col-12'>
                          <label>Nama Kelas<span className="text-danger">*</span></label>
                          <input
                            type='text'
                            className='form-control'
                            name="name"
                            value={editFormData.name}
                            onChange={handleChangeEdit}
                            maxLength="20"
                            minLength="5"
                            required
                            //disabled={location.state?.from === "Naik"}
                          />
                          <Form.Control.Feedback type="invalid">
                            Nama kelas harus diisi minimal 5 karakter.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group col-md-6 col-12'>
                          <label>Level<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={editFormData.learning_level}
                            name="learning_level"
                            onChange={handleChangeEdit}
                            required
                            //disabled={location.state?.from === "Naik"}
                          >
                            <option value="">Pilih Level</option>
                            {Array.isArray(levelBelajar) && levelBelajar.map((levels) => (
                              <option
                                key={levels.id}
                                value={levels.id}
                              >
                                {levels.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Level harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group co-md-12 col-12'>
                          <label>Tujuan Kelas<span className="text-danger">*</span></label>
                          <textarea
                            className='form-control'
                            rows="5"
                            value={tujuanKelas}
                            onChange={handleChangeEdit}
                            disabled
                          />
                        </div>
                        {pengajar?.length > 0 && (
                          <div className='form-group col-md-6 col-12'>
                            <label>Nama Pengajar<span className="text-danger">*</span></label>
                            {editFormData.teachers_id && (
                              <Select
                                isMulti
                                name="teachers_id"
                                options={pengajar.map(item => ({ value: item.user_id, label: item.user?.name }))}
                                onChange={(selectedOptions) => handleSelectChangeEdit(selectedOptions, 'teachers_id')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={editFormData?.teachers_id?.filter(pengajarObj => pengajarObj?.user_id).map(pengajarObj => ({ value: pengajarObj?.user_id, label: pengajar?.find(item => item?.user_id === pengajarObj?.user_id)?.user?.name }))}
                                styles={selectStyle}
                                //isDisabled={location.state?.from === "Naik"}
                              />
                            )}
                            {editFormData?.teachers_id === "" || editFormData?.teachers_id?.length === 0 && touched && ( // Menampilkan pesan feedback jika bank_code kosong
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Pengajar harus dipilih.
                              </span>
                            )}
                          </div>
                        )} 
                        {pelajar?.length > 0 && (
                          <div className='form-group col-md-6 col-12'>
                            <label>Nama Pelajar<span className="text-danger">*</span></label>
                            {editFormData.students_id && (
                              <Select
                                isMulti
                                name="students_id"
                                options={pelajar.map(item => ({ value: item.user_id, label: item.user?.name }))}
                                onChange={(selectedOptions) => handleSelectChangeEdit(selectedOptions, 'students_id')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={editFormData?.students_id?.filter(studentObj => studentObj?.user_id).map(studentObj => ({ value: studentObj?.user_id, label: pelajar?.find(item => item?.user_id === studentObj?.user_id)?.user?.name }))}
                                styles={selectStyle}
                                //isDisabled={location.state?.from === "Naik"}
                              />
                            )}
                            {editFormData.students_id === "" || editFormData?.students_id?.length === 0 && touched && (
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Pelajar harus dipilih.
                              </span>
                            )}
                          </div>
                        )}
                        <div className='form-group col-md-6 col-12'>
                          <label>Kota/Kabupaten<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={editFormData.city}
                            onChange={handleChangeEdit}
                            name="city"
                            required
                            //disabled={location.state?.from === "Naik"}
                          >
                            <option value="">Pilih Kota/Kabupaten</option>
                            {Array.isArray(kota) && kota.map((kotas) => (
                              <option
                                key={kotas.id}
                                value={kotas.id}
                              >
                                {kotas.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Kota/Kabupaten harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group col-md-6 col-12'>
                          <label>Lokasi<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={editFormData.location}
                            name="location"
                            onChange={handleChangeEdit}
                            required
                            //disabled={location.state?.from === "Naik"}
                          >
                            <option value="">Pilih Lokasi</option>
                            {Array.isArray(lokasi) && lokasi.map((locations) => (
                              <option
                                key={locations.id}
                                // value={locations.name === 'Tuna Netra' ? 1 : locations.name === 'Orang Awas' ? 2 : ''}
                                value={locations.id}
                              >
                                {locations.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Lokasi harus dipilih.
                          </Form.Control.Feedback>
                        </div>

                        {/* {locations === "Naik" && (
                          <div className='form-group col-md-12 col-12'>
                            <label>Naik Kelas<span className="text-danger">*</span></label>
                            {editFormData && editFormData.length > 0 ? (
                              <DataTable
                                columns={columns}
                                data={editFormData}
                                customStyles={customStyles}
                              />
                            ) : (
                              <p>Tidak ada data</p>
                            )}
                          </div>
                        )} */}
                      </div>
                    </div>

                    {locations === "Detail" ? (
                      ""
                    ) : (
                      <div className="form-group mb-3 d-flex">
                        <div className="col-xl-6">
                          <Link className="btn btn-light w-100" to={`/kelola-kelas`}>
                            Batal
                          </Link>
                        </div>
                        <div className="col-xl-6">
                          <button type="submit" className="btn btn-primary w-100" disabled={!validated || editFormData.teachers_id === ""}>
                            Simpan Perubahan
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                ) : flag === "Naik" ?(
                  <form className="form-valide" ref={formRef} onSubmit={handleNaikSubmit}>
                    <div className="row">
                      <div>
                        <div className='form-group col-md-6 col-12'>
                          <label>Periode<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={editFormData.period}
                            name="period"
                            onChange={handleChangeEdit}
                            required
                            disabled={flag === "Naik"}
                          >
                            <option value="">Pilih Periode</option>
                            {Array.isArray(periode) && periode.map((periodes) => (
                              <option
                                key={periodes.id}
                                value={periodes.id}
                              >
                                {periodes.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Periode harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap">
                        <div className='form-group col-md-6 col-12'>
                          <label>Nama Kelas<span className="text-danger">*</span></label>
                          <input
                            type='text'
                            className='form-control'
                            name="name"
                            value={editFormData.name}
                            onChange={handleChangeEdit}
                            maxLength="20"
                            minLength="5"
                            required
                            disabled={location.state?.from === "Naik"}
                          />
                          <Form.Control.Feedback type="invalid">
                            Nama kelas harus diisi minimal 5 karakter.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group col-md-6 col-12'>
                          <label>Level<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={editFormData.learning_level}
                            name="learning_level"
                            onChange={handleChangeEdit}
                            required
                            disabled={location.state?.from === "Naik"}
                          >
                            <option value="">Pilih Level</option>
                            {Array.isArray(levelBelajar) && levelBelajar.map((levels) => (
                              <option
                                key={levels.id}
                                value={levels.id}
                              >
                                {levels.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Level harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group co-md-12 col-12'>
                          <label>Tujuan Kelas<span className="text-danger">*</span></label>
                          <textarea
                            className='form-control'
                            rows="5"
                            value={tujuanKelas}
                            onChange={handleChangeEdit}
                            disabled
                          />
                        </div>
                        {pengajar?.length > 0 && (
                          <div className='form-group col-md-6 col-12'>
                            <label>Nama Pengajar<span className="text-danger">*</span></label>
                            {editFormData.teachers_id && (
                              <Select
                                isMulti
                                name="teachers_id"
                                options={pengajar.map(item => ({ value: item.user_id, label: item.user?.name }))}
                                onChange={(selectedOptions) => handleSelectChangeEdit(selectedOptions, 'teachers_id')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={editFormData?.teachers_id?.filter(pengajarObj => pengajarObj?.user_id).map(pengajarObj => ({ value: pengajarObj?.user_id, label: pengajar?.find(item => item?.user_id === pengajarObj?.user_id)?.user?.name }))}
                                styles={selectStyle}
                                isDisabled={location.state?.from === "Naik"}
                              />
                            )}
                            {editFormData?.teachers_id === "" || editFormData?.teachers_id?.length === 0 && touched && ( // Menampilkan pesan feedback jika bank_code kosong
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Pengajar harus dipilih.
                              </span>
                            )}
                          </div>
                        )} 
                        {pelajar?.length > 0 && (
                          <div className='form-group col-md-6 col-12'>
                            <label>Nama Pelajar<span className="text-danger">*</span></label>
                            {editFormData.students_id && (
                              <Select
                                isMulti
                                name="students_id"
                                options={pelajar.map(item => ({ value: item.user_id, label: item.user?.name }))}
                                onChange={(selectedOptions) => handleSelectChangeEdit(selectedOptions, 'students_id')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={editFormData?.students_id?.filter(studentObj => studentObj?.user_id).map(studentObj => ({ value: studentObj?.user_id, label: pelajar?.find(item => item?.user_id === studentObj?.user_id)?.user?.name }))}
                                styles={selectStyle}
                                isDisabled={location.state?.from === "Naik"}
                              />
                            )}
                            {editFormData.students_id === "" || editFormData?.students_id?.length === 0 && touched && (
                              <span
                                className="mb-1 text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                Pelajar harus dipilih.
                              </span>
                            )}
                          </div>
                        )}
                        <div className='form-group col-md-6 col-12'>
                          <label>Kota/Kabupaten<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={editFormData.city}
                            onChange={handleChangeEdit}
                            name="city"
                            required
                            disabled={location.state?.from === "Naik"}
                          >
                            <option value="">Pilih Kota/Kabupaten</option>
                            {Array.isArray(kota) && kota.map((kotas) => (
                              <option
                                key={kotas.id}
                                value={kotas.id}
                              >
                                {kotas.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Kota/Kabupaten harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group col-md-6 col-12'>
                          <label>Lokasi<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={editFormData.location}
                            name="location"
                            onChange={handleChangeEdit}
                            required
                            disabled={location.state?.from === "Naik"}
                          >
                            <option value="">Pilih Lokasi</option>
                            {Array.isArray(lokasi) && lokasi.map((locations) => (
                              <option
                                key={locations.id}
                                // value={locations.name === 'Tuna Netra' ? 1 : locations.name === 'Orang Awas' ? 2 : ''}
                                value={locations.id}
                              >
                                {locations.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Lokasi harus dipilih.
                          </Form.Control.Feedback>
                        </div>

                        
                          <div className='form-group col-md-12 col-12'>
                            <label>Naik Kelas<span className="text-danger">*</span></label>
                            {editFormData.naikkelsd && editFormData.naikkelsd.length > 0 ? (
                              <DataTable
                                columns={columns}
                                data={editFormData.naikkelsd}
                                customStyles={customStyles}
                              />
                            ) : (
                              <p>Tidak ada data</p>
                            )}
                          </div>
                        
                      </div>
                    </div>

                    
                      <div className="form-group mb-3 d-flex">
                        <div className="col-xl-6">
                          <Link className="btn btn-light w-100" to={`/kelola-kelas`}>
                            Batal
                          </Link>
                        </div>
                        <div className="col-xl-6">
                          <button type="submit" className="btn btn-primary w-100" disabled={!validated || editFormData.teachers_id === ""}>
                            Simpan Perubahan
                          </button>
                        </div>
                      </div>
                  
                  </form>

                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormKelolaKelas