import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET, PATCH } from "../../../services/AxiosService";
import Swal from "sweetalert2";

const DaftarPanduan = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  //server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");

  const fetchData = async (page, perPage, sortField, sortOrder, search) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
    };
    const res = await GET(`/guide`, params);
    // setData(res?.data);
    // setTotalRows(res?.meta?.amount);
    console.log("Data dari API:d", res.data); // Cek data di sini

    const sortedData = res.data.sort((a, b) => a.order - b.order);
    setData(sortedData);
    setTotalRows(res.meta.amount);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    localStorage.setItem("searchTerm", e.target.value);
  };

  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder]);

  const handleUpClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const res = await PATCH(`/guide/${id}/move-up`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Urutan dipindah ke atas!",
      }).then(() => {
        Swal.close();
        fetchData();
      });
    }
  };

  const handleDownClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/guide/${id}/move-down`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Urutan dipindah ke bawah!",
      }).then(() => {
        Swal.close();
        fetchData();
      });
    }
  };

  const dataDummy = [
    {
      topik_panduan: "Panduan Halaman Profil",
      status: "Aktif",
      urutan: "~",
    },
    {
      topik_panduan: "Panduan Pesanan Buku",
      status: "Aktif",
      urutan: "~",
    },
    {
      topik_panduan: "Panduan Pencapaian",
      status: "Tidak Aktif",
      urutan: "~",
    },
    {
      topik_panduan: "Panduan Halaman",
      status: "Aktif",
      urutan: "~",
    },
  ];
  const columns = [
    {
      name: "Topik Panduan",
      //selector: row => row.topic,
      selector: (row) => row.topic,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Aktif
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Tidak Aktif
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      },
    },
    {
      name: "Urutan",
      selector: (row) => row.order,
      width: "130px",
      cell: (row) => {
        const maxOrder = Math.max(...data.map((item) => item.order));
        const minOrder = Math.min(...data.map((item) => item.order).filter((order) => order !== 0));
        return (
          <div>
            {row.order !== 0 && (
              <>
                <Link className={`btn btn-success shadow btn-xs sharp mx-1 ${row.order === minOrder ? "disabled" : ""}`} title="up" onClick={() => handleUpClick(row.id)}>
                  <i className="fa fa-arrow-up"></i>
                </Link>
                <Link className={`btn btn-success shadow btn-xs sharp ${row.order === maxOrder ? "disabled" : ""}`} title="down" onClick={() => handleDownClick(row.id)}>
                  <i className="fa fa-arrow-down"></i>
                </Link>
              </>
            )}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      selector: (row) => row.id,
      width: "180px",
      cell: (row) => {
        return (
          <div>
            {row.status === 1 ? (
              <button className="btn btn-danger shadow btn-xs sharp" onClick={() => handleLockOpenClick(row.id)} title="Non-Aktif">
                <i className="fa fa-lock"></i>
              </button>
            ) : (
              <button className="btn btn-success shadow btn-xs sharp" onClick={() => handleLockOpenClick(row.id)} title="Aktif">
                <i className="fa fa-unlock"></i>
              </button>
            )}
            &nbsp;
            <button className="btn btn-info shadow btn-xs sharp" title="Detail" onClick={() => navigate(`/${row.id}/form-panduan/Detail`)}>
              <i className="fa fa-eye"></i>
            </button>
            <button className="btn btn-primary shadow btn-xs sharp mx-1" onClick={() => navigate(`/${row.id}/form-panduan/Edit`)} title="Edit">
              <i className="fa fa-pencil"></i>
            </button>
            <button className="btn btn-danger shadow btn-xs sharp mx-1" title="Delete" onClick={() => handleDeleteClick(row.id)}>
              <i className="fa fa-trash"></i>
            </button>
          </div>
        );
      },
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleLockOpenClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/guide/${id}/lock-unlock`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: `${res?.message}`,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/guide/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus Panduan!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Panduan</Card.Title>
            </Card.Header>
            <div className="d-flex flex-row-reverse mt-4 px-5">
              <div className="input-group search-area d-xl-inline-flex">
                <input type="text" className="form-control" placeholder="Cari Panduan" value={searchTerm} onChange={handleSearchChange} />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <Link to="#">
                      <i className="flaticon-381-search-2" />
                    </Link>
                  </span>
                </div>
              </div>
              <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-panduan/Tambah`)}>
                Tambah Panduan
              </button>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                  // defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DaftarPanduan;
