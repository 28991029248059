import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { GET, PATCH, POST } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import moment from 'moment';

const FormCeritaHikmah = () => {
  const location = useLocation();
  const { id, flag } = useParams();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    source_person: "",
    article: "",
    location: "",
    synopsis: "",
    hp_no: "",
    created_at: "",
  });
  const [namaPelajar, setNamaPelajar] = useState("")
  const fetchDataByNoHp = async () => {
    const res = await GET(`/wisdom-story/pelajar/${formData.hp_no}`);
    setNamaPelajar(res?.data?.name);
  };
  useEffect(() => {
    if (formData?.hp_no?.length > 0) {
      fetchDataByNoHp();
    }
  }, [formData.hp_no]);
  const fetchData = async () => {
    const res = await GET(`/wisdom-story/${id}`);
    setFormData({
      title: res?.data?.title,
      hp_no: res?.data?.student?.user?.no_hp,
      source_person: res?.data?.source_person,
      article: res.data?.article,
      location: res.data?.locationName?.name,
      synopsis: res.data?.synopsis,
      location: res.data?.location,
      created_at: res.data?.created_at
    });
    setNamaPelajar(res.data?.student?.user?.name)
  };
  useEffect(() => {
    if (flag !== "Tambah") {
      fetchData();
    }
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const form = e.currentTarget.form;
    const isValid = form.checkValidity();
    setValidated(isValid);
    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      title: formData.title,
      source_person: formData.source_person,
      article: formData.article,
      location: formData.location,
      synopsis: formData.synopsis,
      hp_no: formData.hp_no
    };
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const res = await POST(`/wisdom-story`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil membuat cerita hikmah!",
      }).then(() => {
        Swal.close();
        setFormData({
          title: "",
          source_person: "",
          article: "",
          location: "",
          synopsis: "",
          hp_no: ""
        });
        router("/daftar-cerita-hikmah")
      });
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      title: formData.title,
      source_person: formData.source_person,
      article: formData.article,
      location: formData.location,
      synopsis: formData.synopsis,
      hp_no: formData.hp_no
    };
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const res = await PATCH(`/wisdom-story/${id}`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil mengubah cerita hikmah!",
      }).then(() => {
        Swal.close();
        setFormData({
          title: "",
          source_person: "",
          article: "",
          location: "",
          synopsis: "",
          hp_no: ""
        });
        router("/daftar-cerita-hikmah")
      });

    }
  };
  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Cerita Hikmah`}
        motherMenu="Daftar Cerita Hikmah"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Cerita Hikmah</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/daftar-cerita-hikmah`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form className="form-valide">
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className='form-group col-md-6 col-1'>
                        <label>Judul<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="title"
                          type='text'
                          value={formData.title}
                          onChange={handleChange}
                          placeholder="Masukkan judul cerita"
                          minLength="5"
                          maxLength="100"
                          required
                          disabled={flag === "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          Judul harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group col-md-6 col-1'>
                        <label>No HP Pelajar<span className="text-danger">*</span></label>
                        <input
                          name='hp_no'
                          type='text'
                          className='form-control'
                          value={formData.hp_no}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setFormData((prevState) => ({
                              ...prevState,
                              hp_no: numericValue,
                            }));

                            const form = e.currentTarget.form;
                            const isValid = form.checkValidity();

                            setValidated(isValid);

                            if (isValid) {
                              form.classList.remove("was-validated");
                            } else {
                              form.classList.add("was-validated");
                            }
                          }}
                          placeholder="Masukkan no HP pelajar"
                          minLength={8}
                          maxLength={20}
                          required
                          disabled={flag === "Detail"}
                        />
                      </div>
                      <div className='form-group col-md-6 col-1'>
                        <label>Nama Pelajar</label>
                        <input
                          className='form-control'
                          type='text'
                          value={namaPelajar}
                          required
                          disabled
                        />
                      </div>
                      <div className='form-group col-md-6 col-1'>
                        <label>Nama Narasumber<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="source_person"
                          type='text'
                          value={formData.source_person}
                          onChange={handleChange}
                          placeholder="Masukkan nama narasumber"
                          minLength="2"
                          maxLength="20"
                          required
                          disabled={flag === "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama narasumber harus diisi minimal 2 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group col-md-6 col-1'>
                        <label>Lokasi Narasumber<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="location"
                          type='text'
                          value={formData.location}
                          onChange={handleChange}
                          placeholder="Masukkan lokasi narasumber"
                          minLength="3"
                          maxLength="20"
                          required
                          disabled={flag === "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          Judul harus diisi minimal 3 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group col-md-12 col-1'>
                        <label>Isi Cerita<span className="text-danger">*</span></label>
                        <textarea
                          className='form-control'
                          name="article"
                          rows="8"
                          value={formData.article}
                          onChange={handleChange}
                          placeholder="Masukkan cerita hikmah"
                          minLength="10"
                          maxLength="5000"
                          required
                          disabled={flag === "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          isi cerita harus diisi minimal 10 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className='form-group col-md-12 col-1'>
                        <label>Sinopsis Pendek<span className="text-danger">*</span></label>
                        <textarea
                          className='form-control'
                          name="synopsis"
                          rows="5"
                          value={formData.synopsis}
                          onChange={handleChange}
                          placeholder="Masukkan sinopsis pendek 1-2 kalimat"
                          minLength="20"
                          maxLength="300"
                          required
                          disabled={flag === "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          Sinopsis pendek harus diisi minimal 20 karakter.
                        </Form.Control.Feedback>
                      </div>
                      {flag === "Detail" && (
                        <div className='form-group col-md-6 col-1'>
                          <label>Tanggal Submit<span className="text-danger">*</span></label>
                          <input
                            className='form-control'
                            type='text'
                            value={moment(formData.created_at).format("DD MMMM YYYY, HH:mm")}
                            disabled
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {flag !== "Detail" && (
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-cerita-hikmah`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={flag === "Tambah" ? handleSubmit : handleEditSubmit} disabled={!validated}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default FormCeritaHikmah