/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { Fragment, useEffect, useState, useRef } from 'react'
import PageTitle from '../../layouts/PageTitle'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Form from "react-bootstrap/Form";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from 'sweetalert2';
import moment from "moment";

const FormKonten = () => {
  const { id, flag } = useParams();
  console.log('hoooo',id,flag)
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState(null);
  const [fileAdditional, setFileAdditional] = useState(null);
  const [isNewFileSelected, setIsNewFileSelected] = useState(false);
  const [isNewFileAdditionalSelected, setIsNewFileAdditionalSelected] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [imageAdditionalPreviewUrl, setImageAdditionalPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [page, setPage] = useState([]);
  const [section, setSection] = useState([]);
  const fileAdditionalInputRef = useRef(null);
  const [formData, setFormData] = useState({
    page: "",
    selection: "",
    type: "",
    content: "",
    additional_content: "",
  });
  const [contentType, setContentType] = useState([]);
  const [showButtonDelete, setShowButtonDelete] = useState(false);
  const [isDeletePhotoAdditional, setIsDeletePhotoAdditional] = useState(false);
  const navigate = useNavigate();
  const fileSizeLimit = 2 * 1024 * 1024;

  useEffect(() => {
    if (id) {
      fetchData();
    }
    fetchDataContentType();
    fetchPage();
  }, []);

  const fetchData = async () => {
    const res = await GET(`master_data_content/${id}`);
    setFormData(res?.data);
    if (res?.data?.additional_content_image) {
      setShowButtonDelete(true);
    }

    const resPage = await GET(`master-data/type?type=Page`);
    const fillPage = resPage.data.filter(pg => pg.name === res.data.page);
    fetchSection(fillPage[0]?.id);
  };

  const fetchDataContentType = async () => {
    const res = await GET(`master-data/type?type=content_type`);
    setContentType(res?.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
  };

  const fetchPage = async () => {
    const res = await GET(`master-data/type?type=Page`);
    setPage(res?.data);
  };
  
  const fetchSection = async (idPage) => {
    const res = await GET(`master-data/parent/${idPage}`);
    setSection(res?.data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    
    if (name === 'page') {
      const selectedPage = page.find(pg => pg.name === value);
      const selectedPageId = selectedPage ? selectedPage.id : null;
      fetchSection(selectedPageId)
    }
    
    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > fileSizeLimit) {
        return Swal.fire({
          icon: "error",
          title: `Ukuran file melebihi batas maksimum (2 MB)`,
        });
      }

      const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
      if (!allowedTypes.includes(selectedFile.type)) {
        return Swal.fire({
          icon: "error",
          title: `File harus dalam format (jpg, jpeg, png, gif)`,
        });
      }
     
      setFile(selectedFile);
      setValidated(true);
      setIsNewFileSelected(true); // Set the flag when a new file is selected
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  const handleFileAdditionalChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > fileSizeLimit) {
        return Swal.fire({
          icon: "error",
          title: `Ukuran file melebihi batas maksimum (2 MB)`,
        });
      }

      const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
      if (!allowedTypes.includes(selectedFile.type)) {
        return Swal.fire({
          icon: "error",
          title: `File harus dalam format (jpg, jpeg, png, gif)`,
        });
      }
      
      setFileAdditional(selectedFile);
      setValidated(true);
      setIsNewFileAdditionalSelected(true); // Set the flag when a new file is selected
      setIsDeletePhotoAdditional(false);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageAdditionalPreviewUrl(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('formData',formData)

    const result = await Swal.fire({
      title: 'Apakah Anda yakin akan menyimpan perubahan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });
    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let {
        page,
        selection,
        type,
        content,
        additional_content,
      } = formData;

      let content_image, additional_content_image;

      if (!formData.type || contentType.filter(x => x.id === formData.type)[0]?.name.includes('Gambar')) {
        content = '';
        additional_content= '';

        if (file) {
          const formDataObject = new FormData();
          formDataObject.append("type", "content_image");
          formDataObject.append("file", file);
    
          const fileUploadResponse = await POST(
            `/files`,
            formDataObject,
            undefined,
            { "Content-Type": "multipart/form-data" }
          );
  
          content_image = fileUploadResponse?.file?.id;
        }

        if (fileAdditional) {
          const formDataObject = new FormData();
          formDataObject.append("type", "additional_content_image");
          formDataObject.append("file", fileAdditional);
    
          const fileUploadResponse = await POST(
            `/files`,
            formDataObject,
            undefined,
            { "Content-Type": "multipart/form-data" }
          );
  
          additional_content_image = fileUploadResponse?.file?.id;
        }
      }

      if (!type) {
        type = contentType.filter(x => x.name === 'Gambar')[0]?.id
      }
  
      const requestBody = {
        page,
        selection,
        type,
        content,
        additional_content,
        content_image,
        additional_content_image,
      };

      let res;
      if (id) {
        if (isDeletePhotoAdditional) {
          requestBody.additional_content_image = null;
        }
        res = await PATCH(`master_data_content/${id}`, requestBody);
      } else {
        res = await POST(`master_data_content`, requestBody);
      }
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Data Konten berhasil diubah!",
        }).then(() => {
          Swal.close();
          navigate(`/daftar-konten`)
        });
      }
    }
  };

  const handleDeletePhotoAdditional = () => {
    setFormData((prevData) => ({
      ...prevData,
      additional_content_image: null,
    }));
    setShowButtonDelete(false);
    setIsDeletePhotoAdditional(true);
    setValidated(true);
  };
  return (
    <Fragment>
      <PageTitle activeMenu={`${id ? (flag.charAt(0).toUpperCase() + flag.slice(1)) : 'Tambah'} Konten`} motherMenu="Daftar Konten" />
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card'>
            {flag === 'detail' && (
              <div className="card-header">
                <div></div>
                <Link className="btn btn-primary mr-2" to={`/daftar-konten`}>
                  Kembali
                </Link>
              </div>
            )}
            <div className='card-body'>
              <div className='form-validation mt-3'>
                <form className='form-valide' onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-xl-4">
                      <div className='form-group'>
                        <label>Page<span className="text-danger">*</span></label>
                        <select
                          disabled={flag === 'detail'}
                          className='form-control'
                          value={formData.page}
                          name="page"
                          onChange={handleChange}
                          required
                        >
                          <option value={''}>Pilih Page</option>
                          {Array.isArray(page) && page.map((pg) => (
                            <option key={pg.name} value={pg.name}>
                              {pg.name}
                            </option>
                          ))}
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Page harus diisi minimal 3 karakter.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className='form-group'>
                        <label>Selection<span className="text-danger">*</span></label>
                        <select
                          disabled={flag === 'detail'}
                          className='form-control'
                          value={formData.selection}
                          name="selection"
                          onChange={handleChange}
                          required
                        >
                          <option value={''}>Pilih Selection</option>
                          {Array.isArray(section) && section.map((sc) => (
                            <option
                              key={sc.name}
                              value={sc.name}
                            >
                              {sc.name}
                            </option>
                          ))}
                        </select>


                        <Form.Control.Feedback type="invalid">
                          Selection harus diisi minimal 3 karakter.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className='form-group'>
                        <label>Tipe<span className="text-danger">*</span></label>
                        <select
                          disabled={flag === 'detail'}
                          className='form-control'
                          value={formData.type}
                          name="type"
                          onChange={handleChange}
                          required
                        >
                          {Array.isArray(contentType) && contentType.map((ct) => (
                            <option
                              key={ct.id}
                              value={ct.id}
                            >
                              {ct.name}
                            </option>
                          ))}
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Tipe harus dipilih.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Konten<span className="text-danger">*</span></label>
                        {!formData.type || contentType.filter(x => x.id === formData.type)[0]?.name.includes('Gambar') ? (
                          <>
                            {!id && (
                              <div className='input-group'>
                                <button type='button' className="btn btn-primary" disabled={flag === 'detail'} onClick={() => fileInputRef.current.click()}>
                                  Pilih File
                                </button>
                                <span className="input-group-text">
                                  { file ? file.name.length > 25 ? `${file.name.slice(0, 25)}...` : file.name : "Tidak ada file yang dipilih..." }
                                </span>
                                <input
                                  type='file'
                                  ref={fileInputRef}
                                  className='d-none'
                                  onChange={handleFileChange}
                                  accept='.jpg, .jpeg, .png, .gif'
                                  required
                                  isInvalid={file === null && validated}
                                />
                                {file && (
                                  <Form.Control.Feedback type="invalid">
                                    Konten harus diisi.
                                  </Form.Control.Feedback>
                                )}
                              </div>
                            )}
                            {imagePreviewUrl && flag !== 'detail' && isNewFileSelected && (
                              <div className="d-flex flex-column my-1">
                                <Form.Label>
                                  {!id ? "Preview" : "Foto Konten"}
                                </Form.Label>
                                <img
                                  src={imagePreviewUrl}
                                  alt="Foto Konten Preview"
                                  className="img-fluid rounded my-1"
                                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                                />
                              </div>
                            )}
                            <div>
                              {id &&
                                !isNewFileSelected &&
                                formData &&
                                formData.content_image ? (
                                <div>
                                  {formData?.content_image ? (
                                    <img
                                      src={`${process.env.REACT_APP_API_BASE_URL}files/${formData.content_image}/download`}
                                      alt="konten"
                                      className="img-fluid rounded my-1"
                                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                                    />
                                  ) : (
                                    <p>Tidak Ada Photo</p>
                                  )}
                                </div>
                              ) : null}

                              {flag === 'edit' && (
                                <div>
                                  <div className='input-group'>
                                    <button type='button' className="btn btn-primary my-2" onClick={() => fileInputRef.current.click()}>
                                      Ganti Foto
                                    </button>
                                  </div>
                                  <input
                                    type='file'
                                    ref={fileInputRef}
                                    className='d-none'
                                    onChange={handleFileChange}
                                    accept='.jpg, .jpeg, .png, .gif'
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Silakan pilih file foto konten
                                  </Form.Control.Feedback>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <textarea
                              disabled={flag === 'detail'}
                              className='form-control'
                              rows="5"
                              value={formData.content}
                              name="content"
                              onChange={handleChange}
                              maxLength="1000"
                              minLength="3"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Konten harus diisi minimal 3 karakter.
                            </Form.Control.Feedback>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Konten Tambahan</label>
                        {!formData.type || contentType.filter(x => x.id === formData.type)[0]?.name.includes('Gambar') ? (
                          <>
                            {!id && (
                              <div className='input-group'>
                                <button type='button' className="btn btn-primary" disabled={flag === 'detail'} onClick={() => fileAdditionalInputRef.current.click()}>
                                  Pilih File
                                </button>
                                <span className="input-group-text">
                                  { fileAdditional ? fileAdditional.name.length > 25 ? `${fileAdditional.name.slice(0, 25)}...` : fileAdditional.name : "Tidak ada file yang dipilih..." }
                                </span>
                                <input
                                  type='file'
                                  ref={fileAdditionalInputRef}
                                  className='d-none'
                                  onChange={handleFileAdditionalChange}
                                  accept='.jpg, .jpeg, .png, .gif'
                                  isInvalid={fileAdditional === null && validated}
                                />
                              </div>
                            )}
                            {imageAdditionalPreviewUrl && flag !== 'detail' && isNewFileAdditionalSelected && (
                              <div className="d-flex flex-column my-1">
                                <Form.Label>
                                  {!id ? "Preview" : "Foto Konten Tambahan"}
                                </Form.Label>
                                <img
                                  src={imageAdditionalPreviewUrl}
                                  alt="Foto Konten Tambahan Preview"
                                  className="img-fluid rounded my-1"
                                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                                />
                              </div>
                            )}
                            <div>
                              {id &&
                                !isNewFileAdditionalSelected &&
                                formData &&
                                formData.additional_content_image ? (
                                <div>
                                  {formData?.additional_content_image ? (
                                    <img
                                      src={`${process.env.REACT_APP_API_BASE_URL}files/${formData.additional_content_image}/download`}
                                      alt="konten"
                                      className="img-fluid rounded my-1"
                                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                                    />
                                  ) : (
                                    <p>Tidak Ada Photo</p>
                                  )}
                                </div>
                              ) : null}

                              {flag === 'edit' && (
                                <div>
                                  <div className='input-group'>
                                    <button type='button' className="btn btn-primary my-2" onClick={() => fileAdditionalInputRef.current.click()}>
                                      Ganti Foto
                                    </button>
                                    {showButtonDelete && (
                                      <button type='button' className="btn btn-primary my-2 ml-2" onClick={() => handleDeletePhotoAdditional()}>
                                        Hapus Foto
                                      </button>
                                    )}
                                  </div>
                                  <input
                                    type='file'
                                    ref={fileAdditionalInputRef}
                                    className='d-none'
                                    onChange={handleFileAdditionalChange}
                                    accept='.jpg, .jpeg, .png, .gif'
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Silakan pilih file foto konten tambahan
                                  </Form.Control.Feedback>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <input
                            disabled={flag === 'detail'}
                            type='text'
                            className='form-control'
                            value={formData.additional_content}
                            name="additional_content"
                            onChange={handleChange}
                            maxLength="100"
                            minLength="3"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {flag === 'detail' && (
                    <div className="row">
                      <div className="col-xl-4">
                        <div className='form-group'>
                          <label>Update</label>
                          <input
                            disabled
                            type='text'
                            className='form-control'
                            value={moment(formData.updated_at).format("DD MMMM YYYY")}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {flag !== 'detail' && (
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-konten`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" disabled={!validated}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormKonten