import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
// import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/extend.css";

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("accessToken");

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  // const role = localStorage.getItem("accesRole");
  // const idx = nav.map(x => x.url).indexOf(window.location.pathname.replace(process.env.PUBLIC_URL, ''));
  // if (idx !== -1 && nav[idx].role.indexOf(role) === -1) {
  //   return <Navigate to="/forbidden" replace />;
  // }

  return children;
};

function App(props) {
  let routeblog = (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/page-register' element={<SignUp />} />
      <Route path='/page-forgot-password' element={<ForgotPassword />} />
    </Routes>
  );
  return (
    <>
      <Suspense fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }>
        <ProtectedRoute>
          <Index />
        </ProtectedRoute>
        {routeblog}
      </Suspense>
    </>
  );
};


// function App(props) {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   useEffect(() => {
//     checkAutoLogin(dispatch, navigate);
//   }, []);



//   let routeblog = (

//     <Routes>
//       <Route path='/login' element={<Login />} />
//       <Route path='/page-register' element={<SignUp />} />
//       <Route path='/page-forgot-password' element={<ForgotPassword />} />
//     </Routes>
//   );
//   if (props.isAuthenticated) {
//     return (
//       <>
//         <Suspense fallback={
//           <div id="preloader">
//             <div className="sk-three-bounce">
//               <div className="sk-child sk-bounce1"></div>
//               <div className="sk-child sk-bounce2"></div>
//               <div className="sk-child sk-bounce3"></div>
//             </div>
//           </div>
//         }
//         >
//           <Index />
//         </Suspense>
//       </>
//     );

//   } else {
//     return (
//       <div className="vh-100">
//         <Suspense fallback={
//           <div id="preloader">
//             <div className="sk-three-bounce">
//               <div className="sk-child sk-bounce1"></div>
//               <div className="sk-child sk-bounce2"></div>
//               <div className="sk-child sk-bounce3"></div>
//             </div>
//           </div>
//         }
//         >
//           {routeblog}
//         </Suspense>
//       </div>
//     );
//   }
// };


const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

//export default connect((mapStateToProps)(App)); 
export default withRouter(connect(mapStateToProps)(App)); 