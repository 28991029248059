import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Badge, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET, PATCH } from "../../../services/AxiosService";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleCheck } from "@fortawesome/free-solid-svg-icons";

const DaftarAlokasiBuku = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [typeBuku, setTypeBuku] = useState([]);
  //server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedTypeBuku, setSelectedTypeBuku] = useState("");
  const [selectedNameTypeBuku, setSelectedNameTypeBuku] = useState("");

  const [selectedStatusBuku, setSelectedStatusBuku] = useState("");
  const [selectedNameStatusBuku, setSelectedNameStatusBuku] = useState("");

  const DropDownstatusBook = [
    {
      id: 0,
      description: "Already Assigned",
      name: "Already Assigned",
    },
    {
      id: 1,
      description: "Accepted",
      name: "Accepted",
    },
  ];

  const fetchData = async (page, perPage, sortField, sortOrder, search, typeBuku, DropDownstatusBook) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      typeBook: typeBuku,
      statusBook: DropDownstatusBook,
    };
    const res = await GET(`/donation/allocation/buku`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };

  console.log("iniopp", data);

  const fetchTypeBuku = async () => {
    const res = await GET(`/book?size=-1`);
    setTypeBuku(res?.data);
  };

  const handleSelectTipeBuku = (e) => {
    setSelectedTypeBuku(e.target.getAttribute("value"));
    setSelectedNameTypeBuku(e.target.textContent);
  };
  console.log("onChnage", selectedStatusBuku, selectedNameStatusBuku);

  const handleSelectStatusBuku = (e) => {
    setSelectedStatusBuku(e.target.getAttribute("value"));
    setSelectedNameStatusBuku(e.target.textContent);
  };

  console.log("typeBuku", typeBuku);

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedTypeBuku, selectedStatusBuku);
  };

  useEffect(() => {
    fetchServerData();
    fetchTypeBuku();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedTypeBuku, selectedStatusBuku]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    localStorage.setItem("searchTerm", e.target.value);
  };

  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };
  const columns = [
    {
      name: "ID",
      selector: (row) => row.no,
    },
    {
      name: "Nama Donatur",
      selector: (row) => row.donation?.user_name,
    },
    {
      name: "Tipe Buku",
      selector: (row) => row.book_type,
    },
    {
      name: "Qty",
      selector: (row) => row.book_qty,
    },
    {
      name: "Nama Penerima",
      selector: (row) => (Array.isArray(row.recipients) ? row.recipients.map((recipient) => recipient.student_name).join(", ") : "N/A"),
    },
    {
      name: "Status Buku",
      selector: (row) => (Array.isArray(row.recipients) ? row.recipients.map((status_book) => status_book.status).join(",") : "N/A"),
    },
    {
      name: "Aksi",
      selector: (row) => row.id,
      width: "150px",
      cell: (row) => (
        <div>
          <button className="btn btn-success shadow btn-xs sharp mx-1" title="Alokasi" onClick={() => navigate(`/${row.id}/daftar-alokasi-buku-pelajar/Alokasi`)}>
            <FontAwesomeIcon icon={faFileCircleCheck} />
          </button>
          <button className="btn btn-info shadow btn-xs sharp mx-1" title="Detail" onClick={() => navigate(`/${row.donation_id}/form-donasi-alokasi-buku/Detail`)}>
            <i className="fa fa-eye"></i>
          </button>
        </div>
      ),
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Alokasi Buku Donasi</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4">
              <div className="d-flex flex-wrap px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                    }}
                    id="dropdown-basic"
                    width="200px"
                  >
                    {selectedStatusBuku?.length > 0 ? selectedNameStatusBuku : "Semua Status Buku"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectStatusBuku}>
                      Semua Status
                    </Dropdown.Item>
                    {Array.isArray(DropDownstatusBook) &&
                      DropDownstatusBook.map((bukuStatus) => (
                        <Dropdown.Item key={bukuStatus.id} value={bukuStatus.id} onClick={handleSelectStatusBuku}>
                          {bukuStatus.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                      // borderRadius: "0",
                    }}
                    id="dropdown-basic"
                    width="200px"
                  >
                    {selectedTypeBuku?.length > 0 ? selectedNameTypeBuku : "Semua Tipe Buku"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectTipeBuku}>
                      Semua Buku
                    </Dropdown.Item>
                    {Array.isArray(typeBuku) &&
                      typeBuku.map((bukuType) => (
                        <Dropdown.Item key={bukuType.id} value={bukuType.name} onClick={handleSelectTipeBuku}>
                          {bukuType.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="d-flex align-self-baseline px-4">
                <div className="input-group search-area d-xl-inline-flex">
                  <input type="text" className="form-control" placeholder="Cari Donasi" value={searchTerm} onChange={handleSearchChange} />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                  // defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DaftarAlokasiBuku;
