import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonArrowUpFromLine } from "@fortawesome/free-solid-svg-icons";

const KelolaKelas = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [periode, setPeriode] = useState([]);
  const [selectedPeriode, setSelectedPeriode] = useState("");
  const [selectedNamePeriod, setSelectedNamePeriod] = useState("");
  const [kota, setKota] = useState([]);
  const [selectedKota, setSelectedKota] = useState("");
  const [level, setLevel] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");

  console.log("periode", periode);

  const fetchData = async (page, perPage, sortField, sortOrder, search, period, kotakabupaten, levels) => {
    let periodData = periode;
    if (!period) {
      const resPeriode = await GET(`master-data/period`);
      periodData = resPeriode?.data;
      setPeriode(resPeriode?.data);
    }
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      period: period?.length > 0 ? period : periodData[0]?.period_name,
      city: kotakabupaten,
      level: levels,
    };
    const res = await GET(`/class`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };
  const fetchDataKota = async () => {
    const res = await GET(`master-data/type?type=kota kabupaten`);
    setKota(res?.data);
  };
  const fetchDataLevel = async () => {
    const res = await GET(`master-data/type?type=level belajar`);
    setLevel(res?.data);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedPeriode, selectedKota, selectedLevel);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedPeriode, selectedKota, selectedLevel]);

  useEffect(() => {
    fetchDataKota();
    fetchDataLevel();
  }, []);

  const columns = [
    {
      name: "Nama Kelas",
      selector: (row) => row.name,
      sortable: true,
      field: "name",
    },
    {
      name: "Kota/Kabupaten",
      selector: (row) => row.kota_kabupaten?.name,
    },
    {
      name: "Lokasi",
      selector: (row) => row.location?.name,
    },
    {
      name: "Level",
      selector: (row) => row.learning_level?.name,
    },
    {
      name: "Nama Pengajar",
      selector: (row) => row.teachers,
      cell: (row) => (
        <div>
          {row.teachers?.map((teacher, index) => (
            <span key={teacher.id}>
              {teacher.name}
              {index < row.teachers.length - 1 ? ", " : ""}
            </span>
          ))}
        </div>
      ),
    },
    {
      name: "Nama Pelajar",
      selector: (row) => row.periode,
      cell: (row) => (
        <div>
          {row.students?.map((student, index) => (
            <Link to={`/${student.student_id}/detail-pelajar/Detail`} style={{ color: "#0C718C", textDecoration: "underline" }}>
              {student.name}
              {index < row.students.length - 1 ? ", " : ""}
            </Link>
          ))}
        </div>
      ),
    },
    {
      name: "Aksi",
      selector: (row) => row.id,
      width: "130px",
      cell: (row) => (
        <div>
          <button className="btn btn-info shadow btn-xs sharp" onClick={() => navigate(`/${row.id}/form-kelas/Naik`)} title="Naik">
            <FontAwesomeIcon icon={faPersonArrowUpFromLine} />
          </button>
          <button className="btn btn-primary shadow btn-xs sharp mx-1" onClick={() => navigate(`/${row.id}/form-kelas/Ubah`)} title="Edit">
            <i className="fa fa-pencil"></i>
          </button>
          <button className="btn btn-danger shadow btn-xs sharp" title="Delete" onClick={() => handleDeleteClick(row.id)}>
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleSelectPeriode = (e) => {
    setSelectedPeriode(e.target.getAttribute("value"));
    setSelectedNamePeriod(e.target.textContent);
  };
  const handleSelectKota = (e) => {
    setSelectedKota(e.target.getAttribute("value"));
  };
  const handleSelectLevel = (e) => {
    setSelectedLevel(e.target.getAttribute("value"));
  };

  // Set default selected period on component load
  useEffect(() => {
    if (periode?.length > 0 && !selectedPeriode) {
      const defaultPeriod = periode.find((item) => item.status === 1);
      if (defaultPeriod) {
        setSelectedPeriode(defaultPeriod.name);
        setSelectedNamePeriod(defaultPeriod.name);
      }
    }
  }, [periode, selectedPeriode]); // Add selectedPeriod as a dependency

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/class/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus kelas!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Kelas</Card.Title>
            </Card.Header>
            <div className="d-flex mt-4">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic" width="200px">
                    {selectedPeriode?.length > 0 ? selectedNamePeriod : "Semua Periode"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {Array.isArray(periode) &&
                      periode.map((periodes) => (
                        <Dropdown.Item key={periodes.id} value={periodes.name} onClick={handleSelectPeriode}>
                          {periodes.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                    {selectedKota?.length > 0 ? selectedKota : "Semua Kota/Kabupaten"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectKota}>
                      Semua Kota/Kabupaten
                    </Dropdown.Item>
                    {Array.isArray(kota) &&
                      kota.map((kotaKabupaten) => (
                        <Dropdown.Item key={kotaKabupaten.id} value={kotaKabupaten.name} onClick={handleSelectKota}>
                          {kotaKabupaten.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                    {selectedLevel?.length > 0 ? selectedLevel : "Semua Level"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectLevel}>
                      Semua Level
                    </Dropdown.Item>
                    {Array.isArray(level) &&
                      level.map((levels) => (
                        <Dropdown.Item key={levels.id} value={levels.name} onClick={handleSelectLevel}>
                          {levels.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end px-4">
                <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-kelas/Tambah`)}>
                  Tambah Kelas
                </button>
                <div className="input-group search-area d-xl-inline-flex">
                  <input type="text" className="form-control" placeholder="Cari Kelas" value={searchTerm} onChange={handleSearchChange} />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                  defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default KelolaKelas;
