/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { Fragment, useEffect, useState } from 'react'
import PageTitle from '../../layouts/PageTitle'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Form from "react-bootstrap/Form";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from 'sweetalert2';

const FormModule = () => {
  const { id, flag } = useParams();
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    code: "",
    feature: "",
    description: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);
  const fetchData = async () => {
    const res = await GET(`module/${id}`);
    setFormData(res?.data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: 'Apakah Anda yakin akan menyimpan perubahan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let res;
      if (id) {
        res = await PATCH(`module/${id}`, formData);
      } else {
        res = await POST(`module`, formData);
      }
      if (res) {
        Swal.fire({
          icon: "success",
          title: !id ? `Fitur ${formData.code} berhasil ditambahkan.` : "Data Fitur berhasil diubah!",
        }).then(() => {
          Swal.close();
          navigate(`/daftar-fitur`)
        });
      }
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu={`${id ? (flag.charAt(0).toUpperCase() + flag.slice(1)) : 'Tambah'} Fitur`} motherMenu="Daftar Fitur" />
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card'>
            {flag === 'detail' && (
              <div className="card-header">
                <div></div>
                <Link className="btn btn-primary mr-2" to={`/daftar-fitur`}>
                  Kembali
                </Link>
              </div>
            )}
            <div className='card-body'>
              <div className='form-validation mt-3'>
                <form className='form-valide' onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Kode Fitur<span className="text-danger">*</span></label>
                        <input
                          disabled={id}
                          type='text'
                          className='form-control'
                          value={formData.code}
                          name="code"
                          onChange={handleChange}
                          maxLength="10"
                          minLength="3"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Kode Fitur harus diisi minimal 3 karakter.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Nama Fitur<span className="text-danger">*</span></label>
                        <input
                          disabled={flag === 'detail'}
                          type='text'
                          className='form-control'
                          value={formData.feature}
                          name="feature"
                          onChange={handleChange}
                          maxLength="50"
                          minLength="5"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama Fitur harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>

                  <div className='form-group'>
                    <label>Keterangan</label>
                    <textarea
                      disabled={flag === 'detail'}
                      className='form-control'
                      rows="5"
                      value={formData.description}
                      name="description"
                      onChange={handleChange}
                      maxLength="200"
                    />
                  </div>

                  {flag !== 'detail' && (
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-fitur`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" disabled={!validated}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormModule