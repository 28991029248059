import React, { Fragment, useState, useEffect, useRef } from 'react';
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from "sweetalert2";

const FormSlotAssessment = () => {
  const location = useLocation();
  const router = useNavigate();
  const { id,flag } = useParams();
  const [locations, setLocations] = useState(location.state?.from);
  const [kota, setKota] = useState([]);
  const [lokasi, setLokasi] = useState([]);
  const [hari, setHari] = useState([]);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
      time_start: "",
      time_finish: "",
      location: "",
      day: "",
      capacity: "",
      city: "",
  });

  const formRef = useRef(null);
  const [touched, setTouched] = useState(false);

  const fetchDataKota = async () => {
      const res = await GET(`master-data/type?type=kota kabupaten`);
      setKota(res?.data);
  };
  const fetchDataLokasi = async (city) => {
      const res = await GET(`master-data/parent/${city}`);
      setLokasi(res?.data);
  };

  const fetchDataHari = async () => {
    const res = await GET(`master-data/type?type=hari`);
    setHari(res?.data);
  };

  const fetchData = async () => {
      const res = await GET(`/class/slot-assessment/${id}`);
      setFormData({
        day: res?.day.id,
        time_start: res?.time_start,
        time_finish: res?.time_finish,
        location: res?.location.id,
        city: res?.location.kota_kabupaten.id,
        capacity: res?.capacity,
      });
  };

  useEffect(() => {
      fetchDataKota();
      fetchDataHari();
  
      if (flag === "Ubah") {
        fetchData();
      }
  }, []);

  useEffect(() => {
      if (formData?.city) {
        fetchDataLokasi(formData.city);
      }
  }, [formData.city]);

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  
      setTouched(true);
  
      if (name === 'city') {
        formData.location = "";
      }
  
      const form = e.currentTarget.form;
      const isValid = form.checkValidity();
  
      setValidated(isValid);
  
      if (isValid) {
        form.classList.remove("was-validated");
      } else {
        form.classList.add("was-validated");
      }
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      const requestBody = {
        location: formData.location,
        time_start: formData.time_start,
        time_finish: formData.time_finish,
        day: formData.day,
        capacity: formData.capacity,
      };
  
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  
      const res = await POST(`/class/slot-assessment`, requestBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Berhasil membuat slot assessment!",
        }).then(() => {
          Swal.close();
          setFormData({
            time_start: "",
            time_finish: "",
            location: "",
            day: "",
            capacity: "",
          });
          router("/daftar-slot-assessment")
        });
  
      }
  };

  const handleEditSubmit = async (e) => {
      e.preventDefault();
      const requestBody = {
        location: formData.location,
        time_start: formData.time_start,
        time_finish: formData.time_finish,
        day: formData.day,
        capacity: formData.capacity,
      };
  
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  
      const res = await PATCH(`/class/slot-assessment/${id}`, requestBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Data Slot assessment berhasil diubah!",
        }).then(() => {
          Swal.close();
          setFormData({
            time_start: "",
            time_finish: "",
            location: "",
            day: "",
            capacity: "",
            city: "",
          });
          router("/daftar-slot-assessment")
        });
      }
  };
  return(
    <Fragment>
      <PageTitle
        activeMenu={`${flag}`}
        motherMenu="Daftar Slot Assessment"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag}</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/daftar-slot-assessment`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {flag === "Tambah" ? (
                  <form className="form-valide" ref={formRef} onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Kota/Kabupaten<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.city}
                            name="city"
                            onChange={handleChange}
                          >
                            <option value="">Pilih Kota/Kabupaten</option>
                            {Array.isArray(kota) && kota.map((kotas) => (
                              <option
                                key={kotas.id}
                                value={kotas.id}
                              >
                                {kotas.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Kota/Kabupaten harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group'>
                          <label>Lokasi<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.location}
                            name="location"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Pilih Lokasi</option>
                            {Array.isArray(lokasi) && lokasi.map((locations) => (
                              <option
                                key={locations.id}
                                value={locations.id}
                              >
                                {locations.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Lokasi harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group row'>
                          <p className="mb-1">Hari<span className="text-danger">*</span></p>
                          <select
                            className='form-control'
                            value={formData.day}
                            name="day"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Pilih Hari</option>
                            {Array.isArray(hari) && hari.map((haris) => (
                              <option
                                key={haris.id}
                                value={haris.id}
                              >
                                {haris.name}
                              </option>
                            ))}
                          </select>
                          {formData.day === "" && touched && (
                            <span
                              className="mb-1 text-danger"
                              style={{ fontSize: "14px" }}
                            >
                              Hari harus diisi.
                            </span>
                          )}
                        </div>

                        <div className='form-group'>
                          <label>Waktu<span className="text-danger">*</span></label>
                          <div className="d-flex">
                            <input
                              className='form-control col-md-5'
                              type="time"
                              value={formData.time_start}
                              onChange={(e) => {
                                const newTimeStart = e.target.value;
                                setFormData({
                                  ...formData,
                                  time_start: newTimeStart,
                                });

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();
                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              required
                            />
                            <span className="col-md-2 mt-2"><hr style={{ height: "2px", background: "black", width: "20px" }} /></span>
                            <input
                              className='form-control col-md-5'
                              type="time"
                              value={formData.time_finish}
                              onChange={(e) => {
                                const newTimeFinish = e.target.value;
                                setFormData({
                                  ...formData,
                                  time_finish: newTimeFinish,
                                });

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();
                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              required
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Kota/Kabupaten harus dipilih.
                          </Form.Control.Feedback>
                        </div>

                        <div className='form-group'>
                          <label>Kapasitas<span className="text-danger">*</span></label>
                          <input
                            type='text'
                            className='form-control'
                            value={formData.capacity}
                            required
                            onChange={(e) => {
                              const { value } = e.target;
                              // Hanya karakter angka
                              const numericValue = value.replace(/\D/g, "");
                              setFormData((prevState) => ({
                                ...prevState,
                                capacity: numericValue,
                              }));

                              const form = e.currentTarget.form;
                              const isValid = form.checkValidity();

                              setValidated(isValid);

                              if (isValid) {
                                form.classList.remove("was-validated");
                              } else {
                                form.classList.add("was-validated");
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Kapasitas harus diisi.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-slot-assessment`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" disabled={!validated || formData.assessor_id === "" || formData.students_id === ""}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === "Ubah" ? (
                  <form className="form-valide" ref={formRef} noValidate onSubmit={handleEditSubmit}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className='form-group'>
                          <label>Kota/Kabupaten<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.city}
                            name="city"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Pilih Kota/Kabupaten</option>
                            {Array.isArray(kota) && kota.map((kotas) => (
                              <option
                                key={kotas.id}
                                value={kotas.id}
                              >
                                {kotas.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Kota/Kabupaten harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group'>
                          <label>Lokasi<span className="text-danger">*</span></label>
                          <select
                            className='form-control'
                            value={formData.location}
                            name="location"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Pilih Lokasi</option>
                            {Array.isArray(lokasi) && lokasi.map((locations) => (
                              <option
                                key={locations.id}
                                value={locations.id}
                              >
                                {locations.name}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Lokasi harus dipilih.
                          </Form.Control.Feedback>
                        </div>
                        <div className='form-group'>
                          <p className="mb-1">Hari<span className="text-danger">*</span></p>
                          <select
                            className='form-control'
                            value={formData.day}
                            name="day"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Pilih Hari</option>
                            {Array.isArray(hari) && hari.map((haris) => (
                              <option
                                key={haris.id}
                                value={haris.id}
                              >
                                {haris.name}
                              </option>
                            ))}
                          </select>
                          {formData.day === "" && touched && (
                            <span
                              className="mb-1 text-danger"
                              style={{ fontSize: "14px" }}
                            >
                              Hari harus diisi.
                            </span>
                          )}
                        </div>

                        <div className='form-group'>
                          <p className="mb-1">Waktu<span className="text-danger">*</span></p>
                          <div className="d-flex">
                            <input
                              className='form-control col-md-5'
                              type="time"
                              value={formData.time_start}
                              onChange={(e) => {
                                const newTimeStart = e.target.value;
                                setFormData({
                                  ...formData,
                                  time_start: newTimeStart,
                                });

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();
                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              required
                            />
                            <span className="col-md-2 mt-2"><hr style={{ height: "2px", background: "black", width: "20px" }} /></span>
                            <input
                              className='form-control col-md-5'
                              type="time"
                              value={formData.time_finish}
                              onChange={(e) => {
                                const newTimeFinish = e.target.value;
                                setFormData({
                                  ...formData,
                                  time_finish: newTimeFinish,
                                });

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();
                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              required
                            />
                          </div>
                          {formData.date === "" && touched && (
                            <span
                              className="mb-1 text-danger"
                              style={{ fontSize: "14px" }}
                            >
                              Tanggal harus diisi.
                            </span>
                          )}
                        </div>

                        <div className='form-group'>
                          <p className="mb-1">Kapasitas<span className="text-danger">*</span></p>
                          <input
                            type='text'
                            className='form-control'
                            value={formData.capacity}
                            required
                            onChange={(e) => {
                              const { value } = e.target;
                              // Hanya karakter angka
                              const numericValue = value.replace(/\D/g, "");
                              setFormData((prevState) => ({
                                ...prevState,
                                capacity: numericValue,
                              }));

                              const form = e.currentTarget.form;
                              const isValid = form.checkValidity();

                              setValidated(isValid);

                              if (isValid) {
                                form.classList.remove("was-validated");
                              } else {
                                form.classList.add("was-validated");
                              }
                            }}
                          />
                          {formData.capacity === "" && touched && (
                            <span
                              className="mb-1 text-danger"
                              style={{ fontSize: "14px" }}
                            >
                              Kapasitas harus diisi.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    {flag === "Detail" ? (
                      ""
                    ) : (
                      <div className="form-group mb-3 d-flex">
                        <div className="col-xl-6">
                          <Link className="btn btn-light w-100" to={`/daftar-slot-assessment`}>
                            Batal
                          </Link>
                        </div>
                        <div className="col-xl-6">
                          <button type="submit" className="btn btn-primary w-100" disabled={!validated || formData.assessor_id === "" || formData.students_id === ""}>
                            Simpan Perubahan
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default FormSlotAssessment;
