import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { addMethod } from "yup";

const TambahProvinsiKabupaten = () => {
  const location = useLocation();
  const { id, flag } = useParams();
  const router = useNavigate();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
  });
  const [validated, setValidated] = useState(false);
  const [touched, setTouched] = useState(false);
  const formRef = useRef(null);
  const [buku, setBuku] = useState([]);

  // Get Detail
  const fetchData = async (id) => {
    try {
      const res = await GET(`province/${id}`);
      setEditFormData(res?.data);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const dataPromise = id ? fetchData(id) : Promise.resolve();
        await Promise.all([dataPromise]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAllData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Membatasi hingga 4 digit
    // if (value.length > 4) return;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // TAMBAH
  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = {
      name: formData.name,
    };
    console.log("requestBody", params);

    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan perubahan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let res;
      res = await POST(`province`, params);
      if (res) {
        Swal.fire({
          icon: "success",
          title: res.message,
        }).then(() => {
          Swal.close();
          navigate(`/daftar-provinsi`);
        });
      }
    }
  };

  // EDIT
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const params = {
      name: editFormData.name,
    };
    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan perubahan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let res;
      res = await PATCH(`province/${id}`, params);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Provinsi berhasil diubah!",
        }).then(() => {
          Swal.close();
          navigate(`/daftar-provinsi`);
        });
      }
    }
  };

  const handleSelectChange = (selectedOptions, name) => {
    const matchedItem = name === "book_package_id" ? buku.find((item) => item.id === selectedOptions.value) : null;

    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOptions.value, // Update the selected field
      ...(name === "book_package_id" && {
        amount: matchedItem ? matchedItem.price * (prevData.qty || 0) : 0, // Recalculate amount
      }),
    }));

    setEditFormData((prevData) => ({
      ...prevData,
      [name]: {
        id: selectedOptions.value,
        name: selectedOptions.label,
      },
      ...(name === "book_package_id" && {
        price: matchedItem ? matchedItem.price : 0,
      }),
    }));

    setTouched(true);

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const selectStyle = {
    control: (provided) => ({
      ...provided,
      // height: '50px',
      minHeight: "55px",
      borderColor: "#D9D9D9", // Border color
      boxShadow: "none", // Remove shadow
      "&:hover": { borderColor: "#1890FF" }, // Hover effect
    }),
  };

  return (
    <Fragment>
      <PageTitle activeMenu={`${flag} Provinsi`} motherMenu="Daftar Provinsi" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Provinsi</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/daftar-alokasi-pengeluaran-infaq`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {/* TAMBAH */}
                {flag === "Tambah" ? (
                  <form className="form-valide" ref={formRef}>
                    <div className="row">
                      <div className="d-flex flex-wrap">
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Nama<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="name" type="text" value={formData.name} onChange={handleInputChange} placeholder="Nama" />
                          <Form.Control.Feedback type="invalid">Nama harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-provinsi`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={handleSubmit}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === "Edit" ? (
                  <form className="form-valide" ref={formRef}>
                    <div className="row">
                      <div className="d-flex flex-wrap">
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Nama<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="name" type="text" value={editFormData.name} onChange={handleInputChange} placeholder="Provinsi" />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-provinsi`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={handleEditSubmit}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TambahProvinsiKabupaten;
