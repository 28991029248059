import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { addMethod } from "yup";

const FormKabupaten = () => {
  const location = useLocation();
  const { id, flag } = useParams();
  const [province, setProvince] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    province_id: "",
    rajaongkir_id: 0,
    postal_code: "",
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    province_id: "",
    rajaongkir_id: 0,
    postal_code: "",
  });
  const [validated, setValidated] = useState(false);
  const [touched, setTouched] = useState(false);
  const formRef = useRef(null);

  // Get Detail
  const fetchData = async (id) => {
    try {
      const res = await GET(`district/kota/${id}`);
      setEditFormData({
        name: res?.data?.name,
        rajaongkir_id: res?.data?.rajaongkir_id,
        postal_code: res?.data?.postal_code,
        province_id: res?.data?.province, // Directly use the object { id, name }
        // province_id: {
        //   id: res?.data?.province?.id, // Accessing id directly
        //   name: res?.data?.province?.name, // Accessing name directly
        // },
      });
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  console.log("ppp", editFormData);

  //GET PROVINCE
  const fetchDataProvince = async () => {
    const res = await GET(`province?size=-1`);
    setProvince(res?.data);
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const dataProvince = fetchDataProvince();
        const dataPromise = id ? fetchData(id) : Promise.resolve();
        await Promise.all([dataPromise, dataProvince]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAllData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Membatasi hingga 4 digit
    // if (value.length > 4) return;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // TAMBAH
  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = {
      name: editFormData.name,
      province_id: editFormData.province_id, // Use `id` if it's an object
      rajaongkir_id: editFormData.rajaongkir_id,
      postal_code: editFormData.postal_code,
    };
    console.log(params);

    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan perubahan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let res;
      res = await POST(`district`, params);
      if (res) {
        Swal.fire({
          icon: "success",
          title: res.message,
        }).then(() => {
          Swal.close();
          navigate(`/daftar-kabupaten`);
        });
      }
    }
  };

  // EDIT
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const params = {
      name: editFormData.name,
      province_id: editFormData.province_id?.id || editFormData.province_id, // Use `id` if it's an object
      rajaongkir_id: editFormData.rajaongkir_id,
      postal_code: editFormData.postal_code,
    };
    console.log(params);
    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan perubahan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let res;
      res = await PATCH(`district/${id}`, params);
      if (res) {
        Swal.fire({
          icon: "success",
          title: res.message,
        }).then(() => {
          Swal.close();
          navigate(`/daftar-kabupaten`);
        });
      }
    }
  };

  const handleSelectChange = (selectedOptions, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOptions.value, // Update the selected field
    }));

    // setEditFormData((prevData) => ({
    //   ...prevData,
    //   [name]: {
    //     id: selectedOptions.value,
    //     name: selectedOptions.label,
    //   },
    // }));

    setEditFormData((prevData) => ({
      ...prevData,
      [name]: selectedOptions.value, // Only set the ID for province_id
    }));

    setTouched(true);

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const selectStyle = {
    control: (provided) => ({
      ...provided,
      // height: '50px',
      minHeight: "55px",
      borderColor: "#D9D9D9", // Border color
      boxShadow: "none", // Remove shadow
      "&:hover": { borderColor: "#1890FF" }, // Hover effect
    }),
  };

  return (
    <Fragment>
      <PageTitle activeMenu={`${flag} Kabupaten`} motherMenu="Daftar Provinsi" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Kabupaten</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/daftar-alokasi-pengeluaran-infaq`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {/* TAMBAH */}
                {flag === "Tambah" ? (
                  <form className="form-valide" ref={formRef}>
                    <div className="row">
                      <div className="d-flex flex-wrap">
                        {province.length > 0 && (
                          <div className="form-group col-md-6 col-12">
                            <>
                              <label>
                                Provinsi<span className="text-danger">*</span>
                              </label>
                              <Select
                                name="province_id"
                                options={province.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                }))}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, "province_id")}
                                className="basic-single"
                                classNamePrefix="select"
                                required
                                defaultValue={
                                  formData.province_id
                                    ? province
                                        .filter((item) => item.id === formData?.province_id.id) // Adjusted for single value
                                        .map((item) => ({ value: item.id, label: item.name }))[0]
                                    : null // Get the first object
                                }
                                // defaultValue={editFormData.province_id ? { value: editFormData.province_id.id, label: editFormData.province_id.name } : null}
                                styles={selectStyle}
                              />
                            </>
                          </div>
                        )}
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Nama<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="name" type="text" value={formData.name} onChange={handleInputChange} placeholder="Nama" />
                          <Form.Control.Feedback type="invalid">Nama harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Raja Ongkir ID<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="rajaongkir_id" type="text" value={formData.rajaongkir_id} onChange={handleInputChange} placeholder="Raja Ongkir ID" />
                          <Form.Control.Feedback type="invalid">Nama harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Kode Pos<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="postal_code" type="text" value={formData.postal_code} onChange={handleInputChange} placeholder="Kode Pos" />
                          <Form.Control.Feedback type="invalid">Nama harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-kabupaten`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={handleSubmit}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === "Edit" ? (
                  <form className="form-valide" ref={formRef}>
                    <div className="row">
                      <div className="d-flex flex-wrap">
                        {province.length > 0 && (
                          <div className="form-group col-md-6 col-12">
                            <>
                              <label>
                                Provinsi<span className="text-danger">*</span>
                              </label>
                              <Select
                                name="province_id"
                                options={province.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                }))}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, "province_id")}
                                className="basic-single"
                                classNamePrefix="select"
                                required
                                // defaultValue={
                                //   editFormData.province_id
                                //     ? province
                                //         .filter((item) => item.id === editFormData?.province_id.id) // Adjusted for single value
                                //         .map((item) => ({ value: item.id, label: item.name }))[0]
                                //     : null // Get the first object
                                // }
                                defaultValue={editFormData.province_id ? { value: editFormData.province_id.id, label: editFormData.province_id.name } : null}
                                styles={selectStyle}
                              />
                            </>
                          </div>
                        )}
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Nama<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" required name="name" type="text" value={editFormData.name} onChange={handleInputChange} placeholder="Name" />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Raja Ongkir ID<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" required name="rajaongkir_id" type="text" value={editFormData.rajaongkir_id} onChange={handleInputChange} placeholder="Raja Ongkir ID" />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Kode Pos<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" required name="postal_code" type="text" value={editFormData.postal_code} onChange={handleInputChange} placeholder="Kode Pos" />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-kabupaten`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={handleEditSubmit}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormKabupaten;
