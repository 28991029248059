import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { GET, PATCH, POST } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";

const FormTentang = () => {
  const location = useLocation();
  const { id, flag } = useParams();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    type: "",
    title: "",
    image: "",
    image_caption: "",
    description: "",
    updated_at: "",
    updated_by: "",
    status: "",
  })
  const [file, setFile] = useState([]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const ALLOWED_FILE_FORMATS = ['image/jpeg', 'image/png', 'image/gif'];
  const [validated, setValidated] = useState(false);
  const [tipe, setTipe] = useState([]);

  const fetchDataTipe = async () => {
    const res = await GET(`master-data/type?type=tentang`);
    setTipe(res?.data);
  };

  const fetchData = async () => {
    const res = await GET(`/about/${id}`);

    const updatedAtDate = res.data.updated_at ? new Date(res.data.updated_at) : null;
    const formattedDate = updatedAtDate
      ? updatedAtDate.toLocaleDateString('id-ID', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      })
      : '';

    setFormData({
      type: res.data?.type,
      title: res.data?.title,
      image: res.data?.image,
      image_caption: res.data?.image_caption,
      description: res.data?.description,
      updated_at: formattedDate,
      updated_by: res.data?.updated_by,
      status: res.data?.status,
    });

    if (res.data?.image) {
      const response = await GET(
        `/files/${res.data?.image}/download`,
        {},
        { responseType: "arraybuffer" }
      );
      const blob = new Blob([response], { type: "image/jpeg" });
      const reader = new FileReader();
      reader.onload = () => {
        const base64Image = reader.result;
        setImagePreviewUrl(base64Image);
      };
      reader.readAsDataURL(blob);
    }
  };

  useEffect(() => {
    fetchDataTipe()
    if (id) {
      fetchData();
    }
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.size > MAX_FILE_SIZE) {
      Swal.fire({
        icon: 'error',
        title: 'Ukuran file terlalu besar. Ukuran maksimum adalah 2MB.',
      });
      return;
    }

    const fileExtension = selectedFile.type;
    if (!ALLOWED_FILE_FORMATS.includes(fileExtension)) {
      // Format file tidak diizinkan
      Swal.fire({
        icon: 'error',
        title: 'Format file tidak diizinkan. Hanya file JPG, JPEG, PNG, dan GIF yang diperbolehkan.',
      });
      return;
    }

    setFile(selectedFile);
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreviewUrl(reader.result);
    };
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
  };
  const handleRemoveFile = () => {
    setFile('');
    setImagePreviewUrl(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { type, title, image, image_caption, description } = formData;
    const requestBody = {
      type,
      title,
      image,
      image_caption,
      description,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (file.length !== 0) {
      // Upload the new file
      const formDataObject = new FormData();
      formDataObject.append("type", "about");
      formDataObject.append("file", file);

      const fileUploadResponse = await POST(
        `/files`,
        formDataObject,
        undefined,
        { "Content-Type": "multipart/form-data" }
      );

      // Set the new file's ID
      const newPictureFileId = fileUploadResponse?.file.id;
      requestBody.image = newPictureFileId;
    }

    const res = await POST(`/about`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil membuat tentang!",
      }).then(() => {
        Swal.close();
        setFormData({
          type: "",
          title: "",
          image: "",
          image_caption: "",
          description: "",
        });
        setFile('');
        setImagePreviewUrl('');
        router("/tentang-yayasan-dan-aplikasi")
      });
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const { type, title, image, image_caption, description } = formData;
    const requestBody = {
      type,
      title,
      image,
      image_caption,
      description,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (file.length !== 0) {
      // Upload the new file
      const formDataObject = new FormData();
      formDataObject.append("type", "about");
      formDataObject.append("file", file);

      const fileUploadResponse = await POST(
        `/files`,
        formDataObject,
        undefined,
        { "Content-Type": "multipart/form-data" }
      );

      // Set the new file's ID
      const newPictureFileId = fileUploadResponse?.file.id;
      requestBody.image = newPictureFileId;
    }

    const res = await PATCH(`/about/${id}`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil mengubah tentang!",
      }).then(() => {
        Swal.close();
        setFormData({
          type: "",
          title: "",
          image: "",
          image_caption: "",
          description: "",
        });
        setFile('');
        setImagePreviewUrl('');
        router("/tentang-yayasan-dan-aplikasi")
      });
    }
  };

  const getStatusLabel = (status) => {
    if (status === 1) {
      return "Aktif";
    } else if (status === 0) {
      return "Tidak Aktif";
    } else if (status === 2) {
      return "Buka";
    } else if (status === -1) {
      return "Dihapus";
    } else {
      return status;
    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu={`${flag} Tentang Yayasan dan Aplikasi`}
        motherMenu="Tentang Yayasan dan Aplikasi"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} laporan</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/tentang-yayasan-dan-aplikasi`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {flag === 'Tambah' ? (
                  <form className="form-valide">
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className='form-group col-md-6 col-12'>
                        <label>Tipe<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                          disabled={flag === "Detail"}
                        >
                          <option value="">Pilih Tipe</option>
                          {Array.isArray(tipe) && tipe.map((type) => (
                            <option
                              key={type.id}
                              value={type.id}
                            >
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Judul<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="title"
                          type='text'
                          value={formData.title}
                          onChange={handleChange}
                          placeholder="Masukkan judul cerita"
                          minLength="5"
                          maxLength="30"
                          required
                          disabled={flag === "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          Judul harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <label>Gambar<span className="text-danger">*</span></label>
                        {/* {file.length != 0 && ( */}
                        <div className="d-flex">
                          {flag !== "Detail" && (
                            <>
                              <button type="button" className="btn btn-primary" disabled={flag === "Detail"} onClick={() => fileInputRef.current.click()}>
                                Pilih File
                              </button>
                              {flag === "Ubah" && !file.name ? (
                                <></>
                              ) : (
                                <span className="input-group-text">
                                  {file.length != 0 ? file?.name?.length > 25 ? `${file.name.slice(0, 25)}...` : file.name : "Tidak ada file yang dipilih..."}
                                </span>
                              )}
                            </>
                          )}
                          <input
                            className="d-none"
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept=".jpg, .jpeg, .png, .gif"
                          // style={{ maxWidth: "230px" }}
                          />
                        </div>
                        {/* )} */}
                        {imagePreviewUrl && (
                          <>
                            <div>
                              <img
                                src={imagePreviewUrl}
                                style={{ maxHeight: "400px" }}
                                className="card-img-top mt-2"
                                alt="..."
                              />
                            </div>
                            {flag != "Detail" && (
                              <div className="mt-2">
                                <button type='form-control' className="btn btn-danger col-6"
                                  onClick={handleRemoveFile}
                                >
                                  Hapus
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {imagePreviewUrl !== null && (
                        <div className='form-group col-md-6 col-12'>
                          <label>Caption Gambar<span className="text-danger">*</span></label>
                          <input
                            className='form-control'
                            name="image_caption"
                            type='text'
                            value={formData.image_caption}
                            onChange={handleChange}
                            placeholder="Masukkan caption gambar"
                            minLength="5"
                            maxLength="30"
                            required
                            disabled={flag === "Detail"}
                          />
                          <Form.Control.Feedback type="invalid">
                            Caption gambar harus diisi minimal 5 karakter.
                          </Form.Control.Feedback>
                        </div>
                      )}
                      <div className='form-group col-md-12 col-12'>
                        <label>Deskripsi<span className="text-danger">*</span></label>
                        <textarea
                          className='form-control'
                          name="description"
                          rows="8"
                          value={formData.description}
                          onChange={handleChange}
                          placeholder="Masukkan deskripsi"
                          minLength="5"
                          maxLength="600"
                          required
                          disabled={flag === "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          Deskripsi harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                      {flag === "Detail" && (
                        <>
                          <div className='form-group col-md-6 col-1'>
                            <label>Tanggal Update<span className="text-danger">*</span></label>
                            <input
                              className='form-control'
                              type='text'
                              value={formData.updated_at}
                              disabled
                            />
                          </div>
                          <div className='form-group col-md-6 col-12'>
                            <label>Updated By</label>
                            <input
                              className='form-control'
                              type='text'
                              value={formData.updated_by}
                              required
                              disabled
                            />
                          </div>
                          <div className='form-group col-md-6 col-12'>
                            <label>Status</label>
                            <input
                              className='form-control'
                              type='text'
                              value={getStatusLabel(formData.status)}
                              required
                              disabled
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {flag !== "Detail" && (
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/tentang-yayasan-dan-aplikasi`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={flag === "Tambah" ? handleSubmit : handleEditSubmit}
                        // disabled={!validated}
                        >
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  )}
                </form>
                ) : flag === "Ubah" ? (
                  <form className="form-valide">
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className='form-group col-md-6 col-12'>
                        <label>Tipe<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                          disabled={flag === "Detail"}
                        >
                          <option value="">Pilih Tipe</option>
                          {Array.isArray(tipe) && tipe.map((type) => (
                            <option
                              key={type.id}
                              value={type.id}
                            >
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Judul<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="title"
                          type='text'
                          value={formData.title}
                          onChange={handleChange}
                          placeholder="Masukkan judul cerita"
                          minLength="5"
                          maxLength="30"
                          required
                          disabled={flag === "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          Judul harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <label>Gambar<span className="text-danger">*</span></label>
                        {/* {file.length != 0 && ( */}
                        <div className="d-flex">
                          {flag !== "Detail" && (
                            <>
                              <button type="button" className="btn btn-primary" disabled={flag === "Detail"} onClick={() => fileInputRef.current.click()}>
                                Pilih File
                              </button>
                              {flag === "Ubah" && !file.name ? (
                                <></>
                              ) : (
                                <span className="input-group-text">
                                  {file.length != 0 ? file?.name?.length > 25 ? `${file.name.slice(0, 25)}...` : file.name : "Tidak ada file yang dipilih..."}
                                </span>
                              )}
                            </>
                          )}
                          <input
                            className="d-none"
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept=".jpg, .jpeg, .png, .gif"
                          // style={{ maxWidth: "230px" }}
                          />
                        </div>
                        {/* )} */}
                        {imagePreviewUrl && (
                          <>
                            <div>
                              <img
                                src={imagePreviewUrl}
                                style={{ maxHeight: "400px" }}
                                className="card-img-top mt-2"
                                alt="..."
                              />
                            </div>
                            {locations != "Detail" && (
                              <div className="mt-2">
                                <button type='form-control' className="btn btn-danger col-6"
                                  onClick={handleRemoveFile}
                                >
                                  Hapus
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {imagePreviewUrl !== null && (
                        <div className='form-group col-md-6 col-12'>
                          <label>Caption Gambar<span className="text-danger">*</span></label>
                          <input
                            className='form-control'
                            name="image_caption"
                            type='text'
                            value={formData.image_caption}
                            onChange={handleChange}
                            placeholder="Masukkan caption gambar"
                            minLength="5"
                            maxLength="30"
                            required
                            disabled={flag === "Detail"}
                          />
                          <Form.Control.Feedback type="invalid">
                            Caption gambar harus diisi minimal 5 karakter.
                          </Form.Control.Feedback>
                        </div>
                      )}
                      <div className='form-group col-md-12 col-12'>
                        <label>Deskripsi<span className="text-danger">*</span></label>
                        <textarea
                          className='form-control'
                          name="description"
                          rows="8"
                          value={formData.description}
                          onChange={handleChange}
                          placeholder="Masukkan deskripsi"
                          minLength="5"
                          maxLength="600"
                          required
                          disabled={flag === "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          Deskripsi harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                      {flag === "Detail" && (
                        <>
                          <div className='form-group col-md-6 col-1'>
                            <label>Tanggal Update<span className="text-danger">*</span></label>
                            <input
                              className='form-control'
                              type='text'
                              value={formData.updated_at}
                              disabled
                            />
                          </div>
                          <div className='form-group col-md-6 col-12'>
                            <label>Updated By</label>
                            <input
                              className='form-control'
                              type='text'
                              value={formData.updated_by}
                              required
                              disabled
                            />
                          </div>
                          <div className='form-group col-md-6 col-12'>
                            <label>Status</label>
                            <input
                              className='form-control'
                              type='text'
                              value={getStatusLabel(formData.status)}
                              required
                              disabled
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {flag !== "Detail" && (
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/tentang-yayasan-dan-aplikasi`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={flag === "Tambah" ? handleSubmit : handleEditSubmit}
                        // disabled={!validated}
                        >
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  )}
                </form>
                ) : flag === "Detail" ? (
                  <form className="form-valide">
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className='form-group col-md-6 col-12'>
                        <label>Tipe<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                          disabled={flag === "Detail"}
                        >
                          <option value="">Pilih Tipe</option>
                          {Array.isArray(tipe) && tipe.map((type) => (
                            <option
                              key={type.id}
                              value={type.id}
                            >
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='form-group col-md-6 col-12'>
                        <label>Judul<span className="text-danger">*</span></label>
                        <input
                          className='form-control'
                          name="title"
                          type='text'
                          value={formData.title}
                          onChange={handleChange}
                          placeholder="Masukkan judul cerita"
                          minLength="5"
                          maxLength="30"
                          required
                          disabled={flag === "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          Judul harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <label>Gambar<span className="text-danger">*</span></label>
                        {/* {file.length != 0 && ( */}
                        <div className="d-flex">
                          {flag !== "Detail" && (
                            <>
                              <button type="button" className="btn btn-primary" disabled={flag === "Detail"} onClick={() => fileInputRef.current.click()}>
                                Pilih File
                              </button>
                              {flag === "Ubah" && !file.name ? (
                                <></>
                              ) : (
                                <span className="input-group-text">
                                  {file.length != 0 ? file?.name?.length > 25 ? `${file.name.slice(0, 25)}...` : file.name : "Tidak ada file yang dipilih..."}
                                </span>
                              )}
                            </>
                          )}
                          <input
                            className="d-none"
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept=".jpg, .jpeg, .png, .gif"
                          // style={{ maxWidth: "230px" }}
                          />
                        </div>
                        {/* )} */}
                        {imagePreviewUrl && (
                          <>
                            <div>
                              <img
                                src={imagePreviewUrl}
                                style={{ maxHeight: "400px" }}
                                className="card-img-top mt-2"
                                alt="..."
                              />
                            </div>
                            {flag != "Detail" && (
                              <div className="mt-2">
                                <button type='form-control' className="btn btn-danger col-6"
                                  onClick={handleRemoveFile}
                                >
                                  Hapus
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {imagePreviewUrl !== null && (
                        <div className='form-group col-md-6 col-12'>
                          <label>Caption Gambar<span className="text-danger">*</span></label>
                          <input
                            className='form-control'
                            name="image_caption"
                            type='text'
                            value={formData.image_caption}
                            onChange={handleChange}
                            placeholder="Masukkan caption gambar"
                            minLength="5"
                            maxLength="30"
                            required
                            disabled={flag === "Detail"}
                          />
                          <Form.Control.Feedback type="invalid">
                            Caption gambar harus diisi minimal 5 karakter.
                          </Form.Control.Feedback>
                        </div>
                      )}
                      <div className='form-group col-md-12 col-12'>
                        <label>Deskripsi<span className="text-danger">*</span></label>
                        <textarea
                          className='form-control'
                          name="description"
                          rows="8"
                          value={formData.description}
                          onChange={handleChange}
                          placeholder="Masukkan deskripsi"
                          minLength="5"
                          maxLength="600"
                          required
                          disabled={flag === "Detail"}
                        />
                        <Form.Control.Feedback type="invalid">
                          Deskripsi harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                      {flag === "Detail" && (
                        <>
                          <div className='form-group col-md-6 col-1'>
                            <label>Tanggal Update<span className="text-danger">*</span></label>
                            <input
                              className='form-control'
                              type='text'
                              value={formData.updated_at}
                              disabled
                            />
                          </div>
                          <div className='form-group col-md-6 col-12'>
                            <label>Updated By</label>
                            <input
                              className='form-control'
                              type='text'
                              value={formData.updated_by}
                              required
                              disabled
                            />
                          </div>
                          <div className='form-group col-md-6 col-12'>
                            <label>Status</label>
                            <input
                              className='form-control'
                              type='text'
                              value={getStatusLabel(formData.status)}
                              required
                              disabled
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {flag !== "Detail" && (
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/tentang-yayasan-dan-aplikasi`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={locations === "Tambah" ? handleSubmit : handleEditSubmit}
                        // disabled={!validated}
                        >
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  )}
                </form>
                ) : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormTentang