import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Badge, Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET } from '../../../services/AxiosService';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";

const PesananBuku = () => {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [tipePelajar, setTipePelajar] = useState([])
  const [buku, setBuku] = useState([])
  const [statusPesanan, setStatusPesanan] = useState([])
  const [kota, setKota] = useState([]);
  const [selectedKota, setSelectedKota] = useState('');

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedJudul, setSelectedJudul] = useState('');
  const [selectedTipePelajar, setSelectedTipePelajar] = useState('');
  const [selectedStatusPesanan, setSelectedStatusPesanan] = useState('');
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const fetchData = async (page, perPage, sortField, sortOrder, search, buku, tipePelajar, statusPesanan, dateFrom, dateTo, kotaKabupaten) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      heading: buku,
      type: tipePelajar,
      status: statusPesanan,
      city: kotaKabupaten,
      start_date: dateFrom,
      end_date: dateTo
    };
    const res = await GET(`/book-ordering`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };
  const fetchTipePelajar = async () => {
    const res = await GET(`/master-data/type?type=tipe pelajar`);
    setTipePelajar(res?.data);
  }
  const fetchBuku = async () => {
    const res = await GET(`/book?size=-1`);
    setBuku(res?.data);
  }
  const fetchStatusPesanan = async () => {
    const res = await GET(`/master-data/type?type=Status Pesanan Buku`);
    setStatusPesanan(res?.data);
  }
  const fetchKotaKabupaten = async () => {
    const res = await GET(`master-data/type?type=Kota Kabupaten`);
    setKota(res?.data);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedJudul, selectedTipePelajar, selectedStatusPesanan, dateFrom, dateTo, selectedKota);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    localStorage.setItem('searchTerm', e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };
  const handleDateFromChange = (date) => {
    const formattedDate = date?.toLocaleDateString('en-CA');
    setDateFrom(formattedDate);
  };
  const handleDateToChange = (date) => {
    const formattedDate = date?.toLocaleDateString('en-CA');
    setDateTo(formattedDate);
  };

  useEffect(() => {
    fetchTipePelajar();
    fetchBuku();
    fetchStatusPesanan();
    fetchKotaKabupaten();
  }, [])
  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedJudul, selectedTipePelajar, selectedStatusPesanan, dateFrom, dateTo, selectedKota]);

  function currencyFormat(num) {
    num = parseFloat(num);
    return num
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      .replace(/\.00$/, "");
  }
  const columns = [
    {
      name: 'No Pesanan',
      selector: row => row.no,
    },
    {
      name: 'Judul',
      selector: row => row.judul,
      cell: (row) => (
        <div>
          {row.book_detail?.map((book, index) => (
            <span key={book.id}>
              {book.book_name}{index < row.book_detail?.length - 1 ? ', ' : ''}
            </span>
          ))}
        </div>
      )
    },
    {
      name: 'Nama Pelajar',
      selector: row => row.user?.name,
      cell: row =>
        <button onClick={() => navigate(`/${row.students[0]?.id}/form-pelajar`, { state: { from: 'Detail' } })} style={{ background: "transparent", border: "none" }}>
          <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user?.name}</span>
        </button>,
    },
    {
      name: 'Tipe Pelajar',
      selector: row => row.students?.type_user,
      cell: (row) => (
        <div>{row.students[0]?.type_user}</div>
      )
    },
    {
      name: 'Tipe Pesanan',
      selector: row => row.type_orders?.name,
    },
    {
      name: 'Tujuan Pengiriman',
      selector: row => row.delivery?.delivery_name,
    },
    {
      name: 'Total yang Dibayar (Rp)',
      selector: row => currencyFormat(row.total_payment),
    },
    {
      name: 'No Pesanan Percetakan',
      selector: row => row.print_order_no,
    },
    {
      name: 'Waktu Bayar',
      selector: row => {
        const date = new Date(row.paid_at);
        const formattedDate = date?.toLocaleDateString('id-ID', {
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        });
        const formattedTime = date?.toLocaleTimeString('id-ID', {
          hour: '2-digit',
          minute: '2-digit'
        });
        return `${formattedDate} ${formattedTime}`;
      },
    },
    {
      name: 'Status Pesanan',
      selector: row => row.status.name,
      width: "200px",
      cell: (row) => (
        <Badge as="a" variant="outline-success badge-rounded">
          {row.status?.name}
        </Badge>
      )
    },
    {
      name: 'Aksi',
      selector: row => row.id,
      width: "130px",
      cell: (row) => (
        <div>
          <button
            className="btn btn-info shadow btn-xs sharp"
            title="Edit"
            onClick={() => navigate(`/${row.id}/form-pesanan-buku/Detail`)}
          >
            <i className="fa fa-eye"></i>
          </button>
          <Link
            className="btn btn-danger shadow btn-xs sharp mx-2"
            title="Delete"
          // onClick={() => handleDeleteClick(row.id)}
          >
            <i className="fa fa-trash"></i>
          </Link>
        </div>
      ),
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleRowSelected = (state) => {
    const selectedIds = state.selectedRows.map(row => row.id);
    setSelectedRows(selectedIds);
  };

  const handleUbahPesananClick = () => {
    if (selectedRows.length > 0) {
      navigate(`/${selectedRows}/form-pesanan-buku/Edit`);
    }
  };

  const handleSelectTipePelajar = (e) => {
    setSelectedTipePelajar(e.target.getAttribute('value'));
  };
  const handleSelectJudul = (e) => {
    setSelectedJudul(e.target.getAttribute('value'));
  };
  const handleSelectStatusPesanan = (e) => {
    setSelectedStatusPesanan(e.target.getAttribute('value'));
  };
  const handleSelectKota = (e) => {
    setSelectedKota(e.target.getAttribute('value'));
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Pesanan Buku</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4">
              <div className='d-flex flex-wrap px-4'>
                <Dropdown>
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey", borderRadius: "0" }} id="dropdown-basic">
                    {selectedJudul?.length > 0 ? selectedJudul : "Semua Judul"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectJudul}>
                      Semua Judul
                    </Dropdown.Item>
                    {Array.isArray(buku) && (buku).map((bukus) => (
                      <Dropdown.Item key={bukus.id} value={bukus.name} onClick={handleSelectJudul}>
                        {bukus.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey", borderRadius: "0" }} id="dropdown-basic">
                    {selectedTipePelajar?.length > 0 ? selectedTipePelajar : "Semua Tipe Pelajar"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectTipePelajar}>
                      Semua Tipe Pelajar
                    </Dropdown.Item>
                    {Array.isArray(tipePelajar) && (tipePelajar).map((pelajarTipe) => (
                      <Dropdown.Item key={pelajarTipe.id} value={pelajarTipe.name} onClick={handleSelectTipePelajar}>
                        {pelajarTipe.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey", borderRadius: "0" }} id="dropdown-basic">
                    {selectedStatusPesanan?.length > 0 ? selectedStatusPesanan : "Semua Status Pesanan"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectStatusPesanan}>
                      Semua Status Pesanan
                    </Dropdown.Item>
                    {Array.isArray(statusPesanan) && (statusPesanan).map((statusPesanans) => (
                      <Dropdown.Item key={statusPesanans.id} value={statusPesanans.name} onClick={handleSelectStatusPesanan}>
                        {statusPesanans.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey", borderRadius: "0" }} id="dropdown-basic">
                    {selectedKota?.length > 0 ? selectedKota : "Semua Kota/Kabupaten"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectKota}>
                      Semua Kota/Kabupaten
                    </Dropdown.Item>
                    {Array.isArray(kota) && (kota).map((kotas) => (
                      <Dropdown.Item key={kotas.id} value={kotas.name} onClick={handleSelectKota}>
                        {kotas.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* <div className="d-flex align-self-baseline px-4"> */}
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end px-4">
                {/* <div style={{ maxHeigh}}> */}
                  {/* <button className="btn btn-primary mr-2" onClick={handleUbahPesananClick} disabled={selectedRows.length === 0}> */}
                  <button className="btn btn-primary mr-2" style={{boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', fontWeight: 'bold',transition: 'box-shadow 0.2s, transform 0.2s',color: 'white',}} onClick={handleUbahPesananClick} disabled={selectedRows.length === 0}>
                    Ubah Pesanan Buku
                  </button>
                {/* </div> */}
                <div className="input-group search-area d-xl-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari Buku"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex px-4 mt-2">
              <DatePicker className='form-control mr-1' value={dateFrom || ''} style={{ maxHeight: "45px" }} onChange={handleDateFromChange} dateFormat="yyyy-MM-dd" placeholderText='Waktu Registrasi (from)' />
              <DatePicker className='form-control' value={dateTo || ''} style={{ maxHeight: "45px" }} onChange={handleDateToChange} dateFormat="yyyy-MM-dd" placeholderText='Waktu Registrasi (to)' />
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  selectableRows
                  onSelectedRowsChange={handleRowSelected}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default PesananBuku