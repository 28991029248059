import React, { useState } from "react";
/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";
import Orders from "./components/Dashboard/Orders";
import OrderId from "./components/Dashboard/OrderId";
import GeneralCustomers from "./components/Dashboard/GeneralCustomers";
import Analytics from "./components/Dashboard/Analytics";
import Reviews from "./components/Dashboard/Reviews";
import Task from "./components/Dashboard/Task";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import EcomCustomers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import RechartJs from "./components/charts/rechart";
import ChartJs from "./components/charts/Chartjs";
// import Chartist from './components/charts/chartist'
import SparklineChart from "./components/charts/Sparkline";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

/// Widget
import Widget from "./pages/Widget";

/// Table
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";

/// Form

import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import CkEditor from "./components/Forms/CkEditor/CkEditor";
import Pickers from "./components/Forms/Pickers/Pickers";
import FormValidation from "./components/Forms/FormValidation/FormValidation";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Todo from "./pages/Todo";

import RoleAkses from "./components/Setting/RBAC/RoleAkses";
import TambahRole from "./components/Setting/RBAC/TambahRole";

import DaftarPendamping from "./components/Pendamping/DaftarPendamping";
import VerifikasiPendamping from "./components/Pendamping/VerifikasiPendamping";
import DetailPendamping from "./components/Pendamping/DetailPendamping";
import DaftarPelajar from "./components/Pelajar/DaftarPelajar";
import EditPelajar from "./components/Pelajar/EditPelajar";
import VerifikasiPelajar from "./components/Pelajar/VerifikasiPelajar";
import DetailPelajar from "./components/Pelajar/DetailPelajar";
import EditPendamping from "./components/Pendamping/EditPendamping";
import DaftarMateri from "./components/Materi/DaftarMateri";
import DaftarKonten from "./components/Konten/DaftarKonten";
import FormKonten from "./components/Konten/FormKonten";
import DaftarRole from "./components/Role/DaftarRole";
import FormRole from "./components/Role/FormRole";
import DaftarPengguna from "./components/Pengguna/DaftarPengguna";
import FormPengguna from "./components/Pengguna/FormPengguna";
import DaftarModule from "./components/Module/DaftarModule";
import FormModule from "./components/Module/FormModule";
import DaftarMasterData from "./components/MasterData/DaftarMasterData";
import FormMasterData from "./components/MasterData/FormMasterData";
import KelolaKelas from "./components/Kelas/KelolaKelas";
import FormKelolaKelas from "./components/Kelas/FormKelolaKelas";
import FormJadwalKelas from "./components/Kelas/FormJadwalKelas";
import JadwalKelas from "./components/Kelas/JadwalKelas";
import JadwalAssessment from "./components/Assessment/JadwalAssessment";
import FormAssessment from "./components/Assessment/FormAssessment";
import DaftarBuku from "./components/Buku/DaftarBuku";
import FormBuku from "./components/Buku/FormBuku";
import PesananBuku from "./components/Buku/PesananBuku";
import FormPesananBuku from "./components/Buku/FormPesananBuku";
import DaftarPeminatKotaLain from "./components/PeminatKotaLain/DaftarPeminatKotaLain";
import FormPeminatKotaLain from "./components/PeminatKotaLain/FormPeminatKotaLain";
import DaftarTemanBelajarRekomendasi from "./components/TemanBelajar/DaftarTemanBelajarRekomendasi";
import FormTemanBelajar from "./components/TemanBelajar/FormTemanBelajar";
import DaftarCeritaHikmah from "./components/CeritaHikmah/DaftarCeritaHikmah";
import FormCeritaHikmah from "./components/CeritaHikmah/FormCeritaHikmah";
import DaftarSaran from "./components/Saran/DaftarSaran";
import DaftarLaporan from "./components/Laporan/DaftarLaporan";
import FormLaporan from "./components/Laporan/FormLaporan";
import TentangYayasanAplikasi from "./components/Tentang/TentangyayasanAplikasi";
import FormTentang from "./components/Tentang/FormTentang";
import DaftarDonasi from "./components/Donasi/DaftarDonasi";
import DaftarAlokasiBuku from "./components/AlokasiBuku/DaftarAlokasiBuku";
import DaftarAlokasiBukuPelajar from "./components/AlokasiBuku/DaftarAlokasiBukuPelajar";
import DaftarAlokasiPengeluaranInfaq from "./components/AlokasiPengeluaranInfaq/DaftarAlokasiPengeluaranInfaq";
import TambahPengeluaran from "./components/AlokasiPengeluaranInfaq/TambahPengeluaran";
import DaftarPaketBuku from "./components/PaketBuku/DaftarPaketBuku";
import FormPaketBuku from "./components/PaketBuku/FormPaketBuku";
import FormPeriode from "./components/MasterDataPeriode/FormPeriode";
import SetProgressModul from "./components/SetCopyProgress/SetProgressModul";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import FormDonasi from "./components/Donasi/FormDonasi";
import DaftarSlotAssessment from "./components/SlotAssessment/DaftarSlotAssessment";
import FormSlotAssessment from "./components/SlotAssessment/FormSlotAssessment";
import TambahConfigMasterData from "./components/MasterData/TambahConfigMasterData";
import DaftarPanduan from "./components/Panduan/DaftarPanduan";
import FormPanduan from "./components/Panduan/FormPanduan";
import DaftarMasterDataConfig from "./components/MasterData/KonfigurasiMasterData";
import DaftarInboxConfig from "./components/Inbox/DaftarInboxConfig";
import FormInbox from "./components/Inbox/FormInbox";
import FormPencapaian from "./components/Pencapaian/FormPencapaian";
import DaftarInbox from "./components/Inbox/DaftarInbox";
import DaftarFormInbox from "./components/Inbox/DaftarFormInbox";
import DaftarPencapaian from "./components/Pencapaian/DaftarPencapaian";
import DaftarPeriode from "./components/MasterDataPeriode/DaftarPeriode";
import FormDonasiAlokasiBukuPelajar from "./components/AlokasiBuku/FormDonasiAlokasiBukuPelajar";
import FormLaporanUbah from "./components/Laporan/FormLaporanUbah";
import DaftarProvinsiKabupaten from "./components/ProvinsiKabupaten/DaftarProvinsiKabupaten";
import TambahProvinsiKabupaten from "./components/ProvinsiKabupaten/TambahProvinsiKabupaten";
import DaftarKabupaten from "./components/Kabupaten/DaftarKabupaten";
import FormKabupaten from "./components/Kabupaten/FormKabupaten";
//import DaftarPencapaian from './components/Pencapaian/DaftarPencapaian';
//import MasterDataConfig from './components/MasterData/DaftarMasterDataConfig';

const Markup = () => {
  // let path = window.location.pathname
  // path = path.split('/')
  // path = path[path.length - 1]
  // let pagePath = path.split('-').includes('page')
  // const [activeEvent, setActiveEvent] = useState(!path)

  const allroutes = [
    /// Dashboard
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },
    { url: "orders", component: <Orders /> },
    { url: "Order-id", component: <OrderId /> },
    { url: "general-customers", component: <GeneralCustomers /> },
    { url: "analytics", component: <Analytics /> },
    { url: "review", component: <Reviews /> },
    { url: "task", component: <Task /> },

    /// Apps
    { url: "app-profile", component: <AppProfile /> },
    { url: "post-details", component: <PostDetails /> },
    { url: "email-compose", component: <Compose /> },
    { url: "email-inbox", component: <Inbox /> },
    { url: "email-read", component: <Read /> },
    { url: "app-calender", component: <Calendar /> },

    /// Chart
    { url: "chart-sparkline", component: <SparklineChart /> },
    { url: "chart-chartjs", component: <ChartJs /> },
    // { url: 'chart-chartist', component: Chartist },
    { url: "chart-apexchart", component: <ApexChart /> },
    { url: "chart-rechart", component: <RechartJs /> },

    /// Bootstrap
    { url: "ui-alert", component: <UiAlert /> },
    { url: "ui-badge", component: <UiBadge /> },
    { url: "ui-button", component: <UiButton /> },
    { url: "ui-modal", component: <UiModal /> },
    { url: "ui-button-group", component: <UiButtonGroup /> },
    { url: "ui-accordion", component: <UiAccordion /> },
    { url: "ui-list-group", component: <UiListGroup /> },
    { url: "ui-card", component: <UiCards /> },
    { url: "ui-carousel", component: <UiCarousel /> },
    { url: "ui-dropdown", component: <UiDropDown /> },
    { url: "ui-popover", component: <UiPopOver /> },
    { url: "ui-progressbar", component: <UiProgressBar /> },
    { url: "ui-tab", component: <UiTab /> },
    { url: "ui-pagination", component: <UiPagination /> },
    { url: "ui-typography", component: <UiTypography /> },
    { url: "ui-grid", component: <UiGrid /> },

    /// Plugin
    { url: "uc-select2", component: <Select2 /> },
    { url: "uc-nestable", component: <Nestable /> },
    { url: "uc-sweetalert", component: <MainSweetAlert /> },
    { url: "uc-toastr", component: <Toastr /> },
    { url: "map-jqvmap", component: <JqvMap /> },
    { url: "uc-lightgallery", component: <Lightgallery /> },

    /// Widget
    { url: "widget-basic", component: <Widget /> },

    /// Shop
    { url: "ecom-product-grid", component: <ProductGrid /> },
    { url: "ecom-product-list", component: <ProductList /> },
    { url: "ecom-product-detail", component: <ProductDetail /> },
    { url: "ecom-product-order", component: <ProductOrder /> },
    { url: "ecom-checkout", component: <Checkout /> },
    { url: "ecom-invoice", component: <Invoice /> },
    { url: "ecom-product-detail", component: <ProductDetail /> },
    { url: "ecom-customers", component: <EcomCustomers /> },

    /// Form

    { url: "form-element", component: <Element /> },
    { url: "form-wizard", component: <Wizard /> },
    { url: "form-ckeditor", component: <CkEditor /> },
    { url: "form-pickers", component: <Pickers /> },
    { url: "form-validation", component: <FormValidation /> },

    /// table
    { url: "table-datatable-basic", component: <DataTable /> },
    { url: "table-bootstrap-basic", component: <BootstrapTable /> },
    { url: "table-filtering", component: <FilteringTable /> },
    { url: "table-sorting", component: <SortingTable /> },

    /// pages

    { url: "todo", component: <Todo /> },

    { url: "role-akses", component: <RoleAkses /> },
    { url: "tambah-role", component: <TambahRole /> },

    { url: "daftar-pendamping", component: <DaftarPendamping /> },
    { url: ":id/edit-pendamping", component: <EditPendamping /> },
    { url: "verifikasi-pendamping", component: <VerifikasiPendamping /> },
    { url: ":id/detail-pendamping", component: <DetailPendamping /> },
    { url: "daftar-pelajar", component: <DaftarPelajar /> },
    { url: ":id/form-pelajar/:flag?", component: <EditPelajar /> },
    { url: ":id/detail-pelajar/:flag?", component: <DetailPelajar /> },
    { url: "daftar-teman-belajar-rekomendasi", component: <DaftarTemanBelajarRekomendasi /> },
    { url: ":id?/form-teman-belajar/:flag?", component: <FormTemanBelajar /> },
    { url: "daftar-slot-assessment", component: <DaftarSlotAssessment /> },
    { url: ":id?/form-slot-assessment/:flag?", component: <FormSlotAssessment /> },
    { url: "jadwal-assessment", component: <JadwalAssessment /> },
    { url: ":id?/form-assessment/:flag?", component: <FormAssessment /> },
    { url: "kelola-kelas", component: <KelolaKelas /> },
    { url: "jadwal-kelas", component: <JadwalKelas /> },
    { url: ":id?/form-kelas/:flag?", component: <FormKelolaKelas /> },
    { url: ":id?/form-jadwal/:flag?", component: <FormJadwalKelas /> },
    { url: "daftar-peminat-kota-lain", component: <DaftarPeminatKotaLain /> },
    { url: ":id?/form-peminat-kota-lain/:flag?", component: <FormPeminatKotaLain /> },
    { url: "daftar-laporan", component: <DaftarLaporan /> },
    { url: ":id?/:idPeriod?/form-laporan/:flag?", component: <FormLaporan /> },
    { url: ":id?/:idPeriod?/form-laporan-ubah/:flag?", component: <FormLaporanUbah /> },
    { url: "daftar-pencapaian", component: <DaftarPencapaian /> },
    { url: ":id?/pencapaian-siswa-semua", component: <FormPencapaian /> },
    { url: "daftar-buku", component: <DaftarBuku /> },
    { url: ":id?/form-buku/:flag?", component: <FormBuku /> },
    { url: "daftar-paket-buku", component: <DaftarPaketBuku /> },
    { url: ":id?/form-paket-buku/:flag?", component: <FormPaketBuku /> },
    { url: "daftar-pesanan-buku", component: <PesananBuku /> },
    { url: ":id?/form-pesanan-buku/:flag?", component: <FormPesananBuku /> },
    { url: "daftar-donasi", component: <DaftarDonasi /> },
    { url: ":id?/form-donasi", component: <FormDonasi /> },
    { url: "daftar-alokasi-buku", component: <DaftarAlokasiBuku /> },
    { url: ":id?/form-donasi-alokasi-buku/:flag?", component: <FormDonasiAlokasiBukuPelajar /> },
    { url: ":id?/daftar-alokasi-buku-pelajar/:flag?", component: <DaftarAlokasiBukuPelajar /> },
    { url: "daftar-alokasi-pengeluaran-infaq", component: <DaftarAlokasiPengeluaranInfaq /> },
    { url: ":id?/tambah-pengeluaran/:flag?", component: <TambahPengeluaran /> },
    { url: "daftar-inbox", component: <DaftarInbox /> },
    { url: ":id?/daftar-form-inbox/:flag?", component: <DaftarFormInbox /> },
    { url: "daftar-inbox-config", component: <DaftarInboxConfig /> },
    { url: ":id?/form-inbox-config/:flag?", component: <FormInbox /> },
    { url: "daftar-cerita-hikmah", component: <DaftarCeritaHikmah /> },
    { url: ":id?/form-cerita-hikmah/:flag?", component: <FormCeritaHikmah /> },
    { url: "daftar-materi", component: <DaftarMateri /> },
    { url: "daftar-konten", component: <DaftarKonten /> },
    { url: "form-konten/:id?/:flag?", component: <FormKonten /> },
    { url: "tentang-yayasan-dan-aplikasi", component: <TentangYayasanAplikasi /> },
    { url: ":id?/form-tentang/:flag?", component: <FormTentang /> },
    { url: "daftar-panduan", component: <DaftarPanduan /> },
    { url: ":id?/form-panduan/:flag?", component: <FormPanduan /> },
    { url: "daftar-saran", component: <DaftarSaran /> },
    { url: "daftar-master-data", component: <DaftarMasterData /> },
    { url: ":id?/form-master-data/:flag?", component: <FormMasterData /> },
    { url: "master-data-periode", component: <DaftarPeriode /> },
    { url: ":id?/form-master-data-periode/:flag?", component: <FormPeriode /> },
    { url: "master-data-config", component: <DaftarMasterDataConfig /> },
    { url: ":id?/form-config-master-data/:flag?", component: <TambahConfigMasterData /> },
    { url: "daftar-provinsi", component: <DaftarProvinsiKabupaten /> },
    { url: ":id?/form-provinsi-kabupaten/:flag?", component: <TambahProvinsiKabupaten /> },
    { url: "daftar-kabupaten", component: <DaftarKabupaten /> },
    { url: ":id?/form-kabupaten/:flag?", component: <FormKabupaten /> },
    { url: "daftar-role", component: <DaftarRole /> },
    { url: "form-role/:id?/:flag?", component: <FormRole /> },
    { url: "daftar-pengguna", component: <DaftarPengguna /> },
    { url: "form-pengguna/:id?/:flag?", component: <FormPengguna /> },
    { url: "daftar-fitur", component: <DaftarModule /> },
    { url: "form-fitur/:id?/:flag?", component: <FormModule /> },
    { url: "set-Set-or-copy-progress", component: <SetProgressModul /> },

    { url: "verifikasi-pelajar", component: <VerifikasiPelajar /> },
  ];

  return (
    <>
      {/* <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: window.screen.height - 60 }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div> */}
      <Routes>
        <Route path="page-lock-screen" element={<LockScreen />} />
        <Route path="page-error-400" element={<Error400 />} />
        <Route path="page-error-403" element={<Error403 />} />
        <Route path="page-error-404" element={<Error404 />} />
        <Route path="page-error-500" element={<Error500 />} />
        <Route path="page-error-503" element={<Error503 />} />
        {/* <Route path='/*' element={<Error404/>} /> */}
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route key={i} exact path={`${data.url}`} element={data.component} />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};
function MainLayout() {
  // const { menuToggle } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      // className={`show ${ menuToggle ? "menu-toggle" : ""}`}
      className="show"
    >
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
