import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import Select from "react-select";
import DataTable from "react-data-table-component";
const FormInbox = () => {
  const location = useLocation();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const { id, flag } = useParams();
  const [validated, setValidated] = useState(false);
  const formRef = useRef(null);
  const [sender, setSender] = useState([]);
  const [klasifikasi, setKlasifikasi] = useState([]);
  const [link, setLink] = useState([]);
  const [event, setEvent] = useState([]);
  const [subEvent, setSubEvent] = useState([]);
  const [tipePesan, setTipePesan] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    sender: "",
    event: "",
    classification: "",
    notification: 0,
    sub_event: "",
    message_type: "",
    link_to: "",
    button_message: "",
    image: "",
    syarat: "",
    code: "",
  });
  const [editFormData, setEditFormData] = useState({
    title: "",
    content: "",
    sender: "",
    event: "",
    classification: "",
    notification: "",
    sub_event: "",
    message_type: "",
    link_to: "",
    button_message: "",
    image: "",
    syarat: "",
    code: "",
  });
  const fileInputRef = useRef(null);
  const fileSizeLimit = 2 * 1024 * 1024;
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [isNewFileSelected, setIsNewFileSelected] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const fetchData = async () => {
    const res = await GET(`/inbox-config/${id}`);
    setEditFormData({
      title: res?.data?.title,
      content: res?.data?.content,
      sender: res?.data?.sender?.description,
      classification: res?.data?.classification?.description,
      sub_event: res?.data?.sub_event?.id,
      event: res?.data?.sub_event?.event?.id,
      notification: res?.data?.notification,
      message_type: res?.data?.message_type?.description,
      link_to: res?.data?.link_to?.id,
      button_message: res?.data?.button_message,
      image: res?.data?.image?.id,
      code: res?.data?.code,
      syarat: res?.data?.conditions,
    });
  };
  const fetchSender = async () => {
    const res = await GET(`master-data/type?type=Sender`);
    setSender(res?.data);
  };
  const fetchKlasifikasi = async () => {
    const res = await GET(`master-data/type?type=Klasifikasi`);
    setKlasifikasi(res?.data);
  };
  const fetchLink = async () => {
    const res = await GET(`master-data/type?type=Link Halaman`);
    setLink(res?.data);
  };

  const fetchEvent = async () => {
    const res = await GET(`master-data/type?type=Event`);
    setEvent(res?.data);
  };
  const fetchSubEvent = async (event) => {
    const res = await GET(`master-data/parent/${event}`);
    setSubEvent(res?.data);
  };
  const fetchTipePesan = async () => {
    const res = await GET(`master-data/type?type=Tipe Pesan`);
    setTipePesan(res?.data);
  };
  useEffect(() => {
    if (flag === "Detail") {
      fetchData();
    }
    if (flag === "Ubah") {
      fetchData();
    }
    fetchSender();
    fetchKlasifikasi();
    fetchLink();
    fetchEvent();
    fetchTipePesan();
  }, []);

  useEffect(() => {
    if (formData?.event) {
      fetchSubEvent(formData.event);
    }
    if (editFormData?.event) {
      fetchSubEvent(editFormData.event);
    }
  }, [formData.event, editFormData.event]);

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "event") {
      editFormData.sub_event = "";
    }
    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "message_type") {
      setFormData((prevData) => ({
        ...prevData,
        link_to: "",
        button_message: "",
        image: "",
      }));
    }
    if (name === "event") {
      formData.sub_event = "";
    }
    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      notification: checked ? 1 : 0,
    }));
  };
  const handleCheckboxEdit = (e) => {
    const checked = e.target.checked;
    setEditFormData((prevData) => ({
      ...prevData,
      notification: checked ? 1 : 0,
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > fileSizeLimit) {
        return Swal.fire({
          icon: "error",
          title: `Ukuran file melebihi batas maksimum (2 MB)`,
        });
      }
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
      if (!allowedTypes.includes(selectedFile.type)) {
        return Swal.fire({
          icon: "error",
          title: `File harus dalam format (jpg, jpeg, png, gif)`,
        });
      }
      setFile(selectedFile);
      setValidated(true);
      setIsNewFileSelected(true); // Set the flag when a new file is selected
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan perubahan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });
    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      if (file) {
        const formDataObject = new FormData();
        formDataObject.append("type", "image");
        formDataObject.append("file", file);

        const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });
        formData.image = fileUploadResponse?.file?.id || fileUploadResponse?.file?.url;
      }
      const requestBody = {
        title: formData.title,
        content: formData.content,
        sender: formData.sender,
        classification: formData.classification,
        notification: formData.notification,
        sub_event: formData.sub_event,
        message_type: parseInt(formData.message_type),
        link_to: formData.link_to,
        button_message: formData.button_message,
        image: formData.image,
        conditions: formData.syarat,
        code: formData.code,
      };
      const res = await POST("/inbox-config", requestBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Berhasil membuat Inbox!",
        }).then(() => {
          Swal.close();
          router("/daftar-inbox-config");
        });
      }
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    console.log("formData config", formData);
    const { title, content, sender, classification, notification, sub_event, message_type, link_to, button_message, image, code, syarat } = editFormData;
    const requestBody = {
      title,
      content,
      sender,
      classification,
      notification,
      sub_event,
      message_type: Number(message_type),
      link_to,
      button_message,
      image,
      code,
      conditions: syarat,
    };
    console.log("body config", requestBody);
    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan Perubahan",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });
    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      if (file) {
        const formDataObject = new FormData();
        formDataObject.append("type", "image");
        formDataObject.append("file", file);

        const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });
        requestBody.image = fileUploadResponse?.file?.id || fileUploadResponse?.file?.url;
      }

      const res = await PATCH(`/inbox-config/${id}`, requestBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Data Config Berhasil diubah!",
        }).then(() => {
          Swal.close();
          fetchData();
          router("/daftar-inbox-config");
        });
      }
    }
  };
  const handleDeleteImage = () => {
    setImagePreviewUrl(null); // Clear the preview URL
    setFile(null); // Clear the file
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the input field
    }
  };
  return (
    <Fragment>
      <PageTitle activeMenu={`${flag} Inbox Config`} motherMenu="Daftar Inbox Config" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Inbox Config</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/daftar-inbox-config`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {flag === "Tambah" ? (
                  <form className="form-valide" ref={formRef} onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>
                            Kode<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="code" value={formData.code} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                          <label>
                            Judul Pesan<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="title" value={formData.title} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                          <label>
                            Isi Pesan<span className="text-danger">*</span>
                          </label>
                          <textarea name="content" className="form-control" rows="5" value={formData.content} onChange={handleChange} maxLength="400" required />
                        </div>
                        <div className="form-group">
                          <label>
                            Sender<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="sender" value={formData.sender} onChange={handleChange} required>
                            <option value="">Pilih Sender</option>
                            {Array.isArray(sender) &&
                              sender.map((sende) => (
                                <option key={sende.id} value={sende.description}>
                                  {sende.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Klasifikasi<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="classification" value={formData.classification} onChange={handleChange} required>
                            <option value="">Pilih Klasifikasi</option>
                            {Array.isArray(klasifikasi) &&
                              klasifikasi.map((klasi) => (
                                <option key={klasi.id} value={klasi.description}>
                                  {klasi.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Event<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="event" value={formData.event} onChange={handleChange} required>
                            <option value="">Pilih Event</option>
                            {Array.isArray(event) &&
                              event.map((vent) => (
                                <option key={vent.id} value={vent.id}>
                                  {vent.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Sub Event<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="sub_event" value={formData.sub_event} onChange={handleChange} required>
                            <option value="">Pilih Sub Event</option>
                            {Array.isArray(subEvent) &&
                              subEvent.map((subevent) => (
                                <option key={subevent.id} value={subevent.id}>
                                  {subevent.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Syarat<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="syarat" value={formData.syarat} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                          <input type="checkbox" checked={formData.notification === 1} onChange={handleCheckboxChange} />
                          <label className="ml-2">muncul di inbox? </label>
                        </div>
                        {/* <div className="form-group">
                                                    <label>Syarat<span className="text-danger">*</span></label>
                                                    <input
                                                    className="form-control"
                                                    name="syarat"
                                                    required
                                                    />
                                                </div> */}
                        <div className="form-group">
                          <label>
                            Tipe Pesan<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="message_type" value={formData.message_type} onChange={handleChange} required>
                            <option value="">Pilih Tipe Pesan</option>
                            {Array.isArray(tipePesan) &&
                              tipePesan.map((tipes) => (
                                <option key={tipes.id} value={tipes.description}>
                                  {tipes.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {formData.message_type === "2" && (
                          <div className="form-group">
                            <label>
                              Link To<span className="text-danger">*</span>
                            </label>
                            <select className="form-control" name="link_to" value={formData.link_to} onChange={handleChange} required>
                              <option value="">Pilih Link</option>
                              {Array.isArray(link) &&
                                link.map((lint) => (
                                  <option key={lint.id} value={lint.id}>
                                    {lint.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )}
                        {formData.message_type === "2" && (
                          <div className="form-group">
                            <label>
                              Button Message<span className="text-danger">*</span>
                            </label>
                            <input className="form-control" name="button_message" value={formData.button_message} onChange={handleChange} required />
                          </div>
                        )}
                        {formData.message_type === "3" && (
                          <div className="form-group">
                            <label>
                              Gambar <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <button type="button" className="btn btn-primary" onClick={() => fileInputRef.current.click()}>
                                Pilih File
                              </button>
                              <input
                                type="file"
                                ref={fileInputRef}
                                className="d-none"
                                //name='image'
                                accept=".jpg, .jpeg, .png, .gif"
                                onChange={handleFileChange}
                                // isInvalid={file === null}
                                //className="d-none"
                                //placeholder="Upload Gambar"
                                //required
                              />
                              {imagePreviewUrl && isNewFileSelected && (
                                <div className="mt-3">
                                  <div className="d-flex flex-column align-items-center">
                                    <Form.Label className="mb-2">Preview</Form.Label>
                                    <img src={imagePreviewUrl} alt="Gambar Preview" className="img-fluid rounded" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                                    <span className="input-group-text">{file ? (file.name.length > 25 ? `${file.name.slice(0, 25)}` : file.name) : "Tidak ada file yang dipilih..."}</span>
                                  </div>
                                </div>
                              )}
                              {imagePreviewUrl && (
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm" // Smaller button
                                  onClick={handleDeleteImage}
                                  style={{
                                    padding: "0.25rem 0.5rem",
                                    fontSize: "0.75rem",
                                    marginLeft: "10px",
                                    width: "80px", // Adjust width to make it compact
                                  }}
                                >
                                  Hapus
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-inbox-config`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100">
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === "Ubah" ? (
                  <form className="form-valide" ref={formRef} onSubmit={handleEditSubmit}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>
                            Kode<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="code" value={editFormData.code} onChange={handleChangeEdit} required />
                        </div>
                        <div className="form-group">
                          <label>
                            Judul Pesan<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="title" value={editFormData.title} onChange={handleChangeEdit} required />
                        </div>
                        <div className="form-group">
                          <label>
                            Isi Pesan<span className="text-danger">*</span>
                          </label>
                          <textarea name="content" className="form-control" value={editFormData.content} onChange={handleChangeEdit} required />
                        </div>
                        <div className="form-group">
                          <label>
                            Sender<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="sender" value={editFormData.sender} onChange={handleChangeEdit} required>
                            <option value="">Pilih Sender</option>
                            {Array.isArray(sender) &&
                              sender.map((sende) => (
                                <option key={sende.id} value={sende.description}>
                                  {sende.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Klasifikasi<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="classification" value={editFormData.classification} onChange={handleChangeEdit} required>
                            <option value="">Pilih Klasifikasi</option>
                            {Array.isArray(klasifikasi) &&
                              klasifikasi.map((klasik) => (
                                <option key={klasik.id} value={klasik.description}>
                                  {klasik.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Event<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="event" value={editFormData.event} onChange={handleChangeEdit} required>
                            <option value="">Pilih Event</option>
                            {Array.isArray(event) &&
                              event.map((vent) => (
                                <option key={vent.id} value={vent.id}>
                                  {vent.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Sub Event<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="sub_event" value={editFormData.sub_event} onChange={handleChangeEdit} required>
                            <option value="">Pilih Sub Event</option>
                            {Array.isArray(subEvent) &&
                              subEvent.map((subvent) => (
                                <option key={subvent.id} value={subvent.id}>
                                  {subvent.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Syarat<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="syarat" value={editFormData.syarat} onChange={handleChangeEdit} required />
                        </div>
                        <div className="form-group">
                          <input type="checkbox" checked={editFormData.notification === 1} onChange={handleCheckboxEdit} />
                          <label className="ml-2">Muncul di Inbox? </label>
                        </div>
                        <div className="form-group">
                          <label>
                            Tipe Pesan<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="message_type" value={editFormData.message_type} onChange={handleChangeEdit} required>
                            <option value="">Pilih Tipe Pesan</option>
                            {Array.isArray(tipePesan) &&
                              tipePesan.map((tipes) => (
                                <option key={tipes.id} value={tipes.description}>
                                  {tipes.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {editFormData.message_type === "2" && (
                          <div className="form-group">
                            <label>
                              Link To<span className="text-danger">*</span>
                            </label>
                            <select className="form-control" name="link_to" value={editFormData.link_to} onChange={handleChangeEdit} required>
                              <option value="">Pilih Link</option>
                              {Array.isArray(link) &&
                                link.map((lint) => (
                                  <option key={lint.id} value={lint.id}>
                                    {lint.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )}
                        {editFormData.message_type === "2" && (
                          <div className="form-group">
                            <label>
                              Button Message<span className="text-danger">*</span>
                            </label>
                            <input className="form-control" name="button_mesaage" value={editFormData.button_message} onChange={handleChangeEdit} required />
                          </div>
                        )}
                        {editFormData.message_type === "3" && (
                          <div className="form-group">
                            <label>
                              Gambar <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              {!isNewFileSelected && editFormData && editFormData?.image ? (
                                <div>
                                  {editFormData?.image ? (
                                    <img src={`${process.env.REACT_APP_API_BASE_URL}files/${editFormData.image}/download`} alt="image" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                                  ) : (
                                    <p>Tidak ada foto gambar</p>
                                  )}
                                </div>
                              ) : null}
                              <div>
                                <div className="input-group">
                                  <button type="button" className="btn btn-primary my-2" onClick={() => fileInputRef.current.click()}>
                                    Ganti Foto
                                  </button>
                                </div>
                                <input type="file" ref={fileInputRef} className="d-none" onChange={handleFileChange} accept=".jpg, .jpeg, .png, .gif" />
                                {imagePreviewUrl && (
                                  <button
                                    type="button"
                                    className="btn btn-danger" // Smaller button
                                    onClick={handleDeleteImage}
                                  >
                                    Hapus
                                  </button>
                                )}
                              </div>
                            </div>
                            {imagePreviewUrl && isNewFileSelected && (
                              <div className="mt-3">
                                <div className="d-flex flex-column align-items-center">
                                  <Form.Label className="mb-2">Preview</Form.Label>
                                  <img src={imagePreviewUrl} alt="Gambar Preview" className="img-fluid rounded" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                                  <span className="input-group-text">{file ? (file.name.length > 25 ? `${file.name.slice(0, 25)}` : file.name) : "Tidak ada file yang dipilih..."}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-inbox-config`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100">
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === "Detail" ? (
                  <form className="form-valide" ref={formRef}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>
                            Kode<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="code" value={editFormData.code} onChange={handleChangeEdit} disabled />
                        </div>
                        <div className="form-group">
                          <label>
                            Judul Pesan<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="title" value={editFormData.title} onChange={handleChangeEdit} disabled required />
                        </div>
                        <div className="form-group">
                          <label>
                            Isi Pesan<span className="text-danger">*</span>
                          </label>
                          <textarea name="content" className="form-control" value={editFormData.content} onChange={handleChangeEdit} disabled required />
                        </div>
                        <div className="form-group">
                          <label>
                            Sender<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" value={editFormData.sender} name="sender" onChange={handleChangeEdit} disabled required>
                            <option value="">Pilih Sender</option>
                            {Array.isArray(sender) &&
                              sender.map((sende) => (
                                <option key={sende.id} value={sende.description}>
                                  {sende.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Klasifikasi<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="classification" value={editFormData.classification} onChange={handleChangeEdit} disabled required>
                            <option value="">Pilih Klasifikasi</option>
                            {Array.isArray(klasifikasi) &&
                              klasifikasi.map((klasik) => (
                                <option key={klasik.id} value={klasik.description}>
                                  {klasik.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Event<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="event" value={editFormData.event} onChange={handleChangeEdit} disabled required>
                            <option value="">Pilih Event</option>
                            {Array.isArray(event) &&
                              event.map((vent) => (
                                <option key={vent.id} value={vent.id}>
                                  {vent.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Sub Event<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="sub_event" value={editFormData.sub_event} onChange={handleChangeEdit} disabled required>
                            <option value="">Pilih Sub Event</option>
                            {Array.isArray(subEvent) &&
                              subEvent.map((subvent) => (
                                <option key={subvent.id} value={subvent.id}>
                                  {subvent.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Syarat<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="syarat" value={editFormData.syarat} onChange={handleChangeEdit} required />
                        </div>
                        <div className="form-group">
                          {/* <input type="checkbox"/> */}
                          <input type="checkbox" checked={editFormData.notification === 1} onChange={handleCheckboxEdit} disabled />
                          <label className="ml-2">Muncul di Inbox? </label>
                        </div>
                        {/* <div className="form-group">
                                                    <label>Syarat<span className="text-danger">*</span></label>
                                                    <input
                                                    className="form-control"
                                                    name="syarat"
                                                    required
                                                    />
                                                </div> */}
                        <div className="form-group">
                          <label>
                            Tipe Pesan<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="message_type" value={editFormData.message_type} onChange={handleChangeEdit} disabled required>
                            <option value="">Pilih Tipe Pesan</option>
                            {Array.isArray(tipePesan) &&
                              tipePesan.map((tipe) => (
                                <option key={tipe.id} value={tipe.description}>
                                  {tipe.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Link To<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="link_to" value={editFormData.link_to} onChange={handleChangeEdit} disabled required>
                            <option value="">Pilih Link</option>
                            {Array.isArray(link) &&
                              link.map((lint) => (
                                <option key={lint.id} value={lint.id}>
                                  {lint.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Button Message<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="button_mesaage" value={editFormData.button_message} onChange={handleChangeEdit} disabled required />
                        </div>
                        <div className="form-group">
                          <label>
                            Gambar <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            {!isNewFileSelected && editFormData && editFormData?.image ? (
                              <div>
                                {editFormData?.image ? (
                                  <img src={`${process.env.REACT_APP_API_BASE_URL}files/${editFormData.image}/download`} alt="image" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                                ) : (
                                  <p>Tidak ada foto gambar</p>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormInbox;
